import { useState, useContext, useRef } from 'react';
import SpordleTableProvider from '@spordle/datatables';
import { Link } from 'react-router-dom';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { displayI18n, DisplayI18n } from '../../../helpers/i18nHelper';
import ImgPill from '../../../components/visual/imgPill/ImgPill';
import Translate from '@spordle/intl-elements';
import { useIntl } from 'react-intl';
import * as Sentry from "@sentry/react";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { stringBuilder } from "@spordle/helpers";
import { fail } from '@spordle/toasts';
import getReferrer from '../../../helpers/getReferer';
import { stringifyUrl } from 'query-string';
import { AuthContext } from '../../../contexts/AuthContext';

const SearchOrg = ({ id, className, organisationId, pagination = 20, placeholder = 'header.searchorg.placeholder' }) => {
    const organizationSearch = useRef(null);
    const auth = useContext(AuthContext);
    const orgContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);
    const intl = useIntl();
    const [ isFocused, setIsFocused ] = useState(false);
    const [ searchOpen, setSearchOpen ] = useState(false);

    return (
        <div className={stringBuilder(className, "page-main-nav-search", { "is-open": searchOpen }, { "is-focused": isFocused })}>
            { orgContext.federation &&
                <div
                    tabIndex='1'
                    className="search-org-input-container"
                    onBlur={(e) => !e.currentTarget.contains(e.relatedTarget) && setIsFocused(false)}
                >
                    <SpordleTableProvider
                        id={id}
                        searchLimit={20}
                        pagination={pagination}
                        dataIndex='organisation_id'
                        viewMode='GRID'
                        searchKeys={[
                            `i18n.${i18nContext.getGenericLocale()}.name`,
                            'organisation_name',
                            'abbreviation',
                            'city',
                        ]}
                        loadData={(from) => {
                            switch (from){
                                case 'CDM':
                                    // get all the categories
                                    return orgContext.getOrganizationCategories(orgContext.federation.organisation_id, { show_public: 1 })
                                        .then(async(_categories) => {
                                            // find branch
                                            let _branchCategory;
                                            const _otherCategories = [];
                                            const orgsArray = [];

                                            // Avoids multiple loops + removes member branches from second call (avoids duplicates)
                                            _categories.forEach((_cat) => {
                                                if(_cat.default_name === 'Member branches'){
                                                    // So branch categories are first
                                                    _branchCategory = _cat;
                                                }else if(_cat.default_name === 'NSO'){
                                                    // if NSO is not active, we shouldn't show Hockey Canada
                                                    orgsArray.push({
                                                        'organisation_id': orgContext.federation.organisation_id,
                                                        'organisation_name': orgContext.federation.organisation_name,
                                                        'logo': orgContext.federation.logo,
                                                        'i18n': orgContext.federation.i18n,
                                                        'abbreviation': orgContext.federation.abbreviation,
                                                        'country_code': orgContext.federation.country_code,
                                                        'province_code': orgContext.federation.province_code,
                                                        'city': orgContext.federation.city,
                                                        'short_url': orgContext.federation.short_url,
                                                        'short_name': orgContext.federation.short_name,
                                                        'deploy_on': orgContext.federation.deploy_on,
                                                    });
                                                }else{
                                                    // All other categories
                                                    _otherCategories.push(_cat.category_id);
                                                }
                                            });


                                            // get all the organizations that needs to be shown in the select
                                            const _orgsToShow = await orgContext.getCategoryOrganizations(organisationId ?? orgContext.federation.organisation_id, _otherCategories)
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                });

                                            if(_branchCategory){
                                                // no need to filter the categories, we send show_public to the API which returns the ones we want to show
                                                const _branchOrgs = await orgContext.getCategoryOrganizations(organisationId ?? orgContext.federation.organisation_id, [ _branchCategory.category_id ])
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    });

                                                // sort the branches by alphabetical order
                                                _branchOrgs.sort((orgA, orgB) => {
                                                    const valueA = displayI18n('name', orgA.i18n, orgA.organisation_name, i18nContext.getGenericLocale())
                                                    const valueB = displayI18n('name', orgB.i18n, orgB.organisation_name, i18nContext.getGenericLocale())
                                                    return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueA, valueB);
                                                });

                                                // add them to the array
                                                orgsArray.pushArray(_branchOrgs);
                                            }

                                            // sorting the other organizations is not necessary because the list only shows 20 elements, and we display the branches first
                                            orgsArray.pushArray(_orgsToShow);
                                            return orgsArray;
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                console.error(e.message);
                                            }
                                        });
                                default:
                                    break;
                            }
                        }}
                    >
                        { (spordleTable) => (
                            <div className="search-org-input-container-inner">
                                <button
                                    type="button" className="search-org-input-btn is-search" onClick={() => {
                                        Sentry.addBreadcrumb({
                                            type: 'info',
                                            message: 'Opening search org',
                                            level: Sentry.Severity.Log,
                                            category: 'info',
                                        });
                                        organizationSearch.current?.focus();
                                    }}
                                    id={spordleTable.generateId('main-search', 'button')}
                                >
                                    {/* <i className="ti-search font-16 mx-0 mx-lg-1" /> */}
                                    <i className="text-muted fas fa-search font-16 pb-1 mx-0 mx-lg-1" />
                                </button>
                                <input
                                    ref={organizationSearch}
                                    onFocus={() => setIsFocused(true)}
                                    onChange={spordleTable.searchInputChange}
                                    className="search-org-input"
                                    type="search"
                                    value={spordleTable.getInputValue()}
                                    id={spordleTable.generateId('main-search', 'input')}
                                    placeholder={intl.formatMessage({ id: placeholder })}
                                    autoComplete="new-password"
                                />
                                {isFocused && !!spordleTable.getInputValue() &&
                                    <button
                                        type='button'
                                        id={spordleTable.generateId('main-search', 'clear')}
                                        className="search-org-input-btn"
                                        onClick={() => {
                                            Sentry.addBreadcrumb({
                                                type: 'info',
                                                message: 'Clearing search input',
                                                level: Sentry.Severity.Log,
                                                category: 'info',
                                            });
                                            spordleTable.setInputValue('');
                                            organizationSearch.current?.focus();
                                        }}
                                    >
                                        <i className="mdi mdi-close" />
                                    </button>
                                }
                                {isFocused &&
                                        <div className={`search-org-results-container ${isFocused ? 'is-shown' : 'is-hidden'}`}>
                                            <ul className="list-unstyled mb-0">
                                                { spordleTable.dataToDisplay?.length > 0 ?
                                                    spordleTable.dataToDisplay.map((org) => (
                                                        <li key={org.organisation_id} id={spordleTable.generateId('org', org.organisation_id)} className="search-org-result">
                                                            <ImgPill
                                                                className='mr-2'
                                                                innerClassName='img-pill-inner-100'
                                                                size='sm'
                                                                src={org.logo?.full_path}
                                                                alt={`Logo ${org.organisation_name}`}
                                                                abbr={org.organisation_name}
                                                            />
                                                            <div className="d-inline-block">
                                                                <div className='d-flex'>
                                                                    <h3 className="h5 mb-0 font-weight-bold">
                                                                        <DisplayI18n
                                                                            field='name'
                                                                            i18n={org.i18n}
                                                                            defaultValue={org.organisation_name}
                                                                        />
                                                                    </h3>
                                                                    {
                                                                        org?.abbreviation &&
                                                                        <div className='text-muted h5 mb-0 pl-2'>({org.abbreviation})</div>
                                                                    }
                                                                </div>
                                                                <div className="small text-muted">
                                                                    {(org.city || org.province_code || org.country_code) && <i className="mdi mdi-map-marker text-primary" />}
                                                                    {org.city && `${org.city}, `}{org.province_code && `${org.province_code}, `}{org.country_code && `${org.country_code}`}
                                                                </div>
                                                            </div>
                                                            {org.deploy_on == 'PAGE' ?
                                                                <a
                                                                    className="stretched-link"
                                                                    rel="noreferrer"
                                                                    href={stringifyUrl({
                                                                        url: `${getReferrer('PAGE-VERCEL-2')}/${i18nContext.getGenericLocale()}/${org.short_name}`,
                                                                        query: {
                                                                            accessToken: auth.accessToken,
                                                                        },
                                                                    }, { skipNull: true, skipEmptyString: true })}
                                                                />
                                                                : org.deploy_on == 'VERCEL' ?
                                                                    <a
                                                                        className="stretched-link"
                                                                        rel="noreferrer"
                                                                        href={stringifyUrl({
                                                                            url: `${getReferrer('PAGE-VERCEL')}/${i18nContext.getGenericLocale()}/${org.short_name}`,
                                                                            query: {
                                                                                accessToken: auth.accessToken,
                                                                            },
                                                                        }, { skipNull: true, skipEmptyString: true })}
                                                                    />
                                                                    :
                                                                    <Link
                                                                        onClick={() => {
                                                                            setSearchOpen(false);
                                                                            setIsFocused(false);
                                                                        }}
                                                                        className="stretched-link"
                                                                        to={`/page/${org.short_url}`}
                                                                    />
                                                            }
                                                        </li>
                                                    ))
                                                    :
                                                    <li className="search-org-no-result">
                                                        <Translate id='misc.noResults' />
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                }
                            </div>
                        )}
                    </SpordleTableProvider>
                </div>
            }
        </div>
    )
}

export default SearchOrg;