/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import {useRef,useCallback,useEffect,Fragment}from'react';import {useSpordleTable}from'@spordle/datatables';import {useIntl,IntlProvider,FormattedMessage}from'react-intl';import {useForceUpdate,getMessage}from'./utils.js';import ResizeObserver from'resize-observer-polyfill';import messages from'../src/i18n.js';import {stringBuilder}from'@spordle/helpers';import {DummySearchInput}from'../styles/stitches.js';import {Container,ContainerInner,ContainerValues,SearchInput,Icons,Icon,loadingIcon,Chevron,HiddenSearchInput,StyledClearButton,icon,ContainerValuesSingle,Splitter}from'../styles/stitchesSelector.js';import {optionPlaceholder}from'../styles/stitchesOptions.js';const Selector = ({ searchable, id, name, isDisabled, toggle, options, renderSelectedOption, valueSplitter = ',', renderOption, autoFocus, placeholder, skipPlaceholderTranslate, isLoading, textWhenSetting, showClear, inputClassName, isOpen, clearSelected, isMulti, activeOptions, selectorContainerRef, isMobile, }) => {
    const spordleTable = useSpordleTable();
    const intl = useIntl();
    const inputRef = useRef(null);
    const containerRef = useRef(null);
    const selectorRef = useRef(null);
    const missingOptionForValue = useRef(new Set());
    const forceUpdate = useForceUpdate();
    const reziseObserver = useRef(new ResizeObserver(forceUpdate));
    function parseTextWhenRatio(ratio) {
        if (selectorRef.current) {
            const [count, width] = ratio.split('/');
            return Math.max(Math.ceil(selectorRef.current.getBoundingClientRect().width / parseInt(width)) * parseInt(count), 1);
        }
        return 1;
    }
    const isTextWhen = useCallback(() => {
        return isMulti ? textWhenSetting ? typeof textWhenSetting.count === 'string' ? parseTextWhenRatio(textWhenSetting.count) : textWhenSetting.count : parseTextWhenRatio('1/200') : false;
    }, [isMulti, textWhenSetting]);
    const hasLoadError = spordleTable.state.loadingState === 'error';
    useEffect(() => {
        if (!isLoading && !hasLoadError && autoFocus && !isMobile) { // Manual autoFocus -> we don't want the autoFocus on the input
            if (searchable) {
                inputRef.current?.focus();
            }
            else {
                containerRef.current?.focus();
            }
        }
    }, [isLoading]);
    useEffect(() => {
        if (selectorRef.current) {
            reziseObserver.current.disconnect();
            reziseObserver.current.observe(selectorRef.current);
        }
        return () => {
            reziseObserver.current.disconnect();
        };
    }, [selectorRef]);
    const getValueField = (optionValue, index) => {
        const option = spordleTable.getData().find((tableData) => tableData.value === optionValue);
        if (process.env.NODE_ENV !== 'development' && !option && !missingOptionForValue.current.has(optionValue)) {
            missingOptionForValue.current.add(optionValue);
        }
        return option && (jsxs(Fragment, { children: [jsx(ContainerValuesSingle, { className: "spordleSelect-input-container-value-single", "data-testid": `selectedOption-${option.value}`, children: renderSelectedOption?.(option)
                        || renderOption?.({ option, isDisabled: false, isSelected: true }, true)
                        || (option.translateLabel ? intl.formatMessage({ id: option.label }) : option.label) }), index < options.length - 1 &&
                    jsx(Splitter, { className: "spordleSelect-option-splitter", "data-testid": 'selectedOptions-splitter', children: valueSplitter })] }, option.value));
    };
    const showValues = useCallback(() => {
        if (typeof isTextWhen() === 'number' && options.length >= isTextWhen()) { // Must check typeof -> 2 > false = true
            if (textWhenSetting?.label) {
                switch (typeof textWhenSetting.label) {
                    case 'string':
                        return intl.formatMessage({ id: textWhenSetting.label }, { count: options.length });
                    default:
                        return jsx(textWhenSetting.label, { count: options.length });
                }
            }
            const locale = intl.locale.split(/(-|_)/)[0];
            return (
            // @ts-ignore
            jsx(IntlProvider, { locale: locale, messages: messages[locale], children: jsx(FormattedMessage, { id: 'textWhen', values: { count: options.length }, defaultMessage: messages.en.textWhen, description: 'Default textWhen message defined within SpordleSelect' }) }));
        }
        missingOptionForValue.current.clear();
        const mappedOptions = options.map(getValueField);
        if (missingOptionForValue.current.size) {
            console.warn(`SpordleSelect: The value(s) [${Array.from(missingOptionForValue.current)}] do not have a matching option`);
        }
        return mappedOptions;
    }, [options, isTextWhen()]);
    const inputValue = spordleTable.getInputValue();
    const handleToggleFocus = (e) => {
        if (isMobile) {
            if (!isLoading && !hasLoadError)
                toggle();
        }
        else {
            (inputRef.current || containerRef.current)?.focus();
            toggle();
        }
    };
    const placeHolder = placeholder ? skipPlaceholderTranslate ? placeholder : intl.formatMessage({ id: placeholder }) : getMessage(searchable ? 'input.placeholder' : 'select', intl.locale);
    return (searchable ?
        jsxs(Container, { "data-testid": 'selector', ref: (r) => {
                // @ts-ignore
                containerRef.current = r;
                // @ts-ignore
                selectorContainerRef.current = r;
            }, id: spordleTable.generateId('selector'), className: stringBuilder({ 'error': hasLoadError }, inputClassName, "spordleSelect-input-container searchable form-control"), onClick: handleToggleFocus, onBlur: isLoading || hasLoadError ? undefined : (e) => {
                switch (e.relatedTarget) { // Prevent the onBlur event from triggering when either the input or the container
                    case e.currentTarget:
                    case inputRef.current:
                        e.stopPropagation();
                        break;
                }
            }, "data-disabled": isDisabled, "data-name": name, tabIndex: isMobile ? undefined : (isLoading || hasLoadError) ? 0 : -1, children: [jsxs(ContainerInner, { ref: selectorRef, className: "spordleSelect-input-container-inner", children: [jsx(ContainerValues, { className: stringBuilder("spordleSelect-input-container-values", { [`spordleSelect-placeholder ${optionPlaceholder}`]: isLoading || options.length === 0 }), "data-testid": 'selectedOption-container', children: isLoading ?
                                placeHolder
                                :
                                    hasLoadError ?
                                        getMessage('error', intl.locale)
                                        :
                                            isMobile && options.length === 0 ? // There's no input on mobile because of focus issues on ios (scroll to and zooms), we need to show the placeholder.
                                                inputValue.substring(2, inputValue.length - 1) || placeHolder
                                                :
                                                    !inputValue && showValues() /* Placeholder is manage by the input */ }), !isLoading && !hasLoadError &&
                            jsx(SearchInput, { type: "search", as: isMobile ? 'div' : 'input', className: "spordleSelect-input", disabled: isDisabled, placeholder: options.length !== 0 ? undefined : placeHolder, onChange: (e) => {
                                    if (!isOpen) {
                                        toggle(); // When typing, we want to make sure the dropdown menu is open
                                    }
                                    spordleTable.setInputValue(e.target.value ? `'"${e.target.value}"` : '', false);
                                }, size: 1, ref: inputRef, value: inputValue.substring(2, inputValue.length - 1), autoComplete: 'new-password', autoCorrect: 'false', autoCapitalize: 'false', 
                                // SR
                                role: 'combobox', "aria-label": placeHolder, "aria-placeholder": placeHolder, "aria-autocomplete": 'list', "aria-controls": spordleTable.generateId('options', 'container'), "aria-owns": spordleTable.generateId('options', 'container'), "aria-haspopup": true, "aria-expanded": isOpen, "aria-activedescendant": activeOptions.id, "data-testid": 'searchInput' }), !isMobile &&
                            jsx(DummySearchInput, { id: id, disabled: isDisabled, onFocus: () => { (inputRef.current || containerRef.current)?.focus(); } })] }), jsxs(Icons, { className: "spordleSelect-icons", children: [isLoading && jsx(Icon, { className: `spordleSelect-icon spordleSelect-loading-icon ${loadingIcon}` }), jsx(ClearButton, { clearSelected: clearSelected, isDisabled: isDisabled, showClear: showClear, focus: () => inputRef.current?.focus() }), jsx(Icon, { className: "spordleSelect-icon", children: jsx(Chevron, { className: "mdi mdi-chevron-down spordleSelect-chevron" }) })] })] })
        :
            jsxs(Container, { "data-testid": 'selector', ref: (r) => {
                    // @ts-ignore
                    containerRef.current = r;
                    // @ts-ignore
                    selectorContainerRef.current = r;
                }, id: spordleTable.generateId('selector'), tabIndex: isDisabled || isMobile ? undefined : 0, className: stringBuilder({ 'error': hasLoadError }, inputClassName, "spordleSelect-input-container form-control"), onClick: toggle, "data-disabled": isDisabled, "data-name": name, "aria-label": placeHolder, onBlur: isMobile ? (e) => {
                    e.stopPropagation();
                } : undefined, children: [jsx(ContainerInner, { ref: selectorRef, className: "spordleSelect-input-container-inner", children: jsx(ContainerValues, { className: stringBuilder("spordleSelect-input-container-values", { "spordleSelect-placeholder": isLoading || options.length === 0 }), "data-testid": 'selectedOption-container', children: isLoading ?
                                placeHolder
                                :
                                    hasLoadError ?
                                        getMessage('error', intl.locale)
                                        :
                                            options.length === 0 ?
                                                placeHolder
                                                :
                                                    showValues() }) }), jsxs(Icons, { className: "spordleSelect-icons", children: [isLoading &&
                                jsx(Icon, { className: `spordleSelect-icon spordleSelect-loading-icon ${loadingIcon}` }), jsx(ClearButton, { clearSelected: clearSelected, isDisabled: isDisabled, showClear: showClear, focus: () => containerRef.current?.focus() }), jsx(Icon, { className: "spordleSelect-icon", children: jsx(Chevron, { className: "mdi mdi-chevron-down spordleSelect-chevron" }) })] }), !isMobile && // We don't want the search input when mobile because of focus issues on ios (scroll to and zooms).
                        jsx(HiddenSearchInput, { id: id, size: 0, disabled: isDisabled, className: "spordleSelect-input-hidden", autoComplete: 'off', tabIndex: -1, inputMode: 'none', 
                            // SR
                            role: 'combobox', "aria-haspopup": true, "aria-expanded": isOpen, "aria-owns": spordleTable.generateId('options', 'container'), "aria-controls": spordleTable.generateId('options', 'container'), "aria-autocomplete": 'list', "aria-disabled": isDisabled, "aria-readonly": true, onFocus: () => {
                                containerRef.current?.focus(); // Relay the focus to the div
                            }, "aria-activedescendant": activeOptions.id })] }));
};
const ClearButton = ({ showClear, clearSelected, isDisabled, focus }) => {
    const { generateId } = useSpordleTable();
    const intl = useIntl();
    return (showClear && !isDisabled ?
        jsx(StyledClearButton, { id: generateId('values', 'clear'), className: `${icon} spordleSelect-icon spordleSelect-clear-btn`, onClick: clearSelected, onMouseDown: (e) => {
                e.preventDefault();
            }, onKeyDown: (e) => {
                switch (e.code) {
                    case 'Enter':
                        // @ts-ignore
                        clearSelected(e);
                        focus();
                        e.preventDefault();
                        return false;
                }
            }, type: 'button', disabled: isDisabled, "aria-label": getMessage('clear', intl.locale), "data-testid": 'clearButton', children: jsx("i", { className: "mdi mdi-close" }) })
        : null);
};
ClearButton.displayName = 'ClearButton';
Selector.displayName = 'SpordleSelectSelector';export{Selector as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/