import PropTypes from 'prop-types';

import {
    Fade,
    Spinner
} from "reactstrap";

/**
 * Loading overlay that will wrap children and add an overlay with a centered spinner
 * @param {object} props
 * @param {boolean} props.isLoading
 * @return {React.ReactNode}
 */
const OverlayLoader = (props) => (
    <div className="position-relative">
        {props.isLoading &&
            <div className='position-absolute d-flex justify-content-center align-items-center w-100 h-100 bg-white' style={{ opacity: 0.5, zIndex: 100 }}>
                <Fade appear in>
                    <Spinner color='primary' type='grow' />
                </Fade>
            </div>
        }
        {props.children}
    </div>
)

OverlayLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
}

export default OverlayLoader;