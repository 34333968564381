import { useContext, useEffect } from 'react';
import {
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Translate from '@spordle/intl-elements';

// contexts
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';

const RegistrationMoreParticipant = (props) => {
    const registrationContext = useContext(RegistrationContext);

    useEffect(() => {
        registrationContext.setSkipToPayment(true);

        for(const memberId in registrationContext.membersInfo){
            // set all current members as final, so when the user edits the member's registration and then cicks "Previous" in the registration options view, the member doesn't get removed from the cart
            registrationContext.editMember(memberId, { isFinal: true })
        }
    }, [])

    return (
        <>
            <ModalHeader wrapTag={'header'} tag="div" toggle={registrationContext.toggleModal} className="text-white bg-dark">
                <Translate id='participant.registrationModal.views.registrationMoreParticipants.title' />
            </ModalHeader>
            <ModalBody className="py-4 bg-light">
                <div className="registration-container">
                    <p className="mb-4 text-center"><Translate id='participant.registrationModal.views.registrationMoreParticipants.topText' /></p>
                    <Button
                        id='registrationMoreParticipantAddParticipant'
                        onClick={() => registrationContext.goToView(registrationContext.views.existingOrNew)}
                        color="info"
                        className="registration-btn mb-3"
                        outline
                    >
                        <span><Translate id='participant.registrationModal.views.registrationMoreParticipants.another' /></span>
                    </Button>
                    <Button
                        id='registrationMoreParticipantGoToSummary'
                        onClick={() => registrationContext.goToView(registrationContext.views.summary)}
                        color="info"
                        className="registration-btn"
                    >
                        <span className="mr-2"><Translate id='participant.registrationModal.views.registrationMoreParticipants.nextStep' /></span>
                        <i className="mdi mdi-arrow-right" />
                    </Button>
                </div>
            </ModalBody>
            <ModalFooter tag={'footer'} className="bg-light">
                <Button
                    id='registrationMoreParticipantPreviousButton'
                    onClick={() => registrationContext.goToView(registrationContext.views.waivers, true)}
                    color="default"
                    outline
                    className="mr-auto"
                >
                    <Translate id='misc.previous' />
                </Button>
            </ModalFooter>
        </>
    );
}

export default RegistrationMoreParticipant;