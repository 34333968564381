import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    ModalHeader,
    ModalBody,
    Button,
    Alert
} from "reactstrap";
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';
import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import { AccountsContext } from '../../../../../../../../contexts/AccountsContext';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { stringBuilder } from '@spordle/helpers';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';

const RegistrationAddressReview = (props) => {
    const registrationContext = useContext(RegistrationContext);
    const accountsContext = useContext(AccountsContext);
    const auth = useContext(AuthContext);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ selectedOption, setSelectedOption ] = useState(false);

    const address = auth.userData.addresses?.filter((address) => address.active == 1)[0]

    const onSubmit = () => {
        if(selectedOption === 'change'){
            registrationContext.setChangeAddress(true);
            registrationContext.goToView(registrationContext.views.noAddress)
        }else if(selectedOption === 'valid'){
            setIsLoading(true);
            setError(null);
            accountsContext.updateIdentityAddress(auth.userData.userName, address.identity_address_id, {
                reviewed: true,
            })
                .then(() => {
                    setIsLoading(false);
                    registrationContext.goToView(registrationContext.views.existingOrNew)
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        setIsLoading(false);
                        setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    }
                })
        }
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="text-white bg-dark">
                <Translate id='participant.registrationModal.views.addressReview.header' />
            </ModalHeader>
            <ModalBody className="py-4 bg-light">
                <div className='registration-container'>
                    <div className='text-center'>

                        <p className='font-weight-bold mb-3'>
                            <Translate id='participant.registrationModal.views.addressReview.text' />
                        </p>
                        <p>
                            <Translate id='participant.registrationModal.views.addressReview.text2' />
                            <br />
                            {registrationContext.getFullAddress(address)}
                        </p>
                    </div>

                    <div
                        id='addressReviewValidAddress'
                        className={stringBuilder('registration-card', { 'is-selected': selectedOption === 'valid' })}
                        onClick={() => setSelectedOption('valid')}
                    >
                        <div className="mr-3 flex-shrink-0">
                            <div style={{ height: 50, width: 50 }} className="border rounded-circle d-flex align-items-center justify-content-center text-info">
                                <i style={{ fontSize: '30px' }} className="mdi mdi-home-outline" />
                                <i style={{ height: '17px', width: '17px' }} className='d-flex justify-content-center align-items-center mdi mdi-check-circle-outline line-height-1 bg-white rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16' />
                            </div>
                        </div>
                        <div className="mr-3">
                            <p className="text-info font-weight-bold mb-1"><Translate id='participant.registrationModal.views.addressReview.isValid' /></p>
                            <p className="text-info mb-0 mt-n1 small"><Translate id='participant.registrationModal.views.addressReview.isValid.subtitle' /></p>
                        </div>
                        <div className="registration-card-icon"><i className="mdi mdi-check" /></div>
                    </div>
                    <div
                        id='addressReviewChangeAddress'
                        className={stringBuilder('registration-card', { 'is-selected': selectedOption === 'change' })}
                        onClick={() => setSelectedOption('change')}
                    >
                        <div className="mr-3 flex-shrink-0">
                            <div style={{ height: 50, width: 50 }} className="border rounded-circle  d-flex align-items-center justify-content-center text-info">
                                <i style={{ fontSize: '30px' }} className="mdi mdi-home-outline" />
                                <i style={{ height: '17px', width: '17px' }} className='d-flex justify-content-center align-items-center mdi mdi-close-circle-outline line-height-1 bg-white rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16' />
                            </div>
                        </div>
                        <div className="mr-3">
                            <p className="text-info mb-0 font-weight-bold"><Translate id='participant.registrationModal.views.addressReview.change' /></p>
                            <p className="text-info mb-0 mt-n1 small"><Translate id='participant.registrationModal.views.addressReview.change.subtitle' /></p>
                        </div>
                        <div className="registration-card-icon"><i className="mdi mdi-check" /></div>
                    </div>

                    {error &&
                        <div className='mt-3'>
                            <Alert color='danger' toggle={() => setError(null)}>{error}</Alert>
                        </div>
                    }

                    <Button
                        id='addressReviewSubmitButton'
                        onClick={onSubmit}
                        color="info" className="registration-btn"
                        disabled={!selectedOption}
                    >
                        <span className="mr-2"><Translate id='participant.registrationModal.views.addressReview.continue' /></span>
                        <i className="mdi mdi-arrow-right" />
                    </Button>
                </div>
            </ModalBody>
        </OverlayLoader>
    );
}

export default RegistrationAddressReview;