/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import {createContext,PureComponent}from'react';const IntlElementsContext = createContext(null);
IntlElementsContext.displayName = 'IntlElementsContext';
/**
 * @class IntlElementsContextProvider
 * @namespace IntlElementsContextProvider
 * @description
 * The IntlElementsContextProvider provides settings to the intl elements consumming it.
 *
 * It provides a default date format, phone number suggested countries and more.
 *
 * Props in `[]` are optional.
 *
 * @prop {CurrencyFormat} [currencyFormat]
 * @prop {DateFormatProps} [dateformat]
 * @prop {TranslateProps} [translate]
 *
 * @example
 * <IntlElementsContextProvider
 *     currencyFormat={{
 *         currency: "EUR",
 *         currencyDisplay: "code",
 *         currencySign: "accounting",
 *     }}
 *     dateformat={{
 *         format: dateFormats.longMonthDayYear,
 *     }}
 *     translate={{
 *         values: {
 *             name: 'dynamic name here :)',
 *         },
 *         defaultMessageId: "misc.nonExistant",
 *     }}
 * >
 *     ...
 * <IntlElementsContextProvider />
 * @since 4.2.0
 * @copyright Spordle
*/
class IntlElementsContextProvider extends PureComponent {
    render() {
        return (jsx(IntlElementsContext.Provider, { value: {
                ...this.props,
            }, children: this.props.children }));
    }
}export{IntlElementsContext,IntlElementsContextProvider as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/