import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import React, { useContext, useState } from 'react';
import { NavLink, withRouter, matchPath, generatePath } from 'react-router-dom';
import { I18nContext } from '../../../contexts/I18nContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { LOCALES } from '../../../i18n';
import { PageSubroutes } from '../../../routes/pageRoutes';
import PromptModal from './PromptModal';

const SideNav = ({ match, className, sectionClassName, fromHeader, ...props }) => {
    const organizationContext = useContext(OrganizationContext);
    const { locale, setLocale } = useContext(I18nContext);
    const [ promptInfo, setPromptInfo ] = useState({
        isLeaving: false,
        link: '',
    });

    const orgPage = matchPath(props.location.pathname, {
        path: "/page/:orgid+",
        exact: false,
        strict: false,
    });

    return (
        orgPage ?
            <>
                <nav className={stringBuilder(className, "d-flex flex-column")}>
                    {
                        PageSubroutes.map((routeGroup, key) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <section className={stringBuilder(sectionClassName, { "mb-0": key === 0 && !fromHeader })} key={`${routeGroup.title}-${key}`}>
                                    { (routeGroup.title == "routes.group.overview.name" && fromHeader) ?
                                        <h3 className="section-main-title">
                                            <Translate id={routeGroup.title} />
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setLocale(locale === 'fr-ca' ? LOCALES.ENGLISH : LOCALES.FRENCH)
                                                }}
                                                type="button"
                                                className="navbar-lang-btn ml-auto small"
                                            >
                                                {locale === 'fr-ca' ? 'EN' : 'FR'}
                                            </button>
                                        </h3>
                                        :
                                        <h3 className="section-main-title">
                                            <Translate id={routeGroup.title} />
                                        </h3>
                                    }
                                    {/* <div className="d-flex border-bottom font-weight-bold pb-1">
                                    <div className="h3"><Translate id={routeGroup.title} /></div>
                                    { (routeGroup.title == "routes.group.overview.name" && fromHeader) &&
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setLocale(locale === 'fr-ca' ? LOCALES.ENGLISH : LOCALES.FRENCH)
                                            }}
                                            type="button"
                                            className="navbar-lang-btn ml-auto "
                                        >
                                            {locale === 'fr-ca' ? 'EN' : 'FR'}
                                        </button>
                                    }
                                </div> */}
                                    <ul className="nav flex-column">
                                        {
                                            routeGroup.subroutes.map((route, key) => {
                                                const link = fromHeader ?
                                                    generatePath(`/page/:orgid+${route.path}`, { orgid: organizationContext.short_url?.split('/') || [ 'hc' ] })
                                                    :
                                                `${match.url}${route.path}`;

                                                return (
                                                // If in sidebar and there is a route name
                                                    (!route.skipSidebar && route.name) &&
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <li key={`${key}-${route.name}`} className="page-aside-nav-item">
                                                        {route.disabled ?
                                                            <span className="page-aside-nav-link is-disabled">
                                                                {route.skipTranslate ? route.name : <Translate id={route.name} /> }
                                                            </span>
                                                            :
                                                            !route.external ?
                                                                <NavLink className="page-aside-nav-link" activeClassName="active" exact={!route.path} to={link} id={'navLink-' + link}>
                                                                    {route.skipTranslate ? route.name : <Translate id={route.name} /> }
                                                                </NavLink>
                                                                :
                                                                <button
                                                                    className="page-aside-nav-link btn"
                                                                    type="button"
                                                                    onClick={() => setPromptInfo({
                                                                        isLeaving: true,
                                                                        link: route.link,
                                                                    })}
                                                                >
                                                                    {route.skipTranslate ? route.name : <Translate id={route.name} /> } <i className="mdi mdi-launch" />
                                                                </button>
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </section>
                            )
                        })
                    }
                </nav>
                <PromptModal isOpen={promptInfo.isLeaving} link={promptInfo.link} toggle={() => setPromptInfo((prev) => ({ ...prev, isLeaving: false }))} />
            </>
            :
            <React.Fragment />
    );
}

export default withRouter(SideNav);