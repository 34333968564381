/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {createElement}from'react';import {jsx,jsxs}from'react/jsx-runtime';import {Fade,Row,Col}from'reactstrap';import {SpordleTableContext}from'./SpordleTableProvider.js';import PaginationHandler from'./PaginationHandler.js';const GridView = (props) => (jsx(SpordleTableContext.Consumer, { children: (spordleTable) => {
        if (spordleTable.dataToDisplay.length === 0) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            return jsx(Fade, { id: spordleTable.generateId('emptyLayout'), "data-testid": 'emptyLayout', children: typeof spordleTable.props.emptyLayout === 'function' ? jsx(spordleTable.props.emptyLayout, { emptySearch: spordleTable.getData().length > 0 && !!spordleTable.getInputValue() && spordleTable.dataToDisplay.length === 0 }) : spordleTable.props.emptyLayout });
        }
        return (jsxs(Fade, { children: [jsx(Row, { ...spordleTable.state.view.config.row, children: spordleTable.dataToDisplay.map((data) => {
                        const dataIndex = spordleTable.getDataIndexValue(data);
                        return (createElement(Col, { ...spordleTable.state.view.config.col, key: dataIndex, id: spordleTable.generateId('gridView', 'data', dataIndex) },
                            jsx("div", { onMouseDown: (e) => { if (e.button === 1)
                                    e.preventDefault(); }, onContextMenu: (e) => { spordleTable.clickRow(data, e, undefined); }, onClick: (e) => { spordleTable.clickRow(data, e, undefined); }, onAuxClick: (e) => { if (e.button === 1)
                                    spordleTable.clickRow(data, e, undefined); }, children: spordleTable.state.view.config.gridRender(data, spordleTable) })));
                    }) }), !props.hidePagination && (spordleTable.state.pagination ?? 0) !== 0 && jsx(PaginationHandler, { className: spordleTable.state.isMobile ? 'mx-auto' : 'ml-auto' })] }));
    } }));
GridView.displayName = 'SpordleTableGridView';
const GridView$1 = GridView;export{GridView$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/