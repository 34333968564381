/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import {memo,useContext}from'react';import {FormattedNumber}from'react-intl';import {IntlElementsContext}from'./IntlElementsContext.js';/**
 * Display a number as currency according to the I18nProvider locale
 * @prop {number} value The number to display as currency
 * @returns {FormattedNumber} A Currency formatted number
 * @see https://formatjs.io/docs/react-intl/components#formattednumber
 * @copyright Spordle
 * @example
 * <CurrencyFormat value={columnTotal}/>
 */
const CurrencyFormat = memo((props) => {
    const globalConfigs = useContext(IntlElementsContext);
    return (jsx(FormattedNumber, { ...defaultProps, ...globalConfigs?.currencyFormat, ...props, style: 'currency' }));
});
const defaultProps = {
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
};
CurrencyFormat.displayName = 'CurrencyFormat';export{CurrencyFormat as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/