/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import {stringBuilder}from'@spordle/helpers';import {Editor}from'@tinymce/tinymce-react';import {useField}from'formik';import {forwardRef}from'react';import {useIntl}from'react-intl';import {Label,FormText}from'reactstrap';import FormikError from'./FormikError.js';/**
 * @member FormikRTE
 * @description
 * FormikRTE will create a TinyMCE that will work automagically with Fromik(yes it's a typo :)) validations
 * @example
 * <FormikRTE label="Base URL" name="rte" id="rte" helper="login.error.server" />
 * <FormikRTE name="rte" id="rte"/>
 *
 * @prop {string} name Used to make the FormikRTE work with validation
 * @prop {string|JSX.Element} [label] The lang id to be translated OR JSX
 * @prop {string|JSX.Element} [helper] The lang id to be translated OR JSX
 * @prop {boolean} [translateHelper=true]
 * @prop {boolean} [translateLabel=true]
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @returns {JSX.Element} Returns an input text that will work with Formik
 *
 * @see https://www.tiny.cloud/docs/tinymce/6
 * @copyright Spordle
 * @since 4.4.0
 */
const FormikRTE = forwardRef(({ label, manualError, helper, translateLabel, translateHelper, ...props }, ref) => {
    const [field, meta, helpers] = useField(props.name);
    const { locale, formatMessage } = useIntl();
    if (translateHelper && helper && typeof helper === 'string')
        helper = formatMessage({ id: helper });
    if (translateLabel && label && typeof label === 'string')
        label = formatMessage({ id: label });
    return (jsxs(Fragment, { children: [label && typeof label === 'string' ? jsx(Label, { for: props.id || props.name, children: label }) : label, jsx("div", { tabIndex: -1, className: stringBuilder("tiny-mce-custom-container", { "is-error": meta.touched && meta.error }), children: jsx(Editor, { ...field, ...props, onChange: undefined, onEditorChange: (content, editor) => {
                        helpers.setValue(content);
                        props.onEditorChange?.(content, editor);
                    }, init: {
                        height: 250,
                        menubar: false,
                        statusbar: false,
                        branding: false,
                        plugins: [
                            'link',
                            'lists',
                            'advlist',
                            'autolink',
                        ],
                        toolbar: 'undo redo | blocks | bold italic underline | alignnone alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link unlink',
                        extended_valid_elements: 'a[href|target=_blank]',
                        default_link_target: '_blank',
                        target_list: false,
                        link_title: false,
                        link_default_target: '_blank',
                        link_target_list: false,
                        language: locale === 'fr' ? 'fr_FR' : undefined,
                        // https://stackoverflow.com/questions/27010110/tinymce-onpaste-doesnt-set-content-paste-with-context-menu-doesnt-trigger-cha
                        setup: function (editor) {
                            // Listen for paste event, "Paste as plain text" callback
                            editor.on('paste', (e) => {
                                const stripHtml = (html) => {
                                    // removes all html tags and only keeps the content in plain text
                                    const doc = new DOMParser().parseFromString(html, 'text/html');
                                    return doc.body.textContent || "";
                                };
                                // Prevent default paste behavior
                                e.preventDefault();
                                // Let TinyMCE do the heavy lifting for inserting that content into the editor.
                                editor.editorCommands.execCommand('mceInsertContent', false, 
                                // Check for clipboard data in various places for cross-browser compatibility.
                                // Get that data as text.
                                // @ts-ignore
                                stripHtml(((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text')));
                            });
                        },
                        // Custom init
                        ...props.init,
                        formats: {
                            h1: { block: 'div', classes: 'h1' },
                            h2: { block: 'div', classes: 'h2' },
                            h3: { block: 'div', classes: 'h3' },
                            h4: { block: 'div', classes: 'h4' },
                            h5: { block: 'div', classes: 'h5' },
                            h6: { block: 'div', classes: 'h6' },
                            ...props.init?.formats,
                        },
                    }, ref: ref, apiKey: props.apiKey || process.env.NEXT_PUBLIC_TINY_KEY || process.env.REACT_APP_TINY_KEY || process.env.TINY_MCE_KEY }) }), helper && typeof helper === 'string' ? jsx(FormText, { color: "muted", children: helper }) : helper, !manualError && jsx(FormikError, { name: props.name })] }));
});
const FormikRTE$1 = FormikRTE;export{FormikRTE$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/