import { FormikCheckedButton, FormikDateTime, FormikGroup, FormikInputNumber, FormikInputText, FormikRadioButton, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import {
    Row,
    Col,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    FormGroup,
    Alert
} from "reactstrap";
import { DisplayI18n, displayI18n } from '../../../../../../../helpers/i18nHelper';
import * as Yup from 'yup';
import moment from 'moment';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import Translate from '@spordle/intl-elements';
import Required from '../../../../../../../components/formik/Required';
import RegistrationParticipantCard from './RegistrationParticipantCard';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';

// contexts
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';


const RegistrationCustomQuestions = (props) => {

    const i18nContext = useContext(I18nContext);
    const registrationContext = useContext(RegistrationContext);

    const [ activeIndex, setActiveIndex ] = useState(0);

    //////////////////////////////////////////////////////////////////////////////
    // initial values
    //////////////////////////////////////////////////////////////////////////////

    const getInitialValues = ({ formGroups, customFormId, rowId }) => {
        // if the form is already answered
        // const answeredFields = registrationContext.state.currentCart.cartInfo.cart_detail.find((row) => row.item_type === 'REGISTRATION' && row.member.member_id === registrationContext.getCurrentMember().members[0].member_id)?.custom_form?.fields || null
        const answeredFields = registrationContext.state.currentCart.cartInfo.cart_detail.find((row) => row.row_id === rowId)?.custom_form?.fields || null

        const temp = {};
        formGroups.forEach((group, indexGroup) => {
            group.fields.forEach((field, indexField) => {
                switch (field.form_field_code){
                    // simple input fields
                    case 'TEXT':
                    case 'TEXT_AREA':
                    case 'NUMBER':
                        temp[`group_${indexGroup}_fields_${indexField}`] = answeredFields?.find((f) => f.custom_form_field_id === field.custom_form_field_id)?.answer || '';
                        break;

                    // dates
                    case 'DATE':
                    case 'DATETIME':
                        temp[`group_${indexGroup}_fields_${indexField}`] = answeredFields?.find((f) => f.custom_form_field_id === field.custom_form_field_id)?.answer ? moment(answeredFields?.find((f) => f.custom_form_field_id === field.custom_form_field_id)?.answer) : '';
                        break;

                    // arrays
                    case 'CHECKBOX':
                    case 'SELECTBOX_MULTI':
                        temp[`group_${indexGroup}_fields_${indexField}`] = answeredFields?.find((f) => f.custom_form_field_id === field.custom_form_field_id)?.custom_form_field_option_id?.split(',') || [];
                        break;

                    // single options
                    case 'RADIO':
                        // radio should always have a value
                        temp[`group_${indexGroup}_fields_${indexField}`] = answeredFields?.find((f) => f.custom_form_field_id === field.custom_form_field_id)?.custom_form_field_option_id || field.options[0].custom_form_field_option_id;
                        break;

                    case 'SELECTBOX':
                        temp[`group_${indexGroup}_fields_${indexField}`] = answeredFields?.find((f) => f.custom_form_field_id === field.custom_form_field_id)?.custom_form_field_option_id || '';
                        break;
                }
            })
        })
        return temp;
    }

    //////////////////////////////////////////////////////////////////////////////
    // Validation Schema
    //////////////////////////////////////////////////////////////////////////////

    /**
     * Formats a JSON config to a validation schema
     * @see Found on this {@link https://github.com/jquense/yup/issues/559#issuecomment-518953000|post}
     */
    const createYupSchema = (schema, config) => {
        const { id, validationType, validations = [] } = config;
        if(!Yup[validationType]){
            return schema;
        }
        let validator = Yup[validationType]();
        validations.forEach((validation) => {
            const { params, type } = validation;
            if(!validator[type]){
                return;
            }
            validator = validator[type](...params);
        });
        schema[id] = validator;
        return schema;
    }

    const buildConfig = (formGroups) => {
        const schemaConfig = [];
        formGroups.forEach((group, indexGroup) => {
            group.fields.forEach((field, indexField) => {
                switch (field.form_field_code){
                    // array
                    case 'CHECKBOX':
                    case 'SELECTBOX_MULTI':
                        schemaConfig.push({
                            id: `group_${indexGroup}_fields_${indexField}`,
                            validationType: 'array',
                            validations: [
                                // conditionnal array building :)
                                // eslint-disable-next-line react/jsx-key
                                ...field.mandatory === '1' ? [ { type: 'min', params: [ 1, <Translate id='participant.registrationModal.views.registrationCustomQuestions.required' /> ] } ] : [],
                            ],
                        })
                        break;

                    // number
                    case 'NUMBER':
                        schemaConfig.push({
                            id: `group_${indexGroup}_fields_${indexField}`,
                            validationType: 'number',
                            validations: [
                                // conditionnal array building :)
                                // eslint-disable-next-line react/jsx-key
                                ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='participant.registrationModal.views.registrationCustomQuestions.required' /> ] } ] : [],
                                // eslint-disable-next-line react/jsx-key
                                ...field.min ? [ { type: 'min', params: [ field.min, <Translate id='participant.registrationModal.views.registrationCustomQuestions.min' /> ] } ] : [],
                                // eslint-disable-next-line react/jsx-key
                                ...field.max ? [ { type: 'max', params: [ field.max, <Translate id='participant.registrationModal.views.registrationCustomQuestions.max' /> ] } ] : [],
                            ],
                        })
                        break;

                    // date / time
                    case 'DATE':
                    case 'DATETIME':
                        schemaConfig.push({
                            id: `group_${indexGroup}_fields_${indexField}`,
                            validationType: 'mixed',
                            validations: [
                                // conditionnal array building :)
                                ...field.mandatory !== '1' ? [ { type: 'test', params: [ {
                                    name: 'dateTimeNotRequired',
                                    message: <Translate id='form.validation.date.format' />,
                                    test: (value) => {
                                        if(!value)
                                            return true;
                                        return moment.isMoment(value);
                                    },
                                } ] } ] : [],
                                // eslint-disable-next-line react/jsx-key
                                ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='participant.registrationModal.views.registrationCustomQuestions.required' /> ] }, { type: 'test', params: [ {
                                    name: 'dateTimeRequired',
                                    message: <Translate id='form.validation.date.format' />,
                                    test: (value) => {
                                        return moment.isMoment(value);
                                    },
                                } ] } ] : [],
                                ...field.min ? [ { type: 'test', params: [ {
                                    name: 'dateTimeMin',
                                    message: <Translate id='participant.registrationModal.views.registrationCustomQuestions.min' />,
                                    test: (value) => {
                                        if(!value)
                                            return true;
                                        return field.form_field_code === 'DATETIME' ? moment(value).format('HH:mm') >= moment(field.min).format('HH:mm') : moment(value).isAfter(moment(field.min));
                                    },
                                } ] } ] : [],
                                ...field.max ? [ { type: 'test', params: [ {
                                    name: 'dateTimeMax',
                                    message: <Translate id='participant.registrationModal.views.registrationCustomQuestions.max' />,
                                    test: (value) => {
                                        if(!value)
                                            return true;
                                        return field.form_field_code === 'DATETIME' ? moment(value).format('HH:mm') <= moment(field.max).format('HH:mm') : moment(value).isBefore(moment(field.max));
                                    },
                                } ] } ] : [],
                            ],
                        })
                        break;

                    // string
                    case 'TEXT':
                    case 'TEXT_AREA':
                    case 'RADIO':
                    case 'SELECTBOX':
                        schemaConfig.push({
                            id: `group_${indexGroup}_fields_${indexField}`,
                            validationType: 'string',
                            validations: [
                                // conditionnal array building :)
                                // eslint-disable-next-line react/jsx-key
                                ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='participant.registrationModal.views.registrationCustomQuestions.required' /> ] } ] : [],
                            ],
                        })
                        break;
                }
            })
        })
        return schemaConfig;
    }

    const getValidationSchema = (formGroups) => {
        const yupSchema = buildConfig(formGroups).reduce(createYupSchema, {});
        return Yup.object().shape(yupSchema)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Displaying fields
    //////////////////////////////////////////////////////////////////////////////

    const getLabelHelper = (field) => {
        if(field.min && field.max){
            if(field.form_field_code === 'DATETIME'){
                return <span className='text-muted small'> (<Translate id='participant.registrationModal.views.registrationCustomQuestions.label.between' /> {moment(field.min).format('HH:mm')} <Translate id='participant.registrationModal.views.registrationCustomQuestions.label.and' /> {moment(field.max).format('HH:mm')})</span>
            }
            return <span className='text-muted small'> (<Translate id='participant.registrationModal.views.registrationCustomQuestions.label.between' /> {field.min} <Translate id='participant.registrationModal.views.registrationCustomQuestions.label.and' /> {field.max})</span>

        }else if(field.min){
            if(field.form_field_code === 'DATETIME'){
                return <span className='text-muted small'> (<Translate id='participant.registrationModal.views.registrationCustomQuestions.label.min' />: {moment(field.min).format('HH:mm')})</span>
            }
            return <span className='text-muted small'> (<Translate id='participant.registrationModal.views.registrationCustomQuestions.label.min' />: {field.min})</span>

        }else if(field.max){
            if(field.form_field_code === 'DATETIME'){
                return <span className='text-muted small'> (<Translate id='participant.registrationModal.views.registrationCustomQuestions.label.max' />: {moment(field.max).format('HH:mm')})</span>
            }
            return <span className='text-muted small'> (<Translate id='participant.registrationModal.views.registrationCustomQuestions.label.max' />: {field.max})</span>

        }
        return ''
    }

    // TODO: we might want to add some logic to display the fields differently (example: short answers can be displayed with col-6 instead of col-12)
    const displayField = (field, fieldName, formik) => {
        switch (field.form_field_code){
            case 'TEXT':
                return <FormikInputText id={fieldName} name={fieldName} trim />
            case 'TEXT_AREA':
                return <FormikTextArea id={fieldName} name={fieldName} trim />
            case 'CHECKBOX':
                return (
                    <FormikGroup name={fieldName} rowProps={{ className: 'mx-0' }}>
                        {field.options.map((option) => (
                            <FormikCheckedButton
                                key={option.custom_form_field_option_id}
                                className='mr-3 mb-3'
                                id={option.custom_form_field_option_id}
                                name={fieldName}
                                value={option.custom_form_field_option_id}
                                label={<DisplayI18n field={'field_option'} defaultValue={option.field_option} i18n={option.i18n} />}
                                translateLabel={false}
                            />
                        ))}
                    </FormikGroup>
                );
            case 'RADIO':
                return (
                    <FormikGroup name={fieldName} rowProps={{ className: 'mx-0' }}>
                        {field.options.map((option, index) => (
                            <FormikRadioButton
                                key={option.custom_form_field_option_id}
                                className='mr-3 mb-3'
                                id={option.custom_form_field_option_id}
                                name={fieldName}
                                value={option.custom_form_field_option_id}
                                label={<DisplayI18n field={'field_option'} defaultValue={option.field_option} i18n={option.i18n} />}
                                translateLabel={false}
                            />
                        ))}
                    </FormikGroup>
                )
            case 'SELECTBOX_MULTI':
                return (
                    <FormikSelect
                        name={fieldName} id={fieldName}
                        multi search={false}
                        renderOption={(option) => <DisplayI18n field='field_option' defaultValue={option.option.label} i18n={option.option.i18n} />}
                        loadingStatus='success'
                        defaultData={field.options.map((option) => ({
                            value: option.custom_form_field_option_id,
                            label: option.field_option,
                            i18n: option.i18n,
                        }))}
                    />
                )
            case 'SELECTBOX':
                return (
                    <FormikSelect
                        name={fieldName} id={fieldName} search={false}
                        renderOption={(option) => <DisplayI18n field={'field_option'} defaultValue={option.option.label} i18n={option.option.i18n} />}
                        loadingStatus='success'
                        defaultData={field.options.map((option) => ({
                            value: option.custom_form_field_option_id,
                            label: option.field_option,
                            i18n: option.i18n,
                        }))}
                    />
                )
            case 'DATE':
                return (
                    <FormikDateTime
                        id={fieldName}
                        name={fieldName}
                        timeFormat={false}
                        dateFormat={'YYYY-MM-DD'}
                        isValidDate={(current) => {
                            if(field.min && field.max){
                                return moment(current).isAfter(moment(field.min)) && moment(current).isBefore(moment(field.max))
                            }else if(field.min){
                                return moment(current).isAfter(moment(field.min))
                            }else if(field.max){
                                return moment(current).isBefore(moment(field.max))
                            }
                            return true

                        }}
                        renderInput={(props) => (
                            <div className='search-wrapper'>
                                <input {...props} placeholder='' />
                            </div>
                        )}
                    />
                )
            case 'DATETIME':
                return (
                    <FormikDateTime
                        id={fieldName}
                        name={fieldName}
                        dateFormat={false}
                        renderInput={(props) => (
                            <div className='search-wrapper'>
                                <input {...props} placeholder='' />
                            </div>
                        )}
                    />
                )
            case 'NUMBER':
                return <FormikInputNumber id={fieldName} name={fieldName} />
        }
    }

    return (
        <>
            <Formik
                key={activeIndex}
                initialValues={getInitialValues(registrationContext.getCurrentMember().forms[activeIndex])}
                validationSchema={getValidationSchema(registrationContext.getCurrentMember().forms[activeIndex].formGroups)}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    const customForm = registrationContext.getCurrentMember().forms[activeIndex];
                    registrationContext.updateCartForms(
                        Object.keys(values).reduce((newArray, key) => {
                            // keys look like this `group_${indexGroup}_fields_${indexField}`
                            const [ _temp1, groupIndex, _temp2, fieldIndex ] = key.split('_');
                            let answer, option = null;

                            // has options -> checkbox, radio, select or multi select
                            if(customForm.formGroups[groupIndex].fields[fieldIndex].options){
                                option = values[key]

                                // multi options -> checkbox or multi select
                                if(Array.isArray(values[key])){
                                    // answer = array of translated option names
                                    answer = values[key].map((optionId) => {
                                        return displayI18n(
                                            'field_option',
                                            customForm.formGroups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === optionId)?.i18n,
                                            customForm.formGroups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === optionId)?.field_option,
                                            i18nContext.getGenericLocale(),
                                        )
                                    })
                                }else{ // single option -> radio, select
                                    answer = displayI18n(
                                        'field_option',
                                        customForm.formGroups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === values[key])?.i18n,
                                        customForm.formGroups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === values[key])?.field_option,
                                        i18nContext.getGenericLocale(),
                                    ) || '';
                                }
                            }else{ // no options -> short answer, text area, date, time or number
                                // eslint-disable-next-line no-lonely-if
                                if(customForm.formGroups[groupIndex].fields[fieldIndex].form_field_code === 'DATE' && values[key]){ // DATE, YYYY-MM-DD format
                                    answer = moment.isMoment(values[key]) ? values[key].format('YYYY-MM-DD') : moment(values[key]).format('YYYY-MM-DD')
                                }else if(customForm.formGroups[groupIndex].fields[fieldIndex].form_field_code === 'DATETIME' && values[key]){ // DATETIME, ISO format
                                    answer = moment.isMoment(values[key]) ? values[key].toISOString() : moment(values[key]).toISOString()
                                }else{ // simple string or number
                                    answer = values[key]
                                }
                            }

                            newArray.push({
                                custom_form_field_id: customForm.formGroups[groupIndex].fields[fieldIndex].custom_form_field_id,
                                custom_form_field_option_id: option,
                                answer: answer,
                            })
                            return newArray
                        }, []),
                        customForm.rowId,
                        customForm.customFormId,
                    )
                        .then(async() => {
                            await registrationContext.refreshCart();
                            if(activeIndex + 1 === registrationContext.getCurrentMember().forms.length){
                                setSubmitting(false)
                                registrationContext.goToView(registrationContext.views.position)
                            }else{
                                setSubmitting(false);
                                setActiveIndex(activeIndex + 1);
                            }
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false)
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                console.error(error)
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                                <Translate id='participant.registrationModal.views.registrationCustomQuestions.title' />
                            </ModalHeader>
                            <ModalBody className="py-4 bg-light">
                                <div className="registration-container">
                                    <RegistrationParticipantCard participant={registrationContext.getCurrentMember()} />

                                    <div className="h4 font-weight-bold mb-3">
                                        <Translate
                                            id='participant.registrationModal.views.registrationCustomQuestions.count'
                                            values={{
                                                actual: activeIndex + 1,
                                                max: registrationContext.getCurrentMember().forms.length,
                                            }}
                                        />
                                    </div>

                                    {registrationContext.getCurrentMember().forms[activeIndex].formGroups.map((formGroup, groupIndex) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={groupIndex}>
                                            <div className="font-weight-bold mb-2"><DisplayI18n field='name' defaultValue={formGroup.name} i18n={formGroup.i18n} /></div>
                                            <Row>
                                                {formGroup.fields.map((field, fieldIndex) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <Col sm='12' key={fieldIndex}>
                                                        <FormGroup>
                                                            <Label for={`group_${groupIndex}_fields_${fieldIndex}`}>
                                                                <DisplayI18n field={'title'} defaultValue={field.title} i18n={field.i18n} />
                                                                {getLabelHelper(field)}
                                                                {field.mandatory === '1' &&
                                                                    <> <Required /></>
                                                                }
                                                            </Label>
                                                            {displayField(field, `group_${groupIndex}_fields_${fieldIndex}`, formik)}
                                                        </FormGroup>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    ))}
                                    {formik.status &&
                                        <Alert color='danger' toggle={() => formik.setStatus()}>
                                            {formik.status}
                                        </Alert>
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter tag={'footer'} className="bg-light">
                                <Button
                                    id='registrationCustomQuestionsPreviousButton'
                                    onClick={() => {
                                        if(activeIndex === 0){ // first questionnaire
                                            registrationContext.goToView(registrationContext.views.registrations, true)
                                        }else{ // not first questionnaire, go back 1
                                            setActiveIndex(activeIndex - 1)
                                        }
                                    }}
                                    color="default" outline
                                    className="mr-auto"
                                >
                                    <Translate id='misc.previous' />
                                </Button>
                                <Button id='registrationCustomQuestionsSubmitButton' type='submit' color="info"><Translate id='misc.next' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default RegistrationCustomQuestions;