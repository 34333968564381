/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import {useSpordleTable}from'@spordle/datatables';import {stringBuilder}from'@spordle/helpers';import {useState,useCallback,useRef,useEffect}from'react';import {useIntl}from'react-intl';import {scrollLock,DummySearchInput}from'../styles/stitches.js';import {isIOs,getMessage,shouldVirtualize}from'./utils.js';import Option from'./Option.js';import VirtualizedMenu from'./VirtualizedMenu.js';import {ANIMATION_DURATION,Container,SHOWN_CLASS_NAME,HIDDEN_CLASS_NAME,InnerContainer,HeaderWrapper,HeaderSection,Title,CloseButton,PopupSection,MobileSearchInput,ClearBtn,List,shownScrollbar,NoResults}from'../styles/stitchesMobileViewList.js';import {createPortal}from'react-dom';const MobileViewList = ({ isOpen, id, showClear, close, noOptionLayout, searchable, clearable, clearSelected, placeholder, optionClick, selectOption, unselectOption, spordleSelect, isMulti, activeOptions, virtualizationThreshold, }) => {
    if (typeof window === 'undefined')
        return null;
    const spordleTable = useSpordleTable();
    const intl = useIntl();
    const [hideOptions, setHideOptions] = useState(!isOpen);
    const ios = isIOs();
    const getLabelText = useCallback(() => {
        const element = document.querySelector(`label[for="${id}"]`);
        let text = '';
        if (element) {
            for (let i = 0; i < element.childNodes.length; ++i)
                if (element.childNodes[i].nodeType === Node.TEXT_NODE)
                    text += element.childNodes[i].textContent;
        }
        return text;
    }, [id, window]);
    const focusElement = useRef();
    const containerEl = useRef();
    const hideOptionsTimeout = useRef();
    const lockScrollClass = scrollLock({ device: ios ? "ios" : undefined, search: searchable }).toString().split(' ');
    // Blocks page scrolling
    const lockBodyScroll = () => {
        // https://stackoverflow.com/questions/28790889/css-how-to-prevent-scrolling-on-ios-safari
        if (ios) {
            document.ontouchmove = function (e) {
                e.preventDefault();
            };
        }
        document.documentElement.classList.add(...lockScrollClass);
        document.body.classList.add(...lockScrollClass);
    };
    // Re-enabled page scrolling
    const unlockBodyScroll = () => {
        if (ios) {
            document.ontouchmove = function () {
                return true;
            };
        }
        document.documentElement.classList.remove(...lockScrollClass);
        document.body.classList.remove(...lockScrollClass);
    };
    useEffect(() => {
        if (isOpen) { // Opening
            lockBodyScroll();
            clearTimeout(hideOptionsTimeout.current); // Prevents hiding options when playing LOL with selects
            setHideOptions(false);
        }
        else { // Closing
            clearTimeout(hideOptionsTimeout.current); // Prevents hiding options when playing LOL with selects
            unlockBodyScroll();
            focusElement.current?.blur(); // Prevents issue of not focused inputs that aren't visible
            hideOptionsTimeout.current = setTimeout(() => {
                setHideOptions(true);
            }, ANIMATION_DURATION);
            return () => {
                clearTimeout(hideOptionsTimeout.current);
                unlockBodyScroll();
            };
        }
    }, [isOpen]);
    const labelText = getLabelText();
    const finalPlaceholder = placeholder ? intl.formatMessage({ id: placeholder }) : getMessage(searchable ? 'input.placeholder' : 'select', intl.locale);
    const inputValue = spordleTable.getInputValue();
    const closeAriaLabel = getMessage('close', intl.locale);
    const containerRef = useRef(null);
    const clearBtnRef = useRef(null);
    const scrollerListRef = useRef(null);
    const virtualizedListRef = useRef(null);
    return (createPortal(jsx(Container, { id: spordleTable.generateId('mobileView', 'container'), className: stringBuilder(isOpen ? SHOWN_CLASS_NAME : HIDDEN_CLASS_NAME, 'spordleSelect-mobileView-container'), role: 'dialog', "aria-labelledby": spordleTable.generateId('mobileView', 'title'), device: ios ? "ios" : "android", search: searchable, 
        // https://stackoverflow.com/questions/28790889/css-how-to-prevent-scrolling-on-ios-safari
        onTouchMove: (e) => {
            e.preventDefault();
            e.stopPropagation();
            return true;
        }, onClick: (e) => {
            if (e.currentTarget === e.target) {
                close();
            }
        }, children: jsxs(InnerContainer, { id: spordleTable.generateId('mobileView', 'containerInner'), className: 'spordleSelect-mobileView-containerInner', role: 'document', 
            // @ts-ignore
            ref: searchable ? containerEl : focusElement, search: searchable, device: ios ? "ios" : "android", children: [jsxs(HeaderWrapper, { className: "spordleSelect-mobileView-header", children: [jsxs(HeaderSection, { children: [jsx(Title, { className: 'spordleSelect-mobileView-title', id: spordleTable.generateId('mobileView', 'title'), htmlFor: spordleTable.generateId('mobileView', 'searchInput'), children: labelText || finalPlaceholder }), jsx(CloseButton, { type: 'button', onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        close();
                                    }, id: spordleTable.generateId('mobileView', 'closeHandle'), className: 'spordleSelect-mobileView-closeHandle', "aria-label": closeAriaLabel })] }), searchable ?
                            jsxs(PopupSection, { children: [jsx(MobileSearchInput, { isMobile: true, type: "search", className: "spordleSelect-mobileView-input form-control", placeholder: finalPlaceholder, onChange: (e) => {
                                            spordleTable.setInputValue(e.target.value ? `'"${e.target.value}"` : '', false);
                                        }, 
                                        // @ts-ignore
                                        ref: focusElement, value: inputValue.substring(2, inputValue.length - 1), autoComplete: 'new-password', autoCorrect: 'false', autoCapitalize: 'false', 
                                        // SR
                                        role: 'combobox', "aria-labelledby": spordleTable.generateId('mobileView', 'title'), "aria-placeholder": finalPlaceholder, "aria-autocomplete": 'list', "aria-controls": spordleTable.generateId('options', 'container'), "aria-owns": spordleTable.generateId('options', 'container'), "aria-haspopup": true, "aria-expanded": isOpen, "aria-activedescendant": activeOptions.id }), jsx(DummySearchInput, { id: spordleTable.generateId('mobileView', 'searchInput'), onFocus: () => focusElement.current?.focus() })] })
                            :
                                labelText &&
                                    jsx(PopupSection, { className: "spordleSelect-mobileView-placeholder", children: finalPlaceholder }), clearable &&
                            jsx(PopupSection, { as: "small", children: jsx(ClearBtn, { onClick: (e) => {
                                        e.stopPropagation();
                                        clearSelected(e);
                                    }, type: "button", ref: clearBtnRef, isShown: showClear, disabled: !showClear, className: "spordleSelect-mobileView-clear-btn", children: getMessage('clearSelection', intl.locale) }) })] }), !hideOptions && (shouldVirtualize(spordleTable.dataToDisplay.length, virtualizationThreshold) ?
                    jsx(VirtualizedMenu, { optionClick: optionClick, isOpen: true, selectOption: selectOption, unselectOption: unselectOption, isMulti: isMulti, spordleSelect: spordleSelect, previousSelectedOptions: new Map(), activeOptions: activeOptions, 
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        setActiveOption: function () { }, scrollerListRef: scrollerListRef, virtualizedListRef: virtualizedListRef, selectorRef: containerRef, isMobile: true })
                    :
                        spordleTable.dataToDisplay.length > 0 ?
                            jsx(List, { device: ios ? "ios" : "android", className: shownScrollbar().toString(), children: spordleTable.dataToDisplay.map((data, index) => (jsx(Option, { data: data, optionClick: optionClick, displayedData: spordleTable.dataToDisplay, index: index, selectOption: selectOption, unselectOption: unselectOption, spordleSelect: spordleSelect, previousSelectedOptions: new Map(), isFirst: index === 0, isLast: index === spordleTable.dataToDisplay.length - 1, isActive: false, activeOptions: activeOptions, 
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    setActiveOption: function () { }, isMobile: true }, Array.isArray(data.value) ? index : data.isGroup ? data.groupId : spordleTable.getDataIndexValue(data)))) })
                            :
                                jsx(NoResults, { children: noOptionLayout || getMessage('noOptions', intl.locale) }))] }) }), document.body));
};
MobileViewList.displayName = 'MobileViewList';export{MobileViewList as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/