import { useContext } from 'react';
import {
    ModalHeader,
    ModalBody
} from "reactstrap";
import Translate from '@spordle/intl-elements';
import RequestCall from '@spordle/request-calls';
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { OrganizationContext } from '../../../../../../../../contexts/OrganizationContext';
import ImgPill from '../../../../../../../../components/visual/imgPill/ImgPill';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';

const RegistrationWaitingListConfirmation = (props) => {

    const registrationContext = useContext(RegistrationContext);
    const orgContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext);

    return (
        <>
            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                <Translate id='participant.registrationModal.views.waitingListConfirmation.title' />
            </ModalHeader>
            <ModalBody className="py-4 bg-light">
                <div className="text-center">
                    <div className="d-inline-block position-relative mb-4">
                        <ImgPill
                            abbr={orgContext.abbreviation}
                            src={orgContext.logo?.full_path}
                            alt={orgContext.organisation_name}
                            filePos={orgContext.logo?.file_position}
                            size='xl'
                        />
                        <RequestCall call={() => orgContext.getOrganizationBranch().catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })}
                        >
                            {(snapshot) => {
                                if(!snapshot.data)
                                    return null;
                                return (
                                    <ImgPill
                                        abbr={snapshot.data?.organisation_name}
                                        src={snapshot.data?.logo?.full_path}
                                        alt={snapshot.data?.organisation_name}
                                        filePos={snapshot.data?.file_position}
                                        size='xs'
                                        className="position-absolute left-0 bottom-0"
                                    />
                                )
                            }}
                        </RequestCall>
                    </div>
                    <div className="h4 font-weight-bold mb-4"><Translate id='participant.registrationModal.views.waitingListConfirmation.thanks' /></div>
                    <div className='mb-4'><Translate id='participant.registrationModal.views.waitingListConfirmation.number' /> <span className='font-weight-bold'>{registrationContext.state.currentCart.waitingListNumber}</span></div>
                    <div><Translate id='participant.registrationModal.views.waitingListConfirmation.emailSentTo' /></div>
                    <div className="font-weight-bold mb-4">{authContext.userData.attributes.email}</div>

                    <p><Translate id='participant.registrationModal.views.waitingListConfirmation.keepAnEye' /></p>
                </div>
            </ModalBody>
        </>
    );
}

export default RegistrationWaitingListConfirmation;