const RegistrationIcon = () => {
    return (
        <svg className="registration-icon" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 22.9 24">
            <g>
                <path
                    id="path_767" className="st0" d="M20.7,7.6c0,0.6-0.5,1.1-1.1,1.1c-0.4,0-0.7-0.2-0.9-0.5c-0.1-0.2-0.2-0.4-0.2-0.6
                    c0-0.9,0-1.8,0-2.7c0-1-0.5-1.9-1.3-2.4c-0.4-0.2-0.9-0.4-1.3-0.4c-3.7,0-7.3,0-11,0c-1.4,0-2.6,1.2-2.7,2.6c0,0.1,0,0.1,0,0.2
                    c0,4.7,0,9.3,0,14c0,1,0.5,1.9,1.4,2.4c0.4,0.2,0.9,0.4,1.3,0.4c0.4,0,0.7,0,1.1,0c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
                    c0,0,0,0-0.1,0c-0.7,0-1.4,0-2.1,0c-2-0.2-3.6-1.8-3.9-3.7c0-0.2,0-0.5,0-0.7c0-2.5,0-5,0-7.5c0-2.5,0-5,0-7.5
                    c0-1.4,0.6-2.7,1.7-3.5C2.4,0.3,3.3,0,4.3,0c2.9,0,5.8,0,8.8,0c1.1,0,2.2,0,3.4,0c2.4,0,4.3,1.9,4.3,4.3
                    C20.7,5.4,20.7,6.5,20.7,7.6z"
                />
                <path
                    id="path_768" className="st0" d="M22.8,13.5c-0.3-1.7-2-2.9-3.8-2.6c-0.6,0.1-1.2,0.4-1.7,0.9c-0.8,0.8-1.6,1.5-2.3,2.3
                    c-1.6,1.5-3.1,3.1-4.7,4.6c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.5,0,0.9,0,1.4c0,0.5,0,1,0,1.6c0,0.6,0.4,1.1,1,1.1c0,0,0.1,0,0.1,0
                    c1,0,2,0,2.9,0c0.4,0.1,0.9-0.2,1.1-0.5c0,0,0,0,0-0.1c2.3-2.3,4.5-4.7,6.7-7C22.7,15.6,23,14.6,22.8,13.5z M20.4,14.8
                    c-0.2,0.3-0.5,0.5-0.8,0.8c-1.9,2-3.9,4-5.8,6c-0.1,0.1-0.2,0.1-0.2,0.1c-0.5,0-0.9,0-1.4,0h-0.2c0-0.6,0-1.1,0-1.6
                    c0-0.1,0.1-0.1,0.1-0.2c1.1-1,2.1-2.1,3.2-3.1c1.2-1.1,2.3-2.3,3.5-3.4c0.4-0.5,1.1-0.5,1.5-0.1C20.8,13.7,20.9,14.3,20.4,14.8
                    C20.5,14.8,20.4,14.8,20.4,14.8L20.4,14.8z"
                />
                <path
                    id="path_769" className="st0" d="M15.3,8.7c-0.6,0-1.2,0-1.7,0h-3.2c-1.6,0-3.2,0-4.8,0c-0.1,0-0.3,0-0.4,0
                    C4.6,8.5,4.3,8,4.4,7.5c0.1-0.5,0.5-0.9,1.1-1h9.8c0.5,0,1,0.3,1.1,0.8c0.1,0.6-0.2,1.2-0.8,1.3C15.5,8.7,15.4,8.7,15.3,8.7z"
                />
                <path
                    id="path_770" className="st0" d="M12.1,13.1c-0.5,0-0.9,0-1.4,0h-2c-1.1,0-2.1,0-3.2,0c-0.6,0-1.1-0.4-1.1-1s0.4-1.1,1-1.1
                    c0,0,0,0,0,0c0,0,0.1,0,0.1,0h6.4c0.6,0,1.1,0.4,1.1,1C13.1,12.5,12.7,13,12.1,13.1L12.1,13.1z"
                />
                <path
                    id="path_771" className="st0" d="M9.7,16.8c-0.2,0.4-0.6,0.6-1,0.6c-1.1,0-2.2,0-3.3,0c-0.6,0-1.1-0.5-1.1-1.1
                    c0-0.5,0.4-1,0.9-1.1c0.1,0,0.2,0,0.3,0c0.5,0,1,0,1.5,0l0,0c0.6,0,1.1,0,1.7,0c0.6,0,1.1,0.5,1,1.1C9.8,16.5,9.8,16.7,9.7,16.8z"
                />
            </g>
        </svg>
    )
}

export default RegistrationIcon;