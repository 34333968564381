import { useContext } from 'react';

/**
 * Function that returns a HOC with the context's as props
 * NOTE: Define a displayName for your contexts
 */
function withContexts(){
    const contexts = {};

    for(let index = 0; index < arguments.length; index++){
        const element = arguments[index];
        if(element)
            contexts[element.displayName] = element;
    }

    return function(Component){
        return (props) => {
            const tempContexts = {}
            for(const key in contexts){
                if(Object.prototype.hasOwnProperty.call(contexts, key)){
                    tempContexts[key] = useContext(contexts[key]);
                }
            }

            return <Component {...props} {...tempContexts} />
        }
    };
}

export default withContexts;