export default{
    // Global
    "participant.registrationModal.needSupport" : "Problème avec votre inscription? <a>Contactez-nous</a>",

    // Registration Existing or New
    "participant.registrationModal.views.existingOrNew.title"                          : "Inscrire un participant",
    "participant.registrationModal.views.existingOrNew.btn.start"                      : "Commencer l'inscription",
    "participant.registrationModal.views.existingOrNew.registerExisting.skipToPayment" : "Passer au paiement",
    "participant.registrationModal.views.existingOrNew.registerExisting.title"         : "Inscrire un participant existant",
    "participant.registrationModal.views.existingOrNew.registerExisting.desc"          : "A déjà participé en tant que joueur, entraîneur, officiel ou bénévole.",
    "participant.registrationModal.views.existingOrNew.registerNew.title"              : "Inscrire un nouveau participant",
    "participant.registrationModal.views.existingOrNew.registerNew.desc"               : "N'a jamais participé dans une organisation de hockey.",
    "participant.registrationModal.views.existingOrNew.registerNew.warning"            : "Avant de {create} et remplir un {form}, assurez-vous que le membre n'a jamais participé en tant que joueur, entraîneur, officiel ou bénévole dans une organisation de hockey.",
    "participant.registrationModal.views.existingOrNew.registerNew.create"             : "Créer un nouveau membre",
    "participant.registrationModal.views.existingOrNew.registerNew.form"               : "un formulaire d'inscription de nouveau membre",
    "participant.registrationModal.views.existingOrNew.registerIdk.title"              : "Je ne sais pas",
    "participant.registrationModal.views.existingOrNew.registerIdk.desc"               : "Je ne sais pas si j'ai participé dans une organisation de hockey.",

    // Registration New Participant
    "participant.registrationModal.views.newParticipant.title"                : "Créer un participant",
    "participant.registrationModal.views.newParticipant.validation.dateRange" : "La date doit représenter un âge 4 et 100 ans",
    "participant.registrationModal.views.newParticipant.validation.relation"  : "Veuillez sélectionner une relation",
    "participant.registrationModal.views.newParticipant.btn.error.2000"       : "Cliquez ici pour éditer le prénom et le nom de ce membre",
    "participant.registrationModal.views.newParticipant.btn.editAge"          : "Cliquez ici si vous souhaitez changer la date de naissance",
    "participant.registrationModal.views.newParticipant.lang"                 : "Indiquer dans laquelle des langues officielles du Canada, français ou anglais, vous préférez recevoir votre correspondance de Hockey Canada.",

    "participant.registrationModal.views.documents.text" : "Vous êtes sur le point de créer un nouveau profil. Veuillez prendre note que l’organisation pourrait avoir besoin de certains documents et sera en contact avec vous le cas échéant.",

    // Registration Participant List
    "participant.registrationModal.views.registrationParticipantList.title"             : "Inscription d'un participant",
    "participant.registrationModal.views.registrationParticipantList.btn.search"        : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "Rechercher un participant" : "Rechercher un participant HCR",
    "participant.registrationModal.views.registrationParticipantList.selectParticipant" : "Selectionnez un participant de votre compte Spordle",
    "participant.registrationModal.views.registrationParticipantList.noParticipant"     : "Vous n'avez pas de participant dans votre compte Spordle",

    // Registration participant single
    "participant.registrationModal.views.registrationParticipantSingle.edit"               : "Modifier l'inscription de ce membre",
    "participant.registrationModal.views.registrationParticipantSingle.noParticipant"      : "Ce participant est associé avec {associatedOrg}. Pour procéder avec {currentOrg}, il vous faut envoyer une requête de transfert.",
    "participant.registrationModal.views.registrationParticipantSingle.btn.goTo"           : "Aller vers",
    "participant.registrationModal.views.registrationParticipantSingle.btn.proceedRequest" : "Poursuivre avec la requête",
    "participant.registrationModal.views.registrationParticipantSingle.age.warning"        : "Ce participant a moins de 4 ans, donc il est possible qu'il ne puisse pas participer.",
    "participant.registrationModal.views.registrationParticipantSingle.draft"              : "Inscription incomplète",

    // Registration HCR Search
    "participant.registrationModal.views.registrationHcrSearch.btn.create"                    : "Créer un nouveau participant",
    "participant.registrationModal.views.registrationHcrSearch.primaryOrg"                    : "Organisation primaire",
    "participant.registrationModal.views.registrationHcrSearch.search.firstName.regex"        : "Le prénom doit être d'un bon format",
    "participant.registrationModal.views.registrationHcrSearch.search.lastName.regex"         : "Le nom doit être d'un bon format",
    "participant.registrationModal.views.registrationHcrSearch.search.hcrNb"                  : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "Rechercher avec le ID membre" : "Rechercher avec # HCR",
    "participant.registrationModal.views.registrationHcrSearch.search.result.profile"         : "Nous avons trouvé un/des profil(s) correspondant(s). Est-ce vous ?",
    "participant.registrationModal.views.registrationHcrSearch.search.result.noProfile"       : "Nous n'avons pas pu trouver un profil correspondant, souhaitez-vous créer un nouveau participant ?",
    "participant.registrationModal.views.registrationHcrSearch.search.result.noProfileSimple" : "Nous n'avons pas pu trouver un profil correspondant. Veuillez utiliser les filtres ci-dessus pour changer votre recherche.",
    "participant.registrationModal.views.registrationHcrSearch.search.result.text"            : "Ce(s) profile(s) ne m'appartient pas, continuer la création du nouveau participant.",
    "participant.registrationModal.views.registrationHcrSearch.title.search"                  : "Rechercher un participant",
    "participant.registrationModal.views.registrationHcrSearch.title.create"                  : "Inscrire un nouveau participant",
    "participant.registrationModal.views.registrationHcrSearch.date.minAge"                   : "Un membre doit avoir au moins 4 ans pour participer. Cependant, vous pouvez toujours créer ce membre, mais l'organisation pourrait ne pas accepter l'inscription.",
    "participant.registrationModal.views.registrationHcrSearch.date.minDate"                  : "La date de naissance doit être avant aujourd'hui.",
    "participant.registrationModal.views.registrationHcrSearch.date.placeholder"              : "AAAA-MM-JJ",
    "participant.registrationModal.views.registrationHcrSearch.alreadyInAccount"              : "Ce membre est déjà dans votre compte!",
    "participant.registrationModal.views.registrationHcrSearch.notAlreadyInAccount"           : "Ce membre est déjà dans un autre compte!",
    "participant.registrationModal.views.registrationHcrSearch.wrongOrg"                      : "Ce membre fait partie d'une autre organisation!",

    // Registration options
    "participant.registrationModal.views.registrationOptions.noRegistration" : "Aucune inscription disponible à {orgName}",
}