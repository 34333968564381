/*
@Copyrights Spordle 2022 - All rights reserved
*/
/**
 * Checks if a translation is available for the current Locale, returns default value or null if not
 * @param {Object} i18n API Object containing I18N variables
 * @param {string} locale Current Locale
 * @param {string} field Name of the Field
 * @returns {string|null}
 */
function displayI18n(field, i18n, defaultValue, locale) {
    if (i18n?.[locale]?.[field]) {
        return i18n[locale][field];
    }
    return defaultValue || null;
}
async function copyToClipBoard(text) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (navigator.clipboard && window.isSecureContext) { // copy api https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
            await navigator.clipboard.writeText(text);
        }
        else { // fallback
            await _fallbackCopy(text);
        }
    }
    catch (err) {
        console.error(err);
    }
}
async function _fallbackCopy(text) {
    const textArea = document.createElement("textarea");
    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
    // May cease to work, but it still is a fallback for some browsers
    const successful = document.execCommand('copy');
    document.body.removeChild(textArea);
    if (!successful) {
        throw new Error('Could not copy');
    }
    return Promise.resolve(successful);
}export{copyToClipBoard,displayI18n};
/*
@Copyrights Spordle 2022 - All rights reserved
*/