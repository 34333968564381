/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs,Fragment}from'react/jsx-runtime';import'@spordle/helpers';import Fuse from'fuse.js';import PropTypes from'prop-types';import {createContext,Component}from'react';import {Row,Col}from'reactstrap';import GLOBAL_CONFIGS from'./globalConfigs.js';import PaginationHandler from'./PaginationHandler.js';import Refresh from'./Refresh.js';import SearchInput from'./SearchInput.js';import {isNumber,isDate,normalize,getValueFromString,arrayMove}from'./utils.js';import memoizeOne from'memoize-one';import isDeepEqual from'lodash.isequal';// @ts-ignore
const SpordleTableContext = createContext(null);
SpordleTableContext.displayName = 'SpordleTableContext';
/**
 * @class SpordleTableProvider
 * @namespace SpordleTableProvider
 * @see Full documentation for {@link https://github.com/Spordle/DataTables/packages/356339?version=5.3.6 this version} and {@link https://github.com/Spordle/DataTables/releases/tag/v5.3.6 release notes}
 * @description
 * The SpordleTableProvider acts like a DataTable.<br>
 * It can handle column filtering, custom rendering, mobile rendering, filters and pagination with thousands of data.<br>
 * Props in `[]` are optional.
 *
 * @prop {string} id An id to prefix all SpordleTable's DOM element
 *
 * @prop {Array.<Column>} [columns=[]] Initial columns to be rendered
 *
 * @prop {RenderRow} [renderRow] A way to render custom columns
 *
 * @prop {string|boolean} [desktopWhen] The bootstrap delimeter to show the table for desktop format - Table is shown as 'mobile' by default
 *
 * @prop {JSX.Element|React.ComponentType<{ emptySearch: boolean }>} [emptyLayout] An empty layout to display when there is no data OR no data fits the filters
 *
 * @prop {boolean} [tableHover] If the table has a hover effect
 *
 * @prop {boolean} [clickable] This will apply a class to change the cursor
 *
 * @prop {boolean} [striped] If the table is striped
 *
 * @prop {boolean} [bordered] If the table is bordered
 *
 * @prop {boolean} [borderless] If the table is borderless
 *
 * @prop {boolean} [hideHeader] If we want to hide the `thead`
 *
 * @prop {string} [tableClassName] CSS classes that will be applied to the Table
 *
 * @prop {string} [footerClassName] CSS classes that will be applied to the table's tfoot tag
 *
 * @prop {string} [headerClassName] CSS classes that will be applied to the table's thead tag
 *
 * @prop {number} [automaticRefresh] The interval (in ms) where the spordleTable will refresh -> Calls `loadData` with `REFRESH`
 *
 * @prop {Array.<object>} [searchKeys=[]] Array of keys to include in the search - {@link https://fusejs.io/api/options.html#keys fuse.js keys}
 *
 * @prop {boolean} [skipJsSearch=false] Will prevent the search from triggering. This let's you implment a custom search algorithm with the help of `loadData` from `SEARCH`
 *
 * @prop {number} [searchLimit] Max number of returned search results - {@link https://fusejs.io/api/methods.html#search fuse.js search}
 *
 * @prop {number} [searchThreshold=0.2] The search threshold - From `0` to `1` -> A threshold of `0.0` requires a perfect match and a threshold of `1.0` would match anything
 *
 * @prop {string|ReactNode} [loadingMessage=misc.loading] A string index to use when data is loading OR a custom JSX
 *
 * @prop {string|JSX.Element|Component} [errorMessage=misc.error] A string index to use for error message translation OR a custom JSX OR a component
 *
 * @prop {string|PaginationMessageBuilder} [paginationMessage] A string index to use for the pagination message or a function to build the message in a custom way - See {@link PaginationMessageValues} for the string's values
 *
 * @prop {number} [pagination=0] The number of item to display per page
 *
 * @prop {ColumnSorting} [columnSorting] A custom way to sort the data
 *
 * @prop {string} [defaultSorting] The default column sorting. Must be this format: `-columnKey` OR `+columnKey`
 *
 * @prop {string} [defaultSearchValue] The default search value
 *
 * @prop {boolean} [stickyHeader] If the column header will be sticky
 *
 * @prop {boolean} [hasFooter] If the table has a footer
 *
 * @prop {RenderFooter} [renderFooter] A callback function to render the footer in a custom way
 *
 * @prop {boolean} [smallTable] If the table should be smaller
 *
 * @prop {React.RefObject<HTMLTableElement>} [tableRef] The ref to the actual html table
 *
 * @prop {boolean} [swapColumns] If the columns can be reordered
 *
 * @prop {LoadingStatus} [loadingStatus="lazy"] The default loading status
 *
 * @prop {OnColumnClick} [onColumnClick] A callback function for when the click event is triggered
 *
 * @prop {Array.<Data>} [defaultData] The default {@link Data} to render - This data is use only in the contructor of the table. If you wish to add/remove data dynamically, please use the methods provided for that: {@link SpordleTableProvider.addRow} and {@link SpordleTableProvider.deleteRow}
 *
 * @prop {RowIsHighlighted} [rowIsHighlighted] Callback function to determine if the row should be highlighted
 *
 * @prop {string|GetDataIndexValue} [dataIndex=id] This index must be unique as it it used to map the data when rendering
 *
 * @prop {LoadData} [loadData] Function that is being called when a new search needs to be done
 *
 * @prop {object} [initFilter] The initial values of the filter values
 *
 * @prop {ViewModes} [viewMode="LIST"] How to display the data
 *
 * @prop {any} [defaultAdditionalData] Any data that we want to pass throughout the SpordleTable's provider
 *
 * @prop {FilterJSCallback} [filterJSCallback] A callback function to filter the data
 *
 * @prop {boolean} [debugMode=false] This will log some additional data from within the SpordleTable such as performance. Is disabled in production builds. It can also be activated in production build when typing `:spordleTableDebug:` in the search input.
 *
 * @prop {FuseOptions} [fuseOptions] Some of the fuse search options.<br>Some options are excluded because they are either controlled by other props or must not be overridden.<br>**This prop should not be used unless it is necessary**.<br>{@link https://fusejs.io/api/options.html fuse options}
 *
 * @example
 * <SpordleTableProvider
 *    id='someId'
 *    ref={r => this.spordleTable = r}
 *    tableHover
 *    clickable
 *    stickyHeader
 *    hasFooter
 *    swapColumns
 *    striped
 *    bordered
 *    borderless
 *    hideHeader
 *    smallTable
 *    defaultSearchValue='load table with this searchValue'
 *    dataIndex='team_id'
 *    tableClassName='p-3'
 *    footerClassName='p-3'
 *    automaticRefresh={5000}
 *    searchKeys={[]}
 *    searchLimit={20}// Will return only 20 results
 *    searchThreshold={0.5}
 *    renderRow={(key, tournament, spordleTable) => {
 *       switch (key) {
 *           case "tournamentName":
 *               return (
 *                   <div className="d-inline-flex align-items-center">
 *                       <div className="mr-2">
 *                           <img
 *                               src={tournament.image}
 *                               alt="Logo"
 *                               className="rounded-circle"
 *                               width="50"
 *                           />
 *                       </div>
 *                       <div>
 *                           <div className="font-medium text-dark">{tournament.organisationName}</div>
 *                           <I18nContext.Consumer>
 *                               {({getGenericLocale}) => <div className="text-muted">{tournament.membershipInfo[getGenericLocale()]?.name} - {tournament.type}</div>}
 *                           </I18nContext.Consumer>
 *                       </div>
 *                   </div>
 *               )
 *               case "dates":
 *                   return <span><DateFormat date={tournament.from}/> - <DateFormat date={tournament.to}/></span>;
 *               case "price":
 *                   return <span><CurrencyFormat value={tournament.price / 100}/></span>;
 *               case "bought":
 *                 switch (tournament.bought) {
 *                     case 'ALREADY_PAID':
 *                         return <Badge color='success' pill>{translate('team.subscription.status.COMPLETED')}</Badge>;
 *                     case 'AWAITING_APPROVAL':
 *                         return <Badge color='warning' pill>{translate('team.subscription.status.NEED_APPROVAL')}</Badge>;
 *                     case 'TO_PAY':
 *                         return <Badge color='info' pill>{translate('tournaments.column.boughtStatus.TOPAY')}</Badge>;
 *                     default:
 *                         break;
 *                 }
 *               default:
 *                   break;
 *           }
 *    }}
 *    columns={[
 *         {
 *             label: <Translate id='tournaments.column.name'/>,
 *             key: "tournamentName",
 *             className: 'd-none d-md-table-cell',
 *             dataClassName: '',
 *             mobile: true,
 *             sortable: true,
 *             sortKey: 'tournament.i18n.fr.name',
 *             fallbackSortKey: 'tournament.name',
 *         },
 *         {
 *             label: <Translate id='tournaments.column.dates'/>,
 *             key: "dates",
 *             className: '',
 *             mobile: true,
 *             sortable: true,
 *             fallbackSortKey: 'start_date',
 *         },
 *         {
 *             label: <Translate id='tournaments.column.boughtStatus'/>,
 *             key: "bought",
 *             className: 'd-none d-md-table-cell',
 *             mobile: true
 *         },
 *         {
 *             label: <Translate id='tournaments.column.price'/>,
 *             key: "price",
 *             className: '',
 *             dataClassName: '',
 *             mobile: true,
 *             sortable: true
 *         }
 *    ]}
 *    onColumnClick={(e, rowData, spordleTable, columnName) => {
 *        switch(e.button){
 *            case 0: // Left mouse button
 *                console.log('Left', columnName, rowData)
 *                break;
 *            case 1: // Middle mouse click
 *                console.log('Middle', columnName, rowData)
 *                break;
 *            case 2: // Right mouse click
 *                e.preventDefault() // Use to prevent having the default contextMenu
 *                console.log('Right', columnName, rowData)
 *                break;
 *        }
 *    }}
 *    emptyLayout={<h2 className="h6 font-bold">Empty layout</h2>}
 *    initFilter={{
 *        type: [],
 *        received: null
 *    }}
 *    loadData={this.loadData}
 *    filterJSCallback={(transfer, filters) => {
 *        return statusFilter(transfer, filters) && actionFilter(transfer, filters) && typeFilter(transfer.transfer_type, filters)
 *    }}
 *    columnSorting={(tournamentA, tournamentB, spordleTable, orderingData) => {
 *         switch (orderingData.column) {
 *             case 'dates':
 *                 let dateA = `${tournamentA.from} - ${tournamentA.to}`;
 *                 let dateB = `${tournamentB.from} - ${tournamentB.to}`;
 *
 *                 if(dateA === dateB){
 *                     return 0;
 *                 }
 *
 *                 if(orderingData.order === 'ASC'){
 *                     return dateA > dateB ? 1 : -1
 *                 }else{ // orderingData.order is 'DESC'
 *                     return dateA > dateB ? -1 : 1
 *                 }
 *             case 'tournamentName':
 *                 if(tournamentA.organisationName.toLowerCase() === tournamentB.organisationName.toLowerCase()){
 *                     return 0
 *                 }
 *
 *                 if(orderingData.order === 'ASC'){
 *                     return tournamentA.organisationName.toLowerCase() > tournamentB.organisationName.toLowerCase() ? 1 : -1;
 *                 }else{ // orderingData.order is 'DESC'
 *                     return tournamentA.organisationName.toLowerCase() > tournamentB.organisationName.toLowerCase() ? -1 : 1
 *                 }
 *             default:
 *                 break;
 *        }
 *    }}
 *    renderFooter={(columnKey, total, spordleTable) => {
 *        switch (columnKey) {
 *            case 'registrationsCount':
 *                return <span className='text-danger'>{total}</span>
 *            default:
 *                break;
 *        }
 *    }}
 *    rowIsHighlighted={(rowData, index, spordleTable) => index % 2 === 1}
 *    pagination={20}
 *    desktopWhen='md'
 *    loadingMessage='misc.loading'
 *    errorMessage={(props) => {
 *      // This is a react component. We can put states/hooks here.
 *    	 return (
 *       	<div className='text-danger text-center'>
 *       		This is a custom error message:
 *       		<br/>
 *       		{props.error.error}
 *       	</div>
 *       );
 *    }}
 *    paginationMessage='tournaments.table.paginate.message'
 *    defaultSorting='-registrationsCount'
 *    tableRef={r => this.innerTableRef = r}
 *    viewMode='GRID'
 *    gridConfig={{
 *       col: {
 *           md: 4
 *       },
 *       gridRender: (data, spordleTable) => {
 *           return(
 *               <Card body className='border clickable'>
 *                   <div>
 *                       {data.name}
 *                   </div>
 *                   {spordleTable.isMobile() &&
 *                       <div>
 *                           Only in mobile
 *                       </div>
 *                   }
 *                   <div>
 *                       <CurrencyFormat value={data.balance / 100}/>
 *                   </div>
 *               </Card>
 *           )
 *       }
 *   }}
 * >
 *     // Option 1
 *     <TopSection/>// uses <SpordleTableContext.Consumer> inside to access the methods/data
 *     <SpordleTableView/>
 *     // Option 2
 *     {spordleTable => (
 *          <>
 *                <input onChange={spordleTable.searchInputChange}/>
 *                <SpordleTableView/>
 *          </>
 *     )}
 * <SpordleTableProvider/>
 * @access default
 * @copyright Spordle
*/
class SpordleTableProvider extends Component {
    static propTypes = {
        // Mandatory
        id: PropTypes.string.isRequired,
        // Optional
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
        columns: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.elementType]).isRequired,
            key: PropTypes.string.isRequired,
            defaultKey: PropTypes.string,
            sortKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            fallbackSortKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            className: PropTypes.string,
            dataClassName: PropTypes.string,
            mobile: PropTypes.bool,
            sortable: PropTypes.bool,
            hasTotal: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        })),
        renderRow: PropTypes.func,
        desktopWhen: PropTypes.oneOf(["md", "sm", "lg", "xl", true, false]),
        emptyLayout: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
        hideHeader: PropTypes.bool,
        bordered: PropTypes.bool,
        borderless: PropTypes.bool,
        striped: PropTypes.bool,
        tableHover: PropTypes.bool,
        clickable: PropTypes.bool,
        automaticRefresh: PropTypes.number,
        searchKeys: PropTypes.array,
        searchLimit: PropTypes.number,
        searchThreshold: PropTypes.number,
        loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.elementType]),
        paginationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        pagination: PropTypes.number,
        columnSorting: PropTypes.func,
        defaultData: PropTypes.arrayOf(PropTypes.shape({
            footer: PropTypes.objectOf(PropTypes.number),
        })),
        defaultSorting: PropTypes.string,
        defaultSearchValue: PropTypes.string,
        stickyHeader: PropTypes.bool,
        hasFooter: PropTypes.bool,
        renderFooter: PropTypes.func,
        swapColumns: PropTypes.bool,
        loadingStatus: PropTypes.oneOf(['lazy', 'error', 'success', 'loading']),
        onColumnClick: PropTypes.func,
        tableClassName: PropTypes.string,
        footerClassName: PropTypes.string,
        rowIsHighlighted: PropTypes.func,
        smallTable: PropTypes.bool,
        tableRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.string,
            PropTypes.object,
        ]),
        dataIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        loadData: PropTypes.func,
        initFilter: PropTypes.object,
        viewMode: PropTypes.oneOf(['LIST', 'GRID']),
        gridConfig: PropTypes.shape({
            // @ts-ignore
            row: PropTypes.shape(Row.propTypes),
            // @ts-ignore
            col: PropTypes.shape(Col.propTypes),
            gridRender: PropTypes.func.isRequired,
        }),
        defaultAdditionalData: PropTypes.any,
        filterJSCallback: PropTypes.func,
        debugMode: PropTypes.bool,
    };
    static defaultProps = {
        pagination: 0,
        dataIndex: 'id',
        viewMode: 'LIST',
        loadingStatus: 'lazy',
        columns: [],
        errorMessage: 'misc.error',
        loadingMessage: 'misc.loading',
        searchKeys: [],
        searchThreshold: 0.2,
    };
    static displayName = 'SpordleTableProvider';
    /**
     * @description Props that will be used no matter the prop passed to the SpordleTableProvider's instance
     * @static
     * @memberof SpordleTableProvider
     */
    static globalConfig = GLOBAL_CONFIGS;
    _isMounted;
    // eslint-disable-next-line no-undef
    _ga4SearchTimeOut;
    // eslint-disable-next-line no-undef
    _lazyLoadTimeOut;
    // eslint-disable-next-line no-undef
    _automaticRefresh;
    _filters;
    _desktopWhenRef;
    _additionalData;
    _collator;
    _debugModeString = ':spordleTableDebug:';
    // Static components
    /**
     * @static
     * @memberof SpordleTableProvider
     * @description Use module import instead
     * @deprecated
     * @example import { SearchInput } from '@spordle/datatables;
     */
    static SearchInput = SearchInput;
    /**
     * @static
     * @memberof SpordleTableProvider
     * @description Use module import instead
     * @deprecated
     * @example import { Refresh } from '@spordle/datatables;
     */
    static Refresh = Refresh;
    /**
     * @static
     * @memberof SpordleTableProvider
     * @description Use module import instead
     * @deprecated
     * @example import { Paginator } from '@spordle/datatables;
     */
    static Paginator = PaginationHandler;
    error;
    constructor(props) {
        super(props);
        this._filters = props.initFilter ?? {};
        this._isMounted = false;
        this._ga4SearchTimeOut = null;
        this._lazyLoadTimeOut = null;
        this._automaticRefresh = null;
        this._desktopWhenRef = null;
        this._additionalData = props.defaultAdditionalData;
        this._collator = new Intl.Collator(undefined, { sensitivity: 'base', numeric: true });
        this.state = {
            searchValue: props.defaultSearchValue ?? '',
            data: props.defaultData ?? [],
            loadingState: props.loadingStatus,
            isMobile: props.desktopWhen !== true,
            currentPage: 0,
            orderingBy: props.defaultSorting ? this._getColumnSortingFromString(props.defaultSorting) : { column: undefined, order: undefined },
            orderedColumns: props.columns.map(this._createColumn),
            pagination: props.pagination,
            view: {
                mode: props.viewMode,
                config: props.gridConfig ?? {
                    gridRender: () => jsx("div", {}),
                },
            },
        };
    }
    //////////////////////////// Component lifecycle functions ////////////////////////////
    componentDidMount() {
        this._isMounted = true;
        this._fetchDataFromApi('CDM');
        this._lazyLoadTimeOut = setTimeout(() => {
            if (this.state.loadingState === 'lazy')
                this.setLoading();
            this._lazyLoadTimeOut = null;
        }, 500);
        this._initAutomaticRefresh();
        if (this.props.desktopWhen !== true) {
            window.addEventListener('resize', this._resize);
            this._resize();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        if (this._lazyLoadTimeOut) {
            clearTimeout(this._lazyLoadTimeOut);
            this._lazyLoadTimeOut = null;
        }
        if (this._automaticRefresh) {
            clearInterval(this._automaticRefresh);
            this._automaticRefresh = null;
        }
        window.removeEventListener('resize', this._resize);
    }
    //////////////////////////// Private functions ////////////////////////////
    /**
     * Function that automatically add the `mobile: true`
     * @private
     */
    _createColumn = (column) => ({
        mobile: true,
        ...column,
    });
    /**
     * Determines if debug functionnalities should be activated
     * @private
     */
    // eslint-disable-next-line no-undef
    _isDebugMode = () => this.state.searchValue.includes(this._debugModeString) || (process.env.NODE_ENV === "development" && !!this.props.debugMode);
    /**
     * Gets the search value without the debug search string
     * @private
     */
    _getValidSearch = () => this.state.searchValue.replace(this._debugModeString, "").trim();
    /**
     * Initiate the automaticRefresh feature
     * @private
     */
    _initAutomaticRefresh = () => {
        if (this._automaticRefresh)
            clearInterval(this._automaticRefresh);
        if (this.props.automaticRefresh) {
            this._automaticRefresh = setInterval(() => {
                this.refreshTable();
            }, this.props.automaticRefresh);
        }
    };
    /**
     * Function provided to the rezise event listener
     * @private
     */
    _resize = () => {
        this.setMobile(!!this._desktopWhenRef && window.getComputedStyle(this._desktopWhenRef).display !== 'none');
    };
    /**
     * Function to retrive the desktop when class that needs to be applied
     * @private
     */
    _getDesktopWhenClass = () => {
        switch (this.props.desktopWhen) {
            case 'xl':
                return 'd-xl-none';
            case 'lg':
                return 'd-lg-none';
            case 'md':
                return 'd-md-none';
            case 'sm':
                return 'd-sm-none';
            default:
                return;
        }
    };
    /**
     * Trigger the GA4 search event with the given search value.
     * @param {string} searchValue
     * @private
     */
    _triggerGa4SearchEvent = async (searchValue) => {
        try {
            const sendGAEvent = (await import('@spordle/ga4')).sendGAEvent;
            if (this._ga4SearchTimeOut) {
                clearTimeout(this._ga4SearchTimeOut);
                this._ga4SearchTimeOut = null;
            }
            this._ga4SearchTimeOut = setTimeout(() => {
                sendGAEvent('search', { search_term: searchValue });
                this._ga4SearchTimeOut = null;
            }, 500);
        }
        catch (error) {
            // @spordle/ga4 is optional and might not be imported -> This try catch catches this case
            if (this._ga4SearchTimeOut) {
                clearTimeout(this._ga4SearchTimeOut);
                this._ga4SearchTimeOut = null;
            }
        }
    };
    /**
     * Take a string to define which column to sort by and how
     * @param {string} value The string to split
     * @example
     * this._getColumnSortingFromString('-registrationsCount')
     * @returns An orderingBy object
     * @private
     */
    _getColumnSortingFromString = (value) => {
        const columnSortingRegex = new RegExp(/^(\+|-).{1,}$/);
        if (columnSortingRegex.test(value)) {
            const [order, column] = value.split(/^(\+|-)/).filter((e) => e.length !== 0);
            return {
                column: column,
                order: order === '+' ? 'ASC' : 'DESC',
            };
        }
        throw new Error(`The column sorting value(${value}) must match this regex: ${columnSortingRegex.toString()}`);
    };
    /**
     * Gets the extra data
     * @private
     */
    _getExtraData = () => ({
        currentPage: this.state.currentPage,
        filters: this._filters,
        orderBy: this.state.orderingBy,
        searchValue: this._getValidSearch(),
    });
    /**
     * This function will fetch the data from the api if needed
     * @param from Where the call is coming from
     * @private
     */
    _fetchDataFromApi = (from) => {
        if (this._isMounted && this.props.loadData) {
            return this.props.loadData(from, this._getExtraData(), this)?.then((data) => {
                if (Array.isArray(data)) {
                    this.setData(data);
                }
                else if (data !== true) {
                    this.setError(data);
                }
            })
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                .catch(this._isMounted ?
                (error) => {
                    console.error(error);
                    this.setError(error);
                }
                :
                    function () { })
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                .then(this._isMounted && from === 'REFRESH' ? this._initAutomaticRefresh : function () { });
        }
    };
    /**
     * Function that build a search string for Fuse's extended search
     * @returns {string}
     * @private
     */
    _buildFuseSearchString = (validSearchValue, isDebug) => {
        // regex that gets all search inputs including raw fuse's extended search params
        const splitSearch = validSearchValue.match(/[^\s]*"[^"]*"[^\s]*|[^\s"]+/g)?.filter((searchValue) => searchValue) || [];
        const [extendedSearchValues, numberedSearch, dateResults, stringedSearch,] = splitSearch.reduce((valueTypes, searchValue) => {
            if (["'", "=", "!", "^", "$"].some((char) => searchValue.includes(char))) {
                valueTypes[0].push(searchValue);
            }
            else if (isNumber(searchValue)) {
                valueTypes[1].push(searchValue);
            }
            else if (isDate(searchValue)) {
                valueTypes[2].push(searchValue);
            }
            else {
                valueTypes[3].push(searchValue);
            }
            return valueTypes;
        }, [
            [],
            [],
            [],
            [], // other
        ]);
        const fuseArray = extendedSearchValues;
        fuseArray.pushArray(numberedSearch.map((number) => `'${number}`));
        fuseArray.pushArray(dateResults.map((searchValue) => `^${searchValue}`));
        const spacedSearchvalue = stringedSearch.map((searchValue) => `'${searchValue}`).join(" ");
        if (spacedSearchvalue)
            fuseArray.push(spacedSearchvalue);
        fuseArray.pushArray(stringedSearch);
        const finalSearchString = fuseArray.join("|").trim();
        if (isDebug)
            console.log('Fuse search string', finalSearchString);
        return finalSearchString;
    };
    /**
     * A memoized function to create a Fuse index.
     *
     * This helps to improve performances when there is a search value
     * @private
     */
    _createFuseIndex = memoizeOne(Fuse.createIndex, isDeepEqual);
    /**
     * Function to get the data according to the search value.
     * Determines the rows that pass the filtering tests.
     * Doesn't filter when the searchValue is empty.
     * @private
     */
    _getDataFromSearchFilters = (isDebug) => {
        const trimmedSearch = this._getValidSearch();
        let copiedData = this.state.data.slice();
        if (this.props.filterJSCallback) {
            // @ts-ignore
            copiedData = copiedData.filter((rowData) => this.props.filterJSCallback(rowData, this._filters, this));
        }
        const triggerFuseSearch = !this.props.skipJsSearch && trimmedSearch;
        if (triggerFuseSearch) {
            const spordleTable = this; // eslint-disable-line @typescript-eslint/no-this-alias
            const fuseOptions = {
                ignoreFieldNorm: false,
                shouldSort: true,
                isCaseSensitive: false,
                useExtendedSearch: true,
                ignoreLocation: true,
                ...this.props.fuseOptions,
                includeScore: isDebug,
                includeMatches: isDebug,
                threshold: this.props.searchThreshold,
                keys: this.props.searchKeys,
                getFn: function () {
                    // @ts-ignore
                    const values = Fuse.config.getFn.apply(this, arguments); // eslint-disable-line prefer-rest-params
                    const normalizedValues = Array.isArray(values) ? values.map(normalize) : normalize(values);
                    return spordleTable.props.fuseOptions?.getSearchValue?.(normalizedValues, arguments[0], arguments[1].join('.'), spordleTable) ?? normalizedValues; // eslint-disable-line prefer-rest-params
                },
            };
            // @ts-ignore
            copiedData = new Fuse(copiedData, fuseOptions, this._createFuseIndex(this.props.searchKeys, copiedData, fuseOptions)).search(fuseOptions.useExtendedSearch ? this._buildFuseSearchString(normalize(trimmedSearch), isDebug) : normalize(trimmedSearch), this.props.searchLimit ? { limit: this.props.searchLimit } : undefined);
        }
        if (triggerFuseSearch && isDebug)
            console.log('searchResults', copiedData);
        return triggerFuseSearch ? copiedData.map((fuseResult) => fuseResult.item) : copiedData;
    };
    /**
     * This will calculate the data to be rendered
     * @private
     */
    _getDataToShow() {
        const isDebug = this._isDebugMode();
        if (isDebug) {
            console.group('SpordleTable data processing');
            console.time('search/filters');
            console.time('total data processing');
        }
        const dataToShow = this._getDataFromSearchFilters(isDebug);
        if (isDebug)
            console.timeEnd('search/filters');
        // Sorting the rows according to the customSorting or the default sorting
        if (this.state.orderingBy.column) {
            const sortingData = this.state.view.mode === 'LIST' ? this.state.orderingBy : undefined;
            const column = this.state.orderedColumns.find((column) => column.key === this.state.orderingBy.column);
            dataToShow.sort((itemA, itemB) => {
                if (!this.state.orderingBy.column || !this.state.orderingBy.order) {
                    return 0;
                }
                const customSorting = this.props.columnSorting?.(itemA, itemB, this, sortingData);
                if (isNumber(customSorting))
                    return customSorting;
                if (column) {
                    const stringValueA = (
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    getValueFromString(itemA, typeof column.sortKey === "function" ? column.sortKey(itemA, this.state.orderingBy.order) : column.sortKey || column.key) // Try to get a value from main keys
                        ?? (column.fallbackSortKey ? getValueFromString(itemA, typeof column.fallbackSortKey === "function" ? column.fallbackSortKey(itemA, this.state.orderingBy.order) : column.fallbackSortKey) : undefined) // If no value, then try to get from fallbackKey
                    );
                    const stringValueB = (
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    getValueFromString(itemB, typeof column.sortKey === "function" ? column.sortKey(itemB, this.state.orderingBy.order) : column.sortKey || column.key) // Try to get a value from main keys
                        ?? (column.fallbackSortKey ? getValueFromString(itemB, typeof column.fallbackSortKey === "function" ? column.fallbackSortKey(itemB, this.state.orderingBy.order) : column.fallbackSortKey) : undefined) // If no value, then try to get from fallbackKey
                    );
                    const sortResult = this._collator.compare(stringValueA, stringValueB);
                    if (this.state.orderingBy.order === 'DESC')
                        return sortResult * -1;
                    return sortResult;
                }
                return 0;
            });
        }
        /**
         * Function to determine the data to show according to the pagination
         * @returns {Array.<Data>} The data to show from the pagination
         */
        const renderData = () => {
            if (this.state.pagination > 0) {
                const startIndex = this.state.pagination * this.state.currentPage;
                return dataToShow.slice(startIndex, startIndex + this.state.pagination);
            }
            return dataToShow;
        };
        if (isDebug) {
            console.timeEnd('total data processing');
            console.groupEnd();
        }
        return {
            dataToDisplay: renderData(),
            filteredData: dataToShow,
        };
    }
    //////////////////////////// Functions to be accessed outside the component with the use of react refs ////////////////////////////
    /**
     * Function to retrive the `dataIndex` string value
     * @param {Object} data
     * @returns {string}
     * @public
     */
    getDataIndexValue = (data) => {
        if (this.props.dataIndex instanceof Function) {
            return this.props.dataIndex(data, this);
        }
        return getValueFromString(data, this.props.dataIndex); // Assuming it will give a string -> Will cause errors/unwanted behavior if it is not a string
    };
    /////////////// Start: Mobile api ///////////////
    /**
     * If the table is being rendered as mobile or not
     */
    isMobile = () => this.state.isMobile;
    /**
     * Sets the mobile to the given value. Note that calling this can be overridden by the resize callback
     * @param isMobile If the SpordleTalbe should be in mobile view
     */
    setMobile = (isMobile) => {
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState((state) => {
                    if (state.isMobile === !!isMobile || !this._isMounted)
                        return null;
                    return { isMobile: !!isMobile };
                }, resolve);
            }
        });
    };
    /////////////// End: Mobile api ///////////////
    /////////////// Start: Status api ///////////////
    /**
     * Sets the status to error
     * @returns {Promise}
     * @public
     */
    setError = (error) => {
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState(() => {
                    if (!this._isMounted)
                        return null;
                    this.error = error;
                    return { loadingState: 'error' };
                }, resolve);
            }
        });
    };
    /**
     * Sets the status to success
     * @returns {Promise}
     * @public
     */
    setSuccess = () => {
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState(() => {
                    if (!this._isMounted)
                        return null;
                    return { loadingState: 'success' };
                }, resolve);
            }
        });
    };
    /**
     * Sets the status to loading
     * @returns {Promise}
     * @public
     */
    setLoading = () => {
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState(() => {
                    if (!this._isMounted)
                        return null;
                    return { loadingState: 'loading' };
                }, resolve);
            }
        });
    };
    /////////////// End: Status api ///////////////
    /////////////// Start: Data api ///////////////
    /**
     * Handles tr clicks
     * @param row The row data
     * @param event The click event
     * @param columnName The column key name
     */
    clickRow = (row, event, columnName) => {
        if (SpordleTableProvider.globalConfig.onColumnClick?.(event, row, this, columnName) ?? true)
            this.props.onColumnClick?.(event, row, this, columnName);
    };
    /**
     * Sets the data for the data
     * @param newData The data to render
     * @param loadingStatus DEFAULT: `success` - The loading status
     * @returns {Promise}
     * @public
     */
    setData = (newData, loadingStatus = 'success') => {
        if (!Array.isArray(newData))
            throw new Error('First paremeter in call setData must be an array');
        switch (loadingStatus) {
            case 'lazy':
            case 'success':
            case 'loading':
            case 'error':
                // state param is a valid value
                // Proceed to setState
                break;
            default:
                throw new Error('Second parameter in call setData must either be undefined or "lazy" or "success" or "error" or "loading"');
        }
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState(() => {
                    if (!this._isMounted)
                        return null;
                    return { data: newData, loadingState: loadingStatus };
                }, resolve);
            }
        });
    };
    /**
     * @returns The data inside the table
     * @public
     */
    getData = () => this.state.data;
    /**
     * @returns The data that is being rendered on the current page
     * @public
     */
    getDisplayedData = () => this._getDataToShow().dataToDisplay;
    /**
     * Add data to hte table
     * @param newRow The new data
     * @param {boolean} [atStart=false] DEFAULT: `false` - If the data should be added at the beginning of the table
     * @returns {Promise}
     * @public
     */
    addRow = (newRow, atStart = false) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!Array.isArray(newRow) && !newRow)
            throw new Error('Parameter in addRow function must be an object or an array of object');
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState((state) => {
                    if (Array.isArray(newRow)) {
                        let shouldUpdate = false; // For optimization reason
                        for (let index = 0; index < newRow.length; index++) {
                            const isNewData = state.data.findIndex((element) => this.getDataIndexValue(element) === this.getDataIndexValue(newRow[index])) === -1;
                            if (isNewData) {
                                if (atStart)
                                    state.data.unshift(newRow[index]);
                                else
                                    state.data.push(newRow[index]);
                                shouldUpdate = true;
                            }
                            else {
                                console.warn(`Data item with dataIndex: ${this.getDataIndexValue(newRow[index])} already exists. It was not re-added.`);
                            }
                        }
                        // Will prevent rerender if no data was added
                        if (!shouldUpdate || !this._isMounted)
                            return null;
                        return {
                            data: state.data,
                        };
                    }
                    const index = state.data.findIndex((data) => this.getDataIndexValue(data) === this.getDataIndexValue(newRow));
                    if (index !== -1)
                        return null;
                    if (atStart)
                        state.data.unshift(newRow);
                    else
                        state.data.push(newRow);
                    if (!this._isMounted)
                        return null;
                    return {
                        data: state.data,
                    };
                }, resolve);
            }
        });
    };
    /**
     * Delete data from the table
     * @param rowId The row ids to delete
     * @returns {Promise}
     * @public
     */
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    deleteRow = (rowId) => {
        if (!Array.isArray(rowId) && !rowId)
            throw new Error('Parameter in deleteRow function must be an id or an array of ids');
        if (Array.isArray(rowId)) {
            return new Promise((resolve) => {
                if (!this._isMounted) {
                    resolve();
                }
                else {
                    this.setState((state) => {
                        let shouldUpdate = false; // For optimization reason
                        // Because we can't splice the data array since we loop through it
                        const tempArray = state.data.filter((row) => {
                            if (rowId.includes(this.getDataIndexValue(row))) {
                                shouldUpdate = true;
                                return false;
                            }
                            return true;
                        });
                        // Will prevent rerender if no data was removed
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        if (!shouldUpdate || !this._isMounted)
                            return null;
                        const { filteredData } = this._getDataToShow();
                        const actualLenght = filteredData.length - tempArray.length;
                        /**
                         * The first item currently displayed.
                         * From `0` to `this.state.data.length - 1`
                         */
                        const currentFirstDataIndex = state.pagination * state.currentPage;
                        return {
                            data: tempArray,
                            currentPage: actualLenght >= currentFirstDataIndex ? state.currentPage : Math.max(state.currentPage - 1, 0),
                        };
                    }, resolve);
                }
            });
        }
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState((state) => {
                    const index = state.data.findIndex((data) => this.getDataIndexValue(data) === rowId);
                    if (index === -1 || !this._isMounted)
                        return null;
                    state.data.splice(index, 1);
                    const { filteredData } = this._getDataToShow();
                    const actualLenght = filteredData.length - 1; // -1 because we are removing one item
                    /**
                         * The first item currently displayed.
                         * From `0` to `this.state.data.length - 1`
                         */
                    const currentFirstDataIndex = state.pagination * state.currentPage;
                    return {
                        data: state.data,
                        currentPage: actualLenght >= currentFirstDataIndex ? state.currentPage : Math.max(state.currentPage - 1, 0),
                    };
                }, resolve);
            }
        });
    };
    /**
     * Function that will update the given ids with the given data
     * @param {string|Array.<string>} ids Row id(s) to update
     * @param {Data|Array.<Data>} data The data to update the rows with
     * @returns {Object.<string, boolean>} Which ids updated successfully or not
     * @public
     */
    updateDataWith = (ids, data) => {
        const exitInfo = {};
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve(exitInfo);
            }
            else {
                this.setState((prevState) => {
                    /**
                     * Function to find and affect the given id
                     */
                    const processId = (id) => {
                        const index = prevState.data.findIndex((d) => this.getDataIndexValue(d) === id);
                        if (index !== -1) {
                            exitInfo[id] = true;
                            prevState.data[index] = {
                                ...prevState.data[index],
                                ...data,
                            };
                        }
                        else {
                            exitInfo[id] = false;
                        }
                    };
                    if (Array.isArray(ids)) {
                        ids.forEach(processId);
                    }
                    else {
                        processId(ids);
                    }
                    return prevState;
                }, () => { resolve(exitInfo); });
            }
        });
    };
    /////////////// End: Data api ///////////////
    /////////////// Start: Columns api ///////////////
    /**
     * Callback function to set the column ordering
     * @param columnKey The column key name to order
     */
    columnOrdering = (columnKey) => {
        if (!this._isMounted)
            return;
        this.setState((state) => {
            if (state.orderingBy.column === columnKey) {
                if (!this._isMounted)
                    return null;
                return {
                    orderingBy: {
                        column: columnKey,
                        order: state.orderingBy.order === 'ASC' ? 'DESC' : 'ASC',
                    },
                };
            }
            // Was not already ordering by this column
            // Sort ASC first
            if (!this._isMounted)
                return null;
            return {
                orderingBy: {
                    column: columnKey,
                    order: 'ASC',
                },
            };
        }, () => { this._fetchDataFromApi('COLUMN'); });
    };
    /**
     * Sets the column to be sorted on
     * @param columnSorting A string that determines which column to sort and what direction
     * @returns {Promise}
     * @example
     * this.searchTableRef.setColumnSorting('-registrationsCount')
     * @public
     */
    setColumnSorting = (columnSorting) => {
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState(() => {
                    if (!this._isMounted)
                        return null;
                    return {
                        orderingBy: columnSorting ? this._getColumnSortingFromString(columnSorting) : { column: undefined, order: undefined },
                    };
                }, resolve);
            }
        });
    };
    /**
     * @returns {Array.<Column>} The current state of the columns
     * @public
     */
    getColumns = () => this.state.orderedColumns;
    /**
     * Sets the columns as passed to function
     * @param {Array.<Column>} columns The columns data to show is the given order
     * @returns {Promise}
     * @public
     */
    setColumns = (columns) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!columns || !Array.isArray(columns))
            throw new Error('Parameter in setColumns function must be an array');
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState(() => {
                    if (!this._isMounted)
                        return null;
                    return { orderedColumns: columns.map(this._createColumn) };
                }, resolve);
            }
        });
    };
    /**
     * Inserts one or many column(s) starting at the given position
     * @param {Array.<Column>} columns The columns to insert
     * @param {number} [at] DEFAULT: last - The position to insert the columns at
     * @returns {Promise}
     * @public
     */
    insertColumnsAt = (columns, at = this.state.orderedColumns.length - 1) => {
        if (!isNumber(at))
            throw new Error("First parameter must be a valid number");
        if (Array.isArray(columns)) {
            return new Promise((resolve) => {
                if (!this._isMounted) {
                    resolve();
                }
                else {
                    this.setState((state) => {
                        if (!this._isMounted)
                            return null;
                        state.orderedColumns.insertAt(at, columns.map(this._createColumn));
                        return { orderedColumns: state.orderedColumns };
                    }, resolve);
                }
            });
        }
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState((state) => {
                    if (!this._isMounted)
                        return null;
                    state.orderedColumns.insertAt(at, [this._createColumn(columns)]);
                    return { orderedColumns: state.orderedColumns };
                }, resolve);
            }
        });
    };
    /**
     * Remove one or many column(s)
     * @param at The position(s) or the column key name(s) to remove
     * @returns {Promise}
     * @public
     */
    removeColumns = (at) => {
        if (Array.isArray(at)) {
            return new Promise((resolve) => {
                if (!this._isMounted) {
                    resolve();
                }
                else {
                    this.setState((state) => {
                        if (!this._isMounted)
                            return null;
                        return { orderedColumns: state.orderedColumns.filter((column, index) => !(at.includes(column.key) || at.includes(index))) };
                    }, resolve);
                }
            });
        }
        if (isNumber(at)) {
            return new Promise((resolve) => {
                if (!this._isMounted) {
                    resolve();
                }
                else {
                    this.setState((state) => {
                        if (!this._isMounted)
                            return null;
                        const orderedColumnsCopy = state.orderedColumns.slice();
                        orderedColumnsCopy.splice(at, 1);
                        return { orderedColumns: orderedColumnsCopy };
                    }, resolve);
                }
            });
        }
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState((state) => {
                    if (!this._isMounted)
                        return null;
                    return { orderedColumns: state.orderedColumns.filter((column) => column.key !== at) };
                }, resolve);
            }
        });
    };
    /**
     * Function to move a column to position
     * @param from The column to move
     * @param to The position to move the `from` column at
     * @returns {Promise}
     * @public
     */
    moveColumnTo = (from, to) => {
        return new Promise((resolve, reject) => {
            if (!isNumber(from) || !isNumber(to)) {
                reject(new Error("Parameters must be valid numbers"));
            }
            else if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState((state) => {
                    if (!this._isMounted)
                        return null;
                    return { orderedColumns: arrayMove(state.orderedColumns, from, to) };
                }, resolve);
            }
        });
    };
    /////////////// End: Columns api ///////////////
    /////////////// Start: Pagination api ///////////////
    /**
     * Get the current page the SpordleTable is on
     * @returns {number} The current page
     * @public
     */
    getCurrentPage = () => this.state.currentPage;
    /**
     * Get the current pagination
     * @returns {number} The current pagination
     * @public
     */
    getPagination = () => this.state.pagination;
    /**
     * Change page programmatically
     * @param {number} pageIndex The page we want to go to - Start at 0
     * @returns {number} The page it is going to
     * @public
     */
    goToPage = (pageIndex) => {
        const page = Math.max(pageIndex, 0); // Prevents from having page index like -1
        if (!this._isMounted) {
            return page;
        }
        this.setState(() => {
            if (!this._isMounted) {
                return null;
            }
            return { currentPage: page };
        });
        return page;
    };
    /**
     * Change the pagination programatically
     * @param {number} itemCountPerPage The data count on every page
     * @returns {Promise}
     * @public
     */
    setPagination = (itemCountPerPage) => {
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState((prevState) => {
                    if (!this._isMounted) {
                        return null;
                    }
                    const itemPerPages = Math.max(itemCountPerPage, 0); // Prevents from having page index like -1
                    /**
                     * The first item currently displayed.
                     * From `0` to `this.state.data.length - 1`
                     */
                    const currentFirstDataIndex = prevState.pagination * prevState.currentPage;
                    return {
                        pagination: itemPerPages,
                        currentPage: Math.floor(currentFirstDataIndex / (itemPerPages || 1)),
                    };
                }, resolve);
            }
        });
    };
    /////////////// End: Pagination api ///////////////
    /////////////// Start: Filters api ///////////////
    /**
     * Function called when a filter changes. It will call the loadData props with `FILTER` and set the currentPage to `0`
     * @param {string} id The filter id that changed
     * @param {any} value The new value to be associated with the filter id
     * @param {boolean} [triggerLoadData=true] Will trigger `loadData` prop when true - DEFAULT: `true`
     * @param {boolean} [preventPageChange=false] Will prevent setting the current page to 0 - DEFAULT: `false`
     */
    filterChange = (id, value, triggerLoadData = true, preventPageChange = false) => {
        this._filters[id] = value;
        if (!this._isMounted) {
            return;
        }
        if (triggerLoadData) {
            this._fetchDataFromApi('FILTER');
        }
        if (!preventPageChange) {
            this.setState(() => {
                if (!this._isMounted)
                    return null;
                return { currentPage: 0 };
            });
        }
    };
    /**
     * Gets the current filters
     */
    getFilters = () => this._filters;
    /**
     * Callback function to handle the search input change callback. This function triggers the loadData prop with SEARCH
     * @param e ChangeEvent
     */
    searchInputChange = (e) => {
        this.setInputValue(e.target.value);
    };
    /**
     * Sets the input value to the given value. This function triggers the loadData prop with SEARCH
     * @param {string} [value='']
     * @param {boolean} [resetSorting=true] Will remove the column ordering
     */
    setInputValue = (value = '', resetSorting = true) => {
        if (!this._isMounted) {
            return;
        }
        this._triggerGa4SearchEvent(value);
        this.setState(() => {
            if (!this._isMounted)
                return null;
            const newState = {
                searchValue: value,
                currentPage: 0,
            };
            if (resetSorting && value) {
                // @ts-ignore
                newState.orderingBy = { column: undefined, order: undefined };
            }
            return newState;
        }, () => { this._fetchDataFromApi('SEARCH'); });
    };
    /**
     * Gets the current input value
     */
    getInputValue = () => this.state.searchValue;
    /////////////// End: Filters api ///////////////
    /////////////// Start: View type ///////////////
    /**
     * Sets the view mode to the given values
     * @param viewMode The view mode we want to use. Available values: `'LIST'` and `'GRID'`
     * @param gridConfig The grid configuration.
     */
    setView = (viewMode, gridConfig) => {
        return new Promise((resolve) => {
            if (!this._isMounted) {
                resolve();
            }
            else {
                this.setState((prevState) => {
                    if (!this._isMounted)
                        return null;
                    return { view: {
                            mode: viewMode,
                            config: {
                                ...prevState.view.config,
                                ...gridConfig,
                            },
                        } };
                }, resolve);
            }
        });
    };
    /**
     * Gets the current viewMode
     */
    getViewMode = () => this.state.view.mode;
    /////////////// End: View type ///////////////
    // #region Other
    /**
     * Will call the loadData prop with `REFRESH`. If nothing is returned, then it will simply call forceUpdate
     * @param {function} [forceUpdateCallback] Callback function passsed to {@link https://fr.reactjs.org/docs/react-component.html#forceupdate|forceUpdate}'s first param
     */
    refreshTable = (forceUpdateCallback) => {
        if (this._fetchDataFromApi('REFRESH') instanceof Promise) {
            if (this._automaticRefresh)
                clearInterval(this._automaticRefresh);
        }
        else {
            this.forceUpdate(forceUpdateCallback);
        }
    };
    // #endregion Other
    // #region Additional data
    /**
     * Will set the given data as additional data
     */
    setAdditionalData = (additionalData) => {
        this._additionalData = additionalData;
    };
    /**
     * Gets the additional data
     */
    getAdditionalData = () => this._additionalData;
    // #endregion Additional data
    /**
     * Generates a SpordleTableIdLike
     * @param elementId The element we want to generate the id for
     * @param suffix Any additional suffix to append to the id
     */
    generateId = (elementId, ...suffix) => {
        if (elementId) {
            const appendedId = `spordletable___${this.props.id}-${elementId}`;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (suffix) {
                const filteredSuffixes = suffix.filter((suf) => !!suf);
                return filteredSuffixes.length > 0 ? `${appendedId}-${filteredSuffixes.join('-')}` : appendedId;
            }
            return appendedId;
        }
    };
    render() {
        return (jsxs(Fragment, { children: [jsx("div", { ref: (r) => this._desktopWhenRef = r, className: this._getDesktopWhenClass() }), jsx(SpordleTableContext.Provider, { value: {
                        ...this,
                        ...this._getDataToShow(),
                    }, children: typeof this.props.children === 'function' ?
                        jsx(SpordleTableContext.Consumer, { children: this.props.children })
                        :
                            this.props.children })] }));
    }
}
const SpordleTableProvider$1 = SpordleTableProvider;export{SpordleTableContext,SpordleTableProvider$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/