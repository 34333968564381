import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";
import variables from './imgPill.module.scss';

/**
 * @description Component used to fake crop and render an image in a pill
 *
 * @param {string} [props.abbr] 2 letter string as backup if the img is null
 * @param {boolean} [props.container=true] Adds a frame to the img (border + padding)
 * @param {boolean} [props.isLoading] isLoading state for cute animation
 * @param {string} [props.className] add className to the container
 * @param {string} [props.filePos] File position
 * @param {'xs'|'sm'|'md'|'lg'|'xl'} [props.size] Pill size
 * @param {string} [props.innerClassName] add className directly to the img
 * @param {string} [props.placeholder] Placeholder fallback instead of abbr
 * @param {string} [props.src] // img's src prop, if null, we display abbr
 * @param {string} props.alt // img's alt prop, cannot be empty
 * @param {Object} props
 *
 * @returns {React.ReactNode}
 */
const ImgPill = ({
    isLoading,
    abbr,
    container,
    className,
    filePos,
    innerClassName,
    size,
    src,
    placeholder,
    alt,
    ...props
}) => {
    let pos = null;
    try{
        if(filePos)
            pos = JSON.parse(filePos);
    }catch(error){
        console.error(error);
    }
    const rDelta = pos?.cropSize?.width && parseInt(variables[size]) / parseFloat(pos.cropSize?.width);
    const styles = pos && rDelta ? { width: pos.width, height: pos.height, maxWidth: 'unset', maxHeight: 'unset', transform: `translate(${parseFloat(pos.x) * (rDelta || 1)}px, ${parseFloat(pos.y) * (rDelta || 1)}px) scale(${parseFloat(pos.zoom) * (rDelta || 1)})` } : { maxHeight: '100%', maxWidth: '100%' }

    const withContainer = (elements) => (
        <div className={stringBuilder('img-pill', (src || placeholder ? '' : 'bg-light'), (isLoading ? 'is-loading' : ''), (!size || size === 'md' ? 'img-pill-container' : `img-pill-container-${size}`), className || '')}>
            {elements}
        </div>
    )

    return (
        (src || placeholder ?
            container ?
                withContainer(<img style={styles} {...props} src={src || placeholder} alt={alt} className={stringBuilder("img-pill-inner", (innerClassName || ''))} />)
                :
                <img {...props} src={src || placeholder} alt={alt} className={stringBuilder("img-pill-inner", (innerClassName || ''))} />
            :
            container ?
                withContainer(!isLoading && !!abbr && !placeholder ? abbr.substring(0, 2) : 'HC')
                :
                !!abbr && !placeholder ? abbr.substring(0, 2) : 'HC'
        )
    );
};

export default ImgPill;

ImgPill.propTypes = {
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    container: PropTypes.bool,
    filePos: PropTypes.string,
    abbr: PropTypes.string,
    src: PropTypes.string,
    placeholder: PropTypes.string,
    alt: PropTypes.string.isRequired,
    size: PropTypes.oneOf([
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
    ]),
};

ImgPill.defaultProps = {
    container: true,
    isLoading: false,
    className: '',
    size: 'md',
};