import { useEffect, useRef } from 'react';
import { gsap, CSSPlugin, TweenLite, TimelineMax } from 'gsap';
import useIsMount from '../customHooks/useIsMount';
import PropTypes from 'prop-types';

/**
 * @description Custom Modal made with gsap
 *
 * @param {React.ElementType} [tag] Tag of the modal wrapper.
 * @param {boolean} [isOpen] Boolean that indicates if the modal is open or not.
 * @param {string} [size] Size of modal. Different sizes are sm, md and lg. Default is md.
 */
const CustomModal = ({ tag: Tag,
                       isOpen,
                       size,
                       onCloseCallback,
                       children,
                       className,
                       ...props }) => {

    gsap.registerPlugin(CSSPlugin);

    const initRender = useIsMount();
    const modalDropRef = useRef();
    const modalRef = useRef();
    const modalSize = `custom-modal-container-${size || 'md'}`;

    useEffect(() => {
        if(initRender)return;

        const timeline = new TimelineMax({ paused: true, onReverseComplete: onCloseCallback && onCloseCallback() });
        const modalTween = TweenLite.fromTo(modalRef.current,
                                            { autoAlpha: 0, y: 200 },
                                            { autoAlpha: 1, y: 0, duration: 0.25 });
        const backdropTween = TweenLite.fromTo(modalDropRef.current,
                                               { autoAlpha: 0 },
                                               { autoAlpha: 1, duration: 0.1 });

        timeline.add([ modalTween, backdropTween ]);

        !isOpen ? timeline.reverse(0) : timeline.play(0);

        return () => timeline.kill();
    }, [ isOpen ]);

    return (
        <Tag className={`custom-modal${className ? ` ${className}` : ''}`} ref={modalDropRef} style={{ visibility: 'hidden' }} {...props}>
            <div ref={modalRef} className={`container custom-modal-container ${modalSize}`}>
                {children}
            </div>
        </Tag>
    );
}

export default CustomModal;

CustomModal.propTypes = {
    tag: PropTypes.elementType,
    isOpen: PropTypes.bool,
    size: PropTypes.oneOf([
        'sm',
        'md',
        'lg',
    ]),
    onCloseCallback: PropTypes.func,
};

CustomModal.defaultProps = {
    tag: 'div',
    isOpen: false,
};