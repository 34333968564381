import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { AxiosIsCancelled, serverError } from '../api/CancellableAPI';
import queryString, { parse, stringifyUrl } from 'query-string';

import * as Sentry from "@sentry/react";
import { DisplayI18n } from '../helpers/i18nHelper';
import { fail } from '@spordle/toasts';
import getReferrer, { getMultiSportCode } from '../helpers/getReferer';
import { withRouter } from 'react-router-dom';
import withContexts from '../helpers/withContexts';
import { AuthContext } from './AuthContext';
import { I18nContext } from './I18nContext';

/** @type {React.Context<Omit<OrganizationContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>> & OrganizationContextProvider['state']} */
export const OrganizationContext = createContext();
OrganizationContext.displayName = 'OrganizationContext';

export const sessionStorageOrganizationId = 'organizationId';

export function getFederationId(){
    switch (getMultiSportCode()){
        case "SQ":
            return '68bcf4a5-0f63-11ed-bed9-023f3d3ef136';
        case "BQ":
            return 'c2f98c67-0dbf-11ed-bed9-023f3d3ef136';
        case "UK":
            return 'bf2ef31c-14ce-11ed-bed9-023f3d3ef136';
        default:
            break;
    }
    return '1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9';// HC
}

class OrganizationContextProvider extends React.Component{
    /*
    State will look like this:
    {
        abbreviation: "RE"
        active: "1"
        address: ""
        city: ""
        country_id: null
        created_at: "2020-11-23 09:46:44"
        fax: null
        identification_number: "",
        logo: {
            attachement_id: '123123',
            full_path: 'https://123123.com'
        },
        organisation_category: {
            organisation_category_id: "1eb19495-611e-65b8-9eec-e454e8be9b90",
            name: "Association / Club"
        }
        name: "Association / Club"
        organisation_category_id: "1eb19495-611e-65b8-9eec-e454e8be9b90"
        organisation_category_id: "5"
        organisation_email: null
        organisation_id: "1eb2d9ab-49d4-6fb8-866b-0ec110310a18"
        organisation_id_parent: "1eb1f975-74e6-6376-9b74-e454e80c8447"
        organisation_name: "Région Estrie"
        origin: "ADMIN"
        phone: ""
        province_id: ""
        short_url: "t3"
        updated_at: "2020-12-14 10:17:54"
        visible_on_website: "0"
        website: ""
        with_sub_organisation: "1"
        zip_code: ""
    }
    */

    /*state = {
        abbreviation: "Hockey Canada",
        active: "1",
        address: null,
        city: null,
        country_id: null,
        created_at: "2020-11-17 11:46:19",
        fax: null,
        identification_number: "123456789",
        logo: {
            attachement_id: '123123',
            full_path: 'https://123123.com'
        },
        organisation_category: [],
        organisation_category_id: null,
        organisation_email: "infos@hockeycanada.ca",
        organisation_id: "041ed146-8a8a-40f5-8d82-bb9967cfeb42",
        organisation_id_parent: null,
        organisation_name: "Hockey Canada",
        origin: "ADMIN",
        phone: null,
        province_id: null,
        short_url: "hcr",
        updated_at: null,
        visible_on_website: "1",
        website: null,
        with_sub_organisation: "1",
        zip_code: null
    }*/

    federationId = getFederationId();

    /**
     * @type {?Array}
     * @description Cached organization tree
     */
    orgTree = null;

    /**
     * @type {?Array}
     * @description Cached organization branch
     */
    orgBranch = null;

    /**
     * @type {?Array}
     * @description Cached organization branch
     */
    orgRegion = null;

    /**
     * @type {?Array}
     * @description Cached organization categories
     */
    orgCategories = null;

    /**
     * @typedef {object} Setting
     * @property {string} code
     * @property {string} name
     * @property {Record<string, any>} i18n
     * @property {any} response
     */
    /**
     * Formats the settings info from the api to our JS structure
     * @param {Setting[]} settings
     * @returns {object}
     * @private
     */
    _formatSettings = (settings) => {
        return settings.reduce((formattedSettings, setting) => {
            if(setting.code === 'language' && !Array.isArray(setting.response)){
                setting.response = [ setting.response ];
            }

            if(setting.code === 'language' && setting.response.length === 0){ // No languages set
                setting.response = [ 'en' ];// Defaults to english
            }
            formattedSettings[setting.code] = {
                value: setting.response,
                name: setting.name,
                i18n: setting.i18n,
            }
            return formattedSettings;
        }, {});
    }

    componentDidUpdate(){
        Sentry.setContext('organization', this.state?.organisation_id ? {
            organizationId: this.state.organisation_id,
            organizationName: this.state.organisation_name,
            languages: this.state.settings?.languages?.value,
        } : null);
        Sentry.setTag('organizationId', this.state?.organisation_id);
    }

    // Temporary, needed for org search
    setFederation = () => {
        return this.getOrganization(this.federationId)
            .then((fedData) => (
                this.getOrganizationSettings(this.federationId)
                    .then((settings) => {
                        this.setState((prevState) => ({ ...prevState, federation: { ...fedData, ...settings } }));
                        return fedData.organisation_id;
                    }, (error) => {
                        console.error(error.message)
                    }).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
            ), (error) => console.error(error.message))
    }

    /**
     * Set the new organization (with short_url)
     * @param {string} orgShortUrl
     * @returns {Promise}
     */
    setCurrentOrg = (orgShortUrl) => {
        return this.getOrganizationUrl(decodeURI(orgShortUrl))
            .then((orgData) => {
                return this.getOrganizationSettings(orgData.organisation_id)
                    .then((settings) => {
                        this.setState((prevState) => ({ ...prevState, ...orgData, settings: settings }));
                        return orgData.organisation_id;
                    })
            })
    }

    /**
     * Get organization info from short_url
     * @param {string} short_url
     * @returns {Promise}
     */
    getOrganizationUrl = (short_url) => {
        return API_SPORDLE.get(stringifyUrl({
            url: `organisations/url`,
            query: {
                short_url: short_url,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    const org = response.data.organisation[0];
                    switch (org.deploy_on){
                        case 'VERCEL':
                        case 'PAGE':
                            const pathname = this.props.location.pathname.split(org.short_url)[1];
                            window.location.href = stringifyUrl({
                                url: `${getReferrer(org.deploy_on == 'VERCEL' ? 'PAGE-VERCEL' : 'PAGE-VERCEL-2')}/${this.props.I18nContext.getGenericLocale()}/${org.short_name}${pathname || ''}`,
                                query: {
                                    accessToken: this.props.AuthContext.accessToken,
                                    ...parse(this.props.location.search),
                                },
                                fragmentIdentifier: this.props.location.hash.split('#')[1],
                            }, {
                                skipEmptyString: true,
                                skipNull: true,
                            })
                            // Redirecting to Page NextJS on Vercel
                            // Throw a cancel error to act like the call was cancelled -> no log error in Sentry
                            throw new Error('Cancelled');
                        default:
                            break;
                    }
                    return org;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organisation tree with sub-organisation
     * @returns {Promise}
     */
    getOrganizationsTree = () => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(Array.isArray(this.orgTree)){
            return Promise.resolve(this.orgTree);
        }

        return API_SPORDLE.get(`organisations/${this.federationId}/tree`)
            .then((response) => {
                if(response.data.status){
                    return this.orgTree = response.data.organisations;
                }
                this.orgTree = null;
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get organization info
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganization = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization branch
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationBranch = (organizationId = this.state.organisation_id, fromApi = false) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(this.orgBranch?.organisation_id === this.state.organisation_id && !fromApi){
            return Promise.resolve(this.orgBranch);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId || this.state.organisation_id}/branch` }))
            .then((response) => {
                if(response.data.status){
                    return this.orgBranch = response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets the Organization's Settings
     * @param {string} organizationId ID of the Organization we want to get the Settings from
     * @returns {Promise}
     */
    getOrganizationSettings = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/settings` }))
            .then((response) => {
                if(response.data.status){
                    return this._formatSettings(response.data.settings);
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization info
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationCategories = (organizationId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/category`, query: queryParams }))
            .then((response) => {
                if(response.data.status){
                    this.orgCategories = response.data.categories;
                    return response.data.categories;
                }
                this.orgCategories = null;
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all organizations for a category
     * @param {string} organizationId
     * @param {Array} categories
     * @returns {Promise<Array>}
     */
    getCategoryOrganizations = (organizationId, categories) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/categories`, query: {
            organisation_category_id: categories,
        } }, {
            arrayFormat: 'comma',
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getDocumentTypes = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/document-types`,
            query: {
                organisation_id: organizationId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.document_types
                }
                throw response.data.errors[0]
            })
    }

    /**
     * [GET] Find Organizarion close to postal code or member id
     * @param {Object} query See the {@link https://api.id.dev.spordle.dev/documentations/#/Spordle%20Page/65627b95df0a6607c9bd4aea6127e2a1|documentation}
     * @returns {Promise<Array>}
     */
    findOrganization = (query) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/find`,
            query: query,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Determines if the french language is activated for this organization
     * @returns {boolean}
     */
    hasFrench = () => !!this.state.settings?.language?.value.includes('fr');

    /**
      * Determines if the english language is activated for this organization
      * @returns {boolean}
      */
    hasEnglish = () => !!this.state.settings?.language?.value.includes('en');

    render(){
        return (
            <OrganizationContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </OrganizationContext.Provider>
        );
    }
}

export default withContexts(AuthContext, I18nContext)(withRouter(OrganizationContextProvider));
