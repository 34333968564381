/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsxs,jsx}from'react/jsx-runtime';import {debounce,stringBuilder}from'@spordle/helpers';import PropTypes from'prop-types';import {memo,useState,useRef,useCallback,useEffect}from'react';import {useIntl}from'react-intl';import useSpordleTable from'./useSpordleTable.js';import {getMessage}from'./utils.js';/**
 * @member SearchInput
 * @description A default search input linked to the {@link SpordleTableProvider}. It is also linked to GA4 on the search event
 * @prop {boolean} [hideClearButton=false] Hide the clear button or not
 * @prop {boolean} [translatePlaceholder=true] If we want to translate the placholder
 * @prop {string} [placeholder=misc.SimpleSearchTable.search.placeholder] The placeholder or the translation id
 * @prop {number} [searchCoolDown=150] The cooldown before the search filtering is triggered
 * @example
 * <SearchInput />
 * <SearchInput hideClearButton translatePlaceholder={false} placeholder='Non-translated placholder' className='input-classNames' />
 */
const SearchInput = memo(({ placeholder, translatePlaceholder, hideClearButton, searchCoolDown, ...props }) => {
    const spordleTable = useSpordleTable();
    const intl = useIntl();
    const [search, setSearch] = useState(spordleTable.getInputValue());
    const inputRef = useRef(null);
    const debouncedSearchChange = useCallback(debounce(spordleTable.setInputValue, searchCoolDown), []);
    const generateId = (elementId) => {
        return props.id ? spordleTable.generateId(elementId, props.id) : spordleTable.generateId(elementId);
    };
    // const queryBuilderClick = (builder: SearchQuery) => () => {
    // setSearch((prevSearch) => {
    //     debouncedSearchChange(prevSearch);
    //     return prevSearch;
    // });
    // }
    useEffect(() => {
        setSearch(spordleTable.getInputValue());
    }, [spordleTable.getInputValue()]);
    return (jsxs("div", { className: "search-wrapper", id: generateId('search_wrapper'), children: [jsx("input", { ...props, ref: inputRef, type: "search", id: generateId('search_input'), className: stringBuilder("form-control", props.className), placeholder: translatePlaceholder ? intl.formatMessage({ id: placeholder }) : placeholder, value: search, onChange: (e) => {
                    props.onChange?.(e);
                    setSearch(e.target.value);
                    debouncedSearchChange(e.target.value);
                }, onFocus: spordleTable.getInputValue() ? () => spordleTable.setColumnSorting('') : undefined }), !hideClearButton &&
                jsx("button", { type: "button", className: stringBuilder("reset-btn text-link search-clear", { 'd-none': !spordleTable.getInputValue() }), onClick: () => spordleTable.setInputValue(), id: generateId('search_clear'), "aria-label": getMessage('clearSearch.ariaLabel', intl.locale), children: jsx("i", { className: "mdi mdi-close" }) })] }));
});
SearchInput.displayName = 'SearchInput';
SearchInput.propTypes = {
    translatePlaceholder: PropTypes.bool,
    hideClearButton: PropTypes.bool,
    searchCoolDown: PropTypes.number.isRequired,
};
SearchInput.defaultProps = {
    placeholder: 'misc.SimpleSearchTable.search.placeholder',
    translatePlaceholder: true,
    hideClearButton: false,
    searchCoolDown: 150,
};
const SearchInput$1 = SearchInput;export{SearchInput$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/