import registrationParticipantList from "./registrationParticipantList";
import registrationSelectItems from "./registrationSelectItems";
import registrationModal from "./registrationModal"; // To do : refactor
import registrationCart from "./registrationCart"
import registrationAddress from "./registrationAddress";

export default{
    // Other lang files
    ...registrationParticipantList,
    ...registrationSelectItems,
    ...registrationModal, // To do : refactor
    ...registrationCart,
    ...registrationAddress,

    // RegistrationConfirmAddress
    "storeSingle.registrationModal.registrationConfirmAddress.title"                              : "Address confirmation",
    "storeSingle.registrationModal.registrationConfirmAddress.form.validation.3266"               : "This address is not eligible for this organization",
    "storeSingle.registrationModal.registrationConfirmAddress.form.address.label"                 : "Address",
    "storeSingle.registrationModal.registrationConfirmAddress.form.address.streetNumber.required" : "Street number required",
    "storeSingle.registrationModal.registrationConfirmAddress.form.address.address.required"      : "Address required",
    "storeSingle.registrationModal.registrationConfirmAddress.form.address.city.required"         : "City required",
    "storeSingle.registrationModal.registrationConfirmAddress.form.address.country.required"      : "Required country",
    "storeSingle.registrationModal.registrationConfirmAddress.form.address.state.required"        : "Province required",
    "storeSingle.registrationModal.registrationConfirmAddress.form.address.zip.required"          : "Postal code required",
    "storeSingle.registrationModal.registrationConfirmAddress.form.address2.placeholder"          : "Apartment, suite, unit, floor, building, etc.",
    "storeSingle.registrationModal.registrationConfirmAddress.form.addressType.label"             : "Address type",
    "storeSingle.registrationModal.registrationConfirmAddress.form.addressType.empty"             : "No address",
    "storeSingle.registrationModal.registrationConfirmAddress.form.addressType.required"          : "You must enter an address type",

    // Registration Ahm Checker
    "storeSingle.registrationModal.registrationOptions.participantOrgAssociationWith"                      : "This participant is associated with {orgName}.",
    "storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.label"                      : "To continue with {orgName}, you must send a",
    "storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.label.registrationRequest"  : "Registration request",
    "storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.action.goToMha"             : "Go to {orgName}",
    "storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.action.registrationRequest" : "Request a transfer",
    "storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.label.contactOrg"           : "Please contact {orgName}.",

    // Registration request
    "storeSingle.registrationModal.registrationRequest.title"                             : "Registration Request",
    "storeSingle.registrationModal.registrationRequest.requestType.label"                 : "Request Type",
    "storeSingle.registrationModal.registrationRequest.requestType.select.empty"          : "No request type",
    "storeSingle.registrationModal.registrationRequest.requestType.validation.required"   : "Please select a request type",
    "storeSingle.registrationModal.registrationRequest.requestReason.label"               : "Reason",
    "storeSingle.registrationModal.registrationRequest.requestReason.select.empty"        : "No reason",
    "storeSingle.registrationModal.registrationRequest.requestReason.validation.required" : "Please select a reason",
    "storeSingle.registrationModal.registrationRequest.notes.label"                       : "Notes",
    "storeSingle.registrationModal.registrationRequest.notes.validation.required"         : "Please enter a note",
    "storeSingle.registrationModal.registrationRequest.newAddress.label"                  : "New address",

    // Registration confirmation
    "storeSingle.registrationModal.registrationRequestConfirmation.title"  : "Request Confirmation",
    "storeSingle.registrationModal.registrationRequestConfirmation.info"   : "Transfer {memberName} from",
    "storeSingle.registrationModal.registrationRequestConfirmation.fromTo" : "{currentOrg} to {orgName}",
    "storeSingle.registrationModal.registrationRequestConfirmation.send"   : "Send",

    // Gateway
    "storeSingle.registrationModal.gateway.title"                            : "Select Payment Method",
    "storeSingle.registrationModal.gateway.paymentMethod.label"              : "Other Payment Method",
    "storeSingle.registrationModal.gateway.paymentMethod.online"             : "Pay online now",
    "storeSingle.registrationModal.gateway.paymentMethod.manual"             : "Send my payment manually",
    "storeSingle.registrationModal.gateway.paymentMethod.empty"              : "No payment method",
    "storeSingle.registrationModal.gateway.paymentMethod.required"           : "Please select a payment method.",
    "storeSingle.registrationModal.gateway.paymentMethod.option.CREDITCARD"  : "Credit card",
    "storeSingle.registrationModal.gateway.paymentMethod.option.CHEQUE"      : "Check",
    "storeSingle.registrationModal.gateway.paymentMethod.option.CASH"        : "Cash",
    "storeSingle.registrationModal.gateway.paymentMethod.option.INTERAC"     : "Interac transfer",
    "storeSingle.registrationModal.gateway.paymentMethod.warning.CREDITCARD" : "You have selected a payment method with SportsPay. You will be redirected to SportsPay’s payment gateway to process your transaction. Once completed, you will be redirected here with a confirmation message and a link to you invoice.",
    "storeSingle.registrationModal.gateway.paymentMethod.myCard.title"       : "My saved credit cards",
    "storeSingle.registrationModal.gateway.paymentMethod.myCard.add"         : "Add new card",
    "storeSingle.registrationModal.gateway.billingInfo.title"                : "Billing info",
    "storeSingle.registrationModal.gateway.billingInfo.label.firstName"      : "First Name",
    "storeSingle.registrationModal.gateway.billingInfo.label.lastName"       : "Last Name",
    "storeSingle.registrationModal.gateway.billingInfo.label.email"          : "Email Address",
    "storeSingle.registrationModal.gateway.billingInfo.label.street"         : "Street Address",
    "storeSingle.registrationModal.gateway.billingInfo.label.city"           : "City",
    "storeSingle.registrationModal.gateway.billingInfo.label.country"        : "Country",
    "storeSingle.registrationModal.gateway.billingInfo.label.province"       : "Province",
    "storeSingle.registrationModal.gateway.billingInfo.label.zip"            : "ZIP / Postal code",
    "storeSingle.registrationModal.gateway.cardInfo.title"                   : "Payment info",
    "storeSingle.registrationModal.gateway.cardInfo.label.name"              : "Name on card",
    "storeSingle.registrationModal.gateway.cardInfo.label.cardNumber"        : "Card Number",
    "storeSingle.registrationModal.gateway.cardInfo.label.expirationDate"    : "Expiration Date",
    "storeSingle.registrationModal.gateway.cardInfo.helper.expirationDate"   : "MM/YY",
    "storeSingle.registrationModal.gateway.cardInfo.label.cvv"               : "CVV",
    "storeSingle.registrationModal.gateway.cardInfo.helper.cvv"              : "Find the CVV",
    "storeSingle.registrationModal.gateway.cardInfo.helper.findCvv"          : "The CVV is usually found on the back of the card and is 3 to 4 digits long.",
    "storeSingle.registrationModal.gateway.pay"                              : "Pay",
    "storeSingle.registrationModal.gateway.country.CA"                       : "Canada",
    "storeSingle.registrationModal.gateway.country.empty"                    : "No country",
    "storeSingle.registrationModal.gateway.provinces.CA.AB"                  : "Alberta",
    "storeSingle.registrationModal.gateway.provinces.CA.BC"                  : "British",
    "storeSingle.registrationModal.gateway.provinces.CA.MB"                  : "Manitoba",
    "storeSingle.registrationModal.gateway.provinces.CA.NB"                  : "New Brunswick",
    "storeSingle.registrationModal.gateway.provinces.CA.NL"                  : "Newfoundland and Labrador",
    "storeSingle.registrationModal.gateway.provinces.CA.NS"                  : "Nova Scotia",
    "storeSingle.registrationModal.gateway.provinces.CA.NU"                  : "Nunavut",
    "storeSingle.registrationModal.gateway.provinces.CA.NT"                  : "Northwest Territories",
    "storeSingle.registrationModal.gateway.provinces.CA.ON"                  : "Ontario",
    "storeSingle.registrationModal.gateway.provinces.CA.PE"                  : "Prince Edward Island",
    "storeSingle.registrationModal.gateway.provinces.CA.QC"                  : "Quebec",
    "storeSingle.registrationModal.gateway.provinces.CA.SK"                  : "Saskatchewan",
    "storeSingle.registrationModal.gateway.provinces.CA.YT"                  : "Yukon",
    "storeSingle.registrationModal.gateway.provinces.empty"                  : "No province",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.generic" : "The error seems to be due to the configuration of this transaction. Please contact our support.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3002"    : "You submitted an invalid card number or brand or combination of card number and brand with your request.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3004"    : "The zip/postal code must be provided for an AVS check request.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3005"    : "You submitted an incorrect CVV value with your request.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3006"    : "You submitted an expired credit card number with your request.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3007"    : "Your request has failed the AVS check. Note that the amount has still been reserved on the customer's card and will be released in 3–5 business days. Please ensure the billing address is accurate before retrying the transaction.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3008"    : "You submitted a card type for which the merchant account is not configured.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3009"    : "Your request has been declined by the issuing bank.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3011"    : "Your request has been declined by the issuing bank because the card used is a restricted card. Contact the cardholder's credit card company for further investigation.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3012"    : "Your request has been declined by the issuing bank because the credit card expiry date submitted is invalid.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3013"    : "Your request has been declined by the issuing bank due to problems with the credit card account.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3014"    : "Your request has been declined - the issuing bank has returned an unknown response. Contact the card holder's credit card company for further investigation.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3015"    : "The bank has requested that you process the transaction manually by calling the cardholder's credit card company.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3016"    : "The bank has requested that you retrieve the card from the cardholder – it may be a lost or stolen card.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3017"    : "You submitted an invalid credit card number with your request.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3018"    : "The bank has requested that you retry the transaction.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3019"    : "Your request has failed the CVV check. Please note that the amount may still have been reserved on the customer's card, in which case it will be released in 3–5 business days.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3020"    : "The bank has requested that you retry the transaction.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3021"    : "The confirmation number included in this request could not be found.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3022"    : "The card has been declined due to insufficient funds.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3023"    : "Your request has been declined by the issuing bank due to its proprietary card activity regulations.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3024"    : "Your request has been declined because the issuing bank does not permit the transaction for this card.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3025"    : "The external processing gateway has reported invalid data.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3026"    : "The external processing gateway has reported the account type is invalid.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3027"    : "The external processing gateway has reported a limit has been exceeded.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3028"    : "The external processing gateway has reported a system error.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3029"    : "The external processing gateway has rejected the transaction.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3030"    : "The external processing gateway has reported the transaction is unauthorized.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3031"    : "The confirmation number you submitted with your request references a transaction that is not on hold.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3032"    : "Your request has been declined by the issuing bank or external gateway because the card is probably in one of their negative databases.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3035"    : "Your request has been declined due to exceeded PIN attempts.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3036"    : "Your request has been declined due to an invalid issuer.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3037"    : "Your request has been declined because it is invalid.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3038"    : "Your request has been declined due to customer cancellation.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3039"    : "Your request has been declined due to an invalid authentication value.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3040"    : "Your request has been declined because the request type is not permitted on the card.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3041"    : "Your request has been declined due to a timeout.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3042"    : "Your request has been declined due to a cryptographic error.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3044"    : "You have submitted a duplicate request.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3045"    : "You submitted an invalid date format for this request.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3046"    : "The transaction was declined because the amount was set to zero.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3047"    : "The transaction was declined because the amount exceeds the floor limit.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3048"    : "The transaction was declined because the amount is less than the floor limit.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3049"    : "The bank has requested that you retrieve the card from the cardholder – the credit card has expired.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3050"    : "The bank has requested that you retrieve the card from the cardholder – fraudulent activity is suspected.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3051"    : "The bank has requested that you retrieve the card from the cardholder – contact the acquirer for more information.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3052"    : "The bank has requested that you retrieve the card from the cardholder – the credit card is restricted.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3053"    : "The bank has requested that you retrieve the card from the cardholder – please call the acquirer.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3054"    : "The transaction was declined due to suspected fraud.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3055"    : "This type of transaction is not supported.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3056"    : "The original transaction type does not match.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.3057"    : "Please tell cardholder to call the issuer – do not retry transaction.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.5031"    : "Transaction has already been processed.",
    "storeSingle.registrationModal.gateway.paysafe.processing.error.6009"    : "There was an error when adding this card to your account. It might already been linked to your account. Enter a new card or use the <link>existing one</link>.",

    // QE error codes
    "storeSingle.registrationModal.gateway.qe.other" : "An error occurred during the transaction.",
    "storeSingle.registrationModal.gateway.qe.1000"  : "Some data in the transaction was missing or invalid, or the reference number was too long.",
    "storeSingle.registrationModal.gateway.qe.1001"  : "Your terminal ID is not activated or you do not have permission to use it from this IP address.",
    "storeSingle.registrationModal.gateway.qe.1003"  : "Transaction declined by InterPay™ Card Services card velocity tracking system. This card has been used more than the preset limit for this terminal ID allows.",
    "storeSingle.registrationModal.gateway.qe.1004"  : "Transaction declined by InterPay™ Card Services merchant velocity system. This merchant has exceeded their preset transaction volume limits.",
    "storeSingle.registrationModal.gateway.qe.1005"  : "Transaction declined by InterPay™ Card Services card limiting system. The amount of money charged to this card has exceeded preset limits. ",
    "storeSingle.registrationModal.gateway.qe.1006"  : "Transaction declined by InterPay™ Card Services merchant limiting system. The amount of money charged on this terminal ID has exceeded preset limits.",
    "storeSingle.registrationModal.gateway.qe.1008"  : "This terminal ID is using the InterPay™ Card Services contra checking system and the card is in the contra table.",
    "storeSingle.registrationModal.gateway.qe.1010"  : "The transaction request message contained one or more illegal characters. Please make sure you are following the specifications.",
    "storeSingle.registrationModal.gateway.qe.1011"  : "A blank or zero amount was sent in a financial transaction. Check the amount.",
    "storeSingle.registrationModal.gateway.qe.1012"  : "The card you are querying is not in the Contra table.",
    "storeSingle.registrationModal.gateway.qe.1014"  : "The card you are attempting to delete from the Contra table is not present in the table.",
    "storeSingle.registrationModal.gateway.qe.1015"  : "There is no matching Merchandise Return for the Merchandise Return Void you are attempting.",
    "storeSingle.registrationModal.gateway.qe.1016"  : "There is no matching Pre-Authorization for the Completion you are attempting.",
    "storeSingle.registrationModal.gateway.qe.1017"  : "There is no matching Sale, Completion, or Force Post for the Void you are attempting.",
    "storeSingle.registrationModal.gateway.qe.1018"  : "You are trying to process a card type which you are not set up for, or the card you are trying to process is not a valid type.",
    "storeSingle.registrationModal.gateway.qe.1019"  : "You are trying to use a transaction type which you are not set up for, or you are trying to use a transaction type which does not exist.",
    "storeSingle.registrationModal.gateway.qe.1020"  : "The card number did not pass check-digit tests for that card type.",
    "storeSingle.registrationModal.gateway.qe.1021"  : "An upstream processor has disconnected.",
    "storeSingle.registrationModal.gateway.qe.1023"  : "Failure in the authorization network. The transaction was neither approved nor declined.",
    "storeSingle.registrationModal.gateway.qe.1024"  : "Failure in the authorization network. The transaction was neither approved nor declined.",
    "storeSingle.registrationModal.gateway.qe.1025"  : "Failure in the authorization network. The transaction was neither approved nor declined.",
    "storeSingle.registrationModal.gateway.qe.1028"  : "The operator ID you submitted contained something other than letters and numbers.",
    "storeSingle.registrationModal.gateway.qe.1029"  : "You have submitted a Force Post transaction without an authorization number.",
    "storeSingle.registrationModal.gateway.qe.1030"  : "The card number you submitted did not contain the correct number of digits for that card type.",
    "storeSingle.registrationModal.gateway.qe.1031"  : "The reference number is missing or invalid. REF can only contain numbers or letters, no spaces or punctuation.",
    "storeSingle.registrationModal.gateway.qe.1032"  : "The card data is invalid or incomplete.",
    "storeSingle.registrationModal.gateway.qe.1033"  : "A settlement was requested for an empty batch. Remember that a batch is a combination of TERMID and OPERID.",
    "storeSingle.registrationModal.gateway.qe.1034"  : "The terminal setup is invalid.",
    "storeSingle.registrationModal.gateway.qe.1035"  : "The REF for each transaction must be unique in the batch.",
    "storeSingle.registrationModal.gateway.qe.1036"  : "The invoice number is invalid.",
    "storeSingle.registrationModal.gateway.qe.1055"  : "Your terminal ID has a maximum amount per transaction limit set, and this transaction exceeds that amount.",
    "storeSingle.registrationModal.gateway.qe.1088"  : "The authorization network has disconnected.",
    "storeSingle.registrationModal.gateway.qe.1097"  : "Communication with the bank host failed during the transaction. Retry the transaction.",
    "storeSingle.registrationModal.gateway.qe.1098"  : "The bank host provided an invalid response. Retry the transaction.",
    "storeSingle.registrationModal.gateway.qe.1099"  : "A network failure has occurred while communicating with the card issuer.",
    "storeSingle.registrationModal.gateway.qe.9100"  : "The gateway has generated an internal error.",
    "storeSingle.registrationModal.gateway.qe.9101"  : "The gateway has generated an internal error.",
}