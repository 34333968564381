import { useContext, useEffect, useRef, useState } from 'react';
import { I18nContext } from '../../../contexts/I18nContext';

const GoogleMap = ({ city, province, street, streetNb, unitNb, zip, className, ...props }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const [ iframeSrc, setiframeSrc ] = useState(null);
    const googleMapRef = useRef();

    useEffect(() => {
        let query = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_PAGE_SITE_GOOGLE_API_KEY}&q=`;

        if(streetNb) query += streetNb;
        if(street) query += '+' + street;
        if(city) query += ',' + city;
        if(province) query += ',' + province;
        if(zip) query += ',' + zip;

        query += `&language=${getGenericLocale()}`;

        setiframeSrc(query);
    }, [ city, province, street, streetNb, unitNb ]);

    return (
        <iframe
            className={className}
            id="google-map"
            ref={googleMapRef}
            src={iframeSrc}
        />
    );
}

export default GoogleMap;

