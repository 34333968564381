import * as Sentry from "@sentry/react";
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Button, Collapse, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { RegistrationContext } from "../../../../../../../../contexts/RegistrationContext";
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";

const RegistrationSummaryMember = ({ metaMember, membersArray }) => {
    const registrationContext = useContext(RegistrationContext)

    const registrationFee = registrationContext.membersInfo[metaMember.members[0].member_id].registration
    const cartItem = registrationContext.state.currentCart.cartInfo.cart_detail?.find((row) => (row.item_type === 'REGISTRATION' && row.member.member_id === metaMember.members[0].member_id))
    const credits = registrationContext.membersInfo[metaMember.members[0].member_id].credits?.filter((credit) => credit.active === '1' && (!credit?.expiration_date || moment(credit.expiration_date).isAfter(moment().subtract(1, 'day'))) && parseInt(credit.balance) > 0)

    const otherItems = registrationContext.state.currentCart.cartInfo.cart_detail?.reduce((newArray, row) => {
        // only other items, related items are displayed with the registrationFee
        if(row.item_type === 'OTHER' && row.member.member_id === metaMember.members[0].member_id && row.automatically_added == 0){
            newArray.push({
                oFee: registrationContext.currentOnlineStore.items.other_fees.find((f) => f.other_fee_id === row.item_id),
                quantity: parseInt(row.quantity),
            })
        }
        return newArray
    }, [])
    const affiliationFees = registrationContext.state.currentCart.cartInfo.cart_detail.filter((row) => row.item_type === 'AFFILIATION' && row.member.member_id === metaMember.members[0].member_id);
    const affiliationFeesTotal = affiliationFees.reduce((total, affiliationFee) => total += parseInt(affiliationFee.amount), 0)

    const [ isOpen, setIsOpen ] = useState(membersArray.length === 1);
    const [ creditsActivated, setCreditsActivated ] = useState(registrationContext.state.currentCart.cartInfo.cart_summary.members_amount.find((m) => m.member_id === metaMember.members[0].member_id)?.credit_amount > 0);

    useEffect(() => {
        // in case the user deletes a member from the cart, we want to check again the length
        setIsOpen(membersArray.length === 1)
    }, [ membersArray.length ])

    const displayItem = (item) => (
        <div className="d-table-row" key={item.id}>
            <div className="d-table-cell w-100 mb-2">
                <div>
                    <span>{item.name}</span>
                    {item.amount > 1 &&
                        <>
                            <span className="mx-1"><i className="mdi mdi-close" /></span>
                            <span>{item.amount}</span>
                        </>
                    }
                </div>
                {(!item.required || (item.required && item.amount > 1)) &&
                    <>
                        <button
                            type="button"
                            id={`registrationSummaryMemberRemoveItem-${item.id}`}
                            className="reset-btn d-block mt-n1"
                        >
                            <span className="small text-danger"><Translate id='misc.remove' /></span>
                        </button>
                        {/* <RegistrationSummaryRemoveItem itemName={item.name} onClick={() => removeItem(item.feeId, item.amount-1, item.memberId)}/> */}
                        <UncontrolledPopover trigger='legacy' placement="top" target={`registrationSummaryMemberRemoveItem-${item.id}`}>
                            <PopoverBody>
                                <p className="text-danger text-center my-1"><Translate id='participant.registrationModal.views.registrationSummary.action' /></p>
                                <div className="text-center">
                                    <Button
                                        id={'registrationSummaryMemberRemoveItemInPopover-' + item.id}
                                        color="danger"
                                        onClick={() => removeItem(item.feeId, item.amount - 1, item.memberId)}
                                    >
                                        <Translate id='misc.remove' />
                                    </Button>
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </>
                }
            </div>
            <div className="d-table-cell text-right">
                <div><CurrencyFormat value={item.price / 100} /></div>
            </div>
        </div>
    )

    const removeItem = (itemId, quantity, memberId) => {
        const otherItems = registrationContext.state.currentCart.cartInfo.cart_detail.filter((row) => row.item_type === 'OTHER' && row.member.member_id === memberId && row.automatically_added == 0)
        if(registrationContext.membersInfo[memberId].skipRegistrations && otherItems.length === 1 && otherItems[0].quantity == 1){ // skip registrations, last item and last quantity for this member
            removeMember(memberId);
        }else{
            Sentry.addBreadcrumb({
                type: 'info',
                message: 'Removing item from cart...',
                level: Sentry.Severity.Log,
                category: 'info',
                data: {
                    itemId: itemId,
                    quantity: quantity,
                    memberId: memberId,
                },
            });
            registrationContext.setModalLoading(true);
            const rowId = registrationContext.state.currentCart.cartInfo.cart_detail.find((row) => row.item_id === itemId && row.member.member_id === memberId)?.row_id;

            Promise.all([
                quantity === 0 ?
                    registrationContext.removeCartItem(rowId)
                    :
                    registrationContext.updateCartItemQuantity(rowId, quantity),
            ])
                .then(async() => {
                    const cart = await registrationContext.refreshCart();
                    registrationContext.setModalLoading(false);
                    if(cartIsEmpty(cart)){
                        registrationContext.resetRegistration(true)
                    }
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error);
                        registrationContext.setModalLoading(false);
                        fail({ info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />, skipInfoTranslate: true })
                    }
                })
        }
    }

    const removeMember = (memberId) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Removing member from cart...',
            level: Sentry.Severity.Log,
            category: 'info',
            data: {
                member: memberId,
            },
        });
        registrationContext.setModalLoading(true);

        registrationContext.removeMemberItemsFromCurrentCart(memberId)
            .then(async() => {
                const cart = await registrationContext.refreshCart()
                registrationContext.setModalLoading(false);
                if(cartIsEmpty(cart)){ // just deleted last member
                    registrationContext.resetRegistration(true)
                }
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error);
                    registrationContext.setModalLoading(false);
                    fail({ info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />, skipInfoTranslate: true })
                }
            })
    }

    const cartIsEmpty = (cart) => {
        return !cart.cart_detail || cart.cart_detail?.length === 0
    }

    const editCredits = (activate) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Editing credit cart...',
            level: Sentry.Severity.Log,
            category: 'info',
            data: {
                activate: activate,
                cartId: registrationContext.state.currentCart.cartInfo.shopping_cart_id,
            },
        });
        registrationContext.setModalLoading(true);
        Promise.all([
            activate ?
                registrationContext.activateMemberCredits(metaMember.members[0].member_id)
                :
                registrationContext.deactivateMemberCredits(metaMember.members[0].member_id),
        ])
            .then(async() => {
                setCreditsActivated(activate)
                await registrationContext.refreshCart();
                registrationContext.setModalLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    registrationContext.setModalLoading(false);
                    fail({ info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />, skipInfoTranslate: true })
                }
            })
    }

    const editParticipantCart = (member) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Editing participant cart...',
            level: Sentry.Severity.Log,
            category: 'info',
            data: {
                member: member.member_id,
            },
        });

        registrationContext.setCurrentMemberId(member.member_id)
            .then(() => registrationContext.goToView(registrationContext.views.registrations))
    }

    return (
        <div>
            {membersArray?.length === 1 ?
                <>
                    {/* Single member -> no collapse button */}
                    <div className="h3 font-weight-bold mb-0"><Translate id='participant.registrationModal.views.registrationSummary.for' /> {metaMember.first_name + ' ' + metaMember.last_name}</div>
                    <span className='small d-inline-flex'>
                        <button
                            type="button"
                            id={'registrationSummaryMemberEditMember-' + metaMember.members[0].member_id}
                            className='reset-btn'
                            onClick={() => editParticipantCart(metaMember.members[0])}
                        >
                            <span className='text-info'><Translate id='participant.registrationModal.views.registrationSummary.edit' /></span>
                        </button>
                        <div className='mx-3 border-left border-right' />
                        <button
                            type="button"
                            className='reset-btn'
                            id={`registrationSummaryMemberRemoveMember-${metaMember.members[0].member_id}`}
                        >
                            <span className='text-danger'><Translate id='participant.registrationModal.views.registrationSummary.removeMember' /></span>
                        </button>
                    </span>
                    <UncontrolledPopover trigger='legacy' placement="top" target={`registrationSummaryMemberRemoveMember-${metaMember.members[0].member_id}`}>
                        <PopoverBody>
                            <p className="text-danger text-center my-1"><Translate id='participant.registrationModal.views.registrationSummary.removeMember.confirmation' /></p>
                            <div className="text-center">
                                <Button
                                    id={'registrationSummaryMemberRemoveMemberInPopover-' + metaMember.members[0].member_id}
                                    color="danger"
                                    onClick={() => removeMember(metaMember.members[0].member_id)}
                                >
                                    <Translate id='misc.remove' />
                                </Button>
                            </div>
                        </PopoverBody>
                    </UncontrolledPopover>
                </>
                :
                <div className='d-flex justify-content-between'>
                    {/* Multiple members -> collapse buttons for each member */}
                    <div>
                        <div className="h3 font-weight-bold mb-0"><Translate id='participant.registrationModal.views.registrationSummary.for' /> {metaMember.first_name + ' ' + metaMember.last_name}</div>
                        <span className='small d-inline-flex'>
                            <button
                                type="button"
                                id={'registrationSummaryMemberEditMember-' + metaMember.members[0].member_id}
                                className='reset-btn'
                                onClick={() => editParticipantCart(metaMember.members[0])}
                            >
                                <span className='text-info'><Translate id='participant.registrationModal.views.registrationSummary.edit' /></span>
                            </button>
                            <div className='mx-3 border-left border-right' />
                            <button
                                type="button"
                                className='reset-btn'
                                id={`registrationSummaryMemberRemoveMember-${metaMember.members[0].member_id}`}
                            >
                                <span className='text-danger'><Translate id='participant.registrationModal.views.registrationSummary.removeMember' /></span>
                            </button>
                        </span>
                        <UncontrolledPopover trigger='legacy' placement="top" target={`registrationSummaryMemberRemoveMember-${metaMember.members[0].member_id}`}>
                            <PopoverBody>
                                <p className="text-danger text-center my-1"><Translate id='participant.registrationModal.views.registrationSummary.removeMember.confirmation' /></p>
                                <div className="text-center">
                                    <Button
                                        id={'registrationSummaryMemberRemoveMemberInPopover-' + metaMember.members[0].member_id}
                                        color="danger"
                                        onClick={() => removeMember(metaMember.members[0].member_id)}
                                    >
                                        <Translate id='misc.remove' />
                                    </Button>
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                    <div className='ml-auto'>
                        <button
                            id={'registrationSummaryMemberToggleMember-' + metaMember.members[0].member_id}
                            type='button'
                            onClick={() => { setIsOpen(!isOpen) }} className={stringBuilder('accordion-btn btn align-items-center justify-content-center', { 'is-open': isOpen })}
                        >
                            <i className='mdi mdi-plus' />
                        </button>
                    </div>
                </div>
            }
            <hr />

            <Collapse isOpen={isOpen}>
                {registrationFee &&
                    <>
                        <div className="d-table">
                            {/* REGISTRATION ITEM & RELATED ITEMS */}
                            <div className="d-table-row">
                                <div className="d-table-cell w-100">
                                    <div className="font-weight-bold mb-2"><Translate id='participant.registrationModal.views.registrationSummary.registration' /></div>
                                </div>
                            </div>
                            {displayItem({
                                id: metaMember.members[0].member_id + '-' + registrationFee?.registration_fee_id,
                                name:
                                    <DisplayI18n
                                        field='name'
                                        i18n={registrationFee?.fee.i18n}
                                        defaultValue={registrationFee?.fee.name}
                                    />,
                                price:
                                    parseInt(
                                        moment().isBefore(moment(registrationFee?.fee.early_amount_until)) ?
                                            registrationFee?.fee.early_amount
                                            : moment().isAfter(moment(registrationFee?.fee.late_amount_after)) ?
                                                registrationFee?.fee.late_amount
                                                :
                                                registrationFee?.fee.amount,
                                    ) + affiliationFeesTotal,
                                required: true,
                                amount: registrationFee?.quantity,
                                feeId: registrationFee?.registration_fee_id,
                                memberId: metaMember.members[0].member_id,
                            })}

                            {registrationFee?.related_items.length > 0 &&
                                <>
                                    <div className="d-table-row">
                                        <div className="d-table-cell w-100">
                                            <div className="font-weight-bold small text-muted"><Translate id='participant.registrationModal.views.registrationSummary.requiredItems' /></div>
                                        </div>
                                    </div>
                                    {registrationFee?.related_items.map((relatedItem) => {
                                        // found in cart, just in case
                                        if(registrationContext.state.currentCart.cartInfo.cart_detail.find((r) => r.item_type === 'OTHER' && r.member.member_id === metaMember.members[0].member_id && r.item_id === relatedItem.other_fee_id)){
                                            const quantity = parseInt(registrationContext.state.currentCart.cartInfo.cart_detail.find((r) => r.item_type === 'OTHER' && r.member.member_id === metaMember.members[0].member_id && r.item_id === relatedItem.other_fee_id).quantity);
                                            return (
                                                displayItem({
                                                    id: metaMember.members[0].member_id + '-' + relatedItem.other_fee_id,
                                                    name: <DisplayI18n field='name' defaultValue={relatedItem.fee.name} i18n={relatedItem.fee.i18n} />,
                                                    price: relatedItem.fee.amount * quantity,
                                                    required: true,
                                                    amount: quantity,
                                                    feeId: relatedItem.other_fee_id,
                                                    memberId: metaMember.members[0].member_id,
                                                })
                                            )
                                        }
                                    })}
                                </>
                            }
                        </div>
                        <hr />
                    </>
                }

                {/* OTHER ITEMS */}
                {otherItems.length > 0 &&
                    <>
                        <div className='d-table'>
                            <div className="d-table-cell w-100">
                                <div className="font-weight-bold mb-2"><Translate id='participant.registrationModal.views.registrationSummary.otherItems' /></div>
                            </div>
                        </div>

                        {otherItems.map(({ oFee, quantity }) => {
                            // found in cart, just in case
                            if(oFee && registrationContext.state.currentCart.cartInfo.cart_detail.find((r) => r.item_type === 'OTHER' && r.member.member_id === metaMember.members[0].member_id && r.item_id === oFee.other_fee_id)){
                                return (
                                    displayItem({
                                        id: metaMember.members[0].member_id + '-' + oFee.other_fee_id,
                                        name: <DisplayI18n field='name' defaultValue={oFee.fee.name} i18n={oFee.fee.i18n} />,
                                        price: oFee.fee.amount * quantity,
                                        required: false,
                                        amount: quantity,
                                        feeId: oFee.other_fee_id,
                                        memberId: metaMember.members[0].member_id,
                                    })
                                )
                            }
                            return null;
                        })}
                        <hr />
                    </>
                }

                {/* CREDITS / REBATES & SUBTOTAL */}
                {(credits?.length > 0 || (!isNaN(parseInt(cartItem?.rebate_amount)) && cartItem.rebate_amount > 0)) &&
                    <>
                        {credits.length > 0 &&
                            <>
                                <div className="d-table-row">
                                    <div className="d-table-cell w-100 mb-2">
                                        <i className="mdi mdi-information-outline text-info" /> <Translate id='participant.registrationModal.views.registrationSummary.credit' />
                                        {creditsActivated ?
                                            <>
                                                <span className="mx-1">-</span>
                                                <button type="button" id={'registrationSummaryMemberDeactivateCredits-' + metaMember.members[0].member_id} className='reset-btn' onClick={() => editCredits(false, metaMember.members[0].member_id)}>
                                                    <span className='small text-danger'>
                                                        <Translate id='participant.registrationModal.views.registrationSummary.credit.deactivate' />
                                                    </span>
                                                </button>
                                            </>
                                            :
                                            <>
                                                <span className="mx-1">-</span>
                                                <button type="button" id={'registrationSummaryMemberActivateCredits-' + metaMember.members[0].member_id} className='reset-btn' onClick={() => editCredits(true, metaMember.members[0].member_id)}>
                                                    <span className='small text-info'>
                                                        <Translate id='participant.registrationModal.views.registrationSummary.credit.activate' />
                                                    </span>
                                                </button>
                                            </>
                                        }
                                    </div>
                                    <div className="d-table-cell text-right">
                                        {creditsActivated ?
                                            <span className='text-success'>
                                                <CurrencyFormat value={registrationContext.state.currentCart.cartInfo.cart_summary.members_amount.find((m) => m.member_id === metaMember.members[0].member_id).credit_amount / 100 * -1} />
                                            </span>
                                            :
                                            <span>
                                                -<CurrencyFormat value={0} />
                                            </span>
                                        }
                                    </div>
                                </div>
                                <hr />
                            </>
                        }

                        {(!isNaN(parseInt(cartItem?.rebate_amount)) && cartItem.rebate_amount > 0) &&
                            <div className="d-table-row">
                                <div className="d-table-cell w-100 mb-2">
                                    <Translate id='participant.registrationModal.views.registrationSummary.rebate' /> - {cartItem.rebate?.title}
                                </div>

                                <div className="d-table-cell text-right">
                                    <span className='text-success'><CurrencyFormat value={cartItem?.rebate_amount / 100 * -1} /></span>
                                </div>
                            </div>
                        }

                        <div className="d-table-row font-weight-bold">
                            <div className="d-table-cell w-100 mb-2"><Translate id='participant.registrationModal.views.registrationSummary.subtotal' /> {metaMember.first_name + ' ' + metaMember.last_name}</div>
                            <div className="d-table-cell text-right">
                                <div>
                                    <CurrencyFormat value={registrationContext.state.currentCart.cartInfo.cart_summary.members_amount.find((m) => m.member_id === metaMember.members[0].member_id).total_amount / 100} />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </>
                }
            </Collapse>
        </div>
    )
}

export default RegistrationSummaryMember;