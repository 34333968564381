import { DisplayPhoneNumber, TelHref } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { I18nContext } from "../../../contexts/I18nContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import getReferrer, { isProdFishNChips } from "../../../helpers/getReferer";

const SupportBlock = () => {
    const { organisation_email, phone, website } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <section className="section-container">
            <header>
                <h3 className="section-main-title"><Translate id='page.supportBlock.title' /></h3>
            </header>
            { phone || organisation_email || website ?
                <>
                    <p className="mb-1"><Translate id='page.supportBlock.text' /> <Translate id='page.supportBlock.text.contactUs' /></p>
                    <address>
                        {phone &&
                            <span className="d-flex align-items-center">
                                <i className="mdi mdi-cellphone-iphone mr-1" />
                                <TelHref phoneString={phone}>
                                    <DisplayPhoneNumber withExtension phoneString={phone} format='INTERNATIONAL' />
                                </TelHref>
                            </span>
                        }
                        {organisation_email &&
                            <span className="d-flex align-items-center">
                                <i className="mdi mdi-email-outline mr-1" />
                                <a id='email-org-support' title={organisation_email} className="d-block text-truncate" href={`mailto:${organisation_email}`}>
                                    {organisation_email}
                                </a>
                            </span>
                        }
                        {website &&
                            <span className="d-flex align-items-center">
                                <i className="mdi mdi-web mr-1" />
                                <a id='view-org-website-support' title={website} className="d-block text-truncate" href={website} target="_blank" rel="noopener noreferrer">{website}</a>
                            </span>
                        }
                    </address>
                </>
                :
                <p><Translate id='page.supportBlock.text' /> <Translate id='page.supportBlock.text.contactOrg' /></p>
            }
            <footer className="small">
                <h4 className="font-weight-bold"><Translate id='page.supportBlock.subtitle' /></h4>
                <p className="mb-0">
                    <Translate
                        id='page.supportBlock.subtext'
                        values={{ a: (chunks) => (
                            <a
                                href={isProdFishNChips() ?
                                    'https://spordle.atlassian.net/wiki/spaces/HDNE/pages/2426011661/Parents+Guide+-+Online+Registration'
                                    :
                                    `${getReferrer('HCR-SITE', getGenericLocale())}/support`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {chunks}
                            </a>
                        ) }}
                    />
                </p>
            </footer>
        </section>
    )
}

export default SupportBlock;