import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Alert, Button, Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { object, array } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import FileUpload from '../../../../../../../components/fileUpload/FileUpload';
import { FormikError } from '@spordle/formik-elements';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import Translate from '@spordle/intl-elements';
import { mbToBytes } from '../../../../../../../components/fileUpload/uploadHelpers';

const RegistrationDocuments = (props) => {

    const membersContext = useContext(MembersContext);
    const orgContext = useContext(OrganizationContext);
    const registrationContext = useContext(RegistrationContext);

    const requiredDocumentTypeIds = orgContext.settings.attachment_for_new_member_in_registration?.value || [];

    const getDocumentsInitialValues = () => {
        return requiredDocumentTypeIds.reduce((newObj, documentTypeId) => {
            newObj[documentTypeId] = [];
            return newObj
        }, {})
    }

    // as of feburary 8th, we do not want the documents to be required, we might go back on this later
    const getDocumentsValidationSchema = () => {
        if(registrationContext.needsDocuments){
            return requiredDocumentTypeIds.reduce((newObj, documentTypeId) => {
                // newObj[documentTypeId] = array().min(1, <Translate id='form.validation.documents.required' />)
                newObj[documentTypeId] = array()
                return newObj
            }, {})
        }
        return {}

    }

    return (
        <Formik
            initialValues={{
                ...getDocumentsInitialValues(),
            }}
            validationSchema={object().shape({
                ...getDocumentsValidationSchema(),
            })}
            onSubmit={async(values, { setStatus, setSubmitting }) => {
                if(registrationContext.needsDocuments){
                    try{
                        const attachmentsArray = [];

                        for(let i = 0; i < requiredDocumentTypeIds.length; i++){
                            const documentTypeId = requiredDocumentTypeIds[i];
                            attachmentsArray.pushArray(values[documentTypeId].map((file) => ({
                                document_type_id: documentTypeId,
                                file: file,
                            })))
                        }

                        if(attachmentsArray.length > 0){
                            await membersContext.createMemberAttachment(registrationContext.getCurrentMember().members[0].member_id, attachmentsArray)
                        }

                        registrationContext.goToView(registrationContext.views.registrations);
                        setSubmitting(false)
                    }catch(error){
                        if(!AxiosIsCancelled(error.message)){
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            setSubmitting(false)
                            console.error(error);
                        }
                    }
                }else{
                    registrationContext.goToView(registrationContext.views.registrations);
                    setSubmitting(false)
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="text-white bg-dark">
                            <Translate id='participant.registrationModal.views.newParticipant.title' />
                        </ModalHeader>
                        <ModalBody className="py-4 bg-light">
                            {registrationContext.needsDocuments ?
                                requiredDocumentTypeIds.reduce((newArray, documentTypeId) => {
                                    const documentType = registrationContext.documentTypes.find((dT) => dT.document_type_id === documentTypeId);

                                    if(documentType){
                                        newArray.push(
                                            <Col sm='12' className='mb-3' key={documentTypeId}>
                                                <DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} />
                                                <FileUpload
                                                    defaultFiles={[]}
                                                    onFileSelect={(files) => {
                                                        formik.setFieldValue(documentTypeId, files);
                                                    }}
                                                    dropzoneProps={{
                                                        multiple: true,
                                                        maxSize: documentType?.file_size ? mbToBytes(parseFloat(documentType.file_size)) : undefined,
                                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                    }}
                                                />
                                                <FormikError name={documentTypeId} />
                                                {documentType.description &&
                                                    <div className='small text-muted'>
                                                        <DisplayI18n field='description' defaultValue={documentType.description} i18n={documentType.i18n} />
                                                    </div>
                                                }
                                            </Col>,
                                        )
                                    }
                                    return newArray
                                }, [])
                                :
                                <p className='text-center'><Translate id='participant.registrationModal.views.documents.text' /></p>
                            }

                            { formik.status &&
                                <Alert color='danger' toggle={() => formik.setStatus()}>
                                    {formik.status}
                                </Alert>
                            }
                        </ModalBody>
                        <ModalFooter tag='footer' className="bg-light">
                            <Button
                                id='registrationDocumentsPreviousButton'
                                type="button"
                                onClick={() => {
                                    // we go back to existingOrNew because we dont want to come back to new participant and create the same member again
                                    registrationContext.removeMember(registrationContext.state.currentMemberId)
                                    registrationContext.setCurrentMemberId('');
                                    registrationContext.goToView(registrationContext.views.existingOrNew, true)
                                }}
                                color="default" outline
                                className="mr-auto"
                            >
                                <Translate id='misc.previous' />
                            </Button>
                            <Button id='registrationDocumentsSubmitButton' type="submit" color="info"><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default RegistrationDocuments