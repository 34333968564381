import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Fade } from 'reactstrap';
import GoogleMap from '../../../components/google/googleMap/GoogleMap';
// import ItemsList from '../../../components/visual/itemList/ItemsList';
// import SingleItem from '../../../components/visual/itemList/SingleItem';
// import Placeholder from '../../../assets/images/no-profile.png';
import Skeleton from '../../../components/loading/Skeleton';
import Section from '../../../components/visual/structure/Section';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { DisplayPhoneNumber, TelHref } from '@spordle/formik-elements';

const Contacts = ({ ...props }) => {
    const org = useContext(OrganizationContext);
    const intl = useIntl();
    const [ address, setAddress ] = useState(null);

    useEffect(() => {
        const top = [];
        const bottom = [];

        if(org){
            if(org.street_number) top.push(org.street_number);
            if(org.street) top.push(`${org.street} ${org.unit_number || ''}`);
            if(org.city) bottom.push(org.city);
            if(org.province_code) bottom.push(org.province_code);
            if(org.country_code) bottom.push(org.country_code);
            if(org.zip_code) bottom.push(org.zip_code);
        }

        setAddress({ top: top.join(', '), bottom: bottom.join(', ') });
    }, [ org ]);

    useEffect(() => {
        document.title = intl.formatMessage({ id: 'contacts.helmet.title' });
        document.querySelector('meta[name="description"]').setAttribute("content", intl.formatMessage({ id: 'contacts.helmet.description' }, { org: 'Nom Organisation' }));
    }, [ intl.locale ])

    return (
        <Fade>
            <Section>
                <header>
                    <h1 className="section-main-title"><Translate id='contacts.page.name' /></h1>
                </header>
                <section>
                    <header>
                        {!!address?.top &&
                            <div className="rounded overflow-hidden border mb-3 contacts-google-map-container">
                                <GoogleMap
                                    city={org?.city}
                                    country={org?.country_code}
                                    province={org?.province_code}
                                    street={org?.street}
                                    streetNb={org?.street_number}
                                    unitNb={org?.unit_number}
                                    zip={org?.zip_code}
                                    className="contacts-google-map"
                                />
                            </div>
                        }
                        {org ?
                            <h2><DisplayI18n field="name" defaultValue={org.organisation_name} i18n={org.i18n} /></h2>
                            :
                            <Skeleton className="w-50" height={32} />
                        }
                    </header>
                    <address>
                        <span className="d-flex align-items-top">
                            <i className="mdi mdi-map-marker outline mr-1" />
                            {org ?
                                <span>
                                    {address?.top}
                                    {address?.top && address?.bottom && <br />}
                                    {address?.bottom}
                                </span>
                                :
                                <span className="d-block w-50">
                                    <Skeleton className="d-block" tag="span" count={2} height={20} />
                                </span>
                            }
                        </span>
                        {org ?
                            org.phone &&
                                <span className="d-flex align-items-center">
                                    <i className="mdi mdi-cellphone-iphone mr-1" />
                                    <TelHref phoneString={org.phone}><DisplayPhoneNumber phoneString={org.phone} format='INTERNATIONAL' /></TelHref>
                                </span>
                            :
                            <span className="d-flex align-items-center w-50">
                                <i className="mdi mdi-cellphone-iphone mr-1" />
                                <Skeleton tag="span" height={20} />
                            </span>
                        }
                        {org ?
                            org?.organisation_email &&
                            <span className="d-flex align-items-center">
                                <i className="mdi mdi-email-outline mr-1" />
                                <a id='email-org' href={`mailto:${org.organisation_email}`}>{org.organisation_email}</a>
                            </span>
                            :
                            <span className="d-flex align-items-center w-50">
                                <i className="mdi mdi-email-outline mr-1" />
                                <Skeleton tag="span" height={20} />
                            </span>
                        }
                    </address>
                    {org?.website &&
                        <footer className="d-flex justify-content-between">
                            <a id='view-org-website-contact' className="btn btn-primary mr-2" href={org.website} target="_blank" rel="noopener noreferrer"><i className="mdi mdi-launch" /> <Translate id='contacts.btn.website' /></a>
                        </footer>
                    }
                </section>
                {/* <section className="border-top pt-3">
                    <header>
                        <h2 className="mb-3"><Translate id='contacts.list.primary' /></h2>
                    </header>
                    <ItemsList>
                        <SingleItem img={{src: Placeholder, alt:'Alt'}}>
                            <h3 className="h4 mb-0">Contact Name</h3>
                            <SingleItem.Details>
                                <span className="text-muted">Function</span>
                                <a className="d-flex contacts-single-contact-phone" href="">(450) 621-9212</a>
                            </SingleItem.Details>
                        </SingleItem>
                    </ItemsList>
                </section> */}
            </Section>
        </Fade>
    );
}

export default Contacts;