export default{
    "postModal.btn.post" : "Publier",
    "postModal.btn.edit" : "Editer",
    "postModal.btn.img"  : "Ajouter une image",

    "postModal.input.title"    : "Titre de la publication",
    "postModal.input.textarea" : "Quoi de nouveau ? ...",

    "postModal.title.new"  : "Nouvelle publication",
    "postModal.title.edit" : "Modifier la publication",

    "postModal.words.remaining" : "mots restants sur",
}