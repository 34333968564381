import { useContext } from 'react';
import Container from "reactstrap/es/Container";
import icon from './assets/images/BrowserNotSupported.png';
import chrome from './assets/images/logos/chrome.svg';
import edge from './assets/images/logos/edge-40.png';
import firefox from './assets/images/logos/firefox-40.png';
import opera from './assets/images/logos/opera.svg';
import safari from './assets/images/logos/safari-40.png';
import { I18nContext } from './contexts/I18nContext';
import { LOCALES } from './i18n/locales';
import messages from './i18n/messages';

// routes.js -> /browsernotsupported
const BrowserNotSupported = () => {

    return (
        <div className="min-vh-100 d-flex align-items-center bg-light py-5">
            <Container className="text-center">
                <img src={icon} alt="Browser not supported" />
                <h1 className="h2 font-bold mb-3"><GetMessage messageId='misc.notSupported.title' /></h1>
                <p className="font-medium mb-4">
                    <GetMessage messageId='misc.notSupported.text.1' />
                    <br />
                    <GetMessage messageId='misc.notSupported.text.2' />
                </p>
                <p className="text-nowrap">
                    <a className="mx-md-3 mx-sm-2 mx-1" target='_blank' rel='noopener noreferrer' href="https://www.microsoft.com/edge">
                        <img src={edge} alt="Edge" width="40" />
                    </a>
                    <a className="mx-md-3 mx-sm-2 mx-1" target='_blank' rel='noopener noreferrer' href="https://www.mozilla.org/firefox">
                        <img src={firefox} alt="Firefox" width="40" />
                    </a>
                    <a className="mx-md-3 mx-sm-2 mx-1" target='_blank' rel='noopener noreferrer' href="https://www.google.com/chrome">
                        <img src={chrome} alt="Chrome" width="40" />
                    </a>
                    <a className="mx-md-3 mx-sm-2 mx-1" target='_blank' rel='noopener noreferrer' href="https://support.apple.com/downloads/safari">
                        <img src={safari} alt="Safari" width="40" />
                    </a>
                    <a className="mx-md-3 mx-sm-2 mx-1" target='_blank' rel='noopener noreferrer' href="https://www.opera.com/download">
                        <img src={opera} alt="Opera" width="40" />
                    </a>
                </p>
            </Container>
        </div>
    );
}

const GetMessage = ({ messageId }) => {
    return messages[useContext(I18nContext).locale]?.[messageId] || messages[LOCALES.ENGLISH][messageId];
}

export default BrowserNotSupported;