import { useContext } from 'react';
import {
    Collapse
} from "reactstrap";
import UserDisplay from '../../../../../../../../components/userDisplay/UserDisplay';
import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import { OrganizationContext } from '../../../../../../../../contexts/OrganizationContext';
import RegistrationAhmChecker from '../RegistrationAhmChecker';
import Translate from '@spordle/intl-elements';
import ImgPill from '../../../../../../../../components/visual/imgPill/ImgPill';

const RegistrationParticipantSingle = ({ participant, selected, inCart, selectCartParticipant, ...props }) => {
    const orgContext = useContext(OrganizationContext);

    // const togglePrerequisites = () => setPrerequisites(!prerequisites);
    // const toggleLinkParticipant = () => setLinkParticipant(!linkParticipant);
    // const [prerequisites, setPrerequisites] = useState(false);
    // const [linkParticipant, setLinkParticipant] = useState(false);

    const alertWrongOrg = !participant.members[0].available_organisations.find((orgId) => orgId === orgContext.organisation_id);
    const alertTooYoung = moment(participant?.birthdate).isAfter(moment().subtract(4, "years"));
    const draftMember = participant.members[0]?.member_status?.name === 'DRAFT';

    const getIcon = () => {
        let icon = 'hello';
        if(inCart){
            icon = (
                <div className="registration-card-icon is-visible">
                    <i className="mdi mdi-cart-outline" />
                </div>
            )
        }else if(alertWrongOrg || alertTooYoung){
            icon = (
                <div className="registration-card-icon">
                    <i className="mdi mdi-alert-outline" />
                </div>
            )
        // }else if(draftMember){
        //     icon = (
        //         <div className="registration-card-icon is-visible">
        //             <i className="mdi mdi-account-alert" />
        //         </div>
        //     )
        }else{
            icon = (
                <div className="registration-card-icon">
                    <i className="mdi mdi-check" />
                </div>
            )
        }

        return icon
    }

    return (
        <div className="mb-3">
            <div
                {...props} className={stringBuilder(
                    { 'is-selected': selected },
                    { 'is-new': false },
                    { 'is-alert': alertWrongOrg },
                    { 'bg-light border-dashed': draftMember },
                    // {'is-disabled': inCart || false},
                    { 'in-cart': inCart },
                    "registration-card participant p-3 mb-0",
                )}
            >
                <UserDisplay>
                    <UserDisplay.Container>
                        <ImgPill
                            size="sm"
                            src={participant.photo}
                            abbr={participant.first_name.charAt(0) + participant.last_name.charAt(0)}
                            alt={participant.first_name + ' ' + participant.last_name}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <div className="d-flex align-items-center">
                            <UserDisplay.Title className="mr-2">{participant.first_name} {participant.last_name}</UserDisplay.Title>
                            { participant?.members[0]?.age && <UserDisplay.Subtitle className="mr-2">({participant?.members[0]?.age} <Translate id='misc.yo' />)</UserDisplay.Subtitle> }
                            {alertWrongOrg &&
                                <i className="mdi mdi-alert text-warning align-self-end" />
                            }
                            {draftMember &&
                                <i className="mdi mdi-account-alert text-muted align-self-end" />
                            }
                        </div>
                        <UserDisplay.Subtitle>
                            {participant?.members[0]?.unique_identifier && <span><Translate id='misc.hcr#' /> {participant.members[0].unique_identifier}</span>}
                        </UserDisplay.Subtitle>
                        { inCart &&
                            <UserDisplay.Subtitle className="text-info" onClick={() => selectCartParticipant(participant)}>
                                <Translate id='participant.registrationModal.views.registrationParticipantSingle.edit' />
                            </UserDisplay.Subtitle>
                        }
                        {draftMember &&
                            <UserDisplay.Subtitle>
                                <Translate id='participant.registrationModal.views.registrationParticipantSingle.draft' />
                            </UserDisplay.Subtitle>
                        }
                        {/* <UserDisplay.Subtitle>
                            {participant.hcr ?
                                <span>HCR# {participant.unique_identifier}</span>
                            :
                                <button className="reset-btn participant-linkToHcr" onClick={() => setView(RegistrationViews.hcrSearch)}>
                                    <i className="mdi mdi-link-variant"></i>
                                    <span>Link to Hockey Canada Registry</span>
                                    <i className={`mdi mdi-chevron-right`}></i>
                                </button>
                            }
                        </UserDisplay.Subtitle> */}
                    </UserDisplay.Container>
                </UserDisplay>
                {getIcon()}
            </div>
            {(alertWrongOrg || alertTooYoung) &&
                <Collapse isOpen={selected}>
                    <div className="pt-2">
                        { alertTooYoung &&
                            <p className="small pb-2 mb-0"><i className="mdi mdi-alert-circle-outline text-info" /> <Translate id='participant.registrationModal.views.registrationParticipantSingle.age.warning' /></p>
                        }
                        { alertWrongOrg &&
                            <RegistrationAhmChecker participant={participant.members[0]} fromRegistrationOptions />
                        }
                    </div>
                </Collapse>
            }
        </div>
    );
}

export default RegistrationParticipantSingle;
