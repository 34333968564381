import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const Home = (props) => {
    const intl = useIntl();

    useEffect(() => {
        document.title = intl.formatMessage({ id: 'home.helmet.title' });
        document.querySelector('meta[name="description"]').setAttribute("content", intl.formatMessage({ id: 'home.helmet.description' }));
    }, [ intl.locale ])

    return (
        <>
            <section className="section-container mt-0">
                <header>
                    <h1>Home Page (dev only)</h1>
                </header>
                <Link className="btn btn-primary d-block mb-2" to="/page/hc">HC</Link>
                <Link className="btn btn-primary d-block mb-2" to="/page/bc">BC</Link>
                <Link className="btn btn-primary d-block mb-2" to="/page/sc">SC</Link>
            </section>
        </>
    );
}

export default Home;