import { useContext } from 'react';
import {
    ModalHeader,
    ModalBody
} from "reactstrap";
import Translate from '@spordle/intl-elements';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';

const RegistrationWaitingListCheckoutError = (props) => {

    const registrationContext = useContext(RegistrationContext);
    const authContext = useContext(AuthContext);

    return (
        <>
            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                <Translate id='participant.registrationModal.views.waitingListCheckoutError.title' />
            </ModalHeader>
            <ModalBody className="py-4 bg-light">
                <div className="text-center">
                    <i className='mdi mdi-alert-outline text-info' style={{ fontSize: '75px' }} />
                    <div className="mb-4"><Translate id='participant.registrationModal.views.waitingListCheckoutError.text' /></div>
                    <div className="mb-4"><Translate id='participant.registrationModal.views.waitingListConfirmation.number' /> <span className='font-weight-bold'>{registrationContext.state.currentCart.waitingListNumber}</span></div>
                    <div><Translate id='participant.registrationModal.views.waitingListConfirmation.emailSentTo' /></div>
                    <div className="font-weight-bold mb-4">{authContext.userData.attributes.email}</div>

                    <p><Translate id='participant.registrationModal.views.waitingListConfirmation.keepAnEye' /></p>
                </div>
            </ModalBody>
        </>
    );
}

export default RegistrationWaitingListCheckoutError;