/*
@Copyrights Spordle 2022 - All rights reserved
*/
import moment from'moment';import i18n from'../src/i18n.js';const isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0);
const isDate = (n) => n ? moment(n, true).isValid() : false;
const getValueFromString = (obj, stringIndex) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return stringIndex.split('.').reduce((value, index) => value ? value[index] : value, obj);
};
const getMessage = (id, lang) => {
    const formattedLang = lang.split(/(-|_)/)[0]; // en, fr
    switch (formattedLang) {
        default:
        case 'en':
            // @ts-ignore
            return i18n.en[id];
        case 'fr':
            // @ts-ignore
            return i18n.fr[id];
    }
};
const arrayMove = (array, from, to) => {
    array = [...array];
    const startIndex = from < 0 ? 0 : from;
    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = to < 0 ? 0 : to;
        const [item] = array.splice(from, 1);
        array.splice(endIndex, 0, item);
    }
    return array;
};
function normalize(value) {
    return (value || '').normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}export{arrayMove,getMessage,getValueFromString,isDate,isNumber,normalize};
/*
@Copyrights Spordle 2022 - All rights reserved
*/