import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';
import { jsObjectToApi } from '@spordle/helpers';

/** @type {React.Context<Omit<MembersContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const MembersContext = createContext();
MembersContext.displayName = 'MembersContext';

class MembersContextProvider extends React.Component{

    /**
     * [GET] Public call, gets all the members of an org
     * @returns {Promise}
     */
    getPublicMembers = (targetId, queryParams = {}, searchAll = false) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/public/members',
            query: Object.assign({
                target_organisation_id: targetId,
                organisation_id: searchAll ? this.props.OrganizationContext.federation.organisation_id : this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return res.data.members
                }
                throw res.data.errors[0];
            }, serverError)
    }

    /**
     * Get the Members within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AsearchMember|documentation}
     * @returns {Promise.<Array>}
     */
    getMembersElasticSearch = (targetId, queryParams = {}, searchAll = false) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/public/members/elastic-search',
            query: Object.assign({
                target_organisation_id: targetId,
                organisation_id: searchAll ? this.props.OrganizationContext.federation.organisation_id : this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members;
                }
                throw new Error(response.data.errors[0].code);
            }, serverError)
    }

    /**
     * Get the Members within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AsearchMember|documentation}
     * @returns {Promise.<Array>}
     */
    getMembers = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/members',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members;
                }
                throw new Error(response.data.errors[0].code);
            }, serverError)
    }

    /**
     * Creates a Member under an Organization with specified values
     * @param {object} values Values to create a Member
     * @returns {Promise}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AcreateMember|documentation}
     */
    createMember = (values) => {
        const params = new URLSearchParams();

        params.append('organisation_id', values.organizationId);
        params.append('first_name', values.firstName);
        params.append('last_name', values.lastName);
        params.append('email', values.email);
        params.append('address2', values.address2);
        params.append('birthdate', values.dateOfBirth);
        params.append('gender', values.gender);
        params.append('citizenship', values.nationality);
        params.append('source', "MANUAL");
        params.append('birth_country', values.birthCountry);
        params.append('identify_as_indigenous', values.identifyAsIndigenous);
        if(values.indigenousGroupId)
            params.append('indigenous_group_id', values.indigenousGroupId);
        params.append('ethnicity_id', values.ethnicityId);
        if(values.ethnicityNote)
            params.append('ethnicity_note', values.ethnicityNote)

        // Languages
        params.append('primary_language', values.primary_language);
        values.secondary_languages?.map((lang, key) => {
            params.append(`secondary_languages[${key}]`, lang);
        });

        // Google places
        params.append('address[0][default_address]', "1");
        params.append('address[0][address_type_id]', values.addressTypeId || ''); // Domicile, secondaire, appartement, bureau, etc
        params.append('address[0][years_same_address]', values.yearsSameAddress || '');
        params.append('address[0][street_number]', values.address.streetNumber || '');
        params.append('address[0][street]', values.address.address || '');
        params.append('address[0][city]', values.address.city || '');
        params.append('address[0][zip_code]', values.address.zipCode || '');
        params.append('address[0][country_division_code]', values.address.state || '');
        params.append('address[0][country_code]', values.address.country || 'CA');
        params.append('address[0][map_url]', values.address.mapsUrl || '');
        params.append('address[0][unit_number]', values.address2 || '');
        params.append('address[0][origin_address]', values.address.origin);

        if(values.phone?.phoneNumber){
            params.append('address[0][phones][0][phone_number]', values.phone.phoneNumber);
            params.append('address[0][phones][0][phone_type_id]', values.phone.phoneType);
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `members` }), params)
            .then((response) => {
                if(response.data.status){
                    return { member_id: response.data.member_id, unique_identifier: response.data.hcr_number }
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Update a member's missing fields
     * @param {object} memberId Member ID to update
     * @param {object} values Values to update a Member
     * @returns {Promise}
     */
    updateMemberMissingFields = (memberId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params, {
            skipEmptyString: true,
        });

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `members/${memberId}/missing-fields` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [POST] - Create a member attachment
     * @param {string} memberId ID of the member
     * @param {Array} attachments Array of files - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/5261fbb2f1097097c477c35cb2d94575|documentation}
     * @returns {Promise}
     */
    createMemberAttachment = (memberId, attachments) => {
        const params = new FormData();
        for(let index = 0; index < attachments.length; index++){
            const attachment = attachments[index];
            params.append(`attachments[${index}][attachment]`, attachment.file);
            params.append(`attachments[${index}][document_type_id]`, attachment.document_type_id);
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/members/${memberId}/attachments` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Updates a specific member's addresses
     * @param {string} memberId
     * @param {Array.<*>} addresses
     * @returns {Promise.<boolean>}
     */
    updateMemberAddresses = (memberId, addresses = []) => {
        const params = new URLSearchParams();
        addresses.forEach((address, index) => {
            for(const key in address){
                if(Object.hasOwnProperty.call(address, key)){
                    params.append(`address[${index}][${key}]`, address[key]);
                }
            }
        })
        return API_SPORDLE.put(`/members/${memberId}/addresses`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * If the member is eligible to the given org
     * @param {string} memberId
     * @param {string} [organizationId] Defaults to the current org
     * @returns {Promise.<boolean>}
     */
    memberIsEligible = (memberId, organizationId = this.props.OrganizationContext.organisation_id) => {
        return API_SPORDLE.get(`/members/${memberId}/organisations/${organizationId}/eligibility`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /////////////////////////////////////////////////////////////////////
    // Member Credits
    /////////////////////////////////////////////////////////////////////

    /**
     * Get member credit by organisation id
     * @param {string} organisation_id
     * @param {string} member_id
     * @param {Object} queryParams
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Credits/Apicontroller%5CMemberCredits%5CMembercredits%3A%3AgetAllCredits|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberCredits = (organisation_id, member_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/member-credits',
            query: Object.assign({
                organisation_id: organisation_id,
                member_id: member_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_credits;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <MembersContext.Provider value={{ ...this }}>
                {this.props.children}
            </MembersContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(MembersContextProvider);
