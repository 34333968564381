import { Component, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { LOCALES } from '../i18n/locales';

import { getLocalStorageItem, setLocalStorageItem } from '../helpers/browserStorage';
import { setGA } from '@spordle/ga4';
import { setTag } from '@sentry/react';
import { parse } from 'query-string';

/** @type {React.Context<Omit<I18nContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const I18nContext = createContext();
I18nContext.displayName = 'I18nContext';

export const getGenericLocale = (specificLang) => {
    switch (specificLang){
        case 'en-ca':
            return 'en';
        default: // This is valid for default language (fr) and language that is not available
            return 'fr';
    }
}

export const builtLocale = (locale) => {
    switch (locale?.split(/(-|_)/)[0]){
        default:
        case 'en':
            return LOCALES.ENGLISH;
        case 'fr':
            return LOCALES.FRENCH;
    }
};

class I18nContextProvider extends Component{
    constructor(props){
        super(props);
        this.state = {
            locale: builtLocale(parse(props.location.search).lang || getLocalStorageItem('lang') || window.navigator.userLanguage?.toLowerCase() || window.navigator.language.toLowerCase() || 'en-ca'),
        }
        moment.locale(this.state.locale);
        setGA({
            language: this.state.locale,
        });
        setTag('language', this.state.locale);
    }

    getFullLanguage = () => {
        switch (this.state.locale){
            case 'en-ca':
                return 'English';
            default: // This is valid for default language (fr-ca) and language that is not available
                return 'Français';
        }
    }

    getGenericLocale = () => getGenericLocale(this.state.locale);

    setLocale = (locale) => {
        const formattedLocale = builtLocale(locale);
        setLocalStorageItem('lang', formattedLocale)
        moment.locale(formattedLocale);
        this.setState({ locale: formattedLocale });
        setGA({
            language: formattedLocale,
        });
        setTag('language', formattedLocale);
    }

    render(){
        return (
            <I18nContext.Provider value={{ ...this.state, setLocale: this.setLocale, getLanguageName: this.getFullLanguage, getGenericLocale: this.getGenericLocale }}>
                {this.props.children}
            </I18nContext.Provider>
        );
    }
}
export default withRouter(I18nContextProvider);