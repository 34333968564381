export default{
    "default.page.name" : "Accueil",

    "default.helmet.title"       : "{org} Page",
    "default.helmet.description" : "Page d'accueil de l'organisation {org}. Contient toutes les récentes nouvelles.",

    "default.page.welcome.title" : "Bienvenue",
    "default.page.welcome.text"  : "à la page officielle Spordle de {org}.",

    "default.page.register.btn"         : "S'inscrire maintenant",
    "default.page.register.text.branch" : "Inscrivez-vous sur votre AHM",
    "default.page.register.text.mha"    : "Commencez en vous inscrivant dès maintenant !",

    "default.page.post.ourGame.title" : "Notre sport est de retour",
}