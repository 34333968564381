import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

import * as Sentry from "@sentry/react";

/** @type {React.Context<Omit<OnlineStoreContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const OnlineStoreContext = createContext();
OnlineStoreContext.displayName = 'OnlineStoreContext';

class OnlineStoreContextProvider extends React.Component{
    state = {
        cachedStoreOpen: { // page store (without member filter)
            orgId: null,
            stores: null,
            store: null,
            items: null,
            rebates: null,
        },
        cachedStore: {
            orgId: null,
            stores: null,
            store: null,
            items: null,
            rebates: null,
        },
    }

    componentDidUpdate(){
        Sentry.setContext('onlineStore', this.state.cachedStore.store?.online_store_id ? {
            cachedStoreId: this.state.cachedStore.store?.online_store_id,
            cachedStoreName: this.state.cachedStore.store?.name,
        } : null);
        Sentry.setTag('onlineStoreId', this.state.cachedStore.store?.online_store_id);
    }

    updateCachedStore = (newData) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({ cachedStore: { ...prevState.cachedStore, ...newData } }), resolve)
        })
    }

    updateCachedStoreOpen = (newData) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({ cachedStoreOpen: { ...prevState.cachedStoreOpen, ...newData } }), resolve)
        })
    }

    emptyCachedStore = () => {
        this.setState({
            cachedStore: {
                orgId: false,
                stores: false,
                store: false,
                items: false,
                rebates: false,
            },
        });
    }

    presetCachedStore = (store) => {
        if(this.state.cachedStore.store && this.state.cachedStore.store.online_store_id !== store.online_store_id){
            this.setState((prev) => ({ cachedStore: { ...prev.cachedStore, store: { ...store } } }));
        }
    }

    /**
     * Get the Online Stores within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetAllOnlineStore|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStores = (queryParams = {}) => {
        if(this.state.cachedStore.stores && this.state.cachedStore.orgId === this.props.OrganizationContext.organisation_id){
            return Promise.resolve(this.state.cachedStore.stores);
        }
        this.emptyCachedStore();
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/online-stores',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    const sharedOnlineStores = response.data.shared_online_stores.map((store) => ({
                        ...store,
                        organisation: response.data.organisations[store.organisation_id] || {},
                        isSharedStore: true,
                    }))

                    const stores = response.data.online_stores || [];
                    stores.pushArray(sharedOnlineStores);

                    await this.updateCachedStore({ orgId: this.props.OrganizationContext.organisation_id, stores: stores })
                    if(!queryParams?.member_id){
                        await this.updateCachedStoreOpen({ orgId: this.props.OrganizationContext.organisation_id, stores: stores })
                    }

                    return stores;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific online store
     * @param {string} onlineStoreId The online store's id
     * @param {boolean} fromAPI Wether or not to fetch from the API
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetOnlineStoreDetail|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStore = (onlineStoreId, fromAPI, queryParams = {}) => {
        if(this.state.cachedStore.store?.online_store_id === onlineStoreId && !fromAPI){
            return Promise.resolve(this.state.cachedStore.store);
        }
        this.setState((prev) => ({ cachedStore: { ...prev.cachedStore, items: null } }))


        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    await this.updateCachedStore({ store: response.data.online_stores[0] })

                    if(!queryParams?.member_id)
                        await this.updateCachedStoreOpen({ store: response.data.online_stores[0] })

                    return response.data.online_stores[0];
                }
                this.setState(() => ({
                    cachedStore: {},
                }));
                throw response.data.errors[0];
            }, serverError)
    }

    getOnlineStoreItems = (onlineStoreId, fromAPI, queryParams = {}) => {
        // member id in query params triggers a filter from the API
        if(this.state.cachedStore.store?.online_store_id === onlineStoreId && this.state.cachedStore.items && !queryParams.member_id && !fromAPI){
            return Promise.resolve(this.state.cachedStore.items);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}/items`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    await this.updateCachedStore({ items: response.data.items })

                    if(!queryParams?.member_id)
                        await this.updateCachedStoreOpen({ items: response.data.items })

                    return response.data.items;
                }
                throw response.data.errors[0];
            }, serverError);
    }


    /**
     * Gets all online-store's rebates
     * @param {string} onlineStoreId The online store id
     * @param {boolean} fromAPI Wether or not to fetch from the API
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetRebate|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStoreRebates = (onlineStoreId, fromAPI, queryParams = {}) => {
        if(this.state.cachedStore.rebates && this.state.cachedStore.store?.online_store_id === onlineStoreId && !fromAPI){
            return Promise.resolve(this.state.cachedStore.rebates);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}/rebates`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    const data = response.data.rebates.map((rebate) => rebate.rebate)
                    await this.updateCachedStore({ rebates: data });

                    if(!queryParams?.member_id)
                        await this.updateCachedStoreOpen({ rebates: data })

                    return data;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    render(){
        return (
            // Speading `this` is very important because it creates a new value object so it rerenders the Consumers
            // If we don't spead, react/javascript sees it as the same object thus, not rerendering the Consumers
            <OnlineStoreContext.Provider value={{ ...this }}>
                {this.props.children}
            </OnlineStoreContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(OnlineStoreContextProvider);