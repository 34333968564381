import contacts from "./contacts";
import defaultPage from "./defaultPage";
import error404 from "./error404";
import header from "./header";
import participant from "./participant";
import posts from "./posts";
import schedule from "./schedule";
import staff from "./staff";
import supportBlock from "./supportBlock";
import teamsList from "./teamsList";
import venues from "./venues";

export default{
    ...contacts,
    ...defaultPage,
    ...error404,
    ...header,
    ...participant,
    ...posts,
    ...schedule,
    ...staff,
    ...supportBlock,
    ...teamsList,
    ...venues,
}