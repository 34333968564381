/*
@Copyrights Spordle 2022 - All rights reserved
*/
const i18n = {
    en: {
        'clearSearch.ariaLabel': 'Clear search',
        'refreshButton.ariaLabel': 'Refresh',
        'scroll.left.ariaLabel': 'Scroll left',
        'scroll.right.ariaLabel': 'Scroll right',
    },
    fr: {
        'clearSearch.ariaLabel': 'Effacer la recherche',
        'refreshButton.ariaLabel': 'Rafraîchir',
        'scroll.left.ariaLabel': 'Défiler à gauche',
        'scroll.right.ariaLabel': 'Défiler à droite',
    },
};export{i18n as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/