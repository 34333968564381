import { useContext, useState } from 'react';

import {
    Button,
    ModalHeader,
    ModalBody,
    Collapse,
    Alert,
    Fade,
    Col,
    Row,
    FormGroup,
    Label
} from "reactstrap";

import { Form, Formik } from 'formik';
import { object, string, mixed } from 'yup';

import Translate from '@spordle/intl-elements';

import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import RegistrationParticipantCard from '.././RegistrationParticipantCard';
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import { AddressTypesContext } from '../../../../../../../../contexts/AddressTypesContext';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import Required from '../../../../../../../../components/formik/Required';
import { AccountsContext } from '../../../../../../../../contexts/AccountsContext';
import { isMoment } from 'moment';

const RegistrationAddressChange = (props) => {
    const registrationContext = useContext(RegistrationContext);
    const auth = useContext(AuthContext);
    const addressContext = useContext(AddressTypesContext);
    const accountsContext = useContext(AccountsContext);

    const [ showYearsSameAddress, setShowYearsSameAddress ] = useState(false);

    const address = auth.userData.addresses?.filter((address) => address.active == 1)[0]

    return (
        <Formik
            initialValues={{
                yearsSameAddress: '',
                addressType: '',
            }}
            validationSchema={object().shape({
                yearsSameAddress: mixed()
                    .required(<Translate id='participant.registrationModal.views.addressChange.moveInYear.required' />)
                    .isDate(<Translate id='form.validation.date.format' />)
                    .test({
                        name: 'isBefore',
                        message: <Translate id='participant.registrationModal.views.addressChange.moveInYear.future' />,
                        test: (date) => {
                            if(isMoment(date)){
                                return date.year() <= new Date().getFullYear();
                            }
                            return true;
                        },
                    }),
                addressType: string().required(<Translate id='participant.registrationModal.views.addressChange.addressType.required' />),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                setStatus();
                accountsContext.publishAddress(auth.userData.userName, address.identity_address_id, {
                    years_same_address: values.yearsSameAddress.format('YYYY'),
                    address_type_id: values.addressType,
                    member_ids: [ registrationContext.getCurrentMember().members[0].member_id ],
                })
                    .then(() => {
                        setSubmitting(false);
                        registrationContext.goToView(registrationContext.views.registrations)
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                            <Translate id='participant.registrationModal.views.addressChange.header' />
                        </ModalHeader>
                        <ModalBody className="py-4 bg-light">
                            <div className="registration-container">
                                <RegistrationParticipantCard participant={registrationContext.getCurrentMember()} />

                                <hr className='mt-0' />

                                <Collapse isOpen={!showYearsSameAddress}>
                                    <Fade in={!showYearsSameAddress}>
                                        <p className='font-weight-bold mb-3'>
                                            <Translate id='participant.registrationModal.views.addressChange.text' />
                                        </p>
                                        <p>
                                            <Translate
                                                id='participant.registrationModal.views.addressChange.text2'
                                                values={{
                                                    address:
                                                        <span className='font-weight-bold'>
                                                            {registrationContext.getFullAddress(address)}
                                                        </span>,
                                                }}
                                            />
                                        </p>

                                        <div className='d-inline-flex w-50'>
                                            <Button
                                                id='registrationAddressChangeNoButton'
                                                onClick={() => {
                                                    registrationContext.goToView(registrationContext.views.registrations)
                                                }}
                                                color="info"
                                                type='button' outline
                                                className="flex-grow-1 mr-1"
                                                disabled={formik.isSubmitting}
                                            >
                                                <Translate id='misc.no' />
                                            </Button>
                                        </div>
                                        <div className='d-inline-flex w-50'>
                                            <Button
                                                id='registrationAddressChangeYesButton'
                                                color="info" type='button'
                                                disabled={formik.isSubmitting}
                                                className="flex-grow-1 ml-1"
                                                onClick={() => {
                                                    setShowYearsSameAddress(true)
                                                }}
                                            >
                                                <Translate id='misc.yes' />
                                            </Button>
                                        </div>
                                    </Fade>
                                </Collapse>

                                <Collapse isOpen={showYearsSameAddress}>
                                    <Fade in={showYearsSameAddress}>
                                        <p>
                                            <Translate id='participant.registrationModal.views.addressChange.text3' />
                                        </p>

                                        <p className='font-weight-bold text-center'>
                                            {registrationContext.getFullAddress(auth.userData.addresses?.filter((address) => address.active == 1)[0])}
                                        </p>

                                        <Row form>
                                            <Col sm='6'>
                                                <FormGroup>
                                                    <Label for='yearsSameAddress'><Translate id='participant.registrationModal.views.addressChange.moveInYear' /> <Required /></Label>
                                                    <FormikDateTime
                                                        id='yearsSameAddress' name='yearsSameAddress'
                                                        dateFormat='YYYY' timeFormat={false}
                                                        initialViewMode='years'
                                                        isValidDate={(current) => current.year() <= new Date().getFullYear()}
                                                        renderInput={(props) => (
                                                            <div className="search-wrapper">
                                                                <input {...props} />
                                                            </div>
                                                        )}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup>
                                                    <Label for='addressType'><Translate id='participant.registrationModal.views.addressChange.addressType' /> <Required /></Label>
                                                    <FormikSelect
                                                        id='addressType' name='addressType'
                                                        loadData={(from) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return addressContext.getAddressTypes()
                                                                        .then((addressTypes) => {
                                                                            return addressTypes.map((addressType) => ({
                                                                                value: addressType.address_type_id,
                                                                                label: addressType.name,
                                                                                i18n: addressType.i18n,
                                                                            }))
                                                                        })
                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Collapse isOpen={!!formik.status}>
                                            <Alert color='danger' className='mt-3 mb-3'>{formik.status}</Alert>
                                        </Collapse>

                                        <div className='text-right'>
                                            <Button
                                                id='registrationAddressChangeSubmitButton'
                                                color="info" type='submit'
                                                disabled={formik.isSubmitting}
                                            >
                                                <Translate id='misc.confirm' />
                                            </Button>
                                            <Button
                                                id='registrationAddressChangeCancelButton'
                                                onClick={() => {
                                                    setShowYearsSameAddress(false)
                                                }}
                                                color="info"
                                                type='button' outline
                                                className="ml-2"
                                                disabled={formik.isSubmitting}
                                            >
                                                <Translate id='misc.cancel' />
                                            </Button>
                                        </div>
                                    </Fade>
                                </Collapse>
                            </div>
                        </ModalBody>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationAddressChange;