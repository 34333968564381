/**
 * @param {Object} [props]
 * @param {String|React.ElementType} [props.tag='div']
 * @param {number} [props.count=1] Number of times skeleton is rendered
 * @param {string|number} [props.width='100%']
 * @param {string|number} [props.height='20px']
 * @param {boolean} [props.circle=false]
 * @param {string} [props.className]
 * @returns {React.ReactNode}
 */
const Skeleton = ({ tag: Tag = 'div', count = 1, width = '100%', height = '20px', circle = false, className, ...props }) => (
    new Array(count).fill(0).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tag key={i} style={{ height: height, width: width }} className={`skeleton${circle ? ' is-circle' : ''}${className ? ' ' + className : ''}`} {...props} />
    ))
)

export default Skeleton;