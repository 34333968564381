import { Form, Formik, useFormikContext } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Row,
    Col,
    Button,
    FormGroup,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Alert
} from "reactstrap";
import { addExtension, FormikAddress, FormikDateTime, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, getFormikAddressInitialValues } from "@spordle/formik-elements";
import moment from 'moment';
import { object, string, array, mixed } from 'yup';
import Translate from '@spordle/intl-elements';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import ImgPill from '../../../../../../../components/visual/imgPill/ImgPill';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import Required from '../../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';

// Contexts
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { AddressTypesContext } from '../../../../../../../contexts/AddressTypesContext';
import { AuthContext } from '../../../../../../../contexts/AuthContext';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { PhoneTypesContext } from '../../../../../../../contexts/PhoneTypesContext';
import { MemberBirthCountryField, MemberCitizenshipField, MemberEmailField, MemberEthnicityField, MemberGenderField, MemberIdentifyAsIndigenousField, MemberPrimaryLanguageField, MemberRelationField } from './memberFields';

// Used to manage primary vs secondary langauges
const possibleLanguages = {
    'fr': 'french',
    'en': 'english',
}

/**
* This is always the 2nd step of the account creation, HCR search is first step
*/
const RegistrationNewParticipant = ({ ...props }) => {
    const orgContext = useContext(OrganizationContext);
    const addressContext = useContext(AddressTypesContext);
    const membersContext = useContext(MembersContext);
    const authContext = useContext(AuthContext);
    const registrationContext = useContext(RegistrationContext);
    const phoneTypeContext = useContext(PhoneTypesContext);

    const identityAddress = authContext.userData.addresses?.filter((address) => address.active == 1)[0];

    const requiredDocumentTypeIds = orgContext.settings.attachment_for_new_member_in_registration?.value || [];

    const [ errorCode, setErrorCode ] = useState('');

    const [ selectedEthnicity, setSelectedEthnicity ] = useState(null);

    const [ secondaryLanguages, setSecondaryLanguages ] = useState([
        { label: 'misc.en', value: 'en' },
        { label: 'misc.fr', value: 'fr' },
    ]);

    const selectAndProceed = (view, member) => {
        return registrationContext.addMember(member)
            .then(() => registrationContext.goToView(view))
    }

    const handlePrimaryChange = (val) => {
        setSecondaryLanguages(Object.keys(possibleLanguages).reduce((builtOptions, key) => {
            if(key !== val)
                builtOptions.push({ value: key, label: `misc.${possibleLanguages[key]}` });
            return builtOptions;
        }, []))
    }

    const getAgeWarning = (formik) => {
        if(formik.values.dateOfBirth && moment(formik.values.dateOfBirth).isAfter(moment().subtract(4, "years")) && moment(formik.values.dateOfBirth).isBefore(moment())){
            return (
                <div className="small text-muted mb-3">
                    <i className="mdi text-warning mdi-alert-outline" /> <Translate id='participant.registrationModal.views.registrationHcrSearch.date.minAge' />
                    {formik.initialValues.firstName &&
                        <Button
                            id='registrationNewParticipantChangeAgeButton'
                            type="button"
                            onClick={() => {
                                registrationContext.setHcrSearchCreateMode(true);
                                registrationContext.goToView(registrationContext.views.hcrSearch);
                            }}
                            className="btn btn-link btn-sm bg-transparent reset-btn text-decoration-underline text-info font-weight-bold"
                        >
                            <Translate id="participant.registrationModal.views.newParticipant.btn.editAge" />
                        </Button>
                    }
                </div>
            )
        }
        return null

    }

    // using this function with a weird structure to have the same code in the .catch of this function
    // executed if the error is different then 3269 or when one of the api calls crashes,
    // not when the process is done and we change views
    const handleError = (err, newValues) => {
        return new Promise((resolve, reject) => {
            if(err.code == 3269){
                // postal code is not valid for organization
                orgContext.findOrganization({
                    organisation_id: orgContext.federationId,
                    province_code: newValues.address.state,
                    postal_code: newValues.address.zip,
                })
                    .then(async(organisations) => {
                        // filter out orgs with null distance (shouldn't happen, but can)
                        // and then sort to have the shortest distance first
                        // null distance means the postal code of the org is not in the database
                        const filteredOrgs = organisations
                            ?.filter((org) => !!org.distance)
                            ?.sort((orgA, orgB) => parseFloat(orgA.distance) - parseFloat(orgB.distance))

                        registrationContext.setGoToOrgsPrevView(registrationContext.views.newParticipant)
                        registrationContext.setGoToOrgs(filteredOrgs);
                        registrationContext.goToView(registrationContext.views.goToOrganization);
                        resolve();

                        // getOrganization to get the short_url field from the API
                        // await orgContext.getOrganization(filteredOrgs[0]?.organisation_id)
                        //     .then((org) => {
                        //     })
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            // no need for error message display here since nothing should be done if this feature doesn't work
                            console.error(error.message)
                            reject()
                        }
                    })
            }else{
                reject()
            }
        })
    }

    return (
        <>
            <Formik
                initialValues={{
                    firstName: registrationContext.dataForCreation.first_name || '',
                    lastName: registrationContext.dataForCreation.last_name || '',
                    dateOfBirth: registrationContext.dataForCreation.birthdate ? moment(registrationContext.dataForCreation.birthdate) : '',
                    primary_language: '',
                    secondary_languages: [],
                    relation: '',
                    gender: '',
                    googlePlaceAddress: '',
                    address2: identityAddress.unit_number || '',
                    addressTypeId: '',
                    yearsSameAddress: '',
                    address: getFormikAddressInitialValues(identityAddress),
                    nationality: [ 'CA' ],
                    email: authContext.userData.attributes.email,
                    organizationId: orgContext.organisation_id,
                    phone: authContext.userData.attributes.phone_number || '',
                    extension: '',
                    phoneType: '',
                    birthCountry: 'CA',
                    identifyAsIndigenous: '', // im the jesus :)
                    indigenousGroupId: '',
                    ethnicityId: '',
                    ethnicityNote: '',
                }}
                validationSchema={object().shape({
                    firstName: string().required(<Translate id='form.validation.firstName.required' />),
                    lastName: string().required(<Translate id='form.validation.lastName.required' />),
                    primary_language: string().required(<Translate id='form.validation.primaryLanguage.required' />),
                    secondary_languages: array().of(string()),
                    gender: string().required(<Translate id='form.validation.gender.required' />),
                    googlePlaceAddress: string(),
                    relation: string().required(<Translate id='participant.registrationModal.views.newParticipant.validation.relation' />),
                    addressTypeId: string().required(<Translate id='form.validation.addressType.required' />),
                    yearsSameAddress: mixed()
                        .required(<Translate id='participant.registrationModal.views.addressChange.moveInYear.required' />)
                        .isDate(<Translate id='form.validation.date.format' />)
                        .test({
                            name: 'isBefore',
                            message: <Translate id='participant.registrationModal.views.addressChange.moveInYear.future' />,
                            test: (date) => {
                                if(moment.isMoment(date)){
                                    return date.year() <= new Date().getFullYear();
                                }
                                return true;
                            },
                        }),
                    address: object().address(true, {
                        streetNumber: <Translate id='form.validation.streetNumber.required' />,
                        address: <Translate id='form.validation.address.required' />,
                        city: <Translate id='form.validation.city.required' />,
                        zipCode: <Translate id='form.validation.zip.required' />,
                        state: <Translate id='form.validation.province.required' />,
                        country: <Translate id='form.validation.country.required' />,
                    }).required(<Translate id='form.validation.address.required' />),
                    dateOfBirth: mixed().required(<Translate id='form.validation.dateOfBirth.required' />).test({
                        name: 'dateOfBirthFormat',
                        message: <Translate id='form.validation.date.format' />,
                        test: function(date){
                            return moment.isMoment(date)
                        },
                    }).test({
                        name: 'dateOfBirthNotToday',
                        message: <Translate id='participant.registrationModal.views.registrationHcrSearch.date.minDate' />,
                        test: function(dateOfBirth){
                            return moment.isMoment(dateOfBirth) && moment(dateOfBirth).isBefore(moment());
                        },
                    }),
                    nationality: array().of(string()).min(1, <Translate id='form.validation.nationality.required' />),
                    email: string().required(<Translate id='form.validation.email.required' />).email(<Translate id='form.validation.email.valid' />),
                    phone: string().required(<Translate id='form.validation.phone.required' />).isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                    extension: string(),
                    phoneType: string().required(<Translate id='form.validation.phoneType.required' />),
                    birthCountry: string().required(<Translate id='form.validation.birthCountry.required' />),
                    identifyAsIndigenous: string().required(<Translate id='form.fields.identifyAsIndigenous.required' />),
                    indigenousGroupId: string().when('identifyAsIndigenous', {
                        is: 'YES',
                        then: string().required(<Translate id='form.fields.indigenousGroup.required' />),
                    }),
                    ethnicityId: string().required(<Translate id='form.fields.ethnicity.required' />),
                    ethnicityNote: string().when('doesNotMatter', {
                        is: () => !!selectedEthnicity?.withNote,
                        then: string().required(<Translate id='form.fields.ethnicityNote.required' />),
                    }),
                })}
                onSubmit={async(values, { setStatus }) => {
                    const phoneNumberWithExtension = await addExtension(values.phone, values.extension);
                    const newValues = {
                        ...values,
                        yearsSameAddress: values.yearsSameAddress.format('YYYY'),
                        address: values.address,
                        nationality: values.nationality.toString(),
                        phone: {
                            phoneNumber: phoneNumberWithExtension,
                            phoneType: values.phoneType,
                        },
                    };
                    newValues.dateOfBirth = newValues.dateOfBirth.format("YYYY-MM-DD");

                    await membersContext.createMember(newValues)
                        .then(async(memb) => {
                            await registrationContext.createMetaMember(
                                newValues.firstName,
                                newValues.lastName,
                                newValues.dateOfBirth,
                                newValues.relation,
                                memb.member_id,
                            )
                                .then(async(metaId) => {
                                    const needsDocuments = requiredDocumentTypeIds?.length > 0;
                                    registrationContext.setNeedsDocuments(needsDocuments);
                                    if(needsDocuments){
                                        await orgContext.getDocumentTypes(orgContext.organisation_id)
                                            .then((documentTypes) => {
                                                registrationContext.setDocumentTypes(documentTypes);
                                            })
                                    }

                                    await selectAndProceed(
                                        registrationContext.views.documents,
                                        {
                                            first_name: newValues.firstName,
                                            last_name: newValues.lastName,
                                            birthdate: values.dateOfBirth.toISOString(),
                                            // using metaMemberId to have a different variable name than the already linked meta members from the account
                                            metaMemberId: metaId,
                                            meta_member_id: metaId,
                                            relation: newValues.relation,
                                            members: [
                                                {
                                                    age: moment().diff(values.dateOfBirth, 'years'),
                                                    first_name: newValues.firstName,
                                                    last_name: newValues.lastName,
                                                    member_id: memb.member_id,
                                                    unique_identifier: memb.unique_identifier,
                                                    organisation: {
                                                        i18n: { ...orgContext.i18n },
                                                        organisation_id: orgContext.organisation_id,
                                                        organisation_name: orgContext.organisation_name,
                                                    },
                                                },
                                            ],
                                        },
                                    );
                                })
                        })
                        .catch(async(err) => {
                            if(!AxiosIsCancelled(err.message)){
                                await handleError(err, newValues)
                                    .catch(() => {
                                        setErrorCode(err.code || '');
                                        setStatus(<DisplayI18n field='message' defaultValue={err.message} i18n={err.i18n} />);
                                        console.error(err);
                                    })
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <ScrollToError />

                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="text-white bg-dark">
                                <Translate id='participant.registrationModal.views.newParticipant.title' />
                            </ModalHeader>
                            <ModalBody className="py-4 bg-light">
                                {formik.initialValues.firstName &&
                                    <UserDisplay card className="w-100">
                                        <UserDisplay.Container>
                                            <ImgPill
                                                size='sm'
                                                abbr={`${formik.values.firstName?.charAt(0)}${formik.values.lastName?.charAt(0)}`}
                                                src={null}
                                                alt={`${formik.values.firstName} ${formik.values.lastName}`}
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <div className="d-flex align-items-center">
                                                <UserDisplay.Title className="mr-2">
                                                    {formik.values.firstName} {formik.values.lastName}
                                                </UserDisplay.Title>
                                                <UserDisplay.Subtitle className="mr-2">({moment().diff(formik.values.dateOfBirth, 'years')} <Translate id='misc.yo' />)</UserDisplay.Subtitle>
                                            </div>
                                            <UserDisplay.Subtitle>
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={orgContext.organisation_name}
                                                    i18n={orgContext.i18n}
                                                />
                                            </UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                }
                                {!formik.initialValues.firstName &&
                                    <Row form>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label id='firstName' for="firstName-input"><Translate id='form.fields.firstName' /> <Required /></Label>
                                                <FormikInputText id='firstName-input' name='firstName' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label id='lastName' for="lastName-input"><Translate id='form.fields.lastName' /> <Required /></Label>
                                                <FormikInputText id='lastName-input' name='lastName' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label id='dateOfBirth' for="dateOfBirth-picker"><Translate id='form.fields.dateOfBirth' /> <Required /></Label>
                                                <FormikDateTime
                                                    translatePlaceholder
                                                    initialViewDate={moment().subtract(10, "years")}
                                                    isValidDate={(current) => moment(current).isBefore(moment())}
                                                    placeholder='participant.registrationModal.views.registrationHcrSearch.date.placeholder'
                                                    renderInput={(props) => (
                                                        <div className="search-wrapper">
                                                            <input {...props} />
                                                        </div>
                                                    )}
                                                    id='dateOfBirth-picker' name='dateOfBirth'
                                                    timeFormat={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <MemberEmailField fieldName='email' />
                                        {getAgeWarning(formik)}
                                        <Col sm="12">
                                            <hr />
                                        </Col>
                                    </Row>
                                }
                                {formik.initialValues.firstName &&
                                    getAgeWarning(formik)
                                }
                                <Row className="mb-2" form>
                                    <MemberRelationField fieldName='relation' />
                                    <MemberGenderField fieldName='gender' />
                                    <MemberPrimaryLanguageField
                                        fieldName='primary_language'
                                        onOptionSelected={(values) => {
                                            formik.setFieldValue('secondary_languages', []);
                                            handlePrimaryChange(values[0]);
                                        }}
                                    />
                                    <Col sm="6" className="mb-3">
                                        <Label id='secondary_language' for="secondary_languages-select"><Translate id='form.fields.secondaryLanguages' /></Label>
                                        <FormikSelect
                                            id='secondary_languages-select' name='secondary_languages'
                                            search={false} multi
                                            options={secondaryLanguages || []}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                            loadingStatus="success"
                                        />
                                    </Col>
                                    <MemberCitizenshipField fieldName='nationality' />
                                    <MemberBirthCountryField fieldName='birthCountry' />
                                    {formik.initialValues.firstName &&
                                        <MemberEmailField fieldName='email' />
                                    }
                                    <MemberIdentifyAsIndigenousField
                                        fieldName='identifyAsIndigenous'
                                        secondaryFieldName='indigenousGroupId'
                                        identifyAsIndigenousOnSelected={(values) => {
                                            if(values[0] === 'YES')
                                                // TODO: HARDCODED
                                                // set ethnicity as indigenous
                                                formik.setFieldValue('ethnicityId', '07615f60-af8e-11ec-bed9-023f3d3ef136')
                                            else
                                                formik.setFieldValue('indigenousGroupId', '')
                                        }}
                                    />
                                    <MemberEthnicityField
                                        fieldName='ethnicityId'
                                        secondaryFieldName='ethnicityNote'
                                        showNote={!!selectedEthnicity?.withNote}
                                        ethnicityOnSelected={(values, spordleSelect) => {
                                            setSelectedEthnicity(spordleSelect.getSpordleTable().getData().find((data) => data.value === values[0]))
                                        }}
                                    />
                                    <Col sm="12">
                                        <hr />
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label id='phone' for='phone-input'><Translate id='form.fields.phone' /> <Required /></Label>
                                            <FormikPhoneInput name='phone' id='phone-input' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label id='extension' for='extension-input'><Translate id='form.fields.extension' /></Label>
                                            <FormikInputNumber name='extension' id='extension-input' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label id='phoneType' for='phoneType-select'><Translate id='form.fields.phoneType' /> <Required /></Label>
                                            <FormikSelect
                                                id='phoneType-select' name='phoneType'
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return phoneTypeContext.getPhoneTypes()
                                                                .then((phoneTypes) => {
                                                                    return phoneTypes.reduce((newArray, phoneType) => {
                                                                        if(phoneType.active == 1){
                                                                            newArray.push({
                                                                                value: phoneType.phone_type_id,
                                                                                label: phoneType.name,
                                                                                i18n: phoneType.i18n,
                                                                            })
                                                                        }
                                                                        return newArray
                                                                    }, [])
                                                                })
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <hr />
                                    </Col>
                                    <Col sm="6" className="mb-3">
                                        <Label id='addressTypeId' for="addressTypeId-select"><Translate id='form.fields.addressType' /> <Required /></Label>
                                        <FormikSelect
                                            id='addressTypeId-select' name='addressTypeId'
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return addressContext.getAddressTypes()
                                                            .then((addressTypes) => {
                                                                return addressTypes.map((addressType) => ({
                                                                    value: addressType.address_type_id,
                                                                    label: addressType.name,
                                                                    i18n: addressType.i18n,
                                                                }))
                                                            })
                                                    default:
                                                        break;
                                                }
                                            }}
                                            renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                        />
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='yearsSameAddress'><Translate id='participant.registrationModal.views.addressChange.moveInYear' /> <Required /></Label>
                                            <FormikDateTime
                                                id='yearsSameAddress' name='yearsSameAddress'
                                                dateFormat='YYYY' timeFormat={false}
                                                initialViewMode='years'
                                                isValidDate={(current) => current.year() <= new Date().getFullYear()}
                                                renderInput={(props) => (
                                                    <div className="search-wrapper">
                                                        <input {...props} />
                                                    </div>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <FormikAddress
                                            id='address' name='address'
                                            required
                                            label='form.fields.address'
                                            allowManualPlace
                                        />
                                    </Col>
                                    <Col sm="12">
                                        <Label id='address2' for="address2-input"><Translate id='form.fields.address' /> 2</Label>
                                        <FormikInputText id='address2-input' name='address2' helper={<span className="small text-muted"><Translate id='form.fields.address2.placeholder' /></span>} translateHelper={false} autoComplete='new-address2' trim />
                                    </Col>
                                </Row>

                                { formik.status &&
                                    <Alert color='danger' toggle={() => formik.setStatus()}>
                                        {formik.status}
                                        {errorCode == 2000 &&
                                            <Button
                                                id='registrationNewParticipantGoBackToHcrSearchAfterError'
                                                type="button"
                                                color="link"
                                                className="reset-btn mt-1 text-decoration-underline alert-danger text-left d-block font-weight-bold"
                                                disabled={formik.isSubmitting}
                                                onClick={() => {
                                                    registrationContext.setHcrSearchCreateMode(true);
                                                    registrationContext.goToView(registrationContext.views.hcrSearch);
                                                }}
                                            >
                                                <Translate id='participant.registrationModal.views.newParticipant.btn.error.2000' />
                                            </Button>
                                        }
                                    </Alert>
                                }
                            </ModalBody>
                            <ModalFooter tag='footer' className="bg-light">
                                <Button id='registrationNewParticipantPreviousButton' type="button" onClick={() => registrationContext.goToView(registrationContext.views.existingOrNew, true)} color="default" outline className="mr-auto"><Translate id='misc.previous' /></Button>
                                <Button id='registrationNewParticipantSubmitButton' type="submit" color="info"><Translate id='misc.create' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </>
    );
}

const ScrollToError = () => {
    const formik = useFormikContext();
    const count = useRef(formik.submitCount)

    useEffect(() => {
        const errorsArray = Object.keys(formik.errors)
        if(errorsArray.length > 0 && formik.submitCount !== count.current){
            const fieldId = errorsArray[0]
            if(formik.errors[fieldId]){
                const modal = document.querySelector('.modal.show')
                modal.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                })
            }
            count.current = formik.submitCount
        }
    }, [ formik.errors, formik.submitCount ])

    return null
}

export default RegistrationNewParticipant;