import Translate from '@spordle/intl-elements';
import { stringifyUrl } from 'query-string';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { AuthContext } from '../../../../../../../contexts/AuthContext';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import getReferrer from '../../../../../../../helpers/getReferer';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

const RegistrationAhmChecker = ({ participant, fromRegistrationOptions }) => {
    const authContext = useContext(AuthContext);
    const i18nContext = useContext(I18nContext);
    const orgContext = useContext(OrganizationContext);
    const registrationContext = useContext(RegistrationContext);
    return (
        <>
            <p className="mb-2 small">
                <Translate id='storeSingle.registrationModal.registrationOptions.participantOrgAssociationWith' values={{ orgName: <strong className="font-weight-bold"><DisplayI18n field='name' defaultValue={participant.organisation.organisation_name} i18n={participant.organisation.i18n} /></strong> }} />
                &nbsp;
                {fromRegistrationOptions ?
                    <Translate
                        id='storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.label.contactOrg'
                        values={{
                            orgName: <strong className="font-weight-bold"><DisplayI18n field='name' defaultValue={participant.organisation.organisation_name} i18n={participant.organisation.i18n} /></strong>,
                        }}
                    />
                    :
                    <>
                        <Translate id='storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.label' values={{ orgName: <DisplayI18n field='name' defaultValue={orgContext.organisation_name} i18n={orgContext.i18n} /> }} />
                        &nbsp;
                        <strong className="font-weight-bold">
                            <Translate id='storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.label.registrationRequest' />
                        </strong>.
                    </>
                }
            </p>
            <Button
                id='registrationAhmCheckerGoToOrg'
                tag={participant.organisation.deploy_on != 'AMPLIFY' ? 'a' : Link}
                size="sm"
                color='info' className='mb-2 mr-2'
                to={participant.organisation.deploy_on == 'AMPLIFY' ? `/page/${participant.organisation.short_url}/participant` : undefined}
                href={participant.organisation.deploy_on == 'VERCEL' || participant.organisation.deploy_on == 'PAGE' ? stringifyUrl({
                    url: `${getReferrer(participant.organisation.deploy_on == 'VERCEL' ? 'PAGE-VERCEL' : 'PAGE-VERCEL-2')}/${participant.organisation.short_name}/participant`,
                    query: {
                        lang: i18nContext.getGenericLocale(),
                        accessToken: authContext.accessToken,
                    },
                }, { skipEmptyString: true, skipNull: true }) : undefined}
                onClick={() => {
                    registrationContext.resetRegistration();
                }}
            >
                <Translate id='storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.action.goToMha' values={{ orgName: <DisplayI18n field='name' defaultValue={participant.organisation.organisation_name} i18n={participant.organisation.i18n} /> }} />
            </Button>
            {!fromRegistrationOptions &&
                <Button id='registrationAhmCheckerGoToRequest' color="info" size="sm" outline className='mb-2' onClick={() => { registrationContext.goToView(registrationContext.views.request) }}>
                    <Translate id='storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.action.registrationRequest' /> <i className="mdi mdi-arrow-right" />
                </Button>
            }
        </>
    );
}

export default RegistrationAhmChecker;