/*
@Copyrights Spordle 2022 - All rights reserved
*/
/**
 * Global configs used by spordleTableProvider
 */
const GLOBAL_CONFIGS = {};
const GLOBAL_CONFIGS$1 = GLOBAL_CONFIGS;export{GLOBAL_CONFIGS$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/