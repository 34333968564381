import { useContext, useEffect, useState } from 'react';
import PageHeaderPlaceholder from '../../../assets/images/placeholders/PageHeaderPlaceholder.jpg';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import ImgPill from '../../../components/visual/imgPill/ImgPill';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { fail } from '@spordle/toasts';

const Header = ({ ...props }) => {
    const orgContext = useContext(OrganizationContext);
    const [ dataInfo, setDataInfo ] = useState({
        data: null,
        isLoading: true,
    });

    useEffect(() => {
        if(orgContext.organisation_id && orgContext.organisation_parent){
            if(orgContext.organisation_parent.organisation_id === orgContext.federation?.organisation_id){
                setDataInfo((prev) => ({ isLoading: false, data: orgContext.federation }))
            }else{
                orgContext.getOrganizationBranch(orgContext.organisation_id)
                    .then((res) => setDataInfo({ isLoading: false, data: res }))
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setDataInfo({ isLoading: false, data: null });
                        }
                    });
            }
        }
    }, []);

    const headerImg = null;
    return (
        <header className="section-container pt-0 px-0">
            <div className={stringBuilder('header-banner', { 'is-placeholder': !headerImg })}>
                <img src={headerImg || PageHeaderPlaceholder} alt="Organisation Banner" />
                {/* <button className="header-banner-btn btn" type="button">
                    <i className="mdi-camera mdi"></i> <span className="d-none d-md-inline-block"><Translate id='misc.edit'/></span>
                </button> */}
            </div>
            <div className="header-association border-top pt-3 px-3">
                <div className="position-relative">
                    <ImgPill
                        abbr={orgContext?.abbreviation}
                        filePos={orgContext?.logo?.file_position}
                        src={orgContext?.logo?.full_path}
                        alt={`Logo ${orgContext?.organisation_name}`}
                        className="header-association-logo-container"
                        size="xl"
                    />
                    { orgContext?.organisation_parent && dataInfo.data &&
                        <ImgPill
                            isLoading={dataInfo.isLoading}
                            abbr={dataInfo.data?.organisation_name}
                            src={dataInfo.data?.logo?.full_path}
                            alt={`Logo ${dataInfo.data?.organisation_name}`}
                            className="header-organisation-logo"
                        />
                    }
                </div>

                <div className="header-association-info w-100">
                    <strong className="d-flex justify-content-between align-items-start w-100">
                        <DisplayI18n
                            field='name'
                            i18n={orgContext?.i18n}
                            defaultValue={orgContext?.organisation_name}
                        />
                        { orgContext.website &&
                            <a href={orgContext.website} target="_blank" className="btn btn-sm py-0 pl-2 pr-0 btn-link" rel='noopener noreferrer' id='view-org-website'>
                                <span className="d-md-inline d-none"><Translate id='page.header.visitWebsite' /></span><i className="mdi mdi-launch ml-1" />
                            </a>
                        }
                    </strong>
                    { orgContext?.organisation_parent &&
                        <small className="text-muted">
                            <DisplayI18n
                                field='name'
                                i18n={dataInfo.data?.i18n}
                                defaultValue={dataInfo.data?.organisation_name}
                            />
                        </small>
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;