import { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { matchPath, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Error404 from './components/Error404';
import { PageSubroutes } from '../../routes/pageRoutes';
import SideNav from '../../layouts/layout-components/sidebar/SideNav';
import Skeleton from '../../components/loading/Skeleton';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
// Contexts
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { OnlineStoreContext } from '../../contexts/OnlineStoreContext';
import SupportBlock from './components/SupportBlock';
import { DisplayI18n } from '../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';

const Page = ({ match, location, ...props }) => {
    const isRenderPage = () => {
        for(let index = 0; index < PageSubroutes.length; index++){
            const routeGroup = PageSubroutes[index];
            for(let routeGroupIndex = 0; routeGroupIndex < routeGroup.subroutes.length; routeGroupIndex++){
                const route = routeGroup.subroutes[routeGroupIndex];
                const matchResult = matchPath(location.pathname, {
                    path: `${match.path}${route.path}`,
                    exact: !route.path,
                });
                if(matchResult){
                    return true;
                }
            }
        }
        return false;
    }
    const shouldRender = isRenderPage();
    const getRoutes = () => (
        <Switch>
            {
                PageSubroutes.map((routeGroup) => {
                    return (
                        routeGroup.subroutes.map((route, key) => {
                            const path = `${match.path}${route.path}`;
                            return (
                                !route.external &&
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Route key={`${key}-${route.name}`} path={path} exact={route.exact} component={route.component} />
                            )
                        })
                    )
                })
            }
            <Route component={Page} path={`${match.path}/:orgid`} />
            <Route component={Error404} />
        </Switch>
    )

    if(!shouldRender){
        return getRoutes();
    }

    const orgContext = useContext(OrganizationContext);
    const onlineStoreContext = useContext(OnlineStoreContext);

    const [ loading, setLoading ] = useState({
        isLoading: true,
        success: false,
    });

    const shortUrl = matchPath(match.url, {
        path: `/page/:orgid+`,
    }).params.orgid;

    useEffect(() => {
        if(orgContext.organisation_id !== shortUrl){
            setLoading({ isLoading: true, success: false });
            orgContext.setCurrentOrg(shortUrl)
                .then((_org) => setLoading({ isLoading: false, success: true }))
                .then(() => {
                    onlineStoreContext.getOnlineStores({
                        active: "1",
                        status: "ACTIVE",
                        'start_date.lte': new Date().toISOString(),
                        'end_date.gte': new Date().toISOString(),
                    })
                        .catch(console.error);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setLoading({ isLoading: false, success: false });
                    }
                })
        }
    }, [ shortUrl ]);

    return (
        loading.success ?
            <>
                <Header organization={orgContext?.state} />
                <Row>
                    <Col className="order-1 order-lg-0" xs="12" lg="4" xl="3" tag="aside">
                        <div className="page-aside-nav">
                            <SideNav sectionClassName="section-container d-none d-lg-block mb-2" />
                            <SupportBlock />
                        </div>
                    </Col>
                    <Col xs="12" lg="8" xl="9">
                        {getRoutes()}
                    </Col>
                </Row>
            </>
            :
            loading.isLoading ?
                <>
                    <div className="section-container">
                        <div style={{ height: 100 }} />
                        <div className="d-flex align-items-end">
                            <Skeleton circle width={120} height={120} className="mr-3" />
                            <div className="flex-grow-1">
                                <Skeleton height={25} className="mb-2 w-md-50" />
                                <Skeleton height={10} className="mb-3 w-md-25 w-50" />
                            </div>
                        </div>
                    </div>
                    <Row className="text-center">
                        <Col xs="12" lg="4" xl="3" tag="aside" className="d-none d-lg-block">
                            <div className="section-container">
                                <Skeleton height={35} className="mb-2" />
                                <Skeleton height={25} />
                            </div>
                            <div className="section-container">
                                <Skeleton height={35} className="mb-2" />
                                <Skeleton height={25} />
                            </div>
                        </Col>
                        <Col xs="12" lg="8" xl="9">
                            <div className="section-container">
                                <Skeleton height={45} />
                            </div>
                        </Col>
                    </Row>
                </>
                :
                <Error404 />
    );
}

export default Page;