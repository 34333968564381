import { FormikCurrencyInput, FormikAddress } from "@spordle/formik-elements";
import { CurrencyFormat } from "@spordle/intl-elements";
import { isProdFishNChips } from "./getReferer";

/**
 * Function used to set default props for the entire platform
 */
function setDefaultComponentProps(){
    if(isProdFishNChips()){
        FormikCurrencyInput.defaultProps = {
            ...FormikCurrencyInput.defaultProps,
            currencySign: '£',
        }

        CurrencyFormat.defaultProps = {
            ...CurrencyFormat.defaultProps,
            currency: 'GBP',
        }

    }

    FormikAddress.defaultProps = {
        buttonClassName: 'btn btn-info',
        toggleButtonClassName: 'reset-btn text-info',
    }
}

export default setDefaultComponentProps;