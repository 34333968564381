import React from 'react';

// I18N
import { LOCALES } from '../../../i18n';

// Context Providers
import { I18nContext } from '../../../contexts/I18nContext';

// Images
import SideNav from '../sidebar/SideNav';
import SearchOrg from './SearchOrg';
import HeaderLogo from './HeaderLogo';
import HeaderLogin from './HeaderLogin';
import { stringBuilder } from "@spordle/helpers";

class Header extends React.Component{
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
        };
    }

    toggle(toToggle, bool){
        this.setState((state) => ({
            ...state, [toToggle]: bool ?? !state[toToggle],
        }));
    }

    render(){
        return (
            <I18nContext.Consumer>
                {({ locale, setLocale }) => {
                    return (
                        <header className="page-main-header">
                            <nav className="page-main-nav">
                                <div className="page-main-nav-logo">
                                    <HeaderLogo />
                                </div>
                                <SearchOrg id="mainOrgSearch" />
                                <div className="page-main-nav-btns">
                                    <button type='button' id='language-switcher' className="navbar-lang-btn d-none d-lg-block" onClick={() => { setLocale(locale === 'fr-ca' ? LOCALES.ENGLISH : LOCALES.FRENCH) }}>
                                        <span className="font-medium small">
                                            {locale === 'fr-ca' ? 'EN' : 'FR'}
                                        </span>
                                    </button>
                                    <HeaderLogin fromHeader />
                                    <button
                                        type="button"
                                        onClick={() => { this.toggle('isOpen') }}
                                        aria-label="Toggle navigation"
                                        className={stringBuilder('custom-icon-burger-btn nav-bar-toggler', { 'is-open': this.state.isOpen })}
                                        id='small-screen-menu'
                                    >
                                        <i className="custom-icon-burger" />
                                    </button>
                                    <div
                                        onClick={this.state.isOpen ? () => { this.toggle('isOpen') } : undefined}
                                        className={stringBuilder('navbar-burger-nav-container d-lg-none', { 'is-open': this.state.isOpen })}
                                    >
                                        <SideNav className="navbar-burger-nav" fromHeader />
                                    </div>
                                </div>
                            </nav>
                            {/* <Navbar className="pl-0 navbar-light navbar-main" expand="md">
                            </Navbar> */}
                        </header>
                    )
                }}
            </I18nContext.Consumer>
        );
    }
}
export default Header;
