/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,Fragment,jsxs}from'react/jsx-runtime';import {useContext}from'react';import {Spinner}from'reactstrap';import {SpordleTableContext}from'./SpordleTableProvider.js';import TableView from'./TableView.js';import GridView from'./GridView.js';import {FormattedMessage}from'react-intl';/**
 * @member SpordleTableView
 * @description
 * This component will render the {@link SpordleTableProvider}'s content.<br>
 * Make sure to have a {@link SpordleTableProvider} wrapping this component.
 * @prop {boolean} [scrollButtons=false] If we want to show the horizontal scroll buttons in tableView
 * @prop {boolean} [hidePagination=false] Will hide the pagination handler. If there is a pagination, and this prop is `true`, the user won't be able to change page.
 * @prop {boolean} [preventHeaderHoverEffect=false] Will prevent applying the `bg-light` class when hovering `th` tag
 * @example
 * <SpordleTableView />
 * <SpordleTableView scrollButtons/>
 * <SpordleTableView scrollButtons hidePagination/>
 * @copyright Spordle
 */
const SpordleTableView = (props) => {
    const spordleTable = useContext(SpordleTableContext);
    const renderElement = () => {
        switch (spordleTable.state.loadingState) {
            case 'lazy': // Lazy load maison
                return null;
            case 'loading':
                return (jsx(Fragment, { children: typeof spordleTable.props.loadingMessage === 'string' || typeof spordleTable.props.loadingMessage === 'undefined' ?
                        jsxs("div", { className: "text-center my-3", "data-testid": 'loader-message', children: [jsx(Spinner, { color: "primary", type: "grow" }), jsx("div", { className: "h4", children: spordleTable.props.loadingMessage && jsx(FormattedMessage, { id: spordleTable.props.loadingMessage }) })] })
                        :
                            spordleTable.props.loadingMessage }));
            case 'success':
                switch (spordleTable.state.view.mode) {
                    case 'LIST':
                    default:
                        return jsx(TableView, { ...props }, 'TABLE');
                    case 'GRID':
                        return jsx(GridView, { ...props }, 'GRID');
                }
            case 'error':
            default: // Will land here if 'error'
                return (jsx(Fragment, { children: typeof spordleTable.props.errorMessage === 'string' || typeof spordleTable.props.errorMessage === 'undefined' ?
                        jsx("div", { className: "text-center", "data-testid": 'error-message', children: jsx("div", { className: "h4", children: spordleTable.props.errorMessage && jsx(FormattedMessage, { id: spordleTable.props.errorMessage }) }) })
                        : typeof spordleTable.props.errorMessage === 'function' ?
                            jsx(spordleTable.props.errorMessage, { spordleTable: spordleTable, error: spordleTable.error })
                            :
                                spordleTable.props.errorMessage }));
        }
    };
    return renderElement();
};
SpordleTableView.displayName = 'SpordleTableView';
const SpordleTableView$1 = SpordleTableView;export{SpordleTableView$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/