/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {spordleSelectStitches,loadingDots}from'./stitches.js';import {optionPlaceholder}from'./stitchesOptions.js';const Splitter = spordleSelectStitches.styled('div', {
    marginRight: '$paddingHalf',
});
// .spordleSelect-input-container
const Container = spordleSelectStitches.styled('div', {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    overflow: 'hidden',
    maxWidth: '100%',
    paddingLeft: '$padding2',
    position: 'relative',
    py: '$padding',
    '.spordleSelect &': {
        display: 'flex',
        paddingRight: 0,
    },
    '&.searchable': {
        cursor: 'text',
    },
    '&:focus': {
        outline: 0,
    },
    '&:focus-within': {
        outline: 0,
    },
    '&:disabled': {
        cursor: 'initial',
        pointerEvents: 'none',
        opacity: 1,
    },
    '&[data-disabled=true]': {
        cursor: 'initial',
        pointerEvents: 'none',
        opacity: 1,
    },
});
// .spordleSelect-input-container-inner
const ContainerInner = spordleSelectStitches.styled('div', {
    boxSizing: 'content-box',
    height: '100%',
    width: '100%',
    minWidth: 0,
    paddingBottom: 'inherit',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 'inherit',
    position: 'relative',
});
// .spordleSelect-input-container-values
const ContainerValues = spordleSelectStitches.styled('div', {
    maxWidth: '100%',
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [`&:not(${optionPlaceholder})`]: {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)', // vertical centering
    }
});
// .spordleSelect-input-container-value-single
const ContainerValuesSingle = spordleSelectStitches.styled('div', {});
// .spordleSelect-input
const SearchInput = spordleSelectStitches.styled('input', {
    backgroundColor: 'transparent',
    border: 'none',
    flexGrow: 1,
    flexShrink: 0,
    padding: 0,
    left: 0,
    position: 'absolute',
    outline: 'none',
    width: '100%',
    '&::-webkit-search-cancel-button': {
        display: 'none',
        appearance: 'none',
    },
    '&::-webkit-search-results-button': {
        display: 'none',
    },
    '&::-webkit-search-results-decoration': {
        display: 'none',
    },
    '&::-webkit-search-decoration': {
        display: 'none',
    },
    '&::-ms-clear': {
        display: 'none',
        width: 0,
        height: 0,
    },
    '&::-ms-reveal': {
        display: 'none',
        width: 0,
        height: 0,
    },
    [`${Container}:focus &`]: {
        display: 'inline-block',
    },
    [`${Container}:focus-within &`]: {
        display: 'inline-block',
    },
});
// .spordleSelect-input-hidden
const HiddenSearchInput = spordleSelectStitches.styled('input', {
    border: 'none',
    height: 0,
    width: 0,
    opacity: 0,
    padding: 0,
    pointerEvents: 'none',
    position: 'absolute',
});
// .spordleSelect-icons
const Icons = spordleSelectStitches.styled('span', {
    alignItems: 'center',
    display: 'flex',
    marginLeft: 'auto',
    paddingLeft: '$padding2',
    position: 'relative',
    zIndex: 2,
});
// .spordleSelect-icon
const cssIcon = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    height: '66%',
    fontSize: '1rem',
    width: '2rem',
};
const Icon = spordleSelectStitches.styled('span', cssIcon);
const icon = spordleSelectStitches.css(cssIcon);
// .spordleSelect-clear-btn
const StyledClearButton = spordleSelectStitches.styled('button', {
    pointerEvents: 'all',
});
// .spordleSelect-chevron
const Chevron = spordleSelectStitches.styled('i', {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});
// .spordleSelect-loading-icon
const loadingIcon = spordleSelectStitches.css({
    alignItems: 'center',
    cursor: 'default',
    flexDirection: 'column',
    opacity: 1,
    '&::before': {
        content: '',
        width: '2rem',
    },
    '&::after': {
        animationName: loadingDots,
        animationTimingFunction: 'linear',
        animationDuration: '1.25s',
        animationIterationCount: 'infinite',
        animationFillMode: 'forwards',
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderRadius: 1000,
        boxShadow: '-0.4rem 0 0 rgba(0,0,0,0.5), 0.4rem 0 0 rgba(0,0,0,0.5)',
        content: '',
        display: 'inline-block',
        flexShrink: 0,
        height: '0.25rem',
        width: '0.25rem',
    },
});export{Chevron,Container,ContainerInner,ContainerValues,ContainerValuesSingle,HiddenSearchInput,Icon,Icons,SearchInput,Splitter,StyledClearButton,cssIcon,icon,loadingIcon};
/*
@Copyrights Spordle 2022 - All rights reserved
*/