import Translate from "@spordle/intl-elements";
import { stringifyUrl } from "query-string";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../../../contexts/AuthContext";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import getReferrer from "../../../../../../../helpers/getReferer";

const RegistrationContactUs = () => {
    const { short_url, deploy_on, short_name } = useContext(OrganizationContext);
    const authContext = useContext(AuthContext);
    const i18nContext = useContext(I18nContext);

    return (
        <small className="m-0 rounded-bottom text-center d-block bg-dark text-light py-2">
            <Translate
                id='participant.registrationModal.needSupport'
                values={{
                    a: (chunks) => deploy_on == 'VERCEL' || deploy_on == 'PAGE' ?
                        <a
                            id='registrationContactUs'
                            className="text-info"
                            target="_blank" rel="noopener noreferrer"
                            href={stringifyUrl({
                                url: `${getReferrer(deploy_on == 'VERCEL' ? 'PAGE-VERCEL' : 'PAGE-VERCEL-2')}/${short_name}/contact`,
                                query: {
                                    lang: i18nContext.getGenericLocale(),
                                    accessToken: authContext.accessToken,
                                },
                            }, { skipEmptyString: true, skipNull: true })}
                        >
                            {chunks}
                        </a>
                        :
                        <Link id='registrationContactUs' className="text-info" target="_blank" rel="noopener noreferrer" to={`/page/${short_url}/contact`}>{chunks}</Link>
                    ,
                }}
            />
        </small>
    )
}

export default RegistrationContactUs;