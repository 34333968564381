import header from "./header";
import form from "./form";
import footer from "./footer";
import misc from "./misc";
import sidebar from "./sidebar";
import sidenav from "./sidenav";
import warnings from "./warnings";

export default{
    ...header,
    ...form,
    ...footer,
    ...misc,
    ...sidebar,
    ...sidenav,
    ...warnings,
}