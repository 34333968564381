import Contacts from '../views/page/components/Contacts';
import Default from '../views/page/components/Default';
import Home from '../views/Home';
import Page from '../views/page/Page';
import Participant from '../views/page/components/Participant/Participant';
import Posts from '../views/page/components/Posts/Posts';
import getReferrer, { getMultiSportCode, isProdFishNChips } from '../helpers/getReferer';

export function getFederationShortUrl(){
    switch (getMultiSportCode()){
        case "SQ":
            return 'sc';
        case "BQ":
            return 'bc';
        case "UK":
            return 'eiha';
        default:
            break;
    }
    return 'hc';// HC
}

export const PageRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		skipSidebar: true, // skip rendering route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		userLevelExclude: [{userLevel: [FEDERATION, PROVINCIAL, CLUB, ACCOUNT], redirectTo: '/tournaments'},{userLevel: REGION, redirectTo: TournamentAdminRoute}] // These users will NOT have access
	},*/

    // { path: '/page/1', name: 'Page', icon: '', component: Page, skipTranslate: true },
    { path: '/', pathTo: `/page/${getFederationShortUrl()}`, redirect: true, exact: true },
    { path: '/page', pathTo: `/page/${getFederationShortUrl()}`, redirect: true, exact: true },

    { path: '/page', pathTo: '/page', component: Home, exact: true },

    { path: '/page/:orgid', component: Page },
];

export const PageSubroutes = [
    {
        title: 'routes.group.overview.name',
        subroutes: [
            { path: '', name: 'routes.default.name', exact: true, component: Default },
            // { path: '/teams', name: 'routes.teams.name', exact: true, component: TeamsList },
            // { path: '/teams/:teamId', exact: true, skipSidebar: true, component: TeamsProfile },
            // { path: '/staff', name: 'routes.staff.name', exact: true, component: Staff },
            { path: '/contact', name: 'routes.contact.name', exact: true, component: Contacts },
            // { path: '/venues', name: 'routes.venues.name', exact: true, component: Venues },
            // { path: '/schedule', name: 'routes.schedule.name', exact: true, component: MainSchedule },
            { path: '/posts', name: 'Posts', skipSidebar: true, exact: true, component: Posts },
        ],
    },
    {
        title: 'routes.group.registration.name',
        subroutes: [
            { path: '/participant', name: 'routes.registration.name', component: Participant },
            {
                path: '/clinics',
                name: 'routes.clinics.name',
                exact: true,
                external: true,
                skipSidebar: isProdFishNChips(),

                // Will probably change, this is a quick solution
                link: (lang, orgId) => `${getReferrer('HCR-SITE', lang)}/${lang === "fr" ? 'stages' : 'clinics'}${orgId ? `?org=${orgId}` : ''}`,
            },
        ],
    },
];

//modal quand on clique sur clinics, on vous redirige vers clinics
// venues = emplacements