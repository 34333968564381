import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<ResquestContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const ResquestContext = createContext();
ResquestContext.displayName = 'ResquestContext';

class ResquestContextProvider extends React.Component{

    /**
     * Get all request types from an org
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Request%20Types/Apicontroller%5CRequests%5CRequesttypes%3A%3AgetAllRequestTypes|documentation}
     * @returns {Promise.<Array>}
     */
    getRequestTypes = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/request-types',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.request_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all request reasons from an org
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Request%20Reasons/Apicontroller%5CRequests%5CRequestreasons%3A%3AgetAllRequestReasons|documentation}
     * @returns {Promise.<Array>}
     */
    getRequestReasons = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/request-reasons',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.request_reasons;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a registration request
     * @param {string} memberId
     * @param {object} [data] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AaddMemberRequest|documentation}
     * @returns {Promise.<Array>}
     */
    createMemberRequest = (memberId, data = {}) => {
        const params = new URLSearchParams({
            organisation_to: this.props.OrganizationContext.organisation_id, // Default going to the current org
            origin_address: 'GOOGLE',
        });
        for(const key in data){
            if(Object.hasOwnProperty.call(data, key)){
                const element = data[key];
                switch (key){
                    case 'organisationFrom':
                        params.append('organisation_from', element);
                        break;
                    case 'organisationTo':
                        params.append('organisation_to', element);
                        break;
                    case 'requestType':
                        params.append('request_type_id', element);
                        break;
                    case 'reason':
                        params.append('request_reason_id', element);
                        break;
                    case 'moreInfo':
                        params.append('note', element);
                        break;
                    case 'newAddress2':
                        params.append('unit_number', element);
                        break;
                    case 'address':
                        for(const addressKey in element){
                            if(Object.hasOwnProperty.call(element, addressKey)){
                                const addressProperty = element[addressKey];
                                switch (addressKey){
                                    case 'streetNumber':
                                        params.append('street_number', addressProperty);
                                        break;
                                    case 'address':
                                        params.append('street', addressProperty);
                                        break;
                                    case 'mapsUrl':
                                        params.append('map_url', addressProperty);
                                        break;
                                    case 'country':
                                        params.append('country_code', addressProperty);
                                        break;
                                    case 'state':
                                        params.append('province_code', addressProperty);
                                        break;
                                    case 'city':
                                        params.append('city', addressProperty);
                                        break;
                                    case 'zip':
                                        params.append('zip_code', addressProperty);
                                        break;
                                    case 'origin':
                                        params.append('origin_address', addressProperty);
                                        break;

                                    default:
                                        break;
                                }
                            }
                        }
                        break;

                    default:
                        break;
                }
            }
        }
        return API_SPORDLE.post(`/members/${memberId}/request`, params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_request_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <ResquestContext.Provider value={{ ...this }}>
                {this.props.children}
            </ResquestContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(ResquestContextProvider);