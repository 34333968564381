/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import {useContext}from'react';import {SpordleTableContext}from'./SpordleTableProvider.js';import {getValueFromString}from'./utils.js';import {stringBuilder}from'@spordle/helpers';const FooterBuilder = () => {
    const spordleTable = useContext(SpordleTableContext);
    const calculateFooter = (column) => {
        return spordleTable.dataToDisplay.reduce((total, data) => {
            const amount = column.hasTotal instanceof Function ?
                parseFloat(column.hasTotal(data, spordleTable))
                :
                    parseFloat(getValueFromString(data, column.key));
            return total + (amount || 0);
        }, 0);
    };
    return (jsx("tfoot", { className: stringBuilder('spordletable-tfoot', spordleTable.props.footerClassName), "data-testid": 'footer', children: jsx("tr", { id: spordleTable.generateId('table', 'footer'), "data-testid": 'footer-row', children: spordleTable.state.orderedColumns.map((column) => {
                if (column.hasTotal) {
                    const total = calculateFooter(column);
                    if (spordleTable.props.renderFooter) {
                        const element = spordleTable.props.renderFooter(column.key, total, spordleTable);
                        // Column that has total and a custopm rendering
                        if (element)
                            return jsx("td", { id: spordleTable.generateId('table', 'footer', column.key), "data-testid": `footer-${column.key}`, children: element }, column.key);
                    }
                    // Column does have total but no custom footer rendering OR custom footer rendering returns null or undefined
                    return jsx("td", { id: spordleTable.generateId('table', 'footer', column.key), "data-testid": `footer-${column.key}`, children: total }, column.key);
                }
                // Column doesn't have total
                return jsx("td", { id: spordleTable.generateId('table', 'footer', column.key), "data-testid": `footer-${column.key}` }, column.key);
            }) }) }));
};
FooterBuilder.displayName = 'FooterBuilder';
const FooterBuilder$1 = FooterBuilder;export{FooterBuilder$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/