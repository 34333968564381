import { I18nContext } from '../contexts/I18nContext';
import { AuthContext } from '../contexts/AuthContext';
import withContexts from '../helpers/withContexts';
import { useLayoutEffect } from 'react';

// window.Forethought('widget', 'open')
// window.Forethought('widget', 'close')
// window.Forethought('widget', 'hide')
// window.Forethought('widget', 'show')

/*
<script
src="https://solve-widget.forethought.ai/embed.js"
type="application/javascript"
data-api-key="b912010e-9ccf-4f3c-a49a-241c7622ddb4"
data-ft-Full-Name="ENTER-VALUE-HERE"
data-ft-Embed-Script-Language="ENTER-VALUE-HERE"
data-ft-userType="ENTER-VALUE-HERE"
data-ft-Provided-URL="ENTER-VALUE-HERE"
data-ft-Spordle-Client="ENTER-VALUE-HERE"
data-ft-workflow-tag="AVAILABLE TAGS: mha, parent, other"
></script>
*/

const ForeThought = ({ locale, authenticated, fullName }) => {
    const mountScript = () => {
        unmountScript();

        var head = document.head || document.getElementsByTagName("head")[0];
        var forethoughtscript = document.createElement("script");

        forethoughtscript.setAttribute("id", "forethought-script");
        forethoughtscript.setAttribute("src", "https://solve-widget.forethought.ai/embed.js");
        forethoughtscript.setAttribute("type", "application/javascript");
        forethoughtscript.setAttribute("data-api-key", "b912010e-9ccf-4f3c-a49a-241c7622ddb4");
        forethoughtscript.setAttribute("data-ft-Spordle-Client", "Hockey Canada");

        forethoughtscript.setAttribute("data-ft-authenticated", !!authenticated);
        forethoughtscript.setAttribute("data-ft-Embed-Script-Language", locale);
        forethoughtscript.setAttribute("data-ft-Full-Name", fullName);
        forethoughtscript.setAttribute("data-ft-Provided-URL", window.location.href);
        forethoughtscript.setAttribute("data-ft-userType", "parent");
        forethoughtscript.setAttribute("data-ft-workflow-tag", "parent");

        head.appendChild(forethoughtscript);
    }
    const unmountScript = () => {
        if(window.Forethought)
            window.Forethought('widget', 'close')
        if(document.getElementById("forethought-script"))
            document.getElementById("forethought-script").remove()
    }

    useLayoutEffect(() => {
        mountScript()

        return () => {
            unmountScript()
        }
    }, [ locale, authenticated, fullName ])

    return (
        null
    )
}

export default withContexts(AuthContext, I18nContext)(ForeThought);