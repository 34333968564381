/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import Translate from'@spordle/intl-elements';import {useState,useEffect}from'react';import {Collapse}from'reactstrap';import LoadBar from'./LoadBar.js';import PropTypes from'prop-types';/**
 * @class Toast
 * @description The Toast component that will be rendered
 * @param {ToastProps} props
 * @copyright Spordle
 * @example
 * <Toast onExit={() => editAlert(prev.length)} {...config?.defaultToast} {...values} />
 */
const Toast = ({ timeout = 5, horizontalLoad = false, id, ...props }) => {
    const [{ theme = "primary", msg: Msg = "misc.info", info: Info, icon, onExit, permanent = false, skipInfoTranslate = false, skipMsgTranslate = false, showCloseButton = true, }, setPropsState] = useState(props);
    const [passed, setPassed] = useState(0);
    const [isOpen, setIsOpen] = useState(true);
    const [paused, setPaused] = useState(false);
    const INTERVAL = 10;
    const ms = timeout * 1000;
    const jump = 100 * (INTERVAL / ms);
    const timer = () => {
        setPassed((prev) => (prev + jump));
    };
    const dismiss = () => {
        setIsOpen(false);
    };
    const pauseTimer = () => {
        setPaused(true);
    };
    const resumeTimer = () => {
        setPaused(false);
    };
    const getIcon = () => {
        let icon = `transition text-${theme} font-22 mdi mdi-`;
        switch (theme) {
            case 'success':
                icon += "check-circle-outline";
                break;
            case 'danger':
                icon += "close-circle-outline";
                break;
            case 'warning':
                icon += "alert-outline";
                break;
            case 'info':
            case 'primary':
            default:
                icon += "information-outline";
                break;
        }
        return icon;
    };
    const removeToastListener = (e) => {
        if (id === e.detail.id) {
            dismiss();
        }
    };
    const updateToastListener = (e) => {
        if (id === e.detail.id) {
            setPropsState((prev) => ({ ...prev, ...e.detail }));
        }
    };
    useEffect(() => {
        // @ts-ignore
        window.addEventListener("remove", removeToastListener);
        // @ts-ignore
        window.addEventListener("updateToast", updateToastListener);
        return () => {
            // @ts-ignore
            window.removeEventListener("remove", removeToastListener);
            // @ts-ignore
            window.removeEventListener("updateToast", updateToastListener);
        };
    }, []);
    useEffect(() => {
        let t;
        if (passed < 100 && !permanent && !paused) {
            t = setTimeout(timer, INTERVAL);
        }
        else if (t) {
            clearTimeout(t);
        }
        passed >= 100 && !permanent && dismiss();
        return () => t && clearTimeout(t);
    }, [permanent, passed, paused]);
    return (jsx(Collapse, { isOpen: isOpen, onExited: onExit, children: jsxs("div", { onMouseEnter: pauseTimer, onMouseLeave: resumeTimer, className: `spdl-toast${horizontalLoad ? ' is-horizontal' : ''} ${theme}`, children: [jsx(LoadBar, { horizontal: horizontalLoad, height: permanent ? 100 : passed }), jsx("div", { className: "px-2", children: icon || jsx("i", { className: getIcon() }) }), jsxs("div", { children: [jsx("div", { className: "font-medium transition text-" + theme, children: typeof Msg === 'function' ?
                                jsx(Msg, {})
                                : typeof Msg !== 'string' || skipMsgTranslate ?
                                    Msg
                                    :
                                        jsx(Translate, { id: Msg }) }), Info &&
                            jsx("div", { className: "small", children: typeof Info === 'function' ?
                                    jsx(Info, {})
                                    : typeof Info !== 'string' || skipInfoTranslate ?
                                        Info
                                        :
                                            jsx(Translate, { id: Info }) })] }), showCloseButton &&
                    jsx("i", { onClick: dismiss, className: "spdl-toast-close mdi mdi-close" })] }) }));
};
Toast.propTypes = {
    // @ts-ignore
    theme: PropTypes.oneOf([
        "primary",
        "danger",
        "success",
        "warning",
        "secondary",
        "info",
    ]).isRequired,
    // @ts-ignore
    msg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.elementType,
    ]).isRequired,
    // @ts-ignore
    info: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
    ]),
    // @ts-ignore
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    onExit: PropTypes.func.isRequired,
    permanent: PropTypes.bool,
    skipInfoTranslate: PropTypes.bool,
    skipMsgTranslate: PropTypes.bool,
    timeout: PropTypes.number,
    horizontalLoad: PropTypes.bool,
};
Toast.defaultProps = {
    theme: "primary",
    msg: "misc.info",
    permanent: false,
    skipInfoTranslate: false,
    skipMsgTranslate: false,
    timeout: 5,
    horizontalLoad: false,
};
const Toast$1 = Toast;export{Toast$1 as default};