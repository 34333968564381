import { Component, createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
// import { OrganizationContext } from './OrganizationContext';
import { serverError } from '../api/CancellableAPI';

/** @type {React.Context<Omit<FormsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const FormsContext = createContext();
FormsContext.displayName = 'FormsContext';

/**
 * @typedef {object} FormField
 * @property {string} form_field_id
 * @property {'0'|'1'} with_option
 * @property {'0'|'1'} active
 * @property {'TEXT'|'TEXT_AREA'|'CHECKBOX'|'RADIO'|'SELECTBOX'|'SELECTBOX_MULTI'|'DATE'|'DATETIME'|'NUMBER'} code
 */

class FormsContextProvider extends Component{
    // static contextType = OrganizationContext;
    /** @type {React.ContextType<OrganizationContext>} */
    // context;

    state = {
        /** @type {FormField[]} */
        formFields: [],
    }

    /**
     * Get all the forms define for an organization
     * @param {string} [organizationId] The organization id we want to get the forms from. It uses the current org by default
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AgetAllForms|documentation}
     * @returns {Promise.<Array>}
     */
    // getForms = (organizationId = this.context.organisation_id, queryParams = {}) => {
    //     return API_SPORDLE.get(queryString.stringifyUrl({
    //             url: 'forms',
    //             query:  Object.assign({
    //                 organisation_id: organizationId
    //             }, queryParams)
    //         })).then(response => {
    //             if(response.data.status){
    //                 return response.data.forms;
    //             }
    //             throw new Error(response.data.errors[0].code);
    //         }, serverError);
    // }

    /**
     * Get a specific form
     * @param {string} formId The specific form we want to get
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AgetFormDetail|documentation}
     * @returns {Promise.<array>}
     */
    getForm = (formId) => {
        return API_SPORDLE.get(`/forms/${formId}`)
            .then((response) => {
                if(response.data.status){
                    return response.data.form_groups;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    render(){
        return (
            <FormsContext.Provider value={this}>
                {this.props.children}
            </FormsContext.Provider>
        );
    }
}

export default FormsContextProvider;