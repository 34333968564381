import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Button,
    ModalHeader,
    ModalBody,
    Collapse
} from "reactstrap";

import IconNewMember from '../../../../../../../assets/images/icons/NewMember.svg';
import IconExistingMember from '../../../../../../../assets/images/icons/ExistingMember.svg';
import IDontKnow from '../../../../../../../assets/images/icons/IDontKnow.svg';
import { stringBuilder } from "@spordle/helpers";

import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';

const RegistrationExistingOrNew = (props) => {
    const registrationContext = useContext(RegistrationContext);
    const [ selectedOption, setSelectedOption ] = useState(registrationContext.currentOnlineStore.store.can_create_new_member == 0 ? 'existing' : null);

    return (
        <OverlayLoader isLoading={registrationContext.state.modalIsLoading}>
            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="text-white bg-dark">
                <Translate id='participant.registrationModal.views.existingOrNew.title' />
            </ModalHeader>
            <ModalBody className="py-4 bg-light">
                {registrationContext.state.currentCart.skipToPayment &&
                    <div className="text-right mb-3 mt-n2">
                        <button type='button' className="reset-btn text-info" onClick={() => registrationContext.goToView(registrationContext.views.summary)}><Translate id='participant.registrationModal.views.existingOrNew.registerExisting.skipToPayment' /> <i className="mdi mdi-arrow-right" /></button>
                    </div>
                }
                <div className="registration-container">
                    <div id='existingOrNewParticipantList' className={stringBuilder('registration-card', { 'is-selected': selectedOption === 'existing', 'mb-0': registrationContext.currentOnlineStore.store.can_create_new_member == 0 })} onClick={() => setSelectedOption('existing')}>
                        <div className="mr-3 flex-shrink-0">
                            <img className="rounded-circle border p-1" src={IconNewMember} alt="New Member" width="50" />
                        </div>
                        <div className="mr-3">
                            <p className="text-info font-weight-bold mb-1"><Translate id='participant.registrationModal.views.existingOrNew.registerExisting.title' /></p>
                            <p className="text-info mb-0 mt-n1 small"><Translate id='participant.registrationModal.views.existingOrNew.registerExisting.desc' /></p>
                        </div>
                        <div className="registration-card-icon"><i className="mdi mdi-check" /></div>
                    </div>
                    {registrationContext.currentOnlineStore.store.can_create_new_member == 1 &&
                        <>
                            <div
                                id='existingOrNewCreateMember'
                                className={stringBuilder('registration-card', { 'is-selected': selectedOption === 'new' })}
                                onClick={() => setSelectedOption('new')}
                            >
                                <div className="mr-3 flex-shrink-0">
                                    <img className="rounded-circle border p-1" src={IconExistingMember} alt="Existing Member" width="50" />
                                </div>
                                <div className="mr-3">
                                    <p className="text-info mb-0 font-weight-bold"><Translate id='participant.registrationModal.views.existingOrNew.registerNew.title' /></p>
                                    <p className="text-info mb-0 mt-n1 small"><Translate id='participant.registrationModal.views.existingOrNew.registerNew.desc' /></p>
                                </div>
                                <div className="registration-card-icon"><i className="mdi mdi-check" /></div>
                            </div>


                            <div
                                id='existingOrNewIDontKnow'
                                className={stringBuilder('registration-card mb-0', { 'is-selected': selectedOption === 'idk' })}
                                onClick={() => setSelectedOption('idk')}
                            >
                                <div className="mr-3 flex-shrink-0">
                                    <img className="rounded-circle border p-1" src={IDontKnow} alt="Existing Member" width="50" />
                                </div>
                                <div className="mr-3">
                                    <p className="text-info mb-0 font-weight-bold"><Translate id='participant.registrationModal.views.existingOrNew.registerIdk.title' /></p>
                                    <p className="text-info mb-0 mt-n1 small"><Translate id='participant.registrationModal.views.existingOrNew.registerIdk.desc' /></p>
                                </div>
                                <div className="registration-card-icon"><i className="mdi mdi-check" /></div>
                            </div>
                        </>
                    }

                    <div className="p-3"> {/* container with padding to prevent the collapse gliching with margins */}
                        <Collapse isOpen={selectedOption === 'new'}>
                            <p className="text-center mb-0 small text-muted">
                                <Translate
                                    id='participant.registrationModal.views.existingOrNew.registerNew.warning'
                                    values={{
                                        create: <b><Translate id='participant.registrationModal.views.existingOrNew.registerNew.create' /></b>,
                                        form: <b><Translate id='participant.registrationModal.views.existingOrNew.registerNew.form' /></b>,
                                    }}
                                />
                            </p>
                        </Collapse>
                    </div>

                    <Button
                        id='existingOrNewStartRegistration'
                        onClick={() => {
                            registrationContext.setHcrSearchCreateMode(selectedOption === 'new' || selectedOption === 'idk')
                            registrationContext.goToView(selectedOption === 'existing' ? registrationContext.views.participantList : registrationContext.views.hcrSearch)
                        }}
                        color="info" className="registration-btn"
                        disabled={!selectedOption}
                    >
                        <span className="mr-2"><Translate id='participant.registrationModal.views.existingOrNew.btn.start' /></span>
                        <i className="mdi mdi-arrow-right" />
                    </Button>
                </div>
            </ModalBody>
        </OverlayLoader>
    );
}

export default RegistrationExistingOrNew;