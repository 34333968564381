/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import {useState,useEffect}from'react';import Toast from'./Toast.js';function _dispatchToast(options) {
    window.dispatchEvent(new CustomEvent("toast", {
        detail: { toast: options },
    }));
}
/**
 * @description
 * Removes all Toasts
 * @copyright Spordle
 */
function clearToasts() {
    window.dispatchEvent(new CustomEvent("clearToasts"));
}
/**
 * @description
 * Removes the Toast with the provided `id`
 * @param {string} id
 * @copyright Spordle
 */
function removeToast(id) {
    window.dispatchEvent(new CustomEvent("remove", {
        detail: {
            id: id,
        },
    }));
}
/**
 * @description
 * Updates a Toast with the provided `id`
 * @param {ToastOptions} params
 * @copyright Spordle
 */
function updateToast(params) {
    if (!params.id) {
        console.warn('Update Toast', 'Please specify an id');
    }
    else {
        window.dispatchEvent(new CustomEvent("updateToast", {
            detail: params,
        }));
    }
}
/**
 * @description
 * Create a Toast
 * @param {ToastOptions} options
 * @copyright Spordle
 */
function fire(options) {
    _dispatchToast({ theme: "primary", msg: "misc.info", ...options });
}
/**
 * @description
 * Create a Toast with the `success` theme
 * @param {ToastOptions} options
 * @copyright Spordle
 */
function success(options) {
    _dispatchToast({ msg: "misc.success", ...options, theme: "success" });
}
/**
 * @description
 * Create a Toast with the `danger` theme
 * @param {ToastOptions} options
 * @copyright Spordle
 */
function fail(options) {
    _dispatchToast({ msg: "misc.error", ...options, theme: "danger" });
}
/**
 * @class SpordleToast
 * @description
 * Allows the use of toasts globally and handles the state of all toasts and their container.<br>
 * The toasts will be rendered in this component
 * @param {Object} [props]
 * @param {Config} [props.config]
 * @access default
 * @copyright Spordle
 * @example
 * <SpordleToast config={{ position: { top: 60 } }} />
 */
const SpordleToast = ({ children, config }) => {
    const [alerts, setAlerts] = useState([]);
    const initPos = (pos) => {
        return {
            ...pos.top && { top: pos.top },
            ...pos.bottom && { bottom: pos.bottom },
            ...pos.left && { left: pos.left },
            ...pos.right && { right: pos.right },
        };
    };
    const styles = config ? {
        ...config.position && initPos(config.position),
        ...config.width && { width: config.width },
    } : undefined;
    /**
     * Empties the alert array
     * @private
     */
    function emptyAlerts() {
        setAlerts([]);
    }
    /**
     * Changes the alert property exited to true to avoid rendering it
     * If all alerts are exited, then sets the state to an empty array
     * @param {number} i index of the alert to edit
     * @private
     */
    function editAlert(i) {
        setAlerts((prev) => {
            const newA = [...prev];
            newA[i] = { ...newA[i], exited: true };
            // When all toasts are exited, I empty the array.
            // This is to avoid any problems with keys. We know we won't have 10000 alerts at the same time.
            return newA.find((a) => !a.exited) ? newA : [];
        });
    }
    /**
     * Adds an alert to the alerts array
     * @param {ToastOptions} values
     * @private
     */
    function addAlert(values) {
        setAlerts((prev) => {
            if (values.id && prev.findIndex((currentAlert) => currentAlert.id === values.id) !== -1) { // If is unique and already is rendered
                return prev; // Cancels setState
            }
            const newA = [...prev];
            newA.push({
                exited: false,
                id: values.id,
                component: jsx(Toast, { onExit: () => editAlert(prev.length), ...config?.defaultToast, ...values }, prev.length),
            });
            return newA;
        });
    }
    /**
     * Handles the toast event
     * @param {Event} e
     * @private
     */
    const handleEvent = (e) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (e.detail.toast)
            addAlert(e.detail.toast);
    };
    useEffect(() => {
        // @ts-ignore
        window.addEventListener("toast", handleEvent);
        window.addEventListener("clearToasts", emptyAlerts);
        return () => {
            // @ts-ignore
            window.removeEventListener("toast", handleEvent);
            window.removeEventListener("clearToasts", emptyAlerts);
        };
    }, []);
    return (jsxs(Fragment, { children: [alerts.length > 0 &&
                jsx("div", { className: "spdl-toasts-container", style: styles, children: alerts.map((alert) => !alert.exited && alert.component) }), children] }));
};
const SpordleToast$1 = SpordleToast;export{clearToasts,SpordleToast$1 as default,fail,fire,removeToast,success,updateToast};