/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import React from'react';import PropTypes from'prop-types';import {Label,FormText}from'reactstrap';import NumberFormat from'react-number-format';import {useIntl}from'react-intl';import {useField}from'formik';import {stringBuilder}from'@spordle/helpers';import FormikError from'./FormikError.js';/**
 * @member FormikInputNumber
 * @description
 * FormikInputNumber will create an input text that will work automagically with Fromik(yes it's a typo :)) validations
 * @example
 * <FormikInputNumber allowNegative={false} format="##/##" label="Base URL" name="base_URL" id="base_URL" helper="login.error.server"/>
 * <FormikInputNumber allowNegative={false} format="##/##" name="base_URL" id="base_URL"/>
 *
 * @prop {string} name Used to make the FormikInputNumber work with validation
 * @prop {string|React.ReactNode} [label] The lang id to be translated OR JSX
 * @prop {string|React.ReactNode} [helper] The lang id to be translated OR JSX
 * @prop {string} [placeholder] The lang id to be translated
 * @prop {boolean} [translateHelper=true]
 * @prop {boolean} [translatePlaceholder=true]
 * @prop {boolean} [translateLabel=true]
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @returns {React.ReactNode} Returns an input text that will work with Formik that accepts numbers only
 *
 * @see https://github.com/s-yadav/react-number-format
 * @copyright Spordle
 */
const FormikInputNumber = ({ label, helper, translatePlaceholder, translateHelper, translateLabel, manualError, ...props }) => {
    // Removing onChange from the data -> Data will be set with onValueChange
    // @ts-ignore
    // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
    const [{ onChange, ...field }, meta, helpers] = useField(props);
    const intl = useIntl();
    if (translatePlaceholder && props.placeholder && typeof props.placeholder === 'string')
        props.placeholder = intl.formatMessage({ id: props.placeholder });
    if (translateHelper && helper && typeof helper === 'string')
        helper = intl.formatMessage({ id: helper });
    if (translateLabel && label && typeof label === 'string')
        label = intl.formatMessage({ id: label });
    return (jsxs(Fragment, { children: [label && typeof label === 'string' ? jsx(Label, { for: props.id || props.name, children: label }) : label, jsx(NumberFormat, { ...field, ...props, className: stringBuilder('form-control', props.className, { 'is-invalid': meta.touched && meta.error }), isNumericString: props.allowLeadingZeros || props.isNumericString, onValueChange: (value, sourceInfo) => {
                    if (props.allowLeadingZeros) {
                        helpers.setValue(value.value);
                    }
                    else {
                        helpers.setValue(value.floatValue == null ? '' : value.floatValue);
                    }
                    props.onValueChange?.(value, sourceInfo);
                } }), helper && typeof helper === 'string' ? jsx(FormText, { color: "muted", children: helper }) : helper, !manualError && jsx(FormikError, { name: props.name })] }));
};
FormikInputNumber.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateHelper: PropTypes.bool,
    translateLabel: PropTypes.bool,
    translatePlaceholder: PropTypes.bool,
    name: PropTypes.string.isRequired,
    manualError: PropTypes.bool,
};
FormikInputNumber.defaultProps = {
    translateHelper: true,
    translateLabel: true,
    translatePlaceholder: true,
};
FormikInputNumber.displayName = 'FormikInputNumber';
const FormikInputNumber$1 = React.memo(FormikInputNumber);export{FormikInputNumber$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/