import PropTypes from 'prop-types';

/**
 * @description Table that adds a table container and the table tag.
 * @param {String} [tableClassName] Classes to add to the table tag and not the container.
 */
const Table = ({ tableClassName, children, className, ...props }) => {
    const classNames = `table-container${className ? ` ${className}` : ''}`;
    const tableClassNames = `table${tableClassName ? ` ${tableClassName}` : ''}`;

    return (
        <div className={classNames}>
            <table className={tableClassNames} {...props}>
                {children}
            </table>
        </div>
    )
}

Table.propTypes = {
    tableClassName: PropTypes.string,
}

export default Table;