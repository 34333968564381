import { useContext, useEffect } from 'react';

import {
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    FormGroup,
    Collapse,
    Alert
} from "reactstrap";

import { FormikAddress, FormikInputText, FormikSelect, getFormikAddressInitialValues } from '@spordle/formik-elements';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { AddressTypesContext } from '../../../../../../../contexts/AddressTypesContext';
import { MembersContext } from '../../../../../../../contexts/MembersContext';

import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import Translate from '@spordle/intl-elements';

import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import Required from '../../../../../../../components/formik/Required';
import RegistrationParticipantCard from './RegistrationParticipantCard';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { fail } from '@spordle/toasts';
const RegistrationConfirmAddress = (props) => {
    const membersContext = useContext(MembersContext);
    const registrationContext = useContext(RegistrationContext)

    const addressTypesContext = useContext(AddressTypesContext);

    useEffect(() => {
        // Has complete address
        if(registrationContext.getCurrentMember().members[0].incomplete_address == 0){
            registrationContext.goToView(registrationContext.views.registrations)
        }
    }, []);

    return (
        <Formik
            initialValues={{
                address: getFormikAddressInitialValues(),
                address2: '',
                addressTypes: '',
            }}
            validationSchema={object().shape({
                address: object().address(true, {
                    streetNumber: <Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.address.streetNumber.required' />,
                    address: <Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.address.address.required' />,
                    city: <Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.address.city.required' />,
                    country: <Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.address.country.required' />,
                    state: <Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.address.state.required' />,
                    zipCode: <Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.address.zip.required' />,
                }),
                addressTypes: string().required(<Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.addressType.required' />),
            })}
            onSubmit={({ address, ...values }, { setSubmitting, setStatus }) => {
                setStatus();
                membersContext.updateMemberAddresses(registrationContext.getCurrentMember().members[0].member_id, [ {
                    address_type_id: values.addressTypes,
                    unit_number: values.address2,
                    street_number: address.streetNumber,
                    street: address.address,
                    origin_address: address.origin,
                    map_url: address.mapsUrl,
                    country_code: address.country,
                    country_division_code: address.state,
                    city: address.city,
                    zip_code: address.zipCode,
                    default_address: 1,
                } ])
                    .then(async() => {
                        if(await membersContext.memberIsEligible(registrationContext.getCurrentMember().members[0].member_id)
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            }))// If eligible -> continue, otherwise -> .catch
                            registrationContext.goToView(registrationContext.views.registrations)
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            // fail({
                            //     msg: 'misc.error',
                            //     info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            //     skipInfoTranslate: true,
                            // })
                            if(error.i18n){
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            }else{
                                switch (error.message){
                                    case '3266':// Member's postal code is not on the organization's list of permitted postal codes
                                        setStatus(<Translate id={`storeSingle.registrationModal.registrationConfirmAddress.form.validation.${error.message}`} />);
                                        break;
                                    default:// Error not handled
                                        console.error(error.message);
                                        setStatus(<Translate id='misc.error' />);
                                        break;
                                }
                            }
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form autoComplete='off'>
                    <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                        <Translate id='storeSingle.registrationModal.registrationConfirmAddress.title' />
                    </ModalHeader>
                    <div className='position-relative'>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody className="py-4 bg-light">
                                <div className="registration-container">
                                    <RegistrationParticipantCard participant={registrationContext.getCurrentMember()} />

                                    <FormGroup>
                                        <Label for='addressTypes'><Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.addressType.label' /> <Required /></Label>
                                        <FormikSelect
                                            id='addressTypes' name='addressTypes'
                                            renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return addressTypesContext.getAddressTypes({ active: 1 })
                                                            .then((addresses) => {
                                                                return addresses.map((address) => ({
                                                                    id: address.address_type_id,
                                                                    value: address.address_type_id,
                                                                    label: address.name,
                                                                    i18n: address.i18n,
                                                                }))
                                                            })
                                                }
                                            }}
                                        />
                                    </FormGroup>

                                    <FormikAddress
                                        id='address' name='address'
                                        allowManualPlace
                                        required
                                        label='storeSingle.registrationModal.registrationConfirmAddress.form.address.label'
                                    />

                                    <div>
                                        <Label for='address2'><Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.address.label' /> 2 (<Translate id='storeSingle.registrationModal.registrationConfirmAddress.form.address2.placeholder' />)</Label>
                                        <FormikInputText id='address2' name='address2' trim />
                                    </div>

                                    <Collapse isOpen={!!formik.status}>
                                        <Alert color='danger' className='mt-3 mb-0'>{formik.status}</Alert>
                                    </Collapse>
                                </div>
                            </ModalBody>
                            <ModalFooter tag='footer' className="bg-light">
                                <Button id='registrationConfirmAddressPreviousButton' onClick={() => { registrationContext.goToView(registrationContext.views.existingOrNew, true) }} color="default" type='button' outline className="mr-auto" disabled={formik.isSubmitting}><Translate id='misc.previous' /></Button>
                                <Button id='registrationConfirmAddressSubmitButton' color="info" type='submit' disabled={formik.isSubmitting}><Translate id='misc.next' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationConfirmAddress;