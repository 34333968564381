/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import {memo}from'react';import {Button}from'reactstrap';import useSpordleTable from'./useSpordleTable.js';import {useIntl}from'react-intl';import {getMessage}from'./utils.js';import {stringBuilder}from'@spordle/helpers';/**
 * @member Refresh
 * @description A default refresh button linked to the {@link SpordleTableProvider}
 * @example
 * <Refresh outline color='primary'>
 *      <i className='mdi mdi-refresh'/>
 * </Refresh>
 * @example
 * <Refresh className='mdi mdi-refresh' outline color='primary'/>
 */
const Refresh = memo(({ tag: Tag, ...props }) => {
    const spordleTable = useSpordleTable();
    const id = props.id ? spordleTable.generateId('refresh_button', props.id) : spordleTable.generateId('refresh_button');
    const intl = useIntl();
    return (
    // @ts-ignore
    jsx(Tag, { "data-testid": 'refresh-button', ...props, type: "button", id: id, className: stringBuilder('mdi mdi-refresh', props.className), onClick: () => { spordleTable.refreshTable(); }, "aria-label": getMessage('refreshButton.ariaLabel', intl.locale) }));
});
Refresh.displayName = 'Refresh';
Refresh.defaultProps = {
    tag: Button,
    outline: true,
    color: 'primary',
};
const Refresh$1 = Refresh;export{Refresh$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/