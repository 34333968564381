import { useState, useEffect, useCallback, useRef } from 'react';
import { Spinner } from 'reactstrap';
import PostSingle from './PostSingle';

const PostsList = ({ editPost, ...props }) => {
    const LIMIT = 5;

    const [ fetchInfo, setFetchInfo ] = useState({
        canFetch: true,
        isFetching: false,
        offset: 0,
        posts: [],
    });

    const observer = useRef();

    const observedPostRef = useCallback((node) => {
        if(fetchInfo.isFetching)return
        if(observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(([ entry ]) => {
            if(entry.isIntersecting && fetchInfo.canFetch && !fetchInfo.isFetching){
                fetchPublications();
                observer.current.disconnect();
            }
        }, { root: null, rootMargin: '0px', treshold: 1 });

        if(node) observer.current.observe(node);

    }, [ fetchInfo.isFetching, fetchInfo.canFetch ]);

    useEffect(() => {
        fetchPublications();
    }, []);

    const fetchPublications = useCallback(() => {
        setFetchInfo((prevState) => {
            return { ...prevState, isFetching: true }
        });

        fetch(`https://jsonplaceholder.typicode.com/posts?_start=${fetchInfo.offset}&_limit=${LIMIT}`) //regarder axios-context-legendary portal
            .then((response) => response.json())
            .then((data) => {
                setFetchInfo((prevState) => {
                    let prevOffset = prevState.offset;
                    const canFetch = data.length >= LIMIT;
                    prevState.posts.pushArray(data);

                    return {
                        ...prevState,
                        offset: prevOffset += LIMIT,
                        isFetching: false,
                        canFetch: canFetch,
                    }
                });
            }).catch((e) => {
                console.error('Fetch publication operator error : ' + e);
            });
    });

    //placeholder ads
    const renderAd = () => {
        return (
            <div className="py-3 mb-3 text-center">
                <img src="https://via.placeholder.com/300x250?text=300x250" />
            </div>
        );
    }

    return (
        <>
            { fetchInfo.posts?.map((post, i) => (
                <div key={post.id} ref={i === fetchInfo.posts.length - 1 ? observedPostRef : undefined}>
                    {(i % 5 == 0 && i > 0) && renderAd()} {/* Could change that later */}
                    <PostSingle editPost={editPost} postData={post} />
                </div>
            ))}
            { !fetchInfo.canFetch &&
                <div className="text-center my-4">
                    <span className="text-muted">You have reached the end of the publications.</span>
                </div>
            }
            { fetchInfo.isFetching && fetchInfo.canFetch &&
                <div className="text-center">
                    <Spinner type="grow" color="primary" />
                </div>
            }
        </>
    );
}

export default PostsList;