import { useContext, useState } from 'react';
import {
    ModalHeader,
    ModalBody,
    Button,
    ModalFooter,
    Alert,
    Collapse,
    Fade,
    FormGroup,
    Label
} from "reactstrap";
import Translate from '@spordle/intl-elements';
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';
import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { AccountsContext } from '../../../../../../../../contexts/AccountsContext';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import Required from '../../../../../../../../components/formik/Required';
import { FormikTextArea } from '@spordle/formik-elements';
import { OnlineStoreContext } from '../../../../../../../../contexts/OnlineStoreContext';

const RegistrationWaitingListReturn = (props) => {

    const registrationContext = useContext(RegistrationContext);
    const accountsContext = useContext(AccountsContext);
    const auth = useContext(AuthContext);
    const onlineStoreContext = useContext(OnlineStoreContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null); // for second step
    const [ activeStep, setActiveStep ] = useState(1);

    const continueRegistration = () => {
        setIsLoading(true)
        // getting the meta-members to simulate the existintg participant flow
        accountsContext.getIdentityMetaMembers(auth.userData.userName, { is_primary: 0 })
            .then(async(metaMembers) => {
                const metaMember = metaMembers.find((meta) => meta.members?.[0]?.member_id === registrationContext.state.currentCart.waitingListReturnData.memberId)
                await registrationContext.addMember(metaMember)
                const cartId = await registrationContext.addMemberItem(
                    registrationContext.state.currentCart.waitingListReturnData.registrationFeeId,
                    'REGISTRATION',
                    metaMember.members[0].member_id,
                    metaMember.meta_member_id,
                    undefined,
                    registrationContext.state.currentCart.waitingListReturnData.waitingListItemId,
                )
                await registrationContext.refreshCart(cartId)
                const selectedRegistrationFee = await onlineStoreContext.getOnlineStoreItems(registrationContext.currentOnlineStore.store.online_store_id, true, { member_id: metaMember.members[0].member_id })
                    .then((items) => {
                        return items.registration_fees.find((rFee) => rFee.registration_fee_id === registrationContext.state.currentCart.waitingListReturnData.registrationFeeId)
                    })
                await registrationContext.setupRegistration(registrationContext.state.currentCart.waitingListReturnData.registrationFeeId, selectedRegistrationFee)
                registrationContext.goToView(registrationContext.views.questions)
            })
            .catch((errors) => {
                if(!AxiosIsCancelled(errors.message)){
                    if(Array.isArray(errors)){
                        console.error(errors[0].message);
                        setError(<DisplayI18n field='message' defaultValue={errors[0].message} i18n={errors[0].i18n} />)
                    }else{
                        console.error(errors.message);
                        setError(<DisplayI18n field='message' defaultValue={errors.message} i18n={errors.i18n} />)
                    }
                }
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader wrapTag='header' tag="div" className="bg-dark text-light">
                {activeStep == 1 ?
                    <Translate id='participant.registrationModal.views.waitingListReturn.title' />
                    :
                    <Translate id='participant.registrationModal.views.waitingListReturn.header' />
                }
            </ModalHeader>

            <div className='bg-light'>
                <Collapse isOpen={activeStep == 1}>
                    <Fade in={activeStep == 1}>
                        <ModalBody className="py-4 bg-light">
                            <div className="text-center">
                                <i className={`mdi mdi-information-outline text-info`} style={{ fontSize: '75px' }} />
                                <div className="h4 font-weight-bold mb-4"><Translate id='participant.registrationModal.views.waitingListReturn.welcomeBack' /></div>
                                <div className='mb-3'>
                                    <div>
                                        <Translate
                                            id='participant.registrationModal.views.waitingListReturn.selected'
                                            values={{
                                                registration:
                                                    <b>
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={registrationContext.state.currentCart.waitingListReturnData.registrationName}
                                                            i18n={JSON.parse(registrationContext.state.currentCart.waitingListReturnData.registrationI18n)}
                                                        />
                                                    </b>,
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Translate
                                            id='participant.registrationModal.views.waitingListReturn.continueRegistration'
                                            values={{
                                                confirm: <b><Translate id='misc.confirm' /></b>,
                                                cancel: <b><Translate id='misc.cancel' /></b>,
                                                firstName: <b>{registrationContext.state.currentCart.waitingListReturnData.firstName}</b>,
                                                lastName: <b>{registrationContext.state.currentCart.waitingListReturnData.lastName}</b>,
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                            {error &&
                                <Alert color='danger' toggle={() => setError(null)} className='mt-3'>
                                    {error}
                                </Alert>
                            }
                        </ModalBody>
                        <ModalFooter className='bg-light'>
                            <Button onClick={() => continueRegistration()} color='info'>
                                <Translate id='misc.confirm' />
                            </Button>
                            <Button color='info' outline onClick={() => setActiveStep(2)}>
                                <Translate id='misc.cancel' />
                            </Button>
                        </ModalFooter>
                    </Fade>
                </Collapse>

                <Collapse isOpen={activeStep == 2}>
                    <Fade in={activeStep == 2}>
                        <Formik
                            initialValues={{
                                reason: '',
                            }}
                            validationSchema={object().shape({
                                reason: string().required(<Translate id='participant.registrationModal.views.waitingListReturn.reason.required' />),
                            })}
                            onSubmit={(values) => {
                                setIsLoading(true);
                                accountsContext.cancelWaitingListItem(auth.userData.userName, registrationContext.state.currentCart.waitingListReturnData.waitingListItemId, {
                                    cancellation_reason: values.reason,
                                })
                                    .then(async() => {
                                        await auth.getWaitingListNotifications(auth.userData.userName);
                                        setIsLoading(false);
                                        registrationContext.toggleModal();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                            setIsLoading(false)
                                        }
                                    })
                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <ModalBody className="py-4 bg-light">
                                        <FormGroup>
                                            <Label for='reason'><Translate id='participant.registrationModal.views.waitingListReturn.reason' /> <Required /></Label>
                                            <FormikTextArea id='reason' name='reason' rows={3} />
                                        </FormGroup>

                                        {error &&
                                            <Alert color='danger' toggle={() => setError(null)}>
                                                {error}
                                            </Alert>
                                        }
                                    </ModalBody>
                                    <ModalFooter className='bg-light justify-content-between'>
                                        <Button color='info' outline onClick={() => setActiveStep(1)} type='button'>
                                            <Translate id='misc.previous' />
                                        </Button>
                                        <Button color='info' type='submit'>
                                            <Translate id='misc.confirm' />
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </Fade>
                </Collapse>
            </div>
        </OverlayLoader>
    );
}

export default RegistrationWaitingListReturn;