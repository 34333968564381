/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import PropTypes from'prop-types';import {Input}from'reactstrap';import {FormattedMessage}from'react-intl';import useTranslate from'./useTranslate.js';/**
 * Create an input with the placeholder already translated
 * @prop {string} placeholder String index to translate
 * @example
 * <InputIntl placeholder='login.form.label.title'/>
 * @copyright Spordle
 */
const InputIntl = ({ placeholder, ...props }) => (
// @ts-ignore
jsx(FormattedMessage, { id: placeholder, message: useTranslate({ id: placeholder }), children: (message) => jsx(Input, { ...props, placeholder: message.toString() }) }));
InputIntl.propTypes = {
    placeholder: PropTypes.string.isRequired,
};
InputIntl.displayName = 'InputIntl';export{InputIntl as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/