import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserDisplayContainer from './UserDisplayContainer';
import UserDisplayTitle from './UserDisplayTitle';
import UserDisplaySubtitle from './UserDisplaySubtitle';
import { stringBuilder } from "@spordle/helpers";

/**
 * @param {bool} [card] Displays in card style.
 * @param {bool} [hover] add hover effect for cards.
 * @param {string} [to] value param in <Link to={}>.
 * @param {React.ComponentType} [tag='div'] The desired htmlElement of the container. This param is ignored if the param "to" has a value
 * @param {string} [className]
 */
const UserDisplay = ({
    tag: Tag,
    className,
    hover,
    card,
    to,
    ...attributes
}) => {

    const classNames = stringBuilder('userDisplay', { 'userDisplay-hover': hover, 'userDisplay-card': card }, className);

    return (
        (to ?
            <Link to={to} {...attributes} className={classNames} />
            :
            <Tag {...attributes} className={classNames} />
        )
    );
};

UserDisplay.Container = UserDisplayContainer;
UserDisplay.Title = UserDisplayTitle;
UserDisplay.Subtitle = UserDisplaySubtitle;
export default UserDisplay;

UserDisplay.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
    card: PropTypes.bool,
    hover: PropTypes.bool, // currently only works with card prop
    to: PropTypes.string,
};
UserDisplay.defaultProps = {
    tag: 'div',
};