import axios from 'axios';
import { activeCalls } from './CancellableAPI';
import { history } from '../helpers/history';
import { getVersionHeaders } from '../helpers/helper';
import { fire } from '@spordle/toasts';
import { addBreadcrumb, captureException, Severity, withScope } from '@sentry/react';
import { parseUrl } from 'query-string';
import { getApiBaseUrlMultiSport } from '../helpers/getReferer';

export var currentMaintenanceUrl = '';

const DEV_URL = "https://pub-proxy.id.dev.spordle.dev/v1";
const INT_URL = "https://pub-proxy.id.int.spordle.dev/v1";
const STAGE_URL = getApiBaseUrlMultiSport(true) || "https://pub-proxy.id.stage.spordle.dev/v1";
const HCSTAGE_URL = "https://pub-proxy.id.hcstage.spordle.dev/v1";
const UAT_URL = "https://pub-proxy.id.uat.spordle.dev/v1";
const TRAINING_URL = "https://pub-proxy.training.hcr.spordle.com/v1";
const PROD_URL = getApiBaseUrlMultiSport(true) || `https://pub-proxy.hcr.spordle.com/v1`;

var current_url = DEV_URL;
if(process.env.REACT_APP_ENVIRONMENT === 'int')
    current_url = INT_URL
else if(process.env.REACT_APP_ENVIRONMENT === 'stage')
    current_url = STAGE_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'hcstage')
    current_url = HCSTAGE_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'uat')
    current_url = UAT_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'training')
    current_url = TRAINING_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'prod')
    current_url = PROD_URL;


const CancelToken = axios.CancelToken;
const API_SPORDLE = axios.create({
    baseURL: current_url,
    headers: {
        common: {
            ...getVersionHeaders(),
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': 'fr;en', // All supported languages
        },
        patch: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        put: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    },
    validateStatus: (status) => {
        if([ 401, 418, 503 ].indexOf(status) !== -1){
            switch (status){
                case 418:// I'm a teapot
                    addBreadcrumb({
                        type: 'info',
                        category: "version",
                        message: "New version detected",
                    });
                    fire({ theme: 'warning', msg: 'warning.versionOverlapping', timeout: 60, horizontalLoad: true, id: "I'm a teapot", showCloseButton: false });
                case 503: // Maintenance
                    if(!currentMaintenanceUrl)
                        currentMaintenanceUrl = location.href;
                    history.push('/maintenance');
                    break;
                default:
                    break;
            }
        }
        return (status >= 200 && status < 300) || status === 400; // default
    },
});

API_SPORDLE.interceptors.request.use(function(config){
    const pathUrl = parseUrl(config.url).url;
    if(/%/.test(pathUrl)){ // % means that there is a querystring in the url
        withScope(function(scope){
            scope.setExtra('query-url', pathUrl);
            scope.setLevel(Severity.Debug);
            captureException(new Error("Query string url"));
        });
    }
    config.cancelToken = new CancelToken(function executor(c){
        // An executor function receives a cancel function as a parameter
        activeCalls.push(c);
    });
    return config;
});

API_SPORDLE.interceptors.response.use(null, (error) => {
    switch (error.response.status){
        case 403:
            return Promise.reject(new Error("forbidden"));
        case 401:
            return Promise.reject(new Error("unauthorized"));
        case 418:
            return Promise.reject(new Error("teapot"));
        default:
            break;
    }

    return Promise.reject(error);
});

export default API_SPORDLE;
export{ current_url };