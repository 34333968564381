import Translate from '@spordle/intl-elements';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const Error404 = (props) => {
    const intl = useIntl();

    useEffect(() => {
        document.title = intl.formatMessage({ id: 'error404.helmet.title' });
        document.querySelector('meta[name="description"]').setAttribute("content", intl.formatMessage({ id: 'error404.helmet.description' }));
    }, [ intl.locale ])

    return (
        <section className="section-container">
            <h1 className="display-2 text-shadow text-primary font-weight-bold">404</h1>
            <h1><Translate id='error404.page.name' /></h1>
            <p className="mb-4 text-muted">
                <Translate id='error404.content.p' />
            </p>
            <Link className="btn btn-primary mr-2" to='/'><i className="mdi mdi-arrow-left" /> <Translate id='error404.btn.back' /></Link>
        </section>
    );
}

export default Error404;