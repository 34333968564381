import Translate from '@spordle/intl-elements';
import RegistrationFeesRow from './RegistrationFeesRow';

const RegistrationFeesTable = ({ fees, ...props }) => (
    <>
        <thead>
            <tr>
                <th className="cell-sm align-middle text-center"><Translate id='misc.more' /></th>
                <th className="align-middle text-nowrap"><Translate id='storeSingle.section.registration.category' /></th>
                <th className="align-middle text-nowrap"><Translate id='storeSingle.section.registration.memberType' /></th>
                <th className="align-middle text-nowrap text-right"><Translate id='storeSingle.section.registration.registrationFee' /></th>
            </tr>
        </thead>
        <tbody>
            {fees
                .filter((fee) => fee.active == 1)
                .sort((fee1, fee2) => parseInt(fee1.display_order || '0') - parseInt(fee2.display_order || '0'))
                .map((fee) => <RegistrationFeesRow key={fee.registration_fee_id} fee={fee} />)}
        </tbody>
    </>

);

export default RegistrationFeesTable;