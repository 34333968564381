import { useContext, useEffect } from 'react';
import {
    ModalHeader,
    ModalBody
} from "reactstrap";

import Translate from '@spordle/intl-elements';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { DisplayI18n, displayI18n } from '../../../../../../../helpers/i18nHelper';
import ImgPill from '../../../../../../../components/visual/imgPill/ImgPill';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import RequestCall from '@spordle/request-calls';
import { AuthContext } from '../../../../../../../contexts/AuthContext';
import getAccountUrl from '../../../../../../../helpers/getExternalUrl';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { fail } from '@spordle/toasts';

const RegistrationConfirmation = (props) => {
    const i18nContext = useContext(I18nContext);
    const orgContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext);
    const registrationContext = useContext(RegistrationContext);

    useEffect(() => {
        registrationContext.setSkipToPayment(false);
    }, []);

    return (
        <>
            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                <Translate id='participant.registrationModal.views.registrationConfirm.title' />
            </ModalHeader>
            <ModalBody className="py-4 bg-light">
                <div className="text-center">
                    <div className="d-flex justify-content-center mb-4">
                        <div className="d-inline-block position-relative">
                            <ImgPill
                                abbr={orgContext.abbreviation}
                                src={orgContext.logo?.full_path}
                                alt={orgContext.organisation_name}
                                filePos={orgContext.logo?.file_position}
                                size="xl"
                            />
                            <RequestCall call={() => orgContext.getOrganizationBranch().catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })}
                            >
                                {(snapshot) => {
                                    if(!snapshot.data)
                                        return null;
                                    return (
                                        <ImgPill
                                            abbr={snapshot.data?.organisation_name}
                                            src={snapshot.data?.logo?.full_path}
                                            alt={snapshot.data?.organisation_name}
                                            filePos={snapshot.data?.file_position}
                                            size="xs"
                                            className="position-absolute left-0 bottom-0"
                                        />
                                    )
                                }}
                            </RequestCall>
                        </div>
                    </div>
                    <div className="h4 font-weight-bold mb-4"><Translate id='participant.registrationModal.views.registrationConfirm.thankYou' /></div>
                    <div className="font-weight-bold text-info">
                        <Translate id='participant.registrationModal.views.registrationConfirm.receiptNumber' />
                        <a href={`${getAccountUrl(false, false)}purchases/invoice/${registrationContext.state.currentCart.invoiceNumber}`} className="ml-1 text-info" target='_blank' rel='noopener noreferrer'>
                            #{registrationContext.state.currentCart.invoiceNumber}
                        </a>
                    </div>
                    <div><Translate id='participant.registrationModal.views.registrationConfirm.emailSentTo' /></div>
                    <div className="font-weight-bold mb-4">{authContext.userData?.attributes?.email}</div>
                    {registrationContext.state.currentCart.preAuthorizedPayment &&
                        <div className='mb-4 font-weight-bold'><Translate id='participant.registrationModal.views.registrationConfirm.preAuthorizedPayment' /></div>
                    }

                    <p
                        className="mb-3"
                        dangerouslySetInnerHTML={{
                            __html: displayI18n('confirmation_message_email', registrationContext.currentOnlineStore.store.i18n, registrationContext.currentOnlineStore.store.confirmation_message_email, i18nContext.getGenericLocale())?.replace(/href/g, "rel='noopener noreferrer' target='_blank' href"),
                        }}
                    />
                </div>
            </ModalBody>
        </>
    );
}

export default RegistrationConfirmation;