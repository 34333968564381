import { useContext, useEffect, useState } from 'react';
import PostsList from './components/PostsList';
import PostModal from './components/PostModal/PostModal';
import useQuery from '../../../../components/customHooks/useQuery';
import PostSingle from './components/PostSingle';
import Translate from '@spordle/intl-elements';
import ImgPill from '../../../../components/visual/imgPill/ImgPill';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';

const Posts = ({ ...props }) => {
    const [ modalData, setModalData ] = useState({
        isOpen: false,
        isEdit: false,
        postData: undefined,
    });
    const orgContext = useContext(OrganizationContext);

    const postId = useQuery().get('id');

    /**
     * @description Used to determine state of PostModal (whether it's editing or posting a new publication)
     * @param {bool} isEditing isEditing = true means it's editing. Else it's a new post.
     * @param {object} post Post Data
     */
    const toggleModal = (isEditing, post) => {
        setModalData((prev) => {
            return {
                isOpen: !prev.isOpen,
                isEdit: isEditing || false,
                postData: post ?? prev.postData,
            }
        });
    };

    const [ postData, setPostData ] = useState(undefined);

    const renderTitle = () => {
        return (!postId ? <h1 className="section-main-title">Publications</h1> : <h2 className="section-main-title">More publications</h2>);
    }

    postId && useEffect(() => {
        fetch(`https://jsonplaceholder.typicode.com/posts?id=${postId}`) //regarder axios-context-legendary portal
            .then((response) => response.json())
            .then((data) => {
                if(data && data.length) setPostData(data[0]);
            }).catch((e) => {
                console.error('Fetch publication operator error : ' + e);
            });
    }, []);

    return (
        <>
            { postId && postData &&
                <PostSingle highlighted titleTag="h1" postData={postData} editPost={toggleModal} className="section-container" />
            }
            <section className="section-container">
                <header>
                    {renderTitle()}
                    <form className="post-publication-form">
                        <div className="d-flex align-items-center">
                            <ImgPill
                                size="sm"
                                abbr={orgContext.abbreviation}
                                src={orgContext.logo?.full_path}
                                alt={`Logo ${orgContext.abbreviation}`}
                            />
                            <button type="button" onClick={() => toggleModal(false)} className="post-publication-preview">
                                <span><Translate id='post.btn.newPost' /></span> <i className="ml-auto btn-sm btn-primary mdi mdi-plus" />
                            </button>
                        </div>
                    </form>
                </header>
                <PostModal isEdit={modalData.isEdit} postData={modalData.postData} isOpen={modalData.isOpen} toggleModal={toggleModal} />
                <PostsList editPost={toggleModal} />
            </section>
        </>
    );
}

export default Posts;