import { FormikCheckedButton, FormikGroup } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import {
    Alert,
    Button, Collapse, ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import { bool, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import Required from '../../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
// contexts
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { DisplayI18n, displayI18n } from '../../../../../../../helpers/i18nHelper';
import { WaiverFields, waiverNotSigned } from '../../../../../../../helpers/waiversHelper';
import RegistrationParticipantCard from './RegistrationParticipantCard';

const RegistrationTerms = (props) => {
    const i18nContext = useContext(I18nContext);
    const registrationContext = useContext(RegistrationContext);

    const [ activeIndex, setActiveIndex ] = useState(0);

    const goToNextView = () => {
        if(registrationContext.state.currentCart.termsWaiverMode){
            registrationContext.goToView(registrationContext.views.moreParticipant)
        }else{
            registrationContext.goToView(registrationContext.views.gateway)
        }
    }

    const displayWaiver = () => (
        registrationContext.state.currentCart.termsWaiverMode ?
            <>
                <div className="text-info h4 mb-0 font-weight-bold">{registrationContext.getCurrentMember().waivers[activeIndex].organisation?.organisation_name}</div>
                <div className="font-weight-bold h5 mb-3"><DisplayI18n field="name" i18n={registrationContext.getCurrentMember().waivers[activeIndex].i18n} defaultValue={registrationContext.getCurrentMember().waivers[activeIndex].name} /></div>
                <div dangerouslySetInnerHTML={{
                    __html: displayI18n('description', registrationContext.getCurrentMember().waivers[activeIndex].i18n, registrationContext.getCurrentMember().waivers[activeIndex].description, i18nContext.getGenericLocale())?.replace(/href/g, "rel='noopener noreferrer' target='_blank' href"),
                }}
                />
            </>
            :
            <div dangerouslySetInnerHTML={{
                __html: displayI18n('description', registrationContext.terms[activeIndex].term_and_condition.i18n, registrationContext.terms[activeIndex].term_and_condition.description, i18nContext.getGenericLocale())?.replace(/href/g, "rel='noopener noreferrer' target='_blank' href"),
            }}
            />
    )

    const checkout = () => {
        // if the store allows credit card, go with that, or go with the first payment method in display order otherwise
        const creditCardMethod = registrationContext.currentOnlineStore.store?.payment_methods.find((pM) => pM.code === 'CREDITCARD');
        const firstMethod = registrationContext.currentOnlineStore.store?.payment_methods.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))[0]
        const paymentMethod = creditCardMethod || firstMethod

        if(registrationContext.state.currentCart.cartInfo.cart_summary.grand_total === 0 && paymentMethod){ // skip payment methods, checkout
            return registrationContext.checkout(paymentMethod.code) // sets invoice number and sutToken
                .then((invoiceData) => {
                    registrationContext.setIsCheckedOut(true);
                    if(invoiceData.waiting_list_number?.length > 0){
                        registrationContext.goToView(registrationContext.views.waitingListCheckoutError);
                    }else{
                        registrationContext.goToView(registrationContext.views.confirmation)
                    }
                })
        }
        goToNextView()
        return Promise.resolve()
    }

    const goToPreviousView = () => {
        if(registrationContext.state.currentCart.termsWaiverMode){ // waivers
            if(activeIndex === 0){ // first waiver
                registrationContext.goToView(registrationContext.views.items, true)
            }else{ // not first waiver, go back 1
                setActiveIndex(activeIndex - 1 < 0 ? 0 : activeIndex - 1)// Making sure we don't go under 0
            }
        }else{ // terms
            registrationContext.goToView(registrationContext.views.modalities, true)
        }
    }

    return (
        <Formik
            key={activeIndex}
            initialValues={{
                answer: '', // for waivers
                checked: false, // for terms
                dataId: registrationContext.state.currentCart.termsWaiverMode ? registrationContext.getCurrentMember().waivers[activeIndex].waiver_id : registrationContext.terms[activeIndex].term_and_condition.term_and_condition_id,
            }}
            validationSchema={object().shape({
                answer: string().when('doesNotMatter', {
                    is: () => registrationContext.state.currentCart.termsWaiverMode,
                    then: string().required(<Translate id='form.fields.waiver.required' />),
                }),
                checked: bool().oneOf(
                    [
                        true,
                        // if is waiver, let the checked value be false, but when is terms, validate for only true
                        ...!registrationContext.state.currentCart.termsWaiverMode ? [] : [ false ],
                    ],
                    <Translate id='participant.registrationModal.views.registrationTerms.required' />,
                ),
                dataId: string().required(),
            })}
            onSubmit={async(values, { setSubmitting, setStatus }) => {
                try{
                    setStatus();
                    if(registrationContext.state.currentCart.termsWaiverMode){
                        await registrationContext.updateCartWaivers(
                            values.dataId,
                            i18nContext.getGenericLocale(),
                            values.answer || waiverNotSigned,
                        )
                            .then(async() => {
                                if(activeIndex + 1 === registrationContext.getCurrentMember().waivers.length){
                                    await registrationContext.refreshCart();
                                    setSubmitting(false)
                                    goToNextView();
                                }else{
                                    setSubmitting(false);
                                    setActiveIndex(activeIndex + 1 < registrationContext.getCurrentMember().waivers.length ? activeIndex + 1 : registrationContext.getCurrentMember().waivers.length);// making sure we don't go over the data.length
                                }
                            })
                    }else if(!registrationContext.state.currentCart.cartInfo.term_and_condition){
                        await registrationContext.updateCartTerms(values.dataId, i18nContext.getGenericLocale())
                            .then(async() => {
                                if(activeIndex + 1 === registrationContext.terms.length){
                                    await registrationContext.refreshCart()
                                    await checkout();
                                }else{
                                    setSubmitting(false);
                                    setActiveIndex(activeIndex + 1 < registrationContext.terms.length ? activeIndex + 1 : registrationContext.terms.length - 1);// making sure we don't go over the data.length
                                }
                            })
                    }else{
                        await checkout();
                    }
                }catch(error){
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message);
                        setSubmitting(false);
                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                    }
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                            {registrationContext.state.currentCart.termsWaiverMode ?
                                <Translate id='participant.registrationModal.views.registrationWaivers.title' />
                                :
                                <Translate id='participant.registrationModal.views.registrationTerms.title' />
                            }
                        </ModalHeader>
                        <ModalBody key={activeIndex} className="py-4 bg-light">
                            <div className={registrationContext.state.currentCart.termsWaiverMode ? 'registration-container' : ''}>
                                {registrationContext.state.currentCart.termsWaiverMode && <RegistrationParticipantCard participant={registrationContext.getCurrentMember()} />}

                                {registrationContext.state.currentCart.termsWaiverMode ?
                                    <div className="h4 font-weight-bold">
                                        <Translate id='participant.registrationModal.views.registrationWaivers.title' /> ({activeIndex + 1} <Translate id='participant.registrationModal.views.registrationWaivers.of' /> {registrationContext.getCurrentMember().waivers.length})
                                    </div>
                                    :
                                    <div className="h4 font-weight-bold">
                                        <Translate id='participant.registrationModal.views.registrationTerms.title' /> {/*<DisplayI18n field={'name'} defaultValue={organizationContext.state.organisation_name} i18n={organizationContext.state.i18n}/>*/}
                                    </div>
                                }

                                <div className="max-vh-50 bg-white overflow-auto mb-3 p-2 bg-light border rounded">
                                    {displayWaiver()}
                                </div>

                                <div className='mb-3'>
                                    {registrationContext.state.currentCart.termsWaiverMode ?
                                        <WaiverFields waiver={registrationContext.getCurrentMember().waivers[activeIndex]} fieldName='answer' allowSkip={registrationContext.getCurrentMember().waivers[activeIndex].mandatory == 0} />
                                        :
                                        <FormikGroup name='checked' rowProps={{ className: 'mx-0' }}>
                                            <FormikCheckedButton
                                                id='checked' name='checked'
                                                label={
                                                    <>
                                                        <Translate id='participant.registrationModal.views.registrationTerms.accept' />
                                                        <Required className='ml-1' />
                                                    </>
                                                }
                                                translateLabel={false}
                                            />
                                        </FormikGroup>
                                    }
                                </div>

                                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                    <Alert color='danger' toggle={() => formik.setStatus()}>{formik.status}</Alert>
                                </Collapse>
                            </div>
                        </ModalBody>
                        <ModalFooter tag={'footer'} className="bg-light">
                            {/* we do not go back to address because it should already have been linked, user must go to myAccount to change address */}
                            <Button id='registrationTermsPreviousButton' onClick={goToPreviousView} color="default" outline className="mr-auto"><Translate id='misc.previous' /></Button>
                            <Button id='registrationTermsSubmitButton' type='submit' color="info"><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationTerms;