import { FormikError } from '@spordle/formik-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import {
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert
} from "reactstrap";
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { object, number, string } from 'yup';
import { stringBuilder } from "@spordle/helpers";

// contexts
import { CartsContext } from '../../../../../../../contexts/CartsContext';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import RegistrationParticipantCard from './RegistrationParticipantCard';
import noImgItem from '../../../../../../../assets/images/placeholders/NoImage_Item.png'
import ImgPill from '../../../../../../../components/visual/imgPill/ImgPill';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { sendGAEvent } from '@spordle/ga4';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';

const RegistrationSelectItems = (props) => {

    const cartsContext = useContext(CartsContext)
    const registrationContext = useContext(RegistrationContext)

    const [ relatedItems ] = useState(registrationContext.getCurrentMember().mandatoryItems || []);
    const [ otherFees ] = useState(registrationContext.getCurrentMember().otherItems || []);

    useEffect(() => {
        const gaItems = relatedItems.map((item) => ({
            item_id: item.other_fee_id,
            item_name: item.fee.name,
            currency: 'CAD',
            price: item.fee.amount / 100,
        }));
        gaItems.pushArray(otherFees.map((item) => ({
            item_id: item.other_fee_id,
            item_name: item.fee.name,
            currency: 'CAD',
            price: item.fee.amount / 100,
        })));
        sendGAEvent('view_item_list', {
            item_list_id: "other_fees",
            item_list_name: "Other fees",
            items: gaItems,
        });
    }, []);

    const getInitialValues = () => {
        const temp = {};
        // forEach on every related_items of the selected registration item in the cart
        relatedItems?.forEach((item, index) => {
            temp[`mandatory_${index}`] = {
                amount: parseInt(registrationContext.state.currentCart.cartInfo.cart_detail?.find((row) => row.item_id === item.other_fee_id && row.member.member_id === registrationContext.getCurrentMember().members[0].member_id)?.quantity) || 1,
                itemId: item.other_fee_id,

                // no use for it except for displaying the total price at the bottom
                price: item.fee.amount,
            }
        })
        otherFees?.forEach((item, index) => {
            temp[`optionnal_${index}`] = {
                amount: parseInt(registrationContext.state.currentCart.cartInfo.cart_detail?.find((row) => row.item_id === item.other_fee_id && row.member.member_id === registrationContext.getCurrentMember().members[0].member_id)?.quantity) || 0,
                itemId: item.other_fee_id,

                // no use for it except for displaying the total price at the bottom
                price: item.fee.amount,
            }
        })
        return temp
    }

    const getValidationSchema = () => {
        const temp = {};
        relatedItems?.forEach((item, index) => {
            temp[`mandatory_${index}`] = object().shape({
                amount: number().min(1, ''),
                itemId: string().required(),
            })
        })
        otherFees?.forEach((item, index) => {
            if(registrationContext.getCurrentMember().skipRegistrations && index === 0){
                temp[`optionnal_${index}`] = object().shape({
                    amount: number().min(0, '').test({
                        name: 'atLeastOneTest',
                        message: <Translate id='participant.registrationModal.views.registrationSelectItems.atLeastOne' />,
                        test: function(){
                            return !Object.keys(this.from[this.from.length - 1].value).every((key) => this.from[this.from.length - 1].value[key].amount === 0)
                        },
                    }),
                    itemId: string().required(),
                })
            }else{
                temp[`optionnal_${index}`] = object().shape({
                    amount: number().min(0, ''),
                    itemId: string().required(),
                })
            }
        })
        return object().shape(temp)
    }

    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={getValidationSchema()}
                onSubmit={async(values, { setStatus, setSubmitting }) => {
                    try{
                        //create the items in the cart
                        let cartId = cartsContext.state.cachedCart.shopping_cart_id;
                        for(const createKey in values){
                            if(values[createKey].amount > 0 && createKey.indexOf('optionnal') !== -1 && !registrationContext.state.currentCart.cartInfo.cart_detail?.find((r) => r.item_id === values[createKey].itemId && r.member.member_id === registrationContext.getCurrentMember().members[0].member_id)){
                                cartId = await registrationContext.addMemberItem(
                                    values[createKey].itemId,
                                    'OTHER',
                                    registrationContext.getCurrentMember().members[0].member_id,
                                    null,
                                    values[createKey].amount,
                                )
                            }
                        }

                        await registrationContext.refreshCart(cartId)
                            .then(async(cart) => {
                                // check if we need to patch the quantity
                                for(const updateKey in values){
                                    const cartItem = cart.cart_detail.find((r) => r.item_id === values[updateKey].itemId && r.member.member_id === registrationContext.getCurrentMember().members[0].member_id);
                                    if(cartItem){
                                        // required items
                                        if(updateKey.indexOf('mandatory') !== -1 && values[updateKey].amount !== parseInt(cartItem?.quantity)){
                                            await registrationContext.updateCartItemQuantity(cartItem.row_id, values[updateKey].amount)
                                        }else if(updateKey.indexOf('optionnal') !== -1 && values[updateKey].amount !== parseInt(cartItem?.quantity)){ // other items
                                            if(values[updateKey].amount > 0){
                                                await registrationContext.updateCartItemQuantity(cartItem.row_id, values[updateKey].amount)
                                            }else if(values[updateKey].amount === 0){
                                                await registrationContext.removeCartItem(cartItem.row_id)
                                            }
                                        }
                                    }
                                }

                                await registrationContext.refreshCart(cartId)
                                setSubmitting(false)
                                registrationContext.goToView(registrationContext.views.waivers)
                            })
                    }catch(errors){
                        // checking if errors is an array because the addItem call will return an array in case of failure, but not the other calls
                        // that specific call returns an array of errors because we need to show all the missing required fields, which are all separated in multiple errors
                        if(!AxiosIsCancelled(Array.isArray(errors) ? '' : errors.message)){
                            await registrationContext.handleError(errors, values.registrationId)
                                .catch(() => {
                                    setSubmitting(false);
                                    if(Array.isArray(errors)){
                                        console.error(errors[0].message);
                                        setStatus(<DisplayI18n field='message' defaultValue={errors[0].message} i18n={errors[0].i18n} />)
                                    }else{
                                        console.error(errors.message);
                                        setStatus(<DisplayI18n field='message' defaultValue={errors.message} i18n={errors.i18n} />)
                                    }
                                })
                        }
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                                <Translate id='participant.registrationModal.views.registrationSelectItems.title' />
                            </ModalHeader>
                            <ModalBody className="py-4 bg-light">
                                <div className="registration-container">
                                    <RegistrationParticipantCard participant={registrationContext.getCurrentMember()} />
                                    <div className="item-container">
                                        {relatedItems.length > 0 &&
                                            <>
                                                <div className="h4 font-weight-bold"><Translate id='participant.registrationModal.views.registrationSelectItems.mandatory' /></div>
                                                <div className="item-list-container">
                                                    <ul className="list-unstyled item-list mb-3">
                                                        {relatedItems.map((item, index) => {
                                                            return (
                                                                <li className="item mb-2" key={item.other_fee_id}>
                                                                    <div className="mr-2 mr-lg-3">
                                                                        <ImgPill
                                                                            className="rounded"
                                                                            src={item.image?.full_path}
                                                                            placeholder={noImgItem}
                                                                            alt={item.name}
                                                                            filePos={item.image?.file_position}
                                                                            size="sm"
                                                                        />
                                                                    </div>
                                                                    <div className="item-info flex-grow-1 align-self-md-center d-sm-flex align-items-sm-center">
                                                                        <div className="item-title mr-md-2 mr-lg-3"><DisplayI18n field='name' defaultValue={item.fee.name || item.name} i18n={item.fee.i18n} /></div>
                                                                        <div className="item-price ml-md-auto mr-md-2 mr-lg-3 mt-md-0">
                                                                            <Translate id='misc.price' />: {item.fee.amount / 100 > 0 ? <CurrencyFormat value={item.fee.amount / 100} /> : <Translate id='storeSingle.section.registration.fee.free' />}
                                                                        </div>
                                                                        <div className="flex-shrink-0">
                                                                            <button
                                                                                id={'registrationSelectItemsMandatoryItemsReduceQuantity-' + item.other_fee_id}
                                                                                className="btn btn-info item-number-btn" type='button'
                                                                                onClick={() => formik.setFieldValue(`mandatory_${index}[amount]`, formik.values[`mandatory_${index}`].amount - 1)}
                                                                                disabled={formik.values[`mandatory_${index}`].amount <= 1}
                                                                            >
                                                                                <i className="mdi mdi-minus" />
                                                                            </button>
                                                                            <span className={stringBuilder("item-number", { 'text-muted': formik.values[`mandatory_${index}`].amount <= 0 })}>
                                                                                {formik.values[`mandatory_${index}`].amount.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
                                                                            </span>
                                                                            <button
                                                                                id={'registrationSelectItemsMandatoryItemsIncreaseQuantity-' + item.other_fee_id}
                                                                                className="btn btn-info item-number-btn" type='button'
                                                                                onClick={() => formik.setFieldValue(`mandatory_${index}[amount]`, formik.values[`mandatory_${index}`].amount + 1)}
                                                                                disabled={formik.values[`mandatory_${index}`].amount === item.available_place}
                                                                            >
                                                                                <i className="mdi mdi-plus" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </>
                                        }

                                        {otherFees.length > 0 &&
                                            <>
                                                <div className="h4 font-weight-bold"><Translate id='participant.registrationModal.views.registrationSelectItems.optional' /></div>
                                                <div className="item-list-container">
                                                    <ul className="list-unstyled item-list mb-3">
                                                        {otherFees.map((item, index) => {
                                                            return (
                                                                <li className="item mb-2" key={item.other_fee_id}>
                                                                    <div className="mr-2 mr-lg-3">
                                                                        <ImgPill
                                                                            className="rounded"
                                                                            src={item.image?.full_path}
                                                                            placeholder={noImgItem}
                                                                            alt={item.name}
                                                                            filePos={item.image?.file_position}
                                                                            size="sm"
                                                                        />
                                                                    </div>
                                                                    <div className="item-info align-self-md-center align-items-sm-center">
                                                                        <div className="item-title mr-md-2 mr-lg-3"><DisplayI18n field='name' defaultValue={item.fee.name || item.name} i18n={item.fee.i18n} /></div>
                                                                        <div className="item-price ml-md-auto mr-md-2 mr-lg-3 mt-md-0">
                                                                            <Translate id='misc.price' />: {item.fee.amount / 100 > 0 ? <CurrencyFormat value={item.fee.amount / 100} /> : <Translate id='storeSingle.section.registration.fee.free' />}
                                                                        </div>
                                                                        <div className="flex-shrink-0">
                                                                            <button
                                                                                id={'registrationSelectItemsOptionnalItemsReduceQuantity-' + item.other_fee_id}
                                                                                className="btn btn-info item-number-btn" type='button'
                                                                                onClick={() => formik.setFieldValue(`optionnal_${index}[amount]`, formik.values[`optionnal_${index}`].amount - 1)}
                                                                                disabled={formik.values[`optionnal_${index}`].amount <= 0}
                                                                            >
                                                                                <i className="mdi mdi-minus" />
                                                                            </button>
                                                                            <span className={stringBuilder("item-number", { 'text-muted': formik.values[`optionnal_${index}`].amount <= 0 })}>
                                                                                {formik.values[`optionnal_${index}`].amount.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
                                                                            </span>
                                                                            <button
                                                                                id={'registrationSelectItemsOptionnalItemsIncreaseQuantity-' + item.other_fee_id}
                                                                                className="btn btn-info item-number-btn" type='button'
                                                                                onClick={() => formik.setFieldValue(`optionnal_${index}[amount]`, formik.values[`optionnal_${index}`].amount + 1)}
                                                                                disabled={formik.values[`optionnal_${index}`].amount === item.available_place}
                                                                            >
                                                                                <i className="mdi mdi-plus" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                <FormikError name='optionnal_0[amount]' />
                                            </>
                                        }
                                    </div>

                                    {formik.status &&
                                        <Alert color='danger' toggle={() => formik.setStatus()}>
                                            {formik.status}
                                        </Alert>
                                    }

                                    <div className="text-right mt-3">
                                        <span className="text-info font-weight-bold mr-2"><Translate id='participant.registrationModal.views.registrationSelectItems.selected' /> ({Object.keys(formik.values).reduce((number, key) => number += formik.values[key].amount, 0)})</span>
                                        <span className="text-muted font-weight-bold"><CurrencyFormat value={Object.keys(formik.values).reduce((price, key) => price += (formik.values[key].price / 100) * formik.values[key].amount, 0)} /></span>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter tag={'footer'} className="bg-light">
                                <Button id='registrationSelectItemsPreviousButton' onClick={() => registrationContext.goToView(registrationContext.views.position, true)} color="default" outline className="mr-auto"><Translate id='misc.previous' /></Button>
                                <Button id='registrationSelectItemsSubmitButton' type='submit' color="info"><Translate id='misc.next' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default RegistrationSelectItems;