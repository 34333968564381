import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import CustomModal from "../../../../../components/modal/CustomModal";

const PromptSharedStoreModal = ({ isOpen, toggle, promptInfo }) => {
    return (
        <CustomModal
            className="pointer-events-auto"
            size="sm"
            isOpen={isOpen}
            onClick={(e) => e.currentTarget == e.target && toggle()}
        >
            <div className="text-right">
                <button type="button" onClick={toggle} className="btn-circle"><i className="mdi mdi-close" /></button>
            </div>
            <div className="text-center">
                <div className="h3"><Translate id='storesList.shared.redirect.msg' /></div>
                {promptInfo.store?.organisation?.organisation_name ?
                    <div className="h4 mb-5"><DisplayI18n field='name' defaultValue={promptInfo.store?.organisation?.organisation_name} i18n={promptInfo.store?.organisation?.i18n} /></div>
                    :
                    <div className="h4 mb-5">
                        {/* fallback when there's no org name */}
                        <Translate id='storesList.shared.redirect.msg.no.org.name' />
                    </div>
                }
                <div className="d-flex">
                    <button
                        onClick={toggle}
                        type="button"
                        className="btn btn-default mr-2 w-50"
                    >
                        <Translate id='sidenav.btn.no' />
                    </button>
                    <a
                        className={`btn btn-primary w-50`}
                        href={promptInfo.link}
                        rel='noopener noreferrer'
                    >
                        <Translate id='sidenav.btn.yes' /> <i className="mdi mdi-arrow-right" />
                    </a>
                </div>
            </div>
        </CustomModal>
    )
}

export default PromptSharedStoreModal;