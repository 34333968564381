// I didn't want to add language variable in the same file that gabby was working in, cause you know, git
// so sue me
export default{
    // registration options
    "participant.registrationModal.views.registrationOptions.title"             : "Options d'inscription",
    "participant.registrationModal.views.registrationOptions.available"         : "Inscriptions disponibles",
    "participant.registrationModal.views.registrationOptions.available.none"    : "Aucune inscription disponible",
    "participant.registrationModal.views.registrationOptions.required"          : "Veuillez choisir une inscription",
    "participant.registrationModal.views.registrationOptions.early"             : "Frais hâtif",
    "participant.registrationModal.views.registrationOptions.late"              : "Frais tardif",
    "participant.registrationModal.views.registrationOptions.alreadyRegistered" : "Déjà inscrit(e)",
    "participant.registrationModal.views.registrationOptions.waitingList"       : "Liste d'attente",
    "participant.registrationModal.views.registrationOptions.waitingList.hint"  : "Cette inscription est pleine, mais une liste d'attente est disponible et vous pouvez vous y inscrire.",
    "participant.registrationModal.views.registrationOptions.waitingList.hint2" : "Si vous êtes sélectionné pour participer, vous recevrez un courriel vous invitant à revenir sur cette page pour compléter votre inscription.",
    "participant.registrationModal.views.registrationOptions.waitingList.hint3" : "Vous aurez l'option de refuser une invitation si vous changez d'avis.",
    "participant.registrationModal.views.registrationOptions.placesLeft"        : "{amount, plural, =0 {Aucune place disponible} one {# place disponible} other {# places disponibles}}",

    "participant.registrationModal.views.registrationOptions.outstandingBalance" : "Ce membre a une balance à payer pour l'organisation, il ou elle ne peut pas s'inscrire avant de régulariser sa situation avec l'organisation.",

    "participant.registrationModal.views.registrationOptions.none"          : "Aucune",
    "participant.registrationModal.views.registrationOptions.none.subtitle" : "Sauter la sélection d'inscription",

    // custom forms
    "participant.registrationModal.views.registrationCustomQuestions.title"         : "Questionnaire",
    "participant.registrationModal.views.registrationCustomQuestions.count"         : "Questionnaire(s) ({actual} / {max})",
    "participant.registrationModal.views.registrationCustomQuestions.required"      : "Ce champ est requis",
    "participant.registrationModal.views.registrationCustomQuestions.min"           : "Veuillez respecter la valeur minimum",
    "participant.registrationModal.views.registrationCustomQuestions.max"           : "Veuillez respecter la valeur maximum",
    "participant.registrationModal.views.registrationCustomQuestions.label.between" : "Entre",
    "participant.registrationModal.views.registrationCustomQuestions.label.and"     : "et",
    "participant.registrationModal.views.registrationCustomQuestions.label.min"     : "Minimum",
    "participant.registrationModal.views.registrationCustomQuestions.label.max"     : "Maximum",

    // other items
    "participant.registrationModal.views.registrationSelectItems.title"      : "Sélection d'articles",
    "participant.registrationModal.views.registrationSelectItems.mandatory"  : "Articles obligatoires",
    "participant.registrationModal.views.registrationSelectItems.optional"   : "Articles optionnels",
    "participant.registrationModal.views.registrationSelectItems.selected"   : "Articles sélectionnés",
    "participant.registrationModal.views.registrationSelectItems.atLeastOne" : "Au moins un article est requis", // when the user skips registrations

    // waivers / terms
    "participant.registrationModal.views.registrationWaivers.title"    : "Dispenses",
    "participant.registrationModal.views.registrationWaivers.of"       : "de",
    "participant.registrationModal.views.registrationWaivers.accept"   : "J'ai lu et j'accepte la dispense",
    "participant.registrationModal.views.registrationWaivers.required" : "Cette dispense est obligatoire",
    "participant.registrationModal.views.registrationTerms.title"      : "Termes et conditions",
    "participant.registrationModal.views.registrationTerms.accept"     : "J'ai lu et j'accepte les conditions de paiement",
    "participant.registrationModal.views.registrationTerms.required"   : "Veuillez cocher la case ci-dessus pour confirmer que vous acceptez ces termes et conditions",

    // more participants
    "participant.registrationModal.views.registrationMoreParticipants.title"    : "Inscrire un participant",
    "participant.registrationModal.views.registrationMoreParticipants.topText"  : "Voulez-vous inscrire un autre participant?",
    "participant.registrationModal.views.registrationMoreParticipants.another"  : "Inscrire un autre participant",
    "participant.registrationModal.views.registrationMoreParticipants.nextStep" : "Procéder au paiement",

    // summary
    "participant.registrationModal.views.registrationSummary.title"                     : "Résumé d'inscription",
    "participant.registrationModal.views.registrationSummary.orderSummary"              : "Résumé de la commande",
    "participant.registrationModal.views.registrationSummary.for"                       : "Inscription pour",
    "participant.registrationModal.views.registrationSummary.edit"                      : "Modifier l'inscription de ce membre",
    "participant.registrationModal.views.registrationSummary.removeMember"              : "Retirer ce membre",
    "participant.registrationModal.views.registrationSummary.removeMember.confirmation" : "Ce membre sera retiré de votre commande",
    "participant.registrationModal.views.registrationSummary.registration"              : "Inscription",
    "participant.registrationModal.views.registrationSummary.requiredItems"             : "Articles requis",
    "participant.registrationModal.views.registrationSummary.otherItems"                : "Autres articles",
    "participant.registrationModal.views.registrationSummary.action"                    : "Cette action est irréversible",
    "participant.registrationModal.views.registrationSummary.credit"                    : "Crédits disponibles",
    "participant.registrationModal.views.registrationSummary.credit.activate"           : "Activer les crédits pour ce membre",
    "participant.registrationModal.views.registrationSummary.credit.deactivate"         : "Désactiver les crédits pour ce membre",
    "participant.registrationModal.views.registrationSummary.rebate"                    : "Rabais",
    "participant.registrationModal.views.registrationSummary.subtotal"                  : "Sous-total pour",
    "participant.registrationModal.views.registrationSummary.total"                     : "Prix total",
    "participant.registrationModal.views.registrationSummary.noTaxes"                   : "taxes non-incluses",

    "participant.registrationModal.views.registrationSummary.removeText"  : "Vous êtes sur le point de retirer 1 {name}.",
    "participant.registrationModal.views.registrationSummary.removeText2" : "Vous pourrez rajouter cet article seulement en modifiant l'inscription de ce membre.",

    // installments
    "participant.registrationModal.views.registrationModalities.title"        : "Modalités de versements",
    "participant.registrationModal.views.registrationModalities.payNow"       : "Payez maintenant",
    "participant.registrationModal.views.registrationModalities.total"        : "Total",
    "participant.registrationModal.views.registrationModalities.required"     : "Sélectionnez une modalité de versement",
    "participant.registrationModal.views.registrationModalities.payInFull"    : "Versement complet",
    "participant.registrationModal.views.registrationModalities.installments" : "{number} versements",

    // confirmation
    "participant.registrationModal.views.registrationConfirm.title"                : "Confirmation d'inscription",
    "participant.registrationModal.views.registrationConfirm.thankYou"             : "Merci de votre inscription!",
    "participant.registrationModal.views.registrationConfirm.receiptNumber"        : "Votre numéro de reçu est",
    "participant.registrationModal.views.registrationConfirm.emailSentTo"          : "Un courriel a été envoyé à",
    "participant.registrationModal.views.registrationConfirm.preAuthorizedPayment" : "Votre facture a été pré-authorisée. L'argent sera débitée de votre compte dès que le registraire approuvera votre transaction.",

    // missing required fields
    "participant.registrationModal.views.missingRequiredFields.title"    : "Informations manquantes",
    "participant.registrationModal.views.missingRequiredFields.text"     : "Nous avons besoin de ces informations pour vous laisser inscrire votre membre.",
    "participant.registrationModal.views.missingRequiredFields.text2"    : "Veuillez remplir les champs ci-dessous.",
    "participant.registrationModal.views.missingRequiredFields.required" : "Ce champ est requis",

    // waiting list confirmation
    "participant.registrationModal.views.waitingListConfirmation.title"       : "Confirmation d'inscription à la liste d'attente",
    "participant.registrationModal.views.waitingListConfirmation.thanks"      : "Merci de votre inscription à la liste d'attente!",
    "participant.registrationModal.views.waitingListConfirmation.number"      : "Numéro de liste d'attente: ",
    "participant.registrationModal.views.waitingListConfirmation.emailSentTo" : "Un courriel a été envoyé à",
    "participant.registrationModal.views.waitingListConfirmation.keepAnEye"   : "Gardez un oeil sur vos courriels pour rester à jour avec votre inscription, nous vous contacterons en cas de besoin.",

    // waiting list return
    "participant.registrationModal.views.waitingListReturn.title"                : "Finalisation de l'inscription",
    "participant.registrationModal.views.waitingListReturn.welcomeBack"          : "Content de vous revoir!",
    "participant.registrationModal.views.waitingListReturn.selected"             : "Vous avez été sélectionné pour participer à {registration}.",
    "participant.registrationModal.views.waitingListReturn.continueRegistration" : "Cliquez le bouton {confirm} pour continuer votre inscription pour {firstName} {lastName}, ou cliquez sur le bouton {cancel} pour annuler votre inscription à la liste d'attente.",
    "participant.registrationModal.views.waitingListReturn.header"               : "Annulation",
    "participant.registrationModal.views.waitingListReturn.reason"               : "Raison d'annulation",
    "participant.registrationModal.views.waitingListReturn.reason.required"      : "La raison d'annulation est requise",

    // waiting list checkout error
    "participant.registrationModal.views.waitingListCheckoutError.title" : "Liste d'attente",
    "participant.registrationModal.views.waitingListCheckoutError.text"  : "Malheureusement, la dernière place du stage a été remplie lorsque vous complétiez votre inscription, nous vous avons inscrit à la liste d'attente.",
}