/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {useContext}from'react';import {SpordleTableContext}from'./SpordleTableProvider.js';/**
 * @typedef {Object} UseSpordleTableHookOptions
 * @property {boolean} [suppressWarning=false] Suppress the warning for when we use the hook without a {@link SpordleTableProvider}
 */
/**
 * @function useSpordleTable
 * @description Custom hook to get the SpordleTableContext in a functional component
 * @param {UseSpordleTableHookOptions} [options]
 * @example
 * const spordleTable = useSpordleTable();
 */
function useSpordleTable(options) {
    const spordleTableContext = useContext(SpordleTableContext);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!options?.suppressWarning && process.env.NODE_ENV === 'development' && !spordleTableContext) {
        console.warn('useSpordleTable: There is no SpordleTableProvider wrapping this hook');
    }
    return spordleTableContext;
}export{useSpordleTable as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/