import { useRef, useEffect } from 'react';

/**
 * @description Checks if it's the first time that the component renders.
 * @example
 * const didMount = useIsMount();
 *
 * useEffect(() => {
 *      if(didMount) return;
 *
 *      fn();
 *
 *      return () => {
 *          cleanup();
 *      }
 * }, [dependency]);
 * @returns {boolean} - true if it's first render, false if not
 * */
const useIsMount = () => {
    const isMountRef = useRef(true);

    useEffect(() => {
        isMountRef.current = false;
    }, []);

    return isMountRef.current;
};

export default useIsMount;