/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import {stringBuilder}from'@spordle/helpers';import {useField}from'formik';import PropTypes from'prop-types';import {memo,forwardRef}from'react';import {useIntl}from'react-intl';import {Label,FormText}from'reactstrap';import FormikError from'./FormikError.js';/**
 * @member FormikTextArea
 * @description
 * FormikTextArea will create an input text that will work automagically with Fromik(yes it's a typo :)) validations
 * @example
 * <FormikTextArea id="body" name="body" rows='10' helper='login.error.server' trim/>
 *
 * @prop {string} name Used to make the FormikTextArea work with validation
 * @prop {boolean} [trim=true] If the field trims on onBlur
 * @prop {string|JSX.Element} [label] The lang id to be translated OR JSX
 * @prop {string|JSX.Element} [helper] The lang id to be translated OR JSX
 * @prop {string} [placeholder] The lang id to be translated
 * @prop {boolean} [translateHelper=true]
 * @prop {boolean} [translatePlaceholder=true]
 * @prop {boolean} [translateLabel=true]
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @returns {JSX.Element} Returns an textarea that will work with Formik
 *
 * @copyright Spordle
 */
const FormikTextArea = forwardRef(({ trim, label, helper, translatePlaceholder, translateHelper, translateLabel, manualError, ...props }, ref) => {
    // @ts-ignore
    const [field, meta, helpers] = useField({ ...props, type: 'text' });
    if (trim)
        field.onBlur = (event) => helpers.setValue(event.target.value.trim());
    const intl = useIntl();
    if (translatePlaceholder && props.placeholder && typeof props.placeholder === 'string')
        props.placeholder = intl.formatMessage({ id: props.placeholder });
    if (translateHelper && helper && typeof helper === 'string')
        helper = intl.formatMessage({ id: helper });
    if (translateLabel && label && typeof label === 'string')
        label = intl.formatMessage({ id: label });
    return (jsxs(Fragment, { children: [label && typeof label === 'string' ? jsx(Label, { for: props.id || props.name, children: label }) : label, jsx("textarea", { ...field, ...props, ref: ref, className: stringBuilder('form-control', props.className, { 'is-invalid': meta.touched && meta.error }) }), helper && typeof helper === 'string' ? jsx(FormText, { color: "muted", children: helper }) : helper, !manualError && jsx(FormikError, { name: props.name })] }));
});
FormikTextArea.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateHelper: PropTypes.bool,
    translateLabel: PropTypes.bool,
    translatePlaceholder: PropTypes.bool,
    trim: PropTypes.bool,
    name: PropTypes.string.isRequired,
    manualError: PropTypes.bool,
};
FormikTextArea.defaultProps = {
    translateHelper: true,
    translateLabel: true,
    translatePlaceholder: true,
    trim: true,
};
FormikTextArea.displayName = 'FormikTextArea';
const FormikTextArea$1 = memo(FormikTextArea);export{FormikTextArea$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/