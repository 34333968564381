export default{
    // no address
    "participant.registrationModal.views.noAddress.header"       : "Ajouter une adresse",
    "participant.registrationModal.views.noAddress.headerChange" : "Changer votre adresse",
    "participant.registrationModal.views.noAddress.text"         : "Notre système a détecté que votre compte ne possède pas d'adresse.",
    "participant.registrationModal.views.noAddress.textChange"   : "Veuillez entrer votre nouvelle adresse.",

    // address change
    "participant.registrationModal.views.addressChange.header"               : "Changement d'adresse",
    "participant.registrationModal.views.addressChange.text"                 : "Notre système a détecté un changement d'adresse",
    "participant.registrationModal.views.addressChange.text2"                : "L'adresse \"{address}\" est différente de celle que nous avons pour ce joueur. Voulez-vous notifier le registraire de ce changement d'adresse?",
    "participant.registrationModal.views.addressChange.text3"                : "Veuillez entrer la date depuis laquelle le participant réside à cette adresse ainsi que le type de l'adresse.",
    "participant.registrationModal.views.addressChange.moveInYear"           : "Année d'emménagement",
    "participant.registrationModal.views.addressChange.moveInYear.required"  : "L'année d'emménagement est requise",
    "participant.registrationModal.views.addressChange.moveInYear.future"    : "L'année ne peut pas être dans le futur",
    "participant.registrationModal.views.addressChange.moveInYear.format"    : "L'année doit être d'un format valide",
    "participant.registrationModal.views.addressChange.addressType"          : "Type d'adresse",
    "participant.registrationModal.views.addressChange.addressType.required" : "Le type d'adresse est requis",

    // address review
    "participant.registrationModal.views.addressReview.header"           : "Vérification d'adresse",
    "participant.registrationModal.views.addressReview.text"             : "Ça fait un moment que vous n'avez pas vérifié votre adresse.",
    "participant.registrationModal.views.addressReview.text2"            : "Veuillez confirmer que l'adresse actuelle est valide :",
    "participant.registrationModal.views.addressReview.isValid"          : "Mon adresse est valide",
    "participant.registrationModal.views.addressReview.isValid.subtitle" : "Je réside toujours à cette adresse",
    "participant.registrationModal.views.addressReview.change"           : "Changer mon adresse",
    "participant.registrationModal.views.addressReview.change.subtitle"  : "Cette adresse n'est plus valide",
    "participant.registrationModal.views.addressReview.continue"         : "Continuer",

    // registration position
    "participant.registrationModal.views.registrationPosition.header"                 : "Sélection de position",
    "participant.registrationModal.views.registrationPosition.text"                   : "Veuillez choisir un groupe de position et une position.",
    "participant.registrationModal.views.registrationPosition.text2"                  : "Sachez que votre choix ne garantira pas cette position pour ce membre, il s'agit simplement d'une préférence et celle-ci sera prise en considération lors de l'attribution des positions.",
    "participant.registrationModal.views.registrationPosition.positionGroup"          : "Groupe de position",
    "participant.registrationModal.views.registrationPosition.positionGroup.required" : "Le groupe de position est requis",
    "participant.registrationModal.views.registrationPosition.position"               : "Position",

    // go to organization
    "participant.registrationModal.views.registrationGoToOrganization.header" : "Code postal invalide",
    "participant.registrationModal.views.registrationGoToOrganization.text"   : "Le code postal que vous avez entré est invalide pour l'organisation courante.",
    "participant.registrationModal.views.registrationGoToOrganization.text2"  : "Nous vous recommandons d'inscrire votre participant dans {amount, plural, one {l'organisation suivante} other {l'une des organisations suivantes}}.",
}