import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import RegistrationViews from './components/RegistrationViews';
import RegistrationNewParticipant from './components/RegistrationNewParticipant';
import RegistrationParticipantsList from './components/RegistrationParticipantList/RegistrationParticipantList';
import RegistrationHcrSearch from './components/RegistrationHcrSearch';
import RegistrationOptions from './components/RegistrationOptions';
import RegistrationSelectItems from './components/RegistrationSelectItems';
import RegistrationCustomQuestions from './components/RegistrationCustomQuestions';
import RegistrationMoreParticipant from './components/RegistrationMoreParticipant';
import RegistrationConfirmAddress from './components/RegistrationConfirmAddress';
import RegistrationTerms from './components/RegistrationTerms';
import RegistrationSummary from './components/RegistrationSummary/RegistrationSummary';
import RegistrationGateway from './components/RegistrationGateway';
import RegistrationConfirmation from './components/RegistrationConfirmation';
import RegistrationExistingOrNew from './components/RegistrationExistingOrNew';
import RegistrationModalities from './components/RegistrationModalities';

// contexts
import { CartsContext } from '../../../../../../contexts/CartsContext';
import { RegistrationContext } from '../../../../../../contexts/RegistrationContext';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';

import queryString from 'query-string';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import { addBreadcrumb, captureException, Severity, withScope } from '@sentry/react';
import { sendGAEvent } from '@spordle/ga4';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import RegistrationContactUs from './components/RegistrationContactUs';
import RegistrationNoAddress from './components/RegistrationAddress/RegistrationNoAddress';
import RegistrationAddressChange from './components/RegistrationAddress/RegistrationAddressChange';
import RegistrationPosition from './components/RegistrationPosition';
import RegistrationAddressReview from './components/RegistrationAddress/RegistrationAddressReview';
import RegistrationGoToOrganization from './components/RegistrationAddress/RegistrationGoToOrganization';
import RegistrationWaitingListConfirmation from './components/RegistrationWaitingList/RegistrationWaitingListConfirmation';
import RegistrationWaitingListReturn from './components/RegistrationWaitingList/RegistrationWaitingListReturn';
import RegistrationWaitingListCheckoutError from './components/RegistrationWaitingList/RegistrationWaitingListCheckoutError';
import RegistrationDocuments from './components/RegistrationDocuments';
import RegistrationMissingRequiredFields from './components/RegistrationMissingRequiredFields';

const RegistrationModal = (props) => {
    const location = useLocation();
    const history = useHistory();

    const authContext = useContext(AuthContext);
    const cartsContext = useContext(CartsContext);
    const registrationContext = useContext(RegistrationContext);

    useEffect(() => {
        if(location.hash === '#register-confirmation'){
            registrationContext.goToView(registrationContext.views.confirmation)
        }
    }, [])

    // Back from QE page
    const [ loading, setLoading ] = useState(location.hash === '#register-confirmation' && queryString.parse(location.search).cartId);

    // Auth external payment useEffect for cartId + accessToken in url
    useEffect(() => {
        const cartId = queryString.parse(location.search).cartId;
        if(authContext.accessToken && !authContext.waitingForLogin){
            if(location.hash === '#register-confirmation' && cartId){
                registrationContext.refreshCart(cartId)
                    .then((cartInfo) => {
                        if(cartInfo.payment_status === 'DECLINED'){
                            registrationContext.setQEErrorCode(cartInfo.provider_return.CODE);
                            registrationContext.goToView(registrationContext.views.gateway)
                        }

                        registrationContext.setInvoiceNumber(cartInfo.invoice_number);
                        setLoading(false);
                    })
            }else if(location.hash === "#waiting-list-return"){
                registrationContext.setWaitingListReturnData(queryString.parse(location.search));
                const parsedUrl = queryString.parseUrl(`${location.pathname}${location.search}${location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
                // cleaning the URL
                delete parsedUrl.query.firstName;
                delete parsedUrl.query.lastName;
                delete parsedUrl.query.memberId;
                delete parsedUrl.query.waitingListItemId;
                delete parsedUrl.query.registrationFeeId;
                delete parsedUrl.query.registrationName;
                delete parsedUrl.query.registrationI18n;
                history.replace(queryString.stringifyUrl(parsedUrl))
                registrationContext.goToView(registrationContext.views.waitingListReturn);
            }
        }
    }, [ authContext.accessToken, authContext.waitingForLogin ]);

    // logging the cart progress here and not in the goToView in the context because goToView gets called for every view even if it is skipped, and we dont want that
    useEffect(() => {
        if(registrationContext.state.modalIsOpen){
            sendGAEvent('cart_progress', {
                event_category: 'ecommerce',
                view: registrationContext.state.currentView,
            })
        }
    }, [ registrationContext.state.currentView ])

    /**
    * @param {string} view
    * @returns {React.Component} // returns component associated with the view
    */
    const getView = (view) => {
        switch (view){
            default:
                withScope((scope) => {
                    scope.addBreadcrumb({
                        type: 'debug',
                        message: `Could not find view: "${view}".`,
                        level: Severity.Warning,
                        category: 'Registration modal navigation',
                        data: {
                            view: view,
                        },
                    });
                    captureException(new Error(`Could not find view: "${view}".`));// Sending an Error to Sentry because this means something isn't right in the registration modal
                })
                console.warn(`Could not find view: "${view}".`);
                registrationContext.goToView(registrationContext.views.existingOrNew)
                break;

            case registrationContext.views.waitingListReturn:
                return <RegistrationWaitingListReturn />

            case registrationContext.views.noAddress:
                return <RegistrationNoAddress />

            case registrationContext.views.addressReview:
                return <RegistrationAddressReview />

            case registrationContext.views.existingOrNew:
                return <RegistrationExistingOrNew />

            case registrationContext.views.hcrSearch:
                return <RegistrationHcrSearch />

            case registrationContext.views.newParticipant: // step 2 of creation
                return <RegistrationNewParticipant />

            case registrationContext.views.documents:
                return <RegistrationDocuments />

            case registrationContext.views.goToOrganization:
                return <RegistrationGoToOrganization />

            case registrationContext.views.participantList:
                return <RegistrationParticipantsList />

            case registrationContext.views.addressChange:
                return <RegistrationAddressChange />

            case registrationContext.views.registrations:
                return <RegistrationOptions />

            case registrationContext.views.missingRequiredFields:
                return <RegistrationMissingRequiredFields />

            case registrationContext.views.questions:
                return <RegistrationCustomQuestions />

            case registrationContext.views.position:
                return <RegistrationPosition />

                // case RegistrationViews.request:
                //     return <RegistrationRequest setView={setView} toggleModal={registrationContext.toggleModal} participant={{...selectedParticipant.members[0], birthdate: selectedParticipant.birthdate}}/>

            case registrationContext.views.items:
                return <RegistrationSelectItems />

            case registrationContext.views.moreParticipant:
                return <RegistrationMoreParticipant />

            case RegistrationViews.confirmAddress:
                return <RegistrationConfirmAddress />

            case registrationContext.views.waivers:
            case registrationContext.views.terms:
                return <RegistrationTerms />

            case registrationContext.views.summary:
                return <RegistrationSummary />

            case registrationContext.views.modalities:
                return <RegistrationModalities />

            case registrationContext.views.gateway:
                return <RegistrationGateway />

            case registrationContext.views.confirmation:
                return <RegistrationConfirmation />

            case registrationContext.views.waitingListCheckoutError:
                return <RegistrationWaitingListCheckoutError />

            case registrationContext.views.waitingListConfirmation:
                return <RegistrationWaitingListConfirmation />
        }
    }

    return (
        <AnalyticsModal
            analyticsName='RegistrationModal'
            isOpen={registrationContext.state.modalIsOpen}
            centered
            contentClassName="bg-transparent" // fixes weird white top border on >2k screens
            modalClassName="registration-modal"
            onClosed={() => {
                addBreadcrumb({ type: 'info', message: 'Registration modal closed', level: Severity.Log, category: 'info' });
                const newSearchParams = queryString.parse(location.search);
                delete newSearchParams.cartId;
                history.replace(queryString.stringifyUrl({ // Removing the hash from url and cartId when closing
                    url: location.pathname,
                    query: newSearchParams,
                }));
                if(registrationContext.state.currentCart.cartInfo && registrationContext.state.currentCart.cartInfo.shopping_cart_id){
                    if(!registrationContext.state.currentCart.isCheckedOut){
                        cartsContext.emptyCart(registrationContext.state.currentCart.cartInfo.shopping_cart_id)
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    switch (error.code){
                                        case '3045':// This shopping cart is already converted
                                            break;
                                        default:
                                            console.error(error.message);
                                            break;
                                    }
                                }
                            });
                    }
                }
                registrationContext.resetRegistration();
            }}
            onOpened={() => {
                addBreadcrumb({
                    type: 'info',
                    message: 'Registration modal opened',
                    level: Severity.Log,
                    category: 'info',
                    data: {
                        page: registrationContext.state.currentView,
                    },
                });

                if(!queryString.parse(location.search).cartId){
                    // this is made on modal opened, no need to do anything else with error
                    cartsContext.clearCarts()
                        .catch(console.error)
                }
            }}
        >
            <OverlayLoader isLoading={!!loading}>
                {getView(registrationContext.state.currentView)}
                <RegistrationContactUs />
            </OverlayLoader>
        </AnalyticsModal>
    );
}

export default RegistrationModal;