import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Alert
} from "reactstrap";
import RegistrationParticipantSingle from './RegistrationParticipantSingle';

//Contexts
import { AccountsContext } from '../../../../../../../../contexts/AccountsContext';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { OrganizationContext } from '../../../../../../../../contexts/OrganizationContext';
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';

const RegistrationParticipantsList = (props) => {
    const orgContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext);
    const accountsContext = useContext(AccountsContext);
    const registrationContext = useContext(RegistrationContext);
    const [ canProceed, setCanProceed ] = useState(false);
    const [ allParticipants, setAllParticipants ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    // this controls the participant selection before pressing "next"
    const [ chosenParticipant, setChosenParticipant ] = useState(null);

    // this verifies if we can select the participant clicked
    const selectParticipant = (participant, inCart) => {
        setChosenParticipant(chosenParticipant !== participant ? participant : null);
        // setSelectedParticipant(chosenParticipant !== participant ? participant : null);

        if(participant.members[0].available_organisations.find((orgId) => orgId === orgContext.organisation_id) && chosenParticipant !== participant){
            setCanProceed(true);
        }else{
            setCanProceed(false);
        }
    }

    const isInCart = (memberId) => registrationContext.state.currentCart.cartInfo?.cart_detail?.find((d) => d.member.member_id === memberId);

    /**
     * this saves the selected participant to register and proceed
     * @param {Object} [participant] Participant to select - Will use the state's chosen participant by default
     */
    const confirmSelectionAndProceed = async(participant = chosenParticipant) => {
        try{
            setIsLoading(true);
            let nextView = participant.members[0]?.incomplete_address == '0' ? registrationContext.views.registrations : registrationContext.views.confirmAddress;

            if(isInCart(participant.members[0].member_id)){
                await registrationContext.setCurrentMemberId(participant.members[0].member_id);
            }else{
                const members = await accountsContext.getNonMatchingMembers(
                    authContext.userData.userName,
                    authContext.userData.addresses?.filter((address) => address.active == 1)[0]?.identity_address_id,
                    [ participant.members[0].member_id ],
                )
                await registrationContext.addMember(participant);

                if(members.map((m) => m.member_id).includes(participant.members[0].member_id)){
                    // identity address and member address are different
                    nextView = registrationContext.views.addressChange;
                }
            }
            setIsLoading(false);
            registrationContext.goToView(nextView)
        }catch(error){
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message);
                setIsLoading(false);
                setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
            }
        }
    }

    useEffect(() => {
        accountsContext.getIdentityMetaMembers(authContext.userData.userName, { is_primary: 0, online_store_id: registrationContext.currentOnlineStore.store.online_store_id })
            .then((data) => {
                // In list of meta members, need at least one member to show account listing
                if(data.some((metaMember) => Array.isArray(metaMember.members) && metaMember.members.length > 0)){
                    setAllParticipants(data.sort((metaA, metaB) => {
                        if(metaA.members[0]?.member_status?.name === 'DRAFT'){
                            return -1
                        }
                        return 0
                    }));
                }else{
                    registrationContext.setHcrSearchCreateMode(false)
                    registrationContext.goToView(registrationContext.views.hcrSearch)
                }
            })
            .catch((err) => {
                if(!AxiosIsCancelled(err.message)){
                    console.error(err);
                    setAllParticipants([]);
                    registrationContext.setHcrSearchCreateMode(false)
                    registrationContext.goToView(registrationContext.views.hcrSearch)
                }
            });
    }, []);

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                <Translate id='participant.registrationModal.views.registrationParticipantList.title' />
            </ModalHeader>
            <ModalBody className="py-4 px-sm-5 bg-light">
                {allParticipants?.length > 0 ?
                    <div className="registration-container">
                        <p className="font-weight-bold mb-3"><Translate id='participant.registrationModal.views.registrationParticipantList.selectParticipant' /></p>
                        <div className="max-vh-50 overflow-auto">
                            {allParticipants?.length > 0 && allParticipants.map((participant) => {
                                if(Array.isArray(participant.members) && participant.members.length > 0){
                                    const inCart = isInCart(participant.members[0].member_id);
                                    return (
                                        <RegistrationParticipantSingle
                                            key={participant.meta_member_id}
                                            id={'participantListCard-' + participant.meta_member_id}
                                            onClick={() => {
                                                selectParticipant(participant, inCart);
                                            }}
                                            participant={participant}
                                            selected={(participant.meta_member_id === chosenParticipant?.meta_member_id)}
                                            inCart={inCart}
                                            selectCartParticipant={confirmSelectionAndProceed}
                                        />
                                    )
                                }
                            })}
                        </div>
                        <hr className="my-3" />

                        {!!error &&
                            <Alert color='danger' toggle={() => setError()}>{error}</Alert>
                        }

                        <Button
                            id='participantListGoToSearch'
                            color="info"
                            onClick={() => {
                                registrationContext.setHcrSearchCreateMode(false)
                                registrationContext.goToView(registrationContext.views.hcrSearch)
                            }}
                        ><i className="mdi mdi-magnify" /> <Translate id='participant.registrationModal.views.registrationParticipantList.btn.search' />
                        </Button>
                    </div>
                    :
                    allParticipants === false ?
                        <div className="text-center">
                            <Spinner type="grow" color="primary" />
                        </div>
                        :
                        <div>
                            <p className="font-weight-bold mb-3"><Translate id='participant.registrationModal.views.registrationParticipantList.noParticipant' /></p>
                            <hr />
                            <Button
                                id='participantListNoParticipantsGoToSearch'
                                color="info"
                                onClick={() => {
                                    registrationContext.setHcrSearchCreateMode(false)
                                    registrationContext.goToView(registrationContext.views.hcrSearch)
                                }}
                            ><i className="mdi mdi-magnify" /> <Translate id='participant.registrationModal.views.registrationParticipantList.btn.search' />
                            </Button>
                        </div>
                }
            </ModalBody>
            <ModalFooter tag={'footer'} className="bg-light">
                <Button id='participantListPreviousButton' onClick={() => { registrationContext.goToView(registrationContext.views.existingOrNew, true) }} color="default" outline className="mr-auto"><Translate id='misc.previous' /></Button>
                <Button id='participantListNextButton' onClick={() => { confirmSelectionAndProceed() }} color="info" disabled={!canProceed}><Translate id='misc.next' /></Button>
            </ModalFooter>
        </OverlayLoader>
    );
}

export default RegistrationParticipantsList;
