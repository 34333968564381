import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { I18nContext } from './I18nContext';
import { AccountsContext } from './AccountsContext';
import { AuthContext } from './AuthContext';


/** @type {React.Context<Omit<CartsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const CartsContext = createContext();
CartsContext.displayName = 'CartsContext';

class CartsContextProvider extends React.Component{

    //////////////////////////////////////////////////////////////////////////////
    // Carts context
    // You'll notice that the addItem only takes in an item and an optionnal cart ID.
    // The reason for that is that the cart is created with the access token that is sent in the headers.
    // That means that the getCarts call will fetch all the carts for the access token,
    // and we agreed to have a similar workflow as the clinic under creation one.
    // idk, maybe, TODO: delete other carts
    // A user will always have a single cart, when we get all carts, we will select the first one, delete every other ones,
    // and we will ask the user if he wants to restart or continue his cart, if he restarts, we will empty the cart with the PATCH.
    //////////////////////////////////////////////////////////////////////////////

    state = {
        cachedCart: {},
        selectedItems: {},
        selectedMembers: [],
    }

    updateCachedCart = (newData) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({ ...prevState, cachedCart: { ...prevState.cachedCart, ...newData } }), resolve)
        })
    }

    emptyCachedCart = () => {
        this.setState({
            cachedCart: {},
            selectedItems: {},
            selectedMembers: [],
        });
    }

    removeMemberItemsFromCurrentCart = async(memberId, keepMember = false) => {
        // delete calls won't work properly in Promise.all, don't know why
        for(let i = 0; i < this.state.cachedCart.cart_detail.length; i++){ // good old for loop because for-in goes through prototypes of the array (which is very bad)
            if(this.state.cachedCart.cart_detail[i].member.member_id === memberId && this.state.cachedCart.cart_detail[i].item_type !== 'AFFILIATION' && !(this.state.cachedCart.cart_detail[i].item_type === 'OTHER' && this.state.cachedCart.cart_detail[i].automatically_added == 1)){
                await this.deleteCartItem(this.state.cachedCart.shopping_cart_id, this.state.cachedCart.cart_detail[i].row_id)
            }
        }

        if(!keepMember)
            await this.removeMember(memberId)

        return true
    }

    /////////////////////////////////////////////////////////////
    // Members - These functions are to keep the selected members in the state to easliy switch between current members in the cart
    /////////////////////////////////////////////////////////////

    removeMember = (memberId) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                selectedMembers: prevState.selectedMembers.filter((meta) => !meta.members.find((m) => m.member_id === memberId)),
            }), resolve)
        })
    }

    addMember = (metaMember) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                selectedMembers: [ ...prevState.selectedMembers, metaMember ],
            }), resolve)
        })
    }

    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    /**
     * Gets all unfinished carts linked to the access token of the user, deletes all carts except for the most recent one, returns the remaining one
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AgetAllCartInProgress|documentation}
     * @returns {Promise.<Array>}
     */
    getCarts = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/carts` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.carts;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * GET[SPECIFIC] - Cart
     * @param {string} shopping_cart_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AGetCartDetail|documentation}
     * @returns {Promise}
     */
    getCart = (shopping_cart_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/carts/${shopping_cart_id}` }))
            .then(async(response) => {
                if(response.data.status){
                    await this.updateCachedCart(response.data.carts[0])
                    return response.data.carts[0];
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Adds an item to a cart, if the cart doesn't exist, the system creates a cart
     * @param {object} values Values containing a cart ID (optionnal) and information about the item do add
     * @param {string} [values.online_store_id] ID of the store
     * @param {object} values.item Item to add to the cart
     * @param {string} values.item.item_id Item ID
     * @param {'OTHER'|'REGISTRATION'|'CLINIC'|'AFFILIATION'} values.item.type Type of the item, must be OTHER, REGISTRATION, CLINIC or AFFILIATION
     * @param {string} values.item.member_id Member ID
     * @param {string} values.item.meta_member_id Meta member ID of the member to link
     * @param {string} values.item.waiting_list_item_id Waiting list item ID
     * @param {Object} registrationItem Item from the online store to set in this context's state
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AAddToCart|documentation}
     * @returns {Promise}
     */
    addItem = (values, registrationItem) => {
        const params = new URLSearchParams();

        if(values.online_store_id){
            params.append('online_store_id', values.online_store_id);
        }
        params.append('item[item_id]', values.item.item_id)
        params.append('item[type]', values.item.type)
        params.append('item[member_id]', values.item.member_id)

        if(values.item.meta_member_id){
            params.append('item[meta_member_id]', values.item.meta_member_id);
        }
        if(values.item.waiting_list_item_id){
            params.append('item[waiting_list_item_id]', values.item.waiting_list_item_id)
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/carts` }), params)
            .then(async(response) => {
                if(response.data.status){
                    await this.updateCachedCart({ shopping_cart_id: response.data.shopping_cart_id })

                    // registration items cache logic :)
                    if(registrationItem){
                        const temp = {
                            ...this.state.selectedItems,
                        }
                        temp[values.item.member_id] = registrationItem
                        this.setState((prevState) => ({ ...prevState, selectedItems: temp }))
                    }

                    return response.data.shopping_cart_id;
                }
                throw response.data.errors
            }, serverError)
    }

    /**
     * Checkout
     * @param {String} shopping_cart_id
     * @param {String} payment_type
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3Acheckout|documentation}
     * @returns {Promise}
     */
    checkoutCart = (shopping_cart_id, payment_type = 'CREDITCARD') => {
        const params = new URLSearchParams({
            payment_type: payment_type,
            test_mode: (process.env.REACT_APP_ENVIRONMENT !== 'prod') >>> 0, // false -> 0, true -> 1
            language_code: this.props.I18nContext.getGenericLocale(),
            redirect_url: queryString.stringifyUrl({
                url: window.location.href,
                fragmentIdentifier: 'register-confirmation',
                query: {
                    cartId: shopping_cart_id,
                    accessToken: this.props.AuthContext.accessToken,
                },
            }),
        });

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/checkout`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    if(response.data.init_info?.error){
                        throw new Error(response.data.init_info.error[0].code);
                    }else{
                        return response.data;
                    }
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Re-checkout
     * @param {String} shoppingCartId
     * @param {String} paymentType
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3ApatchPaymentMethod%7D
     * @returns {Promise}
     */
    reCheckout = (shoppingCartId, paymentType, redirectUrl = false) => {
        const data = new URLSearchParams({
            payment_method: paymentType,
            test_mode: (process.env.REACT_APP_ENVIRONMENT !== 'prod') >>> 0, // false -> 0, true -> 1
        });

        if(redirectUrl){ // this is used for SportsPay recheckout
            data.append('redirect_url', queryString.stringifyUrl({
                url: window.location.href,
                fragmentIdentifier: 'register-confirmation',
                query: {
                    cartId: shoppingCartId,
                    accessToken: this.props.AuthContext.accessToken,
                },
            }))
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/carts/${shoppingCartId}/payment-methods` }), data)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Associate terms and condition to a cart.
     * @param {string} shopping_cart_id
     * @param {Object} values
     * @param {string} values.language_code The code for the language in which the user read the terms and conditions
     * @param {string} values.term_and_condition_id ID of the term and condition to associate
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AaddTermsToCart|documentation}
     * @returns {Promise}
     */
    associateTermsToCart = (shopping_cart_id, values) => {
        const params = new URLSearchParams();
        params.append('language_code', values.language_code)
        params.append('term_and_condition_id', values.term_and_condition_id)

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/terms-and-conditions`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Update answers for an item's form
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @param {Object} values The values for that call
     * @param {string} values.custom_form_id The values for that call
     * @param {Array} values.fields The custom form's fields
     * @param {string} values.fields.custom_form_field_id Field's ID
     * @param {string|Array} [values.fields.custom_form_field_option_id] Field's select option ID - Optionnal - Used for radio buttons, selects, etc.
     * @param {string|Array} values.fields.answer Field's answer - Send the answer in the language that the user answered in - If the fields has options, send the text value of that option in the language that the user answered in
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AaddCustomFormToCart|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCartItemForm = (shopping_cart_id, row_id, values) => {
        const params = new URLSearchParams()

        params.append('custom_form_id', values.custom_form_id);

        values.fields.forEach((field, index) => {
            params.append(`fields[${index}][custom_form_field_id]`, field.custom_form_field_id);

            // array.toString() formats it to a string with a comma separating the values
            if(field.custom_form_field_option_id !== null)
                params.append(`fields[${index}][custom_form_field_option_id]`, Array.isArray(field.custom_form_field_option_id) ? field.custom_form_field_option_id.toString() : field.custom_form_field_option_id);

            params.append(`fields[${index}][answer]`, Array.isArray(field.answer) ? field.answer.toString() : field.answer);
        })

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/items/${row_id}/forms`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Update a cart item's position
     * @param {string} shoppingCartId
     * @param {string} rowId
     * @param {Object} values The values for that call
     * @param {string} values.positionGroupId Position group ID
     * @param {string} [values.positionId] Position ID
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Carts/e37f7b8275397470e960e03b2987970f|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCartItemPosition = (shoppingCartId, rowId, values) => {
        const params = new URLSearchParams()

        params.append('position_group_id', values.positionGroupId);

        if(values.positionId){
            params.append('position_id', values.positionId)
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/carts/${shoppingCartId}/items/${rowId}/positions`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Associate waiver to a cart item
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @param {Object} values The values for that call
     * @param {string} values.waiver_id ID of the waiver to answer
     * @param {string} values.language_code The language in which the user read the waiver
     * @param {'YES'|'NO'} values.answer The answer to the waiver
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AaddWaiverToCartItem|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCartItemWaiver = (shopping_cart_id, row_id, values) => {
        const params = new URLSearchParams()
        params.append('waiver_id', values.waiver_id)
        params.append('language_code', values.language_code)
        params.append('answer', values.answer)

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/items/${row_id}/waivers`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Associate installment mode to a cart item.
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @param {string} [installment_mode_id]
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AapplyInstallmentModeToCartItem|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCartItemInstallment = (shopping_cart_id, row_id, installment_mode_id) => {
        const data = new URLSearchParams();
        if(installment_mode_id)
            data.append('installment_mode_id', installment_mode_id)

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/items/${row_id}/installments`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Delete an installment mode from a cart item.
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AremoveInstallementModeFromCartItem|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteCartItemInstallment = (shopping_cart_id, row_id) => {
        return API_SPORDLE.delete(`/carts/${shopping_cart_id}/items/${row_id}/installments`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Activate the credits for a member
     * @param {string} shopping_cart_id
     * @param {string} member_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AaddCreditsToCart|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCartCredits = (shopping_cart_id, member_id) => {
        const data = new URLSearchParams();
        data.append('member_id', member_id)

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/credits`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Deactivate the credits for a member.
     * @param {string} shopping_cart_id
     * @param {string} member_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AremoveCreditFromCart|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteCartCredits = (shopping_cart_id, member_id) => {
        return API_SPORDLE.delete(`/carts/${shopping_cart_id}/credits/members/${member_id}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Empties cart
     * @param {string} shopping_cart_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AemptyCart|documentation}
     * @returns {Promise.<boolean>}
     */
    emptyCart = (shopping_cart_id) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/carts/${shopping_cart_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Change quantity on an item in an existing cart, quantity can only be modify for item with Type OTHER that are not link to a registration
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @param {number} quantity
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3ApatchCartItemQuantity|documentation}
     * @returns {Promise.<boolean>}
     */
    updateItemQuantity = (shopping_cart_id, row_id, quantity) => {
        const params = new URLSearchParams()
        params.append('quantity', quantity)

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/carts/${shopping_cart_id}/items/${row_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }


    /**
    * Deletes a cart
    * @param {string} shopping_cart_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AdeleteCart|documentation}
    * @returns {Promise}
    */
    deleteCart = (shopping_cart_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `carts/${shopping_cart_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }


    /**
    * Deletes a item in existing cart
    * @param {string} shopping_cart_id
    * @param {string} item_row_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AdeleteCartItem|documentation}
    * @returns {Promise}
    */
    deleteCartItem = (shopping_cart_id, item_row_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `carts/${shopping_cart_id}/items/${item_row_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
    * Clears carts for the user and returns a new empty cart id
    * @returns {Promise}
    */
    clearCarts = () => {
        return this.getCarts()
            .then((carts) => {
                carts.forEach((cart) => {
                    this.deleteCart(cart.shopping_cart_id)
                })
            })
    }

    /**
    * Proccess a payment
    * @param {string} shoppingCartId
    * @param {Object} data
    * @returns {Promise}
    */
    processPayment = (shoppingCartId, data) => {
        const splittedLang = this.props.I18nContext.locale.split('-');
        const urlData = new URLSearchParams({
            ...data,
            locale: `${splittedLang[0]}_${splittedLang[1].toUpperCase()}`,
        });
        if(process.env.REACT_APP_ENVIRONMENT !== 'prod')
            urlData.append('test_mode', 1);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `carts/${shoppingCartId}/process-payment` }), urlData)
            .then((response) => {
                if(response.data.status){
                    return response.data.provider_data?.permanent_payment_token || null
                }
                if(response.data.errors[0].code === '3308')// Payment error check provider data for error message -> Paysafe error
                    throw new Error(response.data.provider_data.paysafe_response.error.code);
                else
                    throw response.data.errors[0]
            }, serverError)
    }

    /**
    * Get all vault cards
    * @param {string} shoppingCartId DEFAULT to `cartContext.cachedCart.shopping_cart_id`
    * @returns {Promise.<Array>}
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AgetVaultCard|documentation}
    */
    getVaultCards = (shoppingCartId = this.state.cachedCart?.shopping_cart_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/carts/${shoppingCartId}/vault-card`,
            query: {
                test_mode: process.env.REACT_APP_ENVIRONMENT !== 'prod' ? '1' : null,
            },
        }, {
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.vault_cards;
                }
                if(response.data.errors[0].code === '3060'){ // No vault -> no cards
                    return [];
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            // Speading `this` is very important because it creates a new value object so it rerenders the Consumers
            // If we don't spead, react/javascript sees it as the same object thus, not rerendering the Consumers
            <CartsContext.Provider value={{ ...this }}>
                {this.props.children}
            </CartsContext.Provider>
        )
    }
}

export default withContexts(I18nContext, AccountsContext, AuthContext)(CartsContextProvider);