/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {spordleSelectStitches}from'./stitches.js';const optionPlaceholder = spordleSelectStitches.css({});
const optionMulti = spordleSelectStitches.css({});
const optionCss = {
    display: 'inline-flex',
    verticalAlign: 'middle',
    minWidth: '100%',
    variants: {
        isClickable: {
            false: {},
            true: { cursor: 'pointer' },
        },
        mode: {
            normal: {
                padding: '$padding $padding2',
            },
            mobile: {
                padding: '$padding2 $padding3',
            },
        },
    },
    '&[data-disabled=true]': {
        cursor: 'initial',
        opacity: 0.6,
        pointerEvents: 'none',
    },
    [`&${optionMulti}`]: {
        alignItems: 'center',
        display: 'flex',
    },
};
/*
 * .spordleSelect-option
 */
const StyledOption = spordleSelectStitches.styled('li', optionCss);
const styledOptionClass = spordleSelectStitches.css(optionCss);
/*
 * .spordleSelect-option-content
 */
const OptionContent = spordleSelectStitches.styled('div', {
    variants: {
        mode: {
            normal: {},
            mobile: {},
        },
    },
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    width: '100%',
});
/*
 * .spordleSelect-option-virtualizer
 */
spordleSelectStitches.styled('div', {
    position: 'absolute',
});
const groupCss = {
    padding: '$padding2 $padding2 $padding $padding2',
    fontSize: '0.8em',
    textTransform: 'uppercase',
};
/*
 * .spordleSelect-optgroup
 */
spordleSelectStitches.styled('li', groupCss);
const styledGroupClass = spordleSelectStitches.css(groupCss);
/*
 * .spordleSelect-checkbox
 */
const OptionCheckBox = spordleSelectStitches.styled('div', {
    variants: {
        mode: {
            normal: {},
            mobile: {},
        },
    },
    alignSelf: 'center',
    pointerEvents: 'none',
});
/*
 * .spordleSelect-li
 */
const SpordleSelectLi = spordleSelectStitches.styled('li', {
    variants: {
        mode: {
            normal: {},
            mobile: {},
        },
    },
    display: 'inline-block',
    minWidth: '100%',
    position: 'relative',
    '&::before': {
        content: ' ',
        display: 'table',
    },
});
/*
 * .spordleSelect-option-container
 */
const OptionContainer = spordleSelectStitches.styled('ul', {
    variants: {
        mode: {
            normal: {
                maxHeight: '$selectMenuHeight',
                top: `calc(100% + $selectMenuHeight)`,
                position: 'absolute',
                zIndex: 10,
            },
            mobile: {
                maxHeight: '100%',
            },
        },
    },
    backgroundColor: '#fff',
    // display: 'none',
    left: 0,
    listStyleType: 'none',
    marginBottom: 0,
    overflow: 'auto',
    paddingLeft: 0,
    width: '100%',
    '& > ul': {
        marginBottom: 0,
        paddingLeft: 0,
        listStyleType: 'none',
    },
});export{OptionCheckBox,OptionContainer,OptionContent,SpordleSelectLi,StyledOption,optionMulti,optionPlaceholder,styledGroupClass,styledOptionClass};
/*
@Copyrights Spordle 2022 - All rights reserved
*/