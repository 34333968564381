import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import {
    ModalHeader,
    ModalBody,
    Button,
    ModalFooter
} from "reactstrap";
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import { OrganizationContext } from '../../../../../../../../contexts/OrganizationContext';
import UserDisplay from '../../../../../../../../components/userDisplay/UserDisplay';
import ImgPill from '../../../../../../../../components/visual/imgPill/ImgPill';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedNumber } from 'react-intl';
import { stringifyUrl } from 'query-string';
import getReferrer from '../../../../../../../../helpers/getReferer';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { I18nContext } from '../../../../../../../../contexts/I18nContext';

const RegistrationGoToOrganization = (props) => {
    const registrationContext = useContext(RegistrationContext);
    const orgContext = useContext(OrganizationContext);
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const i18nContext = useContext(I18nContext);

    return (
        <>
            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="text-white bg-dark">
                <Translate id='participant.registrationModal.views.registrationGoToOrganization.header' />
            </ModalHeader>
            <ModalBody className="py-4 bg-light">
                <div className='registration-container'>
                    <div className='text-center'>

                        <p className='font-weight-bold mb-3'>
                            <Translate id='participant.registrationModal.views.registrationGoToOrganization.text' />
                        </p>
                        <OrgCard org={orgContext} invalid />

                        {registrationContext.goToOrgs.length > 0 &&
                            <>
                                <p>
                                    <Translate
                                        id='participant.registrationModal.views.registrationGoToOrganization.text2'
                                        values={{
                                            amount: registrationContext.goToOrgs.length,
                                        }}
                                    />
                                </p>

                                <PerfectScrollbar
                                    style={{ maxHeight: '40vh' }}
                                    options={{ suppressScrollX: true }}
                                    className='p-2 m-n2'
                                >
                                    {registrationContext.goToOrgs.map((org) => (
                                        <OrgCard
                                            key={org.organisation_id}
                                            org={org} hover
                                            onClick={() => {
                                                switch (org.deploy_on){
                                                    case 'VERCEL':
                                                    case 'PAGE':
                                                        window.location.href = stringifyUrl({
                                                            url: `${getReferrer(org.deploy_on == 'VERCEL' ? 'PAGE-VERCEL' : 'PAGE-VERCEL-2')}/${org.short_name}/participant`,
                                                            query: {
                                                                lang: i18nContext.getGenericLocale(),
                                                                accessToken: authContext.accessToken,
                                                            },
                                                        }, { skipEmptyString: true, skipNull: true })
                                                        break;
                                                    default:
                                                        history.push(`/page/${org.short_url}/participant`)
                                                        registrationContext.resetRegistration();
                                                        break;
                                                }
                                            }}
                                            className='mb-2 w-100'
                                        />
                                    ))}
                                </PerfectScrollbar>
                            </>
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter tag='footer' className="bg-light">
                <Button
                    color='info' outline
                    onClick={() => registrationContext.goToView(registrationContext.goToOrgsPrevView, true)}
                    className='mr-auto'
                >
                    <Translate id='misc.previous' />
                </Button>
            </ModalFooter>
        </>
    );
}

const OrgCard = ({ org, invalid, ...props }) => {
    return (
        <UserDisplay
            card className='w-100'
            {...props}
        >
            <UserDisplay.Container>
                <ImgPill
                    size="sm"
                    abbr={org.organisation_name.charAt(0) + org.organisation_name.charAt(1)}
                    src={org.logo?.full_path}
                    alt={org.organisation_name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title><DisplayI18n field='name' defaultValue={org.organisation_name} i18n={org.i18n} /></UserDisplay.Title>
                {org.city && org.province_code &&
                    <UserDisplay.Subtitle>
                        <span>{org.city}, {org.province_code}</span>
                        {!invalid &&
                            <span className='ml-1'>(<FormattedNumber value={org.distance} style='unit' unit='kilometer' />)</span>
                        }
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
            <UserDisplay.Container className='flex-grow-1 text-right'>
                {invalid ?
                    <i className='mdi mdi-block-helper text-danger font-22' />
                    :
                    <i className='text-info mdi mdi-arrow-right font-22' />
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default RegistrationGoToOrganization;