import { Collapse } from 'reactstrap';

const TableExpandableRow = ({ isExtended, children, colSpan, ...props }) => {
    return (
        <tr className={`${isExtended ? 'table-row-expanded' : 'table-row-collapsed'} table-row-expandable border-0`}>
            <td colSpan={colSpan}>
                <Collapse isOpen={isExtended}>
                    <div className="td-inner-content">
                        {children}
                    </div>
                </Collapse>
            </td>
        </tr>
    )
}

export default TableExpandableRow;