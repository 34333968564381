/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import SpordleTableProvider from'@spordle/datatables';import PropTypes from'prop-types';import {PureComponent,createRef}from'react';import SpordleSelectRenderer from'./SpordleSelectRenderer.js';import {isTruthyValue,isMobile}from'./utils.js';/**
 * @namespace Styles
 */
/**
 * @typedef {Object} spordleSelect.scss
 * @description
 * This is the package's default stylesheet.
 * It uses bootstrap default variables and functions.
 * @see {@link https://getbootstrap.com/ Bootstrap}
 * @memberof! Styles
 * @example
 * // Using import in a stylesheet
 * import '~@spordle/spordle-select/defaultStyles/spordleSelect.scss';
 * @example
 * // Using the stylesheet in a scss file
 * use '~@spordle/spordle-select/defaultStyles/spordleSelect.scss' with (
 *  $spordle-select-border-color: #000
 * );
 */
/**
 * @typedef {Object} GroupType
 * @description This is the definition of a group
 * @property {string} groupId The groupId that will be used to link options to this group.
 * @property {string} label The default label uses to show the group in the select.
 * @property {boolean} [translateLabel=false] Will automatically translate the label given for this option.
 */
/**
 * @typedef {Object} OptionType
 * @description This is the definition of an option
 * @property {string} label The default label uses to show the option in the select.
 * @property {boolean} [translateLabel=false] Will automatically translate the label given for this option.
 * @property {string} [groupId] The groupId that will be used to link the current option to the correcponding group.
 * @property {boolean} [isDisabled] If the option is disabled or not.
 */
/**
 * @typedef {Object} OptionStates
 * @description This is the definition of an option with its current states
 * @property {OptionType} option The current option's data
 * @property {boolean} isSelected If the option is selected or not.
 * @property {boolean} isDisabled If the option is disabled or not.
 */
/**
 * @typedef {Object} TextWhenSetting
 * @description This is the definition of a `textWhenSetting` prop
 * @property {number|string} [count='1/200'] The threshold before the label is displayed.
 * @property {string | React.ReactNode} [label] A string id to translate / Callback function that returns JSX / A React Component that receives the values as props
 */
/**
 * @callback GetOptionSettings
 * @description A function used to get all the props for a `div` element
 * @param {OptionStates} option The current option
 * @returns {HTMLProps<HTMLDivElement>}
 */
/**
 * @callback IsOptionDisabled
 * @description
 * Used to denermine if the given option should be disabled or not.<br>
 * Defining this prop will opt-out of the default behavior.
 * @param {OptionType} option The current option
 * @returns {boolean}
 */
/**
 * @callback RenderOption
 * @description
 * Callback function to render options in the menu list AND the selected options if needed<br>
 * Note for selected options only: if {@link RenderSelectedOption} is passed, it will have priority over this prop
 * @param {OptionStates} option The current option
 * @param {boolean} fromSelectedOption If the function is called to render a selected option and **NOT** an option from the menu list
 * @returns {React.ReactNode}
 */
/**
 * @callback RenderSelectedOption
 * @description
 * Callback function to custom render the selected options.<br>
 * Will be called before {@link RenderOption}
 * @param {OptionType} option The current option
 * @returns {React.ReactNode | void}
 */
/**
 * @callback OnOptionSelected
 * @description
 * Callback function called BEFORE the internal onOptionSelected.
 * @param {string[]} values The list of the selected options
 * @param {SpordleSelect} spordleSelect The select itself
 * @returns {boolean | void} Return `true` to prevent the default onSelect behavior
 */
/**
 * @callback OnMenuClose
 * @description
 * Callback function triggered when the select closes.
 * @param {SpordleSelect} spordleSelect The select itself
 * @returns {void}
 */
/////////////////// SpordleSelect components/functions ///////////////////
/**
 * @function getSpordleTable
 * @memberof SpordleSelect
 * @description Gets the internal SpordleTable's ref allowing us to use the {@link https://github.com/Spordle/DataTables/packages/356339|Spordletable's API}
 * @returns {SpordleTableRef} The internal SpordleTable
 * @example
 * selectRef.current.getSpordleTable();
*/
/**
 * @class SpordleSelect
 * @namespace SpordleSelect
 * @see Full documentation for {@link https://github.com/Spordle/Spordle-Select/packages/399619?version=2.1.6 this version} and {@link https://github.com/Spordle/Spordle-Select/releases/tag/v2.1.6 release notes}
 * @description
 * Homemade SR friendly select using the SpordleTable's features.<br>
 * Props in `[]` are optional.
 *
 * @prop {string[]} [searchKeys] Array of keys to search
 * @prop {Array.<GroupType | OptionType>} [options] The list of options<br>When given, it disables the `loadData` prop<br>{@link GroupType} - {@link OptionType}
 * @prop {string} [name] Name of the HTML input
 * @prop {boolean} [clearable] If the select is clearable
 * @prop {boolean} [search=true] If the select is searchable or not
 * @prop {boolean} [closeMenuOnSelect] If we want to close the select when an option is selected.<br>By default, the menu will close when we select an option in "single select" mode and will stay open in multi select mode.
 * @prop {string} [className] Classes for the select's inner wrapper
 * @prop {string} [inputClassName] The classnames to be applied on the input
 * @prop {string} [prefixClassName] A prefix to append to every SpordleSelect's class
 * @prop {boolean} [menuIsDefaultOpen=false] If the menu should be open by default
 * @prop {boolean} [isOpen] If the menu list should be open or not
 * @prop {string[]} [defaultValues] The default values. Will accept the value only on the initial mount.
 * @prop {boolean} [disabled] If the select should be disabled or not
 * @prop {HTMLProps<HTMLDivElement> | GetOptionSettings} [optionSetting] Props for rendered menu options
 * @prop {number} [virtualizationThreshold=20] The number of items before the select virtualizes the option list.<br>Between `20` and `100` inclusively.
 * @prop {React.ReactNode} [noOptionLayout] Used when the select doesn't have any options available to the user
 * @prop {React.ReactNode} [loadingLayout] Used when the select is in a loading state
 * @prop {boolean} [multi=false] If the select allows multiple selected options or not
 * @prop {string[]} [values] A list of {@link OptionType}.value to tell the select to display these options as selected
 * @prop {IsOptionDisabled} [isOptionDisabled] Used to denermine if the given option should be disabled or not.<br>Defining this prop will opt-out of the default behavior.
 * @prop {RenderOption} [renderOption] Callback function to render options in the menu list **AND** the selected options if needed.<br>Note for selected options only: if {@link RenderSelectedOption} is passed, it will have priority over this prop
 * @prop {RenderSelectedOption} [renderSelectedOption] Callback function to custom render the selected options.<br>Will be called before {@link RenderOption}
 * @prop {string} [placeholder] A string id to translate
 * @prop {boolean} [skipPlaceholderTranslate=false] Prevent from automatically translating the placeholder
 * @prop {React.ReactNode} [valueSplitter=,] The react element to show between selected options
 * @prop {OnOptionSelected} [onOptionSelected] Callback function called **BEFORE** the internal onOptionSelected<br>Return `true` to prevent the default onOptionSelected behavior
 * @prop {boolean} [autoFocus] If we need to focus the search input in mount
 * @prop {boolean} [isLoading] If the select should be in a loading state
 * @prop {TextWhenSetting} [textWhenSetting] If we need to focus the search input in mount
 * @prop {OnMenuClose} [onMenuClose] Callback function triggered when the select closes
 * @prop {boolean} [preventSelectedReorder] Determines if we need to order the selected options to the top of the dropdown. Works with multi select mode only
 * @prop {boolean} [hideSelectAll] Hides the select all option when in multi
 * @example
 * <SpordleSelect className='w-100' search
 *     values={formik.values.prerequisites[index].qualifications}
 *     onOptionSelected={(values) => {
 *         formik.setFieldValue(`prerequisites.${index}.qualifications`, values)
 *     }}
 *     id={`prerequisites.${index}.qualifications`}
 *     name={`prerequisites.${index}.qualifications`}
 *     multi
 *     disabled={qualificationsDisabled}
 *     options={qualifications}
 *     searchKeys={[
 *         `category.i18n.${i18nContext.getGenericLocale()}.name`,
 *         'category.name',
 *         `i18nQualification.${i18nContext.getGenericLocale()}.name`,
 *         'qualificationName',
 *         `i18nLevel.${i18nContext.getGenericLocale()}.name`,
 *         'levelName',
 *     ]}
 *     textWhenSetting={{
 *         count: 1,
 *         label: 'clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.selected'
 *     }}
 *     renderOption={(option) => {
 *         const data = option.option;
 *         if(data.levelName){
 *             return(
 *                 <div className='align-items-center d-flex'>
 *                     <ClinicCategory color={data.category.color}>
 *                         <DisplayI18n field='name' defaultValue={data.category?.name} i18n={data.category?.i18n}/> - <DisplayI18n field='name' defaultValue={data.qualificationName} i18n={data.i18nQualification}/> - <DisplayI18n field='name' defaultValue={data.levelName} i18n={data.i18nLevel}/>
 *                     </ClinicCategory>
 *                 </div>
 *             )
 *         } else {
 *             return(
 *                 <div className='align-items-center d-flex'>
 *                     <ClinicCategory color={data.category.color}>
 *                         <DisplayI18n field='name' defaultValue={data.category?.name} i18n={data.category?.i18n}/> - <DisplayI18n field='name' defaultValue={data.label} i18n={data.i18nQualification}/>
 *                     </ClinicCategory>
 *                 </div>
 *             )
 *         }
 *     }}
 * />
 * @extends SpordleTable
 * @see {@link https://github.com/Spordle/DataTables/packages/356339 SpordleTable}
 * @access default
 * @copyright Spordle
 */
class SpordleSelect extends PureComponent {
    static propTypes = {
        // Mandatory
        intl: PropTypes.any.isRequired,
        // Optional
        searchKeys: PropTypes.arrayOf(PropTypes.string),
        options: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string,
            groupId: PropTypes.string,
            isGroup: PropTypes.bool,
            isDisabled: PropTypes.bool,
            options: PropTypes.array,
            group: PropTypes.object,
        })),
        name: PropTypes.string,
        clearable: PropTypes.bool,
        search: PropTypes.bool,
        className: PropTypes.string,
        prefixClassName: PropTypes.string,
        menuIsDefaultOpen: PropTypes.bool,
        isOpen: PropTypes.bool,
        defaultValues: PropTypes.arrayOf(PropTypes.string),
        disabled: PropTypes.bool,
        optionSetting: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.object,
        ]),
        inputClassName: PropTypes.string,
        virtualizationThreshold: PropTypes.number,
        noOptionLayout: PropTypes.node,
        loadingLayout: PropTypes.node,
        multi: PropTypes.bool,
        values: PropTypes.arrayOf(PropTypes.string),
        isOptionDisabled: PropTypes.func,
        renderOption: PropTypes.func,
        renderSelectedOption: PropTypes.func,
        placeholder: PropTypes.string,
        valueSplitter: PropTypes.node,
        onOptionSelected: PropTypes.func,
        autoFocus: PropTypes.bool,
        isLoading: PropTypes.bool,
        textWhenSetting: PropTypes.shape({
            count: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
                PropTypes.elementType,
            ]),
        }),
        preventSelectedReorder: PropTypes.bool,
    };
    static defaultProps = {
        menuIsDefaultOpen: false,
        virtualizationThreshold: 20,
        search: true,
    };
    static displayName = 'SpordleSelect';
    static SELECT_ALL_VALUE = '___spordleSelect__SELECT-ALL';
    _spordleTable;
    /**
     * A list to keep in memory the selected options.
     *
     * Is used to mimic Jira's select behavior with selected options.
     */
    _selectedOptions;
    _isMobileDevice;
    constructor(props) {
        super(props);
        this._spordleTable = createRef();
        this.state = {
            isOpen: props.isOpen ?? props.menuIsDefaultOpen ?? false,
            selectedOptions: props.values?.filter(isTruthyValue) || props.defaultValues?.filter(isTruthyValue) || [],
        };
        this._selectedOptions = this._mapSelectedOptions(this.state.selectedOptions);
        this._isMobileDevice = props.disableMobileView ? false : isMobile();
    }
    componentDidMount() {
        if (Array.isArray(this.props.options))
            this._spordleTable.current.setData(this.props.options);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.options !== prevProps.options) {
            this._spordleTable.current.setData(this.props.options ?? []);
        }
        if (!this.state.isOpen && prevState.isOpen !== this.state.isOpen) {
            this.props.onMenuClose?.(this);
        }
        if (this.props.isOpen !== prevProps.isOpen || this.props.values !== prevProps.values || this.props.disabled !== prevProps.disabled) {
            this.setState((prevState) => {
                const finalNewState = {};
                if (this.props.values !== prevProps.values) {
                    finalNewState.selectedOptions = this.props.values?.filter(isTruthyValue) ?? prevState.selectedOptions;
                }
                if (this.props.isOpen !== prevProps.isOpen) {
                    this._selectedOptions = this._mapSelectedOptions(finalNewState.selectedOptions ?? prevState.selectedOptions);
                    finalNewState.isOpen = this.props.isOpen ?? !prevState.isOpen;
                }
                if (this.props.disabled !== prevProps.disabled) {
                    this._selectedOptions = this._mapSelectedOptions(finalNewState.selectedOptions ?? prevState.selectedOptions);
                    finalNewState.isOpen = this.props.disabled ? false : this.props.isOpen ?? prevState.isOpen;
                }
                return finalNewState;
            });
        }
    }
    _mapSelectedOptions = (options) => {
        return new Map(options.map((o, i) => [o, i]));
    };
    _toggleOpen = () => {
        this.setState((prevState) => {
            const isOpen = this.props.isOpen ?? !prevState.isOpen;
            if (!isOpen) { // When closing
                this._selectedOptions = this._mapSelectedOptions(prevState.selectedOptions);
            }
            return { isOpen };
        });
    };
    _close = () => {
        this._spordleTable.current?.setInputValue(); // Always empty search input when closing the select
        this.setState((prevState) => {
            const isOpen = this.props.isOpen ?? false;
            if (!isOpen) { // When closing
                this._selectedOptions = this._mapSelectedOptions(prevState.selectedOptions);
            }
            return { isOpen };
        });
    };
    _optionClick = (option) => () => {
        if (this.props.multi && option.isSelected) {
            this._unselectOption(option.option).then(this._toggleMenuOnSelect);
        }
        else {
            this._selectOption(option.option).then(this._toggleMenuOnSelect);
        }
    };
    /**
     * Function to decide if we need to close the menu on option selected or not
     */
    _toggleMenuOnSelect = () => {
        switch (this.props.closeMenuOnSelect ?? !this.props.multi) {
            case true:
                this._close();
                break;
        }
    };
    _selectOption = (option) => {
        if (this.props.multi) {
            if (Array.isArray(option)) {
                return new Promise((resolve) => {
                    this.setState((prevState) => {
                        // using reduce to do filter + map
                        prevState.selectedOptions.pushArray(option.reduce((filterMapOptions, currentOption) => {
                            if ( // Filter out the "select all" option, disabled options and group options
                            currentOption.value !== SpordleSelect.SELECT_ALL_VALUE &&
                                !currentOption.isGroup &&
                                !((this.props.isOptionDisabled?.(currentOption) ?? false) || currentOption.isDisabled)) {
                                filterMapOptions.push(currentOption.value);
                            }
                            return filterMapOptions;
                        }, []));
                        const dedupedValues = prevState.selectedOptions.removeDuplicates();
                        const preventDefault = this.props.onOptionSelected?.(dedupedValues, this) ?? false;
                        if (preventDefault) {
                            return null;
                        }
                        return { selectedOptions: dedupedValues };
                    }, resolve);
                });
            }
            return new Promise((resolve) => {
                this.setState((prevState) => {
                    prevState.selectedOptions.push(option.value);
                    const dedupedValues = prevState.selectedOptions.removeDuplicates();
                    const preventDefault = this.props.onOptionSelected?.(dedupedValues, this) ?? false;
                    if (preventDefault) {
                        return null;
                    }
                    return { selectedOptions: dedupedValues };
                }, resolve);
            });
        }
        if (Array.isArray(option)) {
            return new Promise((resolve) => {
                this.setState(() => {
                    const preventDefault = this.props.onOptionSelected?.([option[0].value], this) ?? false;
                    if (preventDefault) {
                        return null;
                    }
                    this._spordleTable.current?.setInputValue();
                    return {
                        selectedOptions: [option[0].value],
                    };
                }, resolve);
            });
        }
        return new Promise((resolve) => {
            this.setState(() => {
                const preventDefault = this.props.onOptionSelected?.([option.value], this) ?? false;
                if (preventDefault) {
                    return null;
                }
                this._spordleTable.current?.setInputValue();
                return {
                    selectedOptions: [option.value],
                };
            }, resolve);
        });
    };
    _unselectOption = (option) => {
        if (Array.isArray(option)) {
            return new Promise((resolve) => {
                this.setState((prevState) => {
                    const selectedOptionsSet = new Set(prevState.selectedOptions);
                    for (let index = 0; index < option.length; index++) {
                        selectedOptionsSet.delete(option[index].value);
                    }
                    const newValues = Array.from(selectedOptionsSet);
                    const preventDefault = this.props.onOptionSelected?.(newValues, this) ?? false;
                    if (preventDefault) {
                        return null;
                    }
                    return {
                        selectedOptions: newValues,
                    };
                }, resolve);
            });
        }
        return new Promise((resolve) => {
            this.setState((prevState) => {
                const newValues = prevState.selectedOptions.filter((opt) => option.value !== opt);
                const preventDefault = this.props.onOptionSelected?.(newValues, this) ?? false;
                if (preventDefault) {
                    return null;
                }
                return {
                    selectedOptions: newValues,
                };
            }, resolve);
        });
    };
    _buildSearchKeys = () => {
        if (Array.isArray(this.props.searchKeys)) {
            return this.props.searchKeys.reduce((searchKeys, searchKey) => {
                searchKeys.pushArray([searchKey, `options.${searchKey}`, `group.${searchKey}`]);
                return searchKeys;
            }, []);
        }
        return [];
    };
    _clearSelected = (e) => {
        e.stopPropagation(); // Prevent opening dropdown when clicking on clear
        if (!this.props.onOptionSelected?.([], this))
            this.setState(() => ({ selectedOptions: [] }));
        this._spordleTable.current?.setInputValue();
    };
    getSpordleTable = () => this._spordleTable.current;
    render() {
        return (jsx(SpordleTableProvider, { ...this.props, 
            // Search must always be include (fuse: ' character)
            searchKeys: [
                'label',
                'options.label',
                'group.label',
                ...this._buildSearchKeys(),
            ], loadData: Array.isArray(this.props.options) ? undefined : this.props.loadData, defaultData: this.props.options ?? this.props.defaultData, desktopWhen: true, pagination: 0, ref: this._spordleTable, defaultSorting: '-doesnotmatter' // opt-in sorting -> Allow to use columnSorting
            , dataIndex: this.props.dataIndex || 'value', defaultSearchValue: this.props.defaultSearchValue ? `'"${this.props.defaultSearchValue}"` : undefined, fuseOptions: {
                ...this.props.fuseOptions,
                shouldSort: false,
                getSearchValue: (normalizedValue, option, searchKey, spordleTable) => {
                    switch (searchKey) {
                        case 'label':
                            if (option.translateLabel) {
                                return this.props.intl.formatMessage({ id: option.label });
                            }
                            break;
                        case 'options.label':
                            if (Array.isArray(option.options)) {
                                return normalizedValue.map((value, index) => {
                                    if (option.options[index].translateLabel) {
                                        return this.props.intl.formatMessage({ id: value });
                                    }
                                    return value;
                                });
                            }
                            break;
                        case 'group.label':
                            if (option.group?.translateLabel) {
                                return this.props.intl.formatMessage({ id: option.group.label });
                            }
                            break;
                    }
                    return this.props.fuseOptions?.getSearchValue?.(normalizedValue, option, searchKey, spordleTable);
                },
            }, columnSorting: (optionA, optionB, spordleTable) => {
                if ((!this.props.multi && !this.props.preventSelectedReorder) || // Disable option sorting for single select mode
                    (this.props.multi && this.props.preventSelectedReorder) // Disable option sorting for multi select with the preventSelectedReorder prop
                ) {
                    return 0;
                }
                const indexValueA = spordleTable.getDataIndexValue(optionA);
                const indexValueB = spordleTable.getDataIndexValue(optionB);
                const optionAIsSelectedIndex = this._selectedOptions.get(indexValueA);
                const optionBIsSelectedIndex = this._selectedOptions.get(indexValueB);
                const optionAIsSelected = typeof optionAIsSelectedIndex === 'number';
                const optionBIsSelected = typeof optionBIsSelectedIndex === 'number';
                if (optionAIsSelected && optionBIsSelected) {
                    return optionAIsSelectedIndex - optionBIsSelectedIndex;
                }
                // Transforming boolean to number -> https://www.w3schools.com/js/js_bitwise.asp
                // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
                return optionBIsSelected - optionAIsSelected;
            }, 
            // Always show the option in single select mode
            filterJSCallback: this.props.multi && !this.props.preventSelectedReorder ? (option, _filters, spordleTable) => {
                if (option.isGroup) {
                    return !option.options.every((o) => this._selectedOptions.has(spordleTable.getDataIndexValue(o)));
                }
                return true;
            } : undefined, children: (spordleTable) => {
                if (this.props.multi && !this.props.hideSelectAll && spordleTable.dataToDisplay.filter((d) => !d.isGroup).length > 1) {
                    spordleTable.dataToDisplay.unshift({
                        label: 'Select all',
                        value: SpordleSelect.SELECT_ALL_VALUE,
                    });
                }
                const isLoading = this.props.isLoading ?? (spordleTable.state.loadingState === 'loading' || spordleTable.state.loadingState === 'lazy');
                return (jsx(SpordleSelectRenderer, { ...this.props, spordleTable: spordleTable, isLoading: isLoading, close: this._close, toggleOpen: this._toggleOpen, spordleSelect: this, clearSelected: this._clearSelected, optionClick: this._optionClick, selectOption: this._selectOption, selectedOptions: this._selectedOptions, unselectOption: this._unselectOption, isMobile: this._isMobileDevice }));
            } }));
    }
}export{SpordleSelect as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/