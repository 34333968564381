export default{
    // Global
    "participant.registrationModal.needSupport" : "Problem with your registration? <a>Contact us</a>",


    // Registration Existing or New
    "participant.registrationModal.views.existingOrNew.title"                          : "Register a participant",
    "participant.registrationModal.views.existingOrNew.btn.start"                      : "Start Registration",
    "participant.registrationModal.views.existingOrNew.registerExisting.skipToPayment" : "Skip to payment",
    "participant.registrationModal.views.existingOrNew.registerExisting.title"         : "Register an existing participant",
    "participant.registrationModal.views.existingOrNew.registerExisting.desc"          : "Already participated in organized hockey as a player, coach, official or volunteer.",
    "participant.registrationModal.views.existingOrNew.registerNew.title"              : "Register a new participant",
    "participant.registrationModal.views.existingOrNew.registerNew.desc"               : "Never participated in organized hockey.",
    "participant.registrationModal.views.existingOrNew.registerNew.warning"            : "Before {create} and filling a {form}, please make sure that the member has never participated as a player, coach, referee or volunteer in any organized Hockey Organization",
    "participant.registrationModal.views.existingOrNew.registerNew.create"             : "creating a new member",
    "participant.registrationModal.views.existingOrNew.registerNew.form"               : "new member registration form",
    "participant.registrationModal.views.existingOrNew.registerIdk.title"              : "I don't know",
    "participant.registrationModal.views.existingOrNew.registerIdk.desc"               : "I don't know if I participated in organized hockey.",

    // Registration New Participant
    "participant.registrationModal.views.newParticipant.title"                : "Create new participant",
    "participant.registrationModal.views.newParticipant.validation.dateRange" : "Date must represent an age between 4 and 100 years old",
    "participant.registrationModal.views.newParticipant.validation.relation"  : "Please select a relation",
    "participant.registrationModal.views.newParticipant.btn.error.2000"       : "Click here to edit this member's first name and last name",
    "participant.registrationModal.views.newParticipant.btn.editAge"          : "Click here if you wish to change the birthdate",
    "participant.registrationModal.views.newParticipant.lang"                 : "Please indicate in which official language - English or French - you prefer to receive correspondence from Hockey Canada.",

    "participant.registrationModal.views.documents.text" : "You are about to create a new profile. Please note that the MHA might require documents to confirm the validity of the registration and they might contact you if they need anything.",

    // Registration Participant List
    "participant.registrationModal.views.registrationParticipantList.title"             : "Participant Registration",
    "participant.registrationModal.views.registrationParticipantList.btn.search"        : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "Search Participant" : "Search HCR Participant",
    "participant.registrationModal.views.registrationParticipantList.selectParticipant" : "Select the participant from your Spordle Account",
    "participant.registrationModal.views.registrationParticipantList.noParticipant"     : "You have no participant in your Spordle Account",

    // Registration participant single
    "participant.registrationModal.views.registrationParticipantSingle.edit"               : "Edit this member's registration",
    "participant.registrationModal.views.registrationParticipantSingle.noParticipant"      : "This participant is associated with {associatedOrg}. To proceed with {currentOrg}, you need to send a registration request.",
    "participant.registrationModal.views.registrationParticipantSingle.btn.goTo"           : "Go to",
    "participant.registrationModal.views.registrationParticipantSingle.btn.proceedRequest" : "Proceed with request",
    "participant.registrationModal.views.registrationParticipantSingle.age.warning"        : "This member is under 4 years old, therefore it's possible that this member may not be able to participate.",
    "participant.registrationModal.views.registrationParticipantSingle.draft"              : "Incomplete registration",

    // Registration HCR Search
    "participant.registrationModal.views.registrationHcrSearch.btn.create"                    : "Create a new participant",
    "participant.registrationModal.views.registrationHcrSearch.primaryOrg"                    : "Primary Organization",
    "participant.registrationModal.views.registrationHcrSearch.search.firstName.regex"        : "First name must be of a valid format",
    "participant.registrationModal.views.registrationHcrSearch.search.lastName.regex"         : "Last name must be of a valid format",
    "participant.registrationModal.views.registrationHcrSearch.search.hcrNb"                  : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "Search by member ID" : "Search by HCR #",
    "participant.registrationModal.views.registrationHcrSearch.search.result.profile"         : "We found matching profile(s). Is this you?",
    "participant.registrationModal.views.registrationHcrSearch.search.result.noProfile"       : "We could not find your profile, would you like to create a new participant?",
    "participant.registrationModal.views.registrationHcrSearch.search.result.noProfileSimple" : "We could not find your profile. Please use the search filters above to change your search.",
    "participant.registrationModal.views.registrationHcrSearch.search.result.text"            : "None of these profiles are mine, proceed with the participant creation.",
    "participant.registrationModal.views.registrationHcrSearch.title.search"                  : "Search participant",
    "participant.registrationModal.views.registrationHcrSearch.title.create"                  : "Register a new participant",
    "participant.registrationModal.views.registrationHcrSearch.date.minAge"                   : "A member must be at least 4 years old to participate. However, you may still create this member, but the organization may not accept the registration.",
    "participant.registrationModal.views.registrationHcrSearch.date.minDate"                  : "Birthdate must be before today.",
    "participant.registrationModal.views.registrationHcrSearch.date.placeholder"              : "YYYY-MM-DD",
    "participant.registrationModal.views.registrationHcrSearch.alreadyInAccount"              : "This member is already in your account!",
    "participant.registrationModal.views.registrationHcrSearch.notAlreadyInAccount"           : "This member is already in another account!",
    "participant.registrationModal.views.registrationHcrSearch.wrongOrg"                      : "This member is part of another organization!",

    // Registration options
    "participant.registrationModal.views.registrationOptions.noRegistration" : "No registration available for {orgName}",
}