/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {spordleSelectStitches}from'./stitches.js';// MOBILE MENU
// Things to be wary of :
// - iOs (Fixed): fixed does not work when an input is focused
// - Android (Fixed/Absolute): Pre 4.0, you need to set the top position also, or a small margin will be added for some reason
// - Mobile (Transform): Hardware-acceleration
const DARK_RGB = [52, 58, 64]; // RGB
const ANIMATION_DURATION = 300;
const SHOWN_CLASS_NAME = 'spordleSelect-mobileView-shown';
const HIDDEN_CLASS_NAME = 'spordleSelect-mobileView-hidden';
const Container = spordleSelectStitches.styled('aside', {
    backgroundColor: `rgba(${DARK_RGB.toString()}, 0.7)`,
    bottom: 0,
    contain: "layout paint",
    left: 0,
    height: "100%",
    opacity: 0,
    transform: "translate3d(0,0,0)",
    pointerEvents: 'none',
    right: 0,
    top: 0,
    zIndex: 100000,
    position: 'fixed',
    transition: `opacity ${ANIMATION_DURATION / 2}ms ease-in-out`,
    touchAction: 'none',
    [`&.${SHOWN_CLASS_NAME}`]: {
        opacity: 1,
        pointerEvents: 'auto',
    },
    variants: {
        search: { true: {} },
        device: {
            android: {},
            ios: {
                overscrollBehavior: 'contain',
                overflow: "scroll",
            },
        },
    },
    compoundVariants: [
        {
            search: true,
            device: "android",
            css: {
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-end",
            },
        },
    ],
}, {
    /* -- HEIGHTS CONTROLLED FALLBACKS --
    magic mobile viewport iOS bug fix
    also see: https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/
    also see: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
    minHeight: "-webkit-fill-available",
});
const InnerContainer = spordleSelectStitches.styled('div', {
    backgroundColor: '#fff',
    boxSizing: "border-box",
    borderTopLeftRadius: `${0.3}rem`,
    borderTopRightRadius: `${0.3}rem`,
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    maxHeight: "80%",
    overflow: 'hidden',
    padding: '$padding3 0 0 0',
    position: 'absolute',
    right: 0,
    variants: {
        search: { true: {} },
        device: {
            ios: {
                borderBottomLeftRadius: `${0.3}rem`,
                borderBottomRightRadius: `${0.3}rem`,
                bottom: 'unset',
                top: '50%',
                left: 0,
                right: 0,
                margin: '2vw',
                transform: "scale(1) translateY(-50%)",
                transition: `transform ${ANIMATION_DURATION / 2.25}ms linear`,
                minHeight: '50%',
                [`.${SHOWN_CLASS_NAME} &`]: {
                    transform: "scale(1) translateY(-50%)",
                    bottom: 'unset',
                },
                [`.${HIDDEN_CLASS_NAME} &`]: {
                    transform: "scale(0.5) translateY(-50%)",
                    bottom: 'unset',
                },
            },
            android: {
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                minHeight: 0,
                flexShrink: 1,
                transformStyle: "preserve-3d",
                transition: `transform ${ANIMATION_DURATION}ms linear`,
                transform: "translateY(100%)",
                [`.${SHOWN_CLASS_NAME} &`]: {
                    transform: "translateY(0)",
                },
                [`.${HIDDEN_CLASS_NAME} &`]: {
                    transform: "translateY(100%)",
                },
            },
        },
    },
    compoundVariants: [
        {
            search: true,
            device: "android",
            css: {
                top: "auto",
                [`.${SHOWN_CLASS_NAME} &`]: {
                    top: "auto",
                },
                [`.${HIDDEN_CLASS_NAME} &`]: {
                    top: "100%",
                },
            },
        },
    ],
});
const List = spordleSelectStitches.styled('ul', {
    display: 'block',
    listStyleType: 'none',
    maxHeight: '100%',
    minHeight: 0,
    flexShrink: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingLeft: 0,
    paddingBottom: '$padding2',
    marginBottom: 0,
    variants: {
        device: {
            ios: {
                /* also see: https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/ */
                /* also see: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
                maxHeight: "-webkit-fill-available",
                "-webkit-overflow-scrolling": "touch",
            },
            android: {},
        },
    },
});
const shownScrollbar = spordleSelectStitches.css({
    // https://stackoverflow.com/questions/22907777/make-scrollbar-visible-in-mobile-browsers
    "&::-webkit-scrollbar": {
        width: '8px !important',
    },
    "&::-webkit-scrollbar-track": {
        "-webkit-appearance": "none",
        // "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3) !important",
        "background": "#dadada !important",
    },
    "&::-webkit-scrollbar-thumb": {
        // "-webkit-border-radius": "10px !important",
        "background": "#67757c !important",
        // "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.5) !important",
    },
    "&::-webkit-scrollbar-thumb:window-inactive": {
        "background": "#41617D !important",
    },
});
const PopupSection = spordleSelectStitches.styled('div', {
    display: 'block',
    px: '$padding3',
});
const HeaderSection = spordleSelectStitches.styled('header', {
    display: 'flex',
    px: '$padding3',
});
const MobileSearchInput = spordleSelectStitches.styled('input', {
    fontSize: '16px !important',
    width: '100%',
    '&::-webkit-search-cancel-button': {
        appearance: 'none',
        display: 'none',
    },
    '&::-webkit-search-results-button': {
        display: 'none',
    },
    '&::-webkit-search-results-decoration': {
        display: 'none',
    },
    '&::-webkit-search-decoration': {
        display: 'none',
    },
    '&::-ms-clear': {
        display: 'none',
        height: 0,
        width: 0,
    },
    '&::-ms-reveal': {
        display: 'none',
        height: 0,
        width: 0,
    },
});
const Title = spordleSelectStitches.styled('label', {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 0,
});
const CloseButton = spordleSelectStitches.styled('button', {
    border: 'none',
    marginLeft: 'auto',
    '&::before': {
        content: "X",
    },
});
const NoResults = spordleSelectStitches.styled('div', {
    textAlign: 'center',
    opacity: 0.5,
    py: '$padding',
});
const ClearBtn = spordleSelectStitches.styled('button', {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    marginLeft: 'auto',
    lineHeight: 2,
    transition: `height ${ANIMATION_DURATION / 2}ms linear`,
    overflow: "hidden",
    variants: {
        isShown: {
            true: {
                height: "2em",
                opacity: 1,
            },
            false: {
                height: "0em",
                opacity: 0,
                pointerEvents: "none",
            },
        },
    },
});
const HeaderWrapper = spordleSelectStitches.styled('div', {
    paddingBottom: '$padding',
    position: 'relative',
    zIndex: 1000,
});export{ANIMATION_DURATION,ClearBtn,CloseButton,Container,HIDDEN_CLASS_NAME,HeaderSection,HeaderWrapper,InnerContainer,List,MobileSearchInput,NoResults,PopupSection,SHOWN_CLASS_NAME,Title,shownScrollbar};
/*
@Copyrights Spordle 2022 - All rights reserved
*/