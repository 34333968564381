/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Route, Switch, Redirect, matchPath } from 'react-router-dom';
import { Badge } from 'reactstrap';

// Components
import Header from './layout-components/header/header';
import Footer from './layout-components/footer/footer';

// Routes
import { PageRoutes } from '../routes/pageRoutes';

// Context Providers
import { AuthContext } from '../contexts/AuthContext';
import { OrganizationContext } from '../contexts/OrganizationContext';
import withContexts from '../helpers/withContexts';
import getReferrer from '../helpers/getReferer';
import { I18nContext } from '../contexts/I18nContext';
import setDefaultComponentProps from '../helpers/setDefaultComponentProps';
import ForeThought from '../components/ForeThought';

class Fulllayout extends React.Component{
    constructor(props){
        super(props);
        this.routes = [
            ...PageRoutes,
        ];
        setDefaultComponentProps();
    }

    // getSubDomaine = () => {
    //     return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    // }

    // getDemoBadge = () => {
    //     const subdomain = this.getSubDomaine();
    //     switch (subdomain){ // subdomain
    //         case "dev":
    //         case "api-dev":
    //             return "DEV";
    //         case "stage":
    //             return `STAGE (${process.env.REACT_APP_BUILD_DATE})`;
    //         case "hcr-demo":
    //         case "hcr":
    //             return `HCR DEMO (${process.env.REACT_APP_BUILD_DATE})`;
    //         case "sid-demo":
    //             return `SID DEMO (${process.env.REACT_APP_BUILD_DATE})`;
    //         default:
    //             return "";
    //     }
    // }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook, Applies when loading or resizing App                           */
    /*--------------------------------------------------------------------------------*/
    componentDidMount(){
        // window.addEventListener("load", this.updateDimensions);

        // this.props.OrganizationContext.getOrganizationsTree();
        this.props.OrganizationContext.setFederation()
            .catch((err) => console.error(err));
        // window.addEventListener("resize", this.updateDimensions);
        // this.updateDimensions();
    }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook                                                                 */
    /*--------------------------------------------------------------------------------*/
    componentWillUnmount(){
        // window.removeEventListener("load", this.updateDimensions);
        // window.removeEventListener("resize", this.updateDimensions);
    }

    render(){
        const shortUrl = matchPath(this.props.location.pathname, {
            path: `/page/:orgid+`,
        })?.params?.orgid;

        if(shortUrl === "hc" && process.env.NODE_ENV === 'production'){
            window.location.replace(getReferrer('HCR-SITE', this.props.I18nContext.getGenericLocale()));
            return null;
        }

        /*--------------------------------------------------------------------------------*/
        /* Theme Setting && Layout Options will be Change From Here                       */
        /*--------------------------------------------------------------------------------*/
        return (
            <>
                {process.env.REACT_APP_ENVIRONMENT !== "prod" && process.env.NODE_ENV !== 'development' &&
                    <Badge color="warning" className="ml-3 position-fixed font-bold font-10" pill style={{ left: "10px", bottom: "10px", zIndex: "9999", color: "white", lineHeight: "unset" }}>
                        {process.env.REACT_APP_ENVIRONMENT} {process.env.REACT_APP_BUILD_DATE}
                    </Badge>
                }

                {/*--------------------------------------------------------------------------------*/}
                {/* Header                                                                         */}
                {/*--------------------------------------------------------------------------------*/}
                <Header {...this.props} routes={[]} />

                {/*--------------------------------------------------------------------------------*/}
                {/* Page Main-Content                                                              */}
                {/*--------------------------------------------------------------------------------*/}
                <main role="main" className="pb-4 pt-0 pt-md-4 container">
                    <AuthContext.Consumer>
                        {({ accessToken, userLevel, userData }) => (
                            <>
                                {process.env.REACT_APP_ENVIRONMENT === "uat" &&
                                    <ForeThought
                                        locale={this.props.I18nContext.getGenericLocale()}
                                        authenticated={!!accessToken}
                                        fullName={userData ? userData.attributes?.name + ' ' + userData.attributes?.family_name : ""}
                                    />
                                }
                                <Switch>
                                    {this.routes.map((prop, key) => {
                                        if(prop.userLevelExclude){
                                            for(let index = 0; index < prop.userLevelExclude.length; index++){
                                                const element = prop.userLevelExclude[index];
                                                if(Array.isArray(element.userLevel) && element.userLevel.includes(userLevel)){
                                                    if(typeof element.redirectTo === 'string')
                                                        return <Redirect from={prop.path} to={element.redirectTo} key={key} exact={(prop.exact)} />
                                                    return <element.redirectTo key={key} {...prop} exact={(prop.exact)} />;
                                                }
                                                if(element.userLevel === userLevel){
                                                    if(typeof element.redirectTo === 'string')
                                                        return <Redirect from={prop.path} to={element.redirectTo} key={key} exact={(prop.exact)} />
                                                    return <element.redirectTo key={key} {...prop} exact={(prop.exact)} />;
                                                }
                                            }
                                        }
                                        if(prop.navlabel){
                                            return null;
                                        }else if(prop.collapse){
                                            return prop.child.map((prop2, key2) => {
                                                if(prop2.collapse){
                                                    return prop2.subchild.map((prop3, key3) => {
                                                        return (
                                                            <Route path={prop3.path} component={prop3.component} key={key3} exact={(prop.exact)} />
                                                        );
                                                    });
                                                }
                                                return (
                                                    <Route path={prop2.path} component={prop2.component} key={key2} exact={(prop.exact)} />
                                                );
                                            });
                                        }else if(prop.redirect){
                                            return <Redirect from={prop.path} to={prop.pathTo} key={key} exact={(prop.exact)} />;
                                        }

                                        return (
                                            <Route path={prop.path} component={prop.component} key={key} exact={(prop.exact)} />
                                        );

                                    })}
                                </Switch>
                            </>
                        )}
                    </AuthContext.Consumer>
                </main>
                <Footer />
            </>
        );
    }
}

export default withContexts(OrganizationContext, I18nContext)(Fulllayout);
