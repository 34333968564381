/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Router, Switch, Route, useHistory } from "react-router-dom";
import { history } from './helpers';

// Routes
import PublicRoute from './routes/PublicRoutes';

import routes from './routes/routes';
import customRoutes from './routes/customRoutes';

// Context Providers
import I18nContextProvider, { I18nContext } from './contexts/I18nContext';
import AuthContextProvider from './contexts/AuthContext';
import OnlineStoreContextProvider from './contexts/OnlineStoreContext';
import FormsContextProvider from './contexts/FormsContext';
import OrganizationContextProvider from './contexts/OrganizationContext';
import CartsContextProvider from './contexts/CartsContext';
import MembersContextProvider from './contexts/MembersContext';
import AccountsContextProvider from './contexts/AccountsContext';
import AddressTypesContextProvider from './contexts/AddressTypesContext';
import ResquestContextProvider from './contexts/ResquestContext';
import UtilsContextProvider, { UtilsContext } from './contexts/UtilsContext';
import RegistrationContextProvider from './contexts/RegistrationContext';
import PhoneTypesContextProvider from './contexts/PhoneTypesContext';
import PositionsContextProvider from './contexts/PositionsContext';

/*eslint indent: "off"*/
import { I18nProvider } from './i18n';

import { ErrorBoundary as SentryErrorBoundary, withProfiler as withSentryProfiler } from '@sentry/react';
import FatalError from './FatalError';

import BrowserNotSupported from './BrowserNotSupported';
import { GApageView } from '@spordle/ga4';
import SpordleToast from '@spordle/toasts';
import { FormikElementsGlobalConfigs } from '@spordle/formik-elements';
import { isProdFishNChips } from './helpers/getReferer';

// conventional commit test 3
// Amplify force build 5
class App extends React.Component{
    constructor(props){
        super(props);
        this.isSupportedBrowser = new RegExp(process.env.REACT_APP_USERAGENT_REGEX.substring(1, process.env.REACT_APP_USERAGENT_REGEX.length - 1)).test(window.navigator.userAgent);
    }

    render(){
        return (
            // <React.StrictMode>
                <SentryErrorBoundary fallback={FatalError} showDialog>
                    <Router basename="/" history={history}>

                    <GA4Routing />

                    <I18nContextProvider>
                    <AuthContextProvider>
                    <OrganizationContextProvider>
                    <OnlineStoreContextProvider>
                    <FormsContextProvider>
                    <AccountsContextProvider>
                    <CartsContextProvider>
                    <MembersContextProvider>
                    <AddressTypesContextProvider>
                    <ResquestContextProvider>
                    <PhoneTypesContextProvider>
                    <UtilsContextProvider>
                    <PositionsContextProvider>
                    <RegistrationContextProvider>
                        <UtilsContext.Consumer>
                            {(utilsContext) => (
                                <FormikElementsGlobalConfigs
                                    formikPhoneInput={{
                                        suggestedCountries: isProdFishNChips() ? [ 'gb' ] : [ 'ca', 'us' ],
                                        defaultSelectedCountry: isProdFishNChips() ? 'gb' : 'ca',
                                    }}
                                    formikDateTime={{
                                        dateFormat: 'YYYY-MM-DD',
                                    }}
                                    formikAddress={{
                                        getCountries: utilsContext.getCountries,
                                        getProvinces: utilsContext.getProvinces,
                                        countryRestrictions: isProdFishNChips() ? [ 'gb' ] : [ 'ca', 'us' ],
                                    }}
                                >
                                    <I18nContext.Consumer>
                                        {({ locale }) => (
                                            <I18nProvider locale={locale}>
                                            <SpordleToast config={{ position: { top: 60 } }} />
                                                {/* <HelpCenter /> */}
                                                <Switch>
                                                    {!this.isSupportedBrowser &&
                                                        <Route component={BrowserNotSupported} />
                                                    }
                                                    {customRoutes.map((prop, key) => {
                                                        if(process.env.REACT_APP_ENVIRONMENT !== 'prod')
                                                            return <PublicRoute path={prop.path} key={key} component={prop.component} />;
                                                        else if(prop.inProduction)
                                                            return <PublicRoute path={prop.path} key={key} component={prop.component} />;
                                                        // No route created if IN production AND inProduction IS false
                                                        return null;
                                                    })}
                                                    {routes.map((prop, key) => {
                                                        return <PublicRoute path={prop.path} key={key} component={prop.component} />;
                                                    })}
                                                </Switch>
                                            </I18nProvider>
                                        )}
                                    </I18nContext.Consumer>
                                </FormikElementsGlobalConfigs>
                            )}
                        </UtilsContext.Consumer>
                    </RegistrationContextProvider>
                    </PositionsContextProvider>
                    </UtilsContextProvider>
                    </PhoneTypesContextProvider>
                    </ResquestContextProvider>
                    </AddressTypesContextProvider>
                    </MembersContextProvider>
                    </CartsContextProvider>
                    </AccountsContextProvider>
                    </FormsContextProvider>
                    </OnlineStoreContextProvider>
                    </OrganizationContextProvider>
                    </AuthContextProvider>
                    </I18nContextProvider>
                    </Router>
                </SentryErrorBoundary>
            // </React.StrictMode>
        )
    }
}

const GA4Routing = () => {
    const routerHistory = useHistory();
    useEffect(() => {
        GApageView(routerHistory.location.pathname);
        const historyListener = routerHistory.listen((location) => {
			GApageView(location.pathname);
        });
        return historyListener;
    }, [])
    return null;
}

export default withSentryProfiler(App, { disabled: process.env.NODE_ENV === 'development' });