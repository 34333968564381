export default{
    "contacts.btn.website" : "Site web",

    "contacts.helmet.title"       : "Contact",
    "contacts.helmet.description" : "Information pour contacter l'organisation {org}.",

    "contacts.list.primary" : "Contacts principaux",

    "contacts.page.name" : "Contact",

}