/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,Fragment}from'react/jsx-runtime';import {useContext}from'react';import PropTypes from'prop-types';import moment from'moment';import {IntlElementsContext}from'./IntlElementsContext.js';/**
 * @member {Object} formats
 * @memberof DateFormat
 * @description A list of predefined moment format to be passed to the `format` prop
 * @prop {'LLLL'} longFullText `'Thursday, September 4, 1986 8:30 PM'`
 * @prop {'llll'} shortFullText `'Thu, Sep 4, 1986 8:30 PM'`
 * @prop {'LLL'} longMonthDayYearTime `'September 4, 1986 8:30 PM'`
 * @prop {'lll'} shortMonthDayYearTime `'Sep 4, 1986 8:30 PM'`
 * @prop {'LL'} longMonthDayYear `'September 4, 1986'`
 * @prop {'ll'} shortMonthDayYear `'Sep 4, 1986'`
 * @readonly
 */
/**
 * @typedef {Object} dateFormats
 * @description A list of predefined moment format to be passed to the `format` prop
 * @prop {'LLLL'} longFullText `'Thursday, September 4, 1986 8:30 PM'`
 * @prop {'llll'} shortFullText `'Thu, Sep 4, 1986 8:30 PM'`
 * @prop {'LLL'} longMonthDayYearTime `'September 4, 1986 8:30 PM'`
 * @prop {'lll'} shortMonthDayYearTime `'Sep 4, 1986 8:30 PM'`
 * @prop {'LL'} longMonthDayYear `'September 4, 1986'`
 * @prop {'ll'} shortMonthDayYear `'Sep 4, 1986'`
 * @readonly
 */
/**
 * Display a Date according to the I18nProvider locale
 * @prop {string} [format=YYYY-MM-DD] The date format to show
 * @prop {*} [value] The date to parse
 * @prop {*} [valueFormat] For non-standard date formats, specify this so moment can parse it correctly. {@link https://momentjs.com/docs/#/parsing/string-formats|Doc}
 * @prop {boolean} [strict] Will identify the parsing error and set the Moment object as invalid.
 * @prop {boolean} [utc=false] If the date should be parsed as a UTC date.
 * @returns {React.ReactNode} A date format
 * @see https://momentjs.com/docs
 * @copyright Spordle
 * @example
 * <DateFormat /> // Now
 * <DateFormat value={refundItem.paymentDate} />
 * <DateFormat value={refundItem.paymentDate} format={dateFormats.shortMonthDayYear} strict valueFormat='DD MM YYYY hh:mm:ss' />
 */
const DateFormat = ({ value, valueFormat, strict, format, utc }) => {
    const globalConfigs = useContext(IntlElementsContext);
    const finalFormat = format ?? globalConfigs?.dateformat?.format ?? defaultProps.format;
    const finalValueFormat = valueFormat ?? globalConfigs?.dateformat?.valueFormat;
    const finalStrict = strict ?? globalConfigs?.dateformat?.strict;
    const finalUtc = utc ?? globalConfigs?.dateformat?.utc;
    const parsedDate = finalUtc ? moment.utc(value, finalValueFormat, finalStrict) : moment(value, finalValueFormat, finalStrict);
    return jsx(Fragment, { children: parsedDate.format(finalFormat) });
};
const dateFormats = {
    longFullText: 'LLLL',
    shortFullText: 'llll',
    longMonthDayYearTime: 'LLL',
    shortMonthDayYearTime: 'lll',
    longMonthDayYear: 'LL',
    shortMonthDayYear: 'll',
};
DateFormat.formats = dateFormats;
DateFormat.propTypes = {
    format: PropTypes.string.isRequired,
    strict: PropTypes.bool,
    utc: PropTypes.bool,
};
const defaultProps = {
    format: 'YYYY-MM-DD',
};
DateFormat.displayName = 'DateFormat';export{dateFormats,DateFormat as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/