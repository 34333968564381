// this object exist only to gave a global control over different views
export default{
    login: 'login',
    existingOrNew: 'existingOrNew',
    startParticipantCreation: 'startParticipantCreation', // redirects to hcrSearch with an additionnal prop
    hcrSearch: 'hcrSearch',
    newParticipant: 'newParticipant',
    participantList: 'participantList',
    request: 'request',
    registrationOptions: 'registrationOptions',
    selectItems: 'selectItems',
    customQuestions: 'customQuestions',
    moreParticipant: 'moreParticipant',
    confirmAddress: 'confirmAddress',
    terms: 'terms',
    summary: 'summary',
    modalities: 'modalities',
    gateway: 'gateway',
    confirmation: 'confirmation',
}