// I didn't want to add language variable in the same file that gabby was working in, cause you know, git
// so sue me
export default{
    // registration options
    "participant.registrationModal.views.registrationOptions.title"             : "Registration Options",
    "participant.registrationModal.views.registrationOptions.available"         : "Available Registrations",
    "participant.registrationModal.views.registrationOptions.available.none"    : "No available registrations",
    "participant.registrationModal.views.registrationOptions.required"          : "Please chose a registration",
    "participant.registrationModal.views.registrationOptions.early"             : "Early fee",
    "participant.registrationModal.views.registrationOptions.late"              : "Late fee",
    "participant.registrationModal.views.registrationOptions.alreadyRegistered" : "Already registered",
    "participant.registrationModal.views.registrationOptions.waitingList"       : "Waiting list",
    "participant.registrationModal.views.registrationOptions.waitingList.hint"  : "This registration is full, but a waiting list is available and you can register to it.",
    "participant.registrationModal.views.registrationOptions.waitingList.hint2" : "If you are selected to participate, you will receive an email inviting you to come back to this page to complete your registration.",
    "participant.registrationModal.views.registrationOptions.waitingList.hint3" : "You will have the option to decline an invitation in case you change your mind.",
    "participant.registrationModal.views.registrationOptions.placesLeft"        : "{amount, plural, =0 {No places left} one {# place left} other {# places left}}",

    "participant.registrationModal.views.registrationOptions.outstandingBalance" : "This member has a balance to pay for the organization, he or she cannot register before regularizing the situation with the organization.",

    "participant.registrationModal.views.registrationOptions.none"          : "None",
    "participant.registrationModal.views.registrationOptions.none.subtitle" : "Skip the registration selection",

    // custom forms
    "participant.registrationModal.views.registrationCustomQuestions.title"         : "Questionnaire",
    "participant.registrationModal.views.registrationCustomQuestions.count"         : "Questionnaire(s) ({actual} / {max})",
    "participant.registrationModal.views.registrationCustomQuestions.required"      : "This field is required",
    "participant.registrationModal.views.registrationCustomQuestions.min"           : "Please respect the minimum value",
    "participant.registrationModal.views.registrationCustomQuestions.max"           : "Please respect the maximum value",
    "participant.registrationModal.views.registrationCustomQuestions.label.between" : "Between",
    "participant.registrationModal.views.registrationCustomQuestions.label.and"     : "and",
    "participant.registrationModal.views.registrationCustomQuestions.label.min"     : "Minimum",
    "participant.registrationModal.views.registrationCustomQuestions.label.max"     : "Maximum",

    // other items
    "participant.registrationModal.views.registrationSelectItems.title"      : "Items Selection",
    "participant.registrationModal.views.registrationSelectItems.mandatory"  : "Mandatory Items",
    "participant.registrationModal.views.registrationSelectItems.optional"   : "Optional Items",
    "participant.registrationModal.views.registrationSelectItems.selected"   : "Selected Items",
    "participant.registrationModal.views.registrationSelectItems.atLeastOne" : "At least one item is required", // when the user skips registrations

    // waivers
    "participant.registrationModal.views.registrationWaivers.title"    : "Waivers",
    "participant.registrationModal.views.registrationWaivers.of"       : "of",
    "participant.registrationModal.views.registrationWaivers.accept"   : "I have read and accept the waiver",
    "participant.registrationModal.views.registrationWaivers.required" : "This waiver is mandatory",
    "participant.registrationModal.views.registrationTerms.title"      : "Payment Terms and Conditions",
    "participant.registrationModal.views.registrationTerms.accept"     : "I have read and accept the terms and conditions",
    "participant.registrationModal.views.registrationTerms.required"   : "Please check the box above to confirm that you accept these terms and conditions",

    // more participants
    "participant.registrationModal.views.registrationMoreParticipants.title"    : "Register a participant",
    "participant.registrationModal.views.registrationMoreParticipants.topText"  : "Would you like to register another participant?",
    "participant.registrationModal.views.registrationMoreParticipants.another"  : "Register another participant",
    "participant.registrationModal.views.registrationMoreParticipants.nextStep" : "Proceed to check out",

    // summary
    "participant.registrationModal.views.registrationSummary.title"                     : "Registration summary",
    "participant.registrationModal.views.registrationSummary.orderSummary"              : "Order summary",
    "participant.registrationModal.views.registrationSummary.for"                       : "Registration for",
    "participant.registrationModal.views.registrationSummary.edit"                      : "Edit this member's registration",
    "participant.registrationModal.views.registrationSummary.removeMember"              : "Remove this member",
    "participant.registrationModal.views.registrationSummary.removeMember.confirmation" : "This member will be removed from your order",
    "participant.registrationModal.views.registrationSummary.registration"              : "Registration",
    "participant.registrationModal.views.registrationSummary.requiredItems"             : "Required items",
    "participant.registrationModal.views.registrationSummary.otherItems"                : "Other items",
    "participant.registrationModal.views.registrationSummary.action"                    : "This action is irreversible",
    "participant.registrationModal.views.registrationSummary.credit"                    : "Available credits",
    "participant.registrationModal.views.registrationSummary.credit.activate"           : "Activate credits for this member",
    "participant.registrationModal.views.registrationSummary.credit.deactivate"         : "Deactivate credits for this member",
    "participant.registrationModal.views.registrationSummary.rebate"                    : "Discount",
    "participant.registrationModal.views.registrationSummary.subtotal"                  : "Subtotal for",
    "participant.registrationModal.views.registrationSummary.total"                     : "Total price",
    "participant.registrationModal.views.registrationSummary.noTaxes"                   : "taxes are not included",

    "participant.registrationModal.views.registrationSummary.removeText"  : "You are about to remove 1 {name}.",
    "participant.registrationModal.views.registrationSummary.removeText2" : "You will be able to add this item only by modifying this member's registration.",

    // installments
    "participant.registrationModal.views.registrationModalities.title"        : "Payment Schedule",
    "participant.registrationModal.views.registrationModalities.payNow"       : "Pay now",
    "participant.registrationModal.views.registrationModalities.total"        : "Total",
    "participant.registrationModal.views.registrationModalities.required"     : "Select an installment modality",
    "participant.registrationModal.views.registrationModalities.payInFull"    : "Full payment",
    "participant.registrationModal.views.registrationModalities.installments" : "{number} installments",

    // confirmation
    "participant.registrationModal.views.registrationConfirm.title"                : "Registration Confirmation",
    "participant.registrationModal.views.registrationConfirm.thankYou"             : "Thank you for registering!",
    "participant.registrationModal.views.registrationConfirm.receiptNumber"        : "Your receipt number is",
    "participant.registrationModal.views.registrationConfirm.emailSentTo"          : "An email confirmation has been sent to",
    "participant.registrationModal.views.registrationConfirm.preAuthorizedPayment" : "The invoice was pre-authorized on your card. The money will be debited from your account once a registrar confirms your invoice.",

    // missing required fields
    "participant.registrationModal.views.missingRequiredFields.title"    : "Missing information",
    "participant.registrationModal.views.missingRequiredFields.text"     : "We need this information in order to let you register your member.",
    "participant.registrationModal.views.missingRequiredFields.text2"    : "Please fill out the fields below.",
    "participant.registrationModal.views.missingRequiredFields.required" : "This field is required",

    // waiting list confirmation
    "participant.registrationModal.views.waitingListConfirmation.title"       : "Waiting list registration confirmation",
    "participant.registrationModal.views.waitingListConfirmation.thanks"      : "Thank you for registering to the waiting list!",
    "participant.registrationModal.views.waitingListConfirmation.number"      : "Waiting list number: ",
    "participant.registrationModal.views.waitingListConfirmation.emailSentTo" : "An email confirmation has been sent to",
    "participant.registrationModal.views.waitingListConfirmation.keepAnEye"   : "Keep an eye on your emails to stay up to date with your registration, we will contact you if necessary.",

    // waiting list return
    "participant.registrationModal.views.waitingListReturn.title"                : "Registration completion",
    "participant.registrationModal.views.waitingListReturn.welcomeBack"          : "Welcome back!",
    "participant.registrationModal.views.waitingListReturn.selected"             : "You have been selected to participate to {registration}.",
    "participant.registrationModal.views.waitingListReturn.continueRegistration" : "Click the {confirm} button to continue your registration for {firstName} {lastName}, or click the {cancel} button to cancel your registration to the waiting list.",
    "participant.registrationModal.views.waitingListReturn.header"               : "Cancellation",
    "participant.registrationModal.views.waitingListReturn.reason"               : "Cancellation reason",
    "participant.registrationModal.views.waitingListReturn.reason.required"      : "Cancellation reason is required",

    // waiting list checkout error
    "participant.registrationModal.views.waitingListCheckoutError.title" : "Waiting list",
    "participant.registrationModal.views.waitingListCheckoutError.text"  : "Unfortunately, the last spot in this clinic was filled while your were completing your registration, we have registered you to the waiting list.",
}