/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import {memo,useContext}from'react';import PropTypes from'prop-types';import {FormattedMessage}from'react-intl';import {IntlElementsContext}from'./IntlElementsContext.js';import useTranslate from'./useTranslate.js';/**
 * @description
 * Translate a given index to the correct language
 * @prop {string} id String index to be translated
 * @prop {object} [values] Object containing indexes and values to be matched with wildcards
 * @prop {string} [defaultMessage] String to be shown as a default message if the id prop is not valid. This prop has priority over `defaultMessageId`
 * @prop {string} [defaultMessageId] String index to be shown as a default message if the id prop is not valid
 * @prop {function} [children] Callback function that provides the translated message
 * @returns {FormattedMessage} A FormattedMessage component
 * @copyright Spordle
 * @access default
 * @example
 * <Translate id='settings.label.edit'/>
 * <Translate id={`settings.error.${formik.status}`}/>
 * <Translate id='misc.priceValue' values={{price: tournament.price}}/>
 * <Translate id='contexts.identityRolesContext.errors.3090' defaultMessageId='misc.error'/>
 * <Translate id='contexts.identityRolesContext.errors.3090' defaultMessageId='misc.error'>{message => <input placeholder={message}/>}</Translate>
 * <Translate id='contexts.identityRolesContext.errors.3090' defaultMessage='Yo big'/>
 */
const Translate = memo(({ id, values, defaultMessageId, defaultMessage, children }) => {
    const globalConfigs = useContext(IntlElementsContext);
    const finalDefaultMessage = defaultMessage ??
        (defaultMessageId ? useTranslate({ id: defaultMessageId, values: values }) : undefined) ??
        globalConfigs?.translate?.defaultMessage ??
        (globalConfigs?.translate?.defaultMessageId ? useTranslate({ id: globalConfigs.translate.defaultMessageId, values: values }) : undefined);
    return (jsx(FormattedMessage, { id: id, values: { ...globalConfigs?.translate?.values, ...values }, defaultMessage: finalDefaultMessage, children: children }));
});
Translate.propTypes = {
    id: PropTypes.string.isRequired,
    values: PropTypes.object,
    defaultMessageId: PropTypes.string,
    children: PropTypes.func,
};
Translate.displayName = 'Translate';export{Translate as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/