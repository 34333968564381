import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import Skeleton from '../../../../components/loading/Skeleton';
import Section from '../../../../components/visual/structure/Section';
import { OnlineStoreContext } from '../../../../contexts/OnlineStoreContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import EmptyRegistrations from './components/EmptyRegistrations';
import StoreSingle from './components/StoreSingle';
import StoresList from './components/StoresList';
import { fail } from '@spordle/toasts';

const Participant = ({ match, ...props }) => {
    const onlineStoreContext = useContext(OnlineStoreContext);
    const [ stores, setStores ] = useState(false);
    const [ currentPath, setCurrentPath ] = useState(false);
    const intl = useIntl();

    // get the "/page/hc/hq/hockey-laurentides-lanaudiere/ahm-lorraine-rosemere"
    const path = props.location.pathname.split('/participant')[0]

    useEffect(() => {
        onlineStoreContext.getOnlineStores({
            active: "1",
            status: "ACTIVE",
            'start_date.lte': new Date().toISOString(),
            'end_date.gte': new Date().toISOString(),
        })
            .then((stores) => {
                setStores(stores)
                // set it to a state so we remember it
                setCurrentPath(path)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    const LoadingRegistration = () => (
        <Section>
            <header>
                <h1 className="section-main-title">
                    <Translate id='storesList.page.title' />
                </h1>
            </header>
            <Skeleton className="mb-2" count={2} height={90} />
        </Section>
    );

    useEffect(() => {
        document.title = intl.formatMessage({ id: 'participant.helmet.title' });
        document.querySelector('meta[name="description"]').setAttribute("content", intl.formatMessage({ id: 'participant.helmet.description' }));
    }, [ intl.locale ])

    return (
        <>
            <Switch>
                {/* compare the state to the current props path to detect an org change and wait for the GET online-store to finish */}
                {((stores?.length == 1 && stores?.[0]?.isSharedStore) && currentPath === path) && <Route path={`${match.url}`} exact><StoresList stores={stores} {...props} /></Route>}
                {(stores?.length > 0 && currentPath === path) && <Route path={`${match.url}/:store`} exact component={StoreSingle} />}
                {((stores?.length > 1) && currentPath === path) && <Route path={`${match.url}`} exact><StoresList stores={stores} {...props} /></Route>}
                {stores === false && <Route><LoadingRegistration /></Route>}
                {(stores?.length == 0 && currentPath === path) && <Route><EmptyRegistrations /></Route>}
                {(stores?.length == 1 && currentPath === path) && <Redirect to={{ pathname: `${match.url}/${stores[0].online_store_id}`, state: { store: stores[0] } }} />}
            </Switch>
        </>
    );
}

export default Participant;