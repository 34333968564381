import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { useContext } from 'react';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import ImgPill from '../../../../../../../components/visual/imgPill/ImgPill';
import { CartsContext } from '../../../../../../../contexts/CartsContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

const RegistrationParticipantCard = ({ participant, className }) => {
    const cartsContext = useContext(CartsContext);
    const registration = cartsContext.state?.selectedItems?.[participant.members[0]?.member_id];

    return (
        <UserDisplay card className={stringBuilder('w-100', className)}>
            <UserDisplay.Container>
                <ImgPill
                    size="sm"
                    abbr={participant.first_name.charAt(0) + participant.last_name.charAt(0)}
                    src={participant.photo}
                    alt={participant.first_name + ' ' + participant.last_name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <div className="d-flex">
                    <UserDisplay.Title className="mr-2">{participant.first_name} {participant.last_name}</UserDisplay.Title>
                    <UserDisplay.Subtitle className="flex-shrink-0">({participant.members[0]?.age} <Translate id='misc.yo' />)</UserDisplay.Subtitle>
                </div>
                {participant.members[0]?.unique_identifier &&
                    <UserDisplay.Subtitle>
                        <Translate id='misc.hcr#' /> {participant.members[0]?.unique_identifier}
                    </UserDisplay.Subtitle>
                }
                { registration &&
                    <UserDisplay.Subtitle>
                        <DisplayI18n
                            field='name'
                            i18n={registration?.i18n}
                            defaultValue={registration?.name}
                        />
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default RegistrationParticipantCard;