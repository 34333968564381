import { FormikError } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { CustomInput } from "reactstrap";
import Required from "../components/formik/Required";
import UserDisplay from "../components/userDisplay/UserDisplay";
import { DisplayI18n } from "./i18nHelper";

export const waiverNotSigned = 'NOT_SIGNED_YET';
export const waiverAccepted = 'YES';
export const waiverDeclined = 'NO';

export const WaiverFields = ({ waiver, allowSkip, fieldName }) => {
    const formik = useFormikContext();

    return (
        <>
            <div className='waiver-helper'>
                <i className='waiver-helper-icon mdi mdi-information-outline' />
                <div className='small'>
                    {waiver.mandatory == 1 ?
                        <>
                            <Translate id="form.fields.waiver.mandatory" /> <Required className="ml-1" />
                        </>
                        :
                        <Translate id='form.fields.waiver.notMandatory' />
                    }
                </div>
            </div>

            <UserDisplay tag="label" htmlFor='waiverAcceptOption' card hover className={`mb-2 w-100 ${formik.values[fieldName] === waiverAccepted ? 'border-info' : ''}`}>
                <UserDisplay.Container className="mr-0">
                    <CustomInput
                        id='waiverAcceptOption' type="radio"
                        name="waiverOption" // name does not matter, as long as it's the same for all options
                        onChange={() => formik.setFieldValue(fieldName, waiverAccepted)}
                    />
                </UserDisplay.Container>
                <UserDisplay.Container className='d-flex align-items-center'>
                    <div className='font-weight-bold text-dark w-100'>
                        {waiver?.confirm_label ?
                            <DisplayI18n field='confirm_label' defaultValue={waiver?.confirm_label} i18n={waiver?.i18n} />
                            :
                            <Translate id='form.fields.waiver.defaultAccept' />
                        }
                    </div>
                </UserDisplay.Container>
            </UserDisplay>

            {waiver?.decline_label &&
                <UserDisplay tag="label" htmlFor='waiverDeclineOption' card hover className={`mb-2 w-100 ${formik.values[fieldName] === waiverDeclined ? 'border-info' : ''}`}>
                    <UserDisplay.Container className="mr-0">
                        <CustomInput
                            id='waiverDeclineOption' type="radio"
                            name="waiverOption" // name does not matter, as long as it's the same for all options
                            onChange={() => formik.setFieldValue(fieldName, waiverDeclined)}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container className='d-flex align-items-center'>
                        <div className='font-weight-bold text-dark w-100'>
                            <DisplayI18n field='decline_label' defaultValue={waiver?.decline_label} i18n={waiver?.i18n} />
                        </div>
                    </UserDisplay.Container>
                </UserDisplay>
            }

            {allowSkip &&
                <UserDisplay tag="label" htmlFor='waiverSkipOption' card hover className={`mb-2 w-100 ${formik.values[fieldName] === waiverNotSigned ? 'border-info' : ''}`}>
                    <UserDisplay.Container className="mr-0">
                        <CustomInput
                            id='waiverSkipOption' type="radio"
                            name="waiverOption" // name does not matter, as long as it's the same for all options
                            onChange={() => formik.setFieldValue(fieldName, waiverNotSigned)}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container className='d-flex align-items-center'>
                        <div className='font-weight-bold text-dark w-100'>
                            <Translate id='form.fields.waiver.doNotSign' />
                        </div>
                    </UserDisplay.Container>
                </UserDisplay>
            }
            <FormikError name={fieldName} />
        </>
    )
}