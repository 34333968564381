import Translate from '@spordle/intl-elements';
import SpordleLogo from '../../../assets/images/spordleSuite/logoSpordleGray.png'

const Footer = () => {
    return (
        <footer className="container py-3">
            <small className="text-muted d-flex justify-content-between align-items-md-center flex-column-reverse flex-md-row flex-wrap">
                <Translate id='footer.copyright' values={{ year: new Date().getFullYear() }} />
                <a className="py-1" href="https://www.spordle.com/" target="_blank" id='spordle-website' rel="noreferrer">
                    <img className="footer-logo" src={SpordleLogo} alt="Spordle" />
                </a>
            </small>
            {/* <Row>
                <Col tag="nav" className="d-flex">
                    <h4 className="mr-3">Spordle</h4>
                    <ul className="list-unstyled footer-nav">
                        <li><a className="d-block" href="https://www.spordle.com/" target="_blank">Home</a></li>
                        <li><a className="d-block" href="">Discovery</a></li>
                        <li><a className="d-block" href="">Photos</a></li>
                        <li><a className="d-block" href="https://www.spordle.com/contact/" target="_blank">Contact</a></li>
                        <li><a className="d-block" href="https://www.spordle.com/about/" target="_blank">About</a></li>
                        <li><a className="d-block" href="https://spordle.atlassian.net/servicedesk/customer/portal/1/group/1/create/1" target="_blank">Help</a></li>
                        <li><a className="d-block" href="https://www.spordle.com/terms/" target="_blank">Terms</a></li>
                        <li><a className="d-block" href="">Guidelines</a></li>
                        <li><a className="d-block" href="">Testimonies</a></li>
                        <li><a className="d-block" href="https://spordle.atlassian.net/servicedesk/customer/portal/1/group/1/create/20" target="_blank">Advertise</a></li>
                        <li><a className="d-block" href="">Integrations</a></li>
                        <li><a className="d-block" href="https://www.spordle.com/fr/nouvelles-blogues-carriere/?careers=1" target="_blank">Careers</a></li>
                    </ul>
                </Col>
                <Col tag="form" lg="3">
                    <div className="footer-newsletter-input-container">
                        <Input type="email" placeholder="Email"></Input>
                        <button className="footer-newsletter-input-btn">
                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                    </div>
                    <small>Restez à l'affût avec Spordle</small>
                </Col>
                <Col lg="3">
                    <ul className="list-inline d-flex justify-content-end">
                        <li className="list-inline-item">
                            <a href="https://www.instagram.com/explore/locations/145683930/canada/boisbriand-quebec/spordle/?hl=en" target="_blank"><i className="mdi mdi-instagram"></i></a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://twitter.com/spordle" target="_blank"><i className="mdi mdi-twitter"></i></a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.facebook.com/spordle/" target="_blank"><i className="mdi mdi-facebook"></i></a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.spordle.com/" target="_blank"><i className="mdi mdi-web"></i></a>
                        </li>
                    </ul>
                </Col>
            </Row> */}
        </footer>
    );
}

export default Footer;
