/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import {stringBuilder}from'@spordle/helpers';import {useState,useRef,useEffect}from'react';import MenuList from'./MenuList.js';import Selector from'./Selector.js';import SpordleSelect from'./SpordleSelect.js';import {shouldVirtualize}from'./utils.js';import VirtualizedMenu from'./VirtualizedMenu.js';import MobileViewList from'./MobileViewList.js';import {SpordleSelectWrapper}from'../styles/stitchesSpordleSelectRenderer.js';const SpordleSelectRenderer = ({ spordleTable, spordleSelect, ...props }) => {
    const [activeOption, setActiveOption] = useState({
        id: '',
        value: '',
        index: -1,
    });
    const containerRef = useRef(null);
    const scrollerListRef = useRef(null);
    const virtualizedListRef = useRef(null);
    const resetActive = () => {
        setActiveOption({
            id: '',
            value: '',
            index: -1,
        });
    };
    useEffect(() => { if (!spordleSelect.state.isOpen)
        resetActive(); }, [spordleSelect.state.isOpen]); // Reset when close
    /**
     * Checks whether the `element` is an enabled option
     */
    const isKeyboardFocusable = (element) => {
        // If is option and not disabled
        return !element.isGroup && (element.value === SpordleSelect.SELECT_ALL_VALUE || !(spordleSelect.props.isOptionDisabled?.(element) ?? element.isDisabled ?? false));
    };
    const findNextOption = (currentIndex, options) => {
        for (let index = currentIndex + 1; index < options.length; index++) { // Look for next valid option
            const element = options[index];
            if (isKeyboardFocusable(element)) {
                return { option: element, index: index };
            }
        }
        for (let index = 0; index < currentIndex; index++) { // Start looking from the start
            const element = options[index];
            if (isKeyboardFocusable(element)) {
                return { option: element, index: index };
            }
        }
        return { option: options[currentIndex], index: currentIndex }; // If no other options available -> stay on the current option. WARNING: currentIndex could be -1 thus, returning undefined
    };
    const findPreviousOption = (currentIndex, options) => {
        for (let index = currentIndex - 1; index >= 0 && index < options.length; index--) { // Look for next valid option
            const element = options[index];
            if (isKeyboardFocusable(element)) {
                return { option: element, index: index };
            }
        }
        for (let index = options.length - 1; index >= 0; index--) { // Start looking from the end
            const element = options[index];
            if (isKeyboardFocusable(element)) {
                return { option: element, index: index };
            }
        }
        return { option: options[currentIndex], index: currentIndex }; // If no other options available -> stay on the current option. WARNING: currentIndex could be -1 thus, returning undefined
    };
    return (jsx("div", { id: spordleTable.generateId('spordleSelect'), className: stringBuilder(props.className, { multi: props.multi }, 'spordleSelect-container'), onBlur: () => {
            if (!props.isMobile) {
                resetActive();
                props.close();
            }
        }, onKeyUp: (e) => {
            switch (e.code) {
                case 'Escape':
                    resetActive();
                    props.close();
                    e.preventDefault();
                    return false;
            }
        }, onKeyDown: (e) => {
            switch (e.code) {
                case 'ArrowDown':
                case 'ArrowUp':
                    if (!spordleSelect.state.isOpen) {
                        props.toggleOpen(); // Open the dropdown when using keyboard
                    }
                    const currentIndex = Math.max(-1, activeOption.index);
                    const { option, index } = e.code === 'ArrowDown' ? findNextOption(currentIndex, spordleTable.dataToDisplay) : findPreviousOption(currentIndex, spordleTable.dataToDisplay);
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (option) {
                        const value = spordleTable.getDataIndexValue(option);
                        const optionId = spordleTable.generateId('option', value, 'measurer');
                        const domOption = document.getElementById(optionId);
                        if (virtualizedListRef.current) {
                            virtualizedListRef.current.scrollToItem(index, 'auto');
                        }
                        else if (domOption && scrollerListRef.current) {
                            const { bottom, top } = domOption.getBoundingClientRect();
                            const bottomFromParent = bottom - scrollerListRef.current.getBoundingClientRect().bottom;
                            const topFromParent = top - scrollerListRef.current.getBoundingClientRect().top;
                            if (bottomFromParent > 0) {
                                if (index === spordleTable.dataToDisplay.length - 1) { // Is last
                                    scrollerListRef.current.scrollTop = scrollerListRef.current.scrollHeight; // Scroll to bottom
                                }
                                else {
                                    scrollerListRef.current.scrollBy({ top: bottomFromParent + 5 });
                                }
                            }
                            if (topFromParent < 0) {
                                if (index === 0) { // Is first
                                    scrollerListRef.current.scrollTop = 0; // Scroll to top
                                }
                                else {
                                    scrollerListRef.current.scrollBy({ top: topFromParent - 5 });
                                }
                            }
                        }
                        setActiveOption({
                            id: optionId,
                            value: value,
                            index: index,
                        });
                    }
                    e.preventDefault(); // Prevents default scroll behavior
                    break;
                case 'Enter':
                    if (activeOption.value) { // When has an active option
                        document.getElementById(activeOption.id)?.click();
                        e.preventDefault(); // Prevents accidental submits
                        return false;
                    }
                    props.toggleOpen();
                    e.preventDefault(); // Prevents accidental submits
                    return false;
            }
        }, "aria-labelledby": props.id, "aria-live": 'polite' // https://www.w3.org/TR/2010/WD-wai-aria-20100916/states_and_properties#aria-live
        , "aria-busy": props.isLoading, children: jsxs(SpordleSelectWrapper, { suppressHydrationWarning: true, className: stringBuilder('spordleSelect', {
                'with-clear': (props.clearable ?? props.multi) && spordleSelect.state.selectedOptions.length > 0,
                'isOpen': spordleSelect.state.isOpen,
            }), children: [jsx(Selector, { id: props.id, name: props.name, isDisabled: props.disabled, searchable: props.search, toggle: props.toggleOpen, options: spordleSelect.state.selectedOptions, renderSelectedOption: props.renderSelectedOption, valueSplitter: props.valueSplitter, renderOption: props.renderOption, autoFocus: props.autoFocus, placeholder: props.placeholder, skipPlaceholderTranslate: props.skipPlaceholderTranslate, isLoading: props.isLoading, textWhenSetting: props.textWhenSetting, isOpen: spordleSelect.state.isOpen, showClear: !!props.clearable && spordleSelect.state.selectedOptions.length > 0, clearSelected: props.clearSelected, inputClassName: props.inputClassName, isMulti: props.multi, activeOptions: activeOption, selectorContainerRef: containerRef, isMobile: props.isMobile }), props.isMobile ?
                    jsx(MobileViewList, { isOpen: spordleSelect.state.isOpen, id: props.id, close: props.close, noOptionLayout: props.noOptionLayout, placeholder: props.placeholder, searchable: props.search, clearable: props.clearable, optionClick: props.optionClick, selectOption: props.selectOption, unselectOption: props.unselectOption, spordleSelect: spordleSelect, isMulti: props.multi, clearSelected: props.clearSelected, activeOptions: activeOption, showClear: !!props.clearable && spordleSelect.state.selectedOptions.length > 0, virtualizationThreshold: props.virtualizationThreshold })
                    :
                        spordleSelect.state.isOpen && (!props.isLoading && shouldVirtualize(spordleTable.dataToDisplay.length, props.virtualizationThreshold) ? // Automatic virtualization for virtualizationThreshold+ items
                            jsx(VirtualizedMenu, { optionClick: props.optionClick, isOpen: spordleSelect.state.isOpen, selectOption: props.selectOption, unselectOption: props.unselectOption, isMulti: props.multi, spordleSelect: spordleSelect, previousSelectedOptions: props.selectedOptions, activeOptions: activeOption, setActiveOption: setActiveOption, scrollerListRef: scrollerListRef, virtualizedListRef: virtualizedListRef, selectorRef: containerRef })
                            :
                                jsx(MenuList, { isLoading: props.isLoading, loadingLayout: props.loadingLayout, noOptionLayout: props.noOptionLayout, optionClick: props.optionClick, selectOption: props.selectOption, unselectOption: props.unselectOption, spordleSelect: spordleSelect, multi: props.multi, isMobile: props.isMobile, previousSelectedOptions: props.selectedOptions, activeOptions: activeOption, setActiveOption: setActiveOption, scrollerListRef: scrollerListRef, selectorRef: containerRef }))] }) }));
};export{SpordleSelectRenderer as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/