export default{
    "teamProfile.btn.back" : "Back to teams list",

    "teamProfile.helmet.title"       : "{team} profile",
    "teamProfile.helmet.description" : "Team {team}'s schedule, roster, leagues & tournaments and notification.",

    "teamProfile.tabs.roster"       : "Roster",
    "teamProfile.tabs.league"       : "League & tournament",
    "teamProfile.tabs.schedule"     : "Schedule",
    "teamProfile.tabs.notification" : "Notification",
}