/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {spordleSelectStitches}from'./stitches.js';import {OptionContainer}from'./stitchesOptions.js';const SpordleSelectWrapper = spordleSelectStitches.styled('div', {
    position: 'relative',
    userSelect: 'none',
    [`&.isOpen ${OptionContainer}`]: {
        display: 'block',
        width: 'fit-content',
        minWidth: '100%',
        maxWidth: '150%',
    }
});export{SpordleSelectWrapper};
/*
@Copyrights Spordle 2022 - All rights reserved
*/