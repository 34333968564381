import { FormikDateTime, FormikInputText } from '@spordle/formik-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Collapse,
    FormGroup,
    Card,
    CardBody,
    Alert
} from "reactstrap";
import { mixed, object, string } from 'yup';
import moment from 'moment';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import Translate from '@spordle/intl-elements';
import Required from '../../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { AuthContext } from '../../../../../../../contexts/AuthContext';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { AccountsContext } from '../../../../../../../contexts/AccountsContext';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import ImgPill from '../../../../../../../components/visual/imgPill/ImgPill';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { Link } from 'react-router-dom';
import { fail } from '@spordle/toasts';
import getReferrer from '../../../../../../../helpers/getReferer';
import { stringifyUrl } from 'query-string';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
/**
* This component serves as the HCR search, but also as the first step of the member creation
*/
const RegistrationHcrSearch = (props) => {
    // Contexts
    const i18nContext = useContext(I18nContext);
    const membersContext = useContext(MembersContext);
    const accountsContext = useContext(AccountsContext);
    const authContext = useContext(AuthContext);
    const { organisation_id } = useContext(OrganizationContext);
    const registrationContext = useContext(RegistrationContext)

    // States
    const [ searchResults, setSearchResults ] = useState(false) // false, [] or [{}]
    const [ isLoading, setIsLoading ] = useState(false);
    const [ createMode, setCreateMode ] = useState(registrationContext.state.currentCart.hcrSearchCreateMode);
    const [ selectError, setSelectError ] = useState(null);
    const [ isSelecting, setIsSelecting ] = useState(false);


    // State Toggling
    const toggleLoading = (bool) => setIsLoading(bool ?? !isLoading);

    const selectAndProceed = (member, isAccountMember) => {
        setIsSelecting(true)
        accountsContext.getNonMatchingMembers(
            authContext.userData.userName,
            authContext.userData.addresses?.filter((address) => address.active == 1)[0]?.identity_address_id,
            [ member.member_id ],
        )
            .then(async(members) => {

                let nextView = member.incomplete_address == '1' ? registrationContext.views.confirmAddress : registrationContext.views.registrations;
                if(members.map((m) => m.member_id).includes(member.member_id)){
                    // identity address and member address are different
                    nextView = registrationContext.views.addressChange;
                }

                if(!isAccountMember){
                    toggleLoading(true)
                    const metaId = await registrationContext.createMetaMember(member.first_name, member.last_name, member.birthdate, null, member.member_id)

                    // using metaMemberId to have a different variable name than the already linked meta members from the account
                    await registrationContext.addMember({
                        ...member,
                        metaMemberId: metaId,
                        meta_member_id: metaId,
                    });
                    toggleLoading(false)
                    registrationContext.goToView(nextView)

                }else{
                    await registrationContext.addMember(member);
                    registrationContext.goToView(nextView)
                }
                setIsSelecting(false)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setIsSelecting(false)
                    console.error(error);
                    toggleLoading(false);
                    setSelectError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                }
            })
    }

    // Send federation id
    const searchCall = (data) => {
        toggleLoading(true);

        return membersContext.getPublicMembers(organisation_id, { ...data, online_store_id: registrationContext.currentOnlineStore.store.online_store_id }, true)
            .then(async(searchResults) => {
                // Get account members to flag as already in profile
                const accountMetaMembers = await accountsContext.getIdentityMetaMembers(authContext.userData.userName, { is_primary: 0, online_store_id: registrationContext.currentOnlineStore.store.online_store_id })
                let _results = searchResults
                // Go straight to create if no search results
                if(createMode && _results?.length <= 0){
                    // send the first name, last name and birthdate to the creation view so they are prefilled
                    registrationContext.setDataForCreation(data)
                    registrationContext.goToView(registrationContext.views.newParticipant);
                }else{
                    // In list of meta members, need at least one to do checkup
                    // if(accountMetaMembers.some((metaMember) => Array.isArray(metaMember.members) && metaMember.members.length > 0)) {
                    // Filter out members from account
                    _results = _results.reduce((builtArray, member) => {
                        builtArray.push({
                            ...member,
                            isAccountMember: accountMetaMembers.some((_accountMember) => _accountMember.members?.[0]?.member_id === member.member_id),
                            validOrg: !!member.available_organisations.find((org) => org === organisation_id),
                        })
                        return builtArray
                    }, [])
                    // }

                    //Show search results
                    setSearchResults(_results);
                }

                toggleLoading(false)
                return _results
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    // Display all of member shared organisations if there is
    const displaySharedOrgs = (orgs) => {
        return (
            orgs.map((org, i) => (
                <span key={org.organisation_id}>
                    <DisplayI18n
                        field="name"
                        i18n={org.i18n}
                        defaultValue={org.organisation_name}
                    />
                    {i !== orgs.length - 1 && ', '}
                </span>
            ))
        )
    }

    // make sure we reset search if we come back
    useEffect(() => setSearchResults(false), []);

    return (
        <>
            <Formik
                initialValues={{
                    unique_identifier: '',
                    first_name: '',
                    last_name: '',
                    birthdate: '',
                }}
                validationSchema={object().shape({
                    unique_identifier: string(),
                    first_name: string()
                        .when('unique_identifier', {
                            is: (unique_identifier) => !unique_identifier,
                            then: string().required(<Translate id='form.validation.firstName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='participant.registrationModal.views.registrationHcrSearch.search.firstName.regex' /> }),
                        }),
                    last_name: string()
                        .when('unique_identifier', {
                            is: (unique_identifier) => !unique_identifier,
                            then: string().required(<Translate id='form.validation.lastName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='participant.registrationModal.views.registrationHcrSearch.search.lastName.regex' /> }),
                        }),
                    birthdate: mixed()
                        .when('unique_identifier', {
                            is: (unique_identifier) => !unique_identifier,
                            then: mixed().test({
                                name: 'isValid',
                                message: <Translate id='form.validation.date.format' />,
                                test: function(date){
                                    return date ? moment.isMoment(date) : false
                                },
                            }).test({
                                name: 'dateOfBirthNotToday',
                                message: <Translate id='participant.registrationModal.views.registrationHcrSearch.date.minDate' />,
                                test: function(dateOfBirth){
                                    return moment(dateOfBirth).isBefore(moment());
                                },
                            }),
                        }),
                })}
                onSubmit={async(values, { setStatus }) => {
                    try{
                        const newValues = { ...values };
                        if(newValues.unique_identifier){
                            await searchCall({ unique_identifier: newValues.unique_identifier })
                        }else{
                            delete newValues.unique_identifier;
                            newValues.birthdate = newValues.birthdate.format('YYYY-MM-DD'); // format needed for search call
                            await searchCall(newValues);
                        }
                    }catch(error){
                        if(!AxiosIsCancelled(error.message)){
                            toggleLoading(false);
                            console.error(error.message);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={isLoading}>
                            <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                                {createMode ?
                                    <span><Translate id='participant.registrationModal.views.registrationHcrSearch.title.create' /></span>
                                    :
                                    <span><Translate id='participant.registrationModal.views.registrationHcrSearch.title.search' /></span>
                                }
                            </ModalHeader>
                            <ModalBody className="py-4 bg-light">
                                {!createMode &&
                                    <>
                                        <Row>
                                            <Col sm='12'>
                                                <FormGroup inline>
                                                    <Label for="unique_identifier"><Translate id='participant.registrationModal.views.registrationHcrSearch.search.hcrNb' /></Label>
                                                    <div className="d-flex">
                                                        <FormikInputText id='unique_identifier' name='unique_identifier' placeholder="ex: 4563485965" translatePlaceholder={false} trim />
                                                        {/* <Button disabled={isLoading || !formik.values.unique_identifier} onClick={() => searchCall({unique_identifier: formik.values.unique_identifier})} color="info" className="text-nowrap ml-2"> <i className="mdi mdi-magnify"></i> <Translate id='misc.search' /></Button> */}
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="d-flex my-3 align-items-center">
                                            <span className="border-top flex-grow-1" />
                                            <span className="mx-2 font-weight-bold"><Translate id='misc.or' /></span>
                                            <span className="border-top flex-grow-1" />
                                        </div>
                                    </>
                                }
                                <Row form>
                                    <Col sm="6" className="mb-3">
                                        <Label for="first_name"><Translate id='form.fields.firstName' /> <Required /></Label>
                                        <FormikInputText id='first_name' name='first_name' trim />
                                    </Col>
                                    <Col sm="6" className="mb-3">
                                        <Label for="last_name"><Translate id='form.fields.lastName' /> <Required /></Label>
                                        <FormikInputText id='last_name' name='last_name' trim />
                                    </Col>
                                    <Col sm="6">
                                        <Label for="birthdate"><Translate id='form.fields.dateOfBirth' /> <Required /></Label>
                                        <FormikDateTime
                                            translatePlaceholder
                                            name='birthdate'
                                            id='birthdate'
                                            initialValue={formik.values.birthdate}
                                            initialViewDate={moment().subtract(10, "years")}
                                            isValidDate={(current) => moment(current).isBefore(moment())}
                                            placeholder='participant.registrationModal.views.registrationHcrSearch.date.placeholder'
                                            timeFormat={false}
                                            renderInput={(props) => (
                                                <div className="search-wrapper">
                                                    <input {...props} />
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col sm="12" className="mb-3">
                                        { formik.values.birthdate && createMode &&
                                            moment(formik.values.birthdate).isAfter(moment().subtract(4, "years")) &&
                                            moment(formik.values.birthdate).isBefore(new Date()) &&
                                                <div className="small text-muted">
                                                    <i className="mdi text-warning mdi-alert" /> <Translate id='participant.registrationModal.views.registrationHcrSearch.date.minAge' />
                                                </div>
                                        }
                                    </Col>
                                </Row>

                                <Collapse isOpen={!!formik.status}>
                                    <Alert color='danger' className='mt-3 mb-3' toggle={() => formik.setStatus()}>{formik.status}</Alert>
                                </Collapse>

                                <div className="text-right">
                                    <Button
                                        id='hcrSearchSubmitButton'
                                        type="submit"
                                        disabled={isLoading}
                                        color="info"
                                    > {/* found profile */}
                                        <i className="mdi mdi-magnify" /> <Translate id='misc.search' />
                                    </Button>
                                </div>
                                <Collapse isOpen={!!searchResults}>
                                    <hr />
                                    {searchResults.length > 0 ?
                                        <>
                                            {createMode ?
                                                <div className="font-weight-bold mb-3"><Translate id='participant.registrationModal.views.registrationHcrSearch.search.result.profile' /></div>
                                                :
                                                <div className="font-weight-bold mb-3"><Translate id='misc.searchResults' /></div>
                                            }

                                            <PerfectScrollbar>
                                                <div style={{ maxHeight: "500px" }}>
                                                    { searchResults.map((member) => (
                                                        <Card key={member.member_id} className='mb-3'>
                                                            <CardBody>
                                                                <UserDisplay className="w-100">
                                                                    <UserDisplay.Container>
                                                                        <ImgPill
                                                                            size="sm"
                                                                            src={member.photo}
                                                                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                                            alt={member.first_name + ' ' + member.last_name}
                                                                        />
                                                                    </UserDisplay.Container>
                                                                    <UserDisplay.Container>
                                                                        <div className="d-flex align-items-center">
                                                                            <UserDisplay.Title className="mr-2">{member.first_name} {member.last_name}</UserDisplay.Title>
                                                                            {member.age && <UserDisplay.Subtitle className="mr-2"> ({member.age} <Translate id='misc.yo' />)</UserDisplay.Subtitle>}
                                                                        </div>
                                                                        <UserDisplay.Subtitle>
                                                                            <Translate id='misc.hcr#' /> {member.unique_identifier}
                                                                        </UserDisplay.Subtitle>
                                                                        <UserDisplay.Subtitle>
                                                                            <span className="font-weight-bold">
                                                                                <DisplayI18n
                                                                                    field='name'
                                                                                    defaultValue={member.organisation?.organisation_name}
                                                                                    i18n={member.organisation?.i18n}
                                                                                />
                                                                            </span>
                                                                            {member.shared_organisations?.length > 0 && <>{' | '}{displaySharedOrgs(member.shared_organisations)}</>}
                                                                        </UserDisplay.Subtitle>
                                                                        {member.isAccountMember &&
                                                                            <div className="small text-info"><i className="mdi mdi-information-outline mr-1" /><Translate id='participant.registrationModal.views.registrationHcrSearch.alreadyInAccount' /></div>
                                                                        }
                                                                        {member.member_in_other_account == "1" &&
                                                                            <div className="small text-info"><i className="mdi mdi-information-outline mr-1" /><Translate id='participant.registrationModal.views.registrationHcrSearch.notAlreadyInAccount' /></div>
                                                                        }
                                                                    </UserDisplay.Container>
                                                                    <Button
                                                                        id={'hcrSearchResultsSelectButton-' + member.member_id}
                                                                        outline
                                                                        color="info"
                                                                        type="button"
                                                                        className="ml-auto"
                                                                        disabled={!member.validOrg || isSelecting}
                                                                        onClick={() => {
                                                                            selectAndProceed(
                                                                                { ...member, members: [ { ...member } ] }, // So the data has same structure as meta_member
                                                                                member.isAccountMember,
                                                                            )
                                                                        }}
                                                                    >
                                                                        <Translate id='misc.select' />
                                                                    </Button>
                                                                </UserDisplay>
                                                            </CardBody>
                                                            {!member.validOrg &&
                                                                <div className='border-top p-3'>
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <p className="mb-0 small">
                                                                            <div className="font-weight-bold font-16"><i className="mdi mdi-alert-outline mr-1 text-warning" /><Translate id='participant.registrationModal.views.registrationHcrSearch.wrongOrg' /></div>
                                                                            <Translate id='storeSingle.registrationModal.registrationOptions.participantOrgAssociationWith' values={{ orgName: <strong className="font-weight-bold"><DisplayI18n field='name' defaultValue={member.organisation.organisation_name} i18n={member.organisation.i18n} /></strong> }} />
                                                                            &nbsp;
                                                                            <Translate
                                                                                id='storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.label.contactOrg'
                                                                                values={{
                                                                                    orgName: <strong className="font-weight-bold"><DisplayI18n field='name' defaultValue={member.organisation.organisation_name} i18n={member.organisation.i18n} /></strong>,
                                                                                }}
                                                                            />
                                                                        </p>
                                                                        <Button
                                                                            id={'hcrSearchResultsGoToOrg-' + member.member_id}
                                                                            tag={member.organisation.deploy_on != 'AMPLIFY' ? 'a' : Link}
                                                                            size="sm"
                                                                            color='info'
                                                                            to={member.organisation.deploy_on == 'AMPLIFY' ? `/page/${member.organisation.short_url}/participant` : undefined}
                                                                            href={member.organisation.deploy_on == 'VERCEL' || member.organisation.deploy_on == 'PAGE' ? stringifyUrl({
                                                                                url: `${getReferrer(member.organisation.deploy_on == 'VERCEL' ? 'PAGE-VERCEL' : 'PAGE-VERCEL-2')}/${member.organisation.short_name}/participant`,
                                                                                query: {
                                                                                    lang: i18nContext.getGenericLocale(),
                                                                                    accessToken: authContext.accessToken,
                                                                                },
                                                                            }, { skipEmptyString: true, skipNull: true }) : undefined}
                                                                            onClick={() => {
                                                                                registrationContext.resetRegistration();
                                                                            }}
                                                                        >
                                                                            <Translate id='storeSingle.registrationModal.registrationOptions.sendRegistrationRequest.action.goToMha' values={{ orgName: <DisplayI18n field='name' defaultValue={member.organisation.organisation_name} i18n={member.organisation.i18n} /> }} /><i className='ml-1 mdi mdi-arrow-right' />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Card>
                                                    ))}
                                                </div>
                                            </PerfectScrollbar>
                                            {!!selectError &&
                                                <Alert color='danger' toggle={() => setSelectError()}>{selectError}</Alert>
                                            }
                                            <hr />
                                            <p className="mt-4"><Translate id='participant.registrationModal.views.registrationHcrSearch.search.result.text' /></p>
                                        </>
                                        :
                                        <>
                                            <div className="font-weight-bold mb-2"><Translate id='misc.searchResults' /></div>
                                            {registrationContext.currentOnlineStore.store.can_create_new_member == 1 ?
                                                <p><Translate id='participant.registrationModal.views.registrationHcrSearch.search.result.noProfile' /></p>
                                                :
                                                <p><Translate id='participant.registrationModal.views.registrationHcrSearch.search.result.noProfileSimple' /></p>
                                            }
                                        </>
                                    }
                                    {registrationContext.currentOnlineStore.store.can_create_new_member == 1 &&
                                        <Button
                                            id='hcrSearchResultsGoToCreate'
                                            color="info" outline
                                            onClick={() => {
                                                // go to create new participant with info from top
                                                setCreateMode(true);
                                                registrationContext.setHcrSearchCreateMode(true)
                                                setSearchResults(false);
                                                registrationContext.setDataForCreation(formik.values)
                                                registrationContext.goToView(registrationContext.views.newParticipant)
                                            }}
                                        >
                                            <Translate id='participant.registrationModal.views.registrationHcrSearch.btn.create' />
                                        </Button>
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter tag={'footer'} className="bg-light">
                                <Button id='hcrSearchPreviousButton' onClick={() => registrationContext.goToView(registrationContext.views.existingOrNew, true)} color="default" outline className="mr-auto"><Translate id='misc.previous' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default RegistrationHcrSearch;