export default{
    // ONLY ADD GENERAL FIELDS
    // Example of a general field   : First Name (can be used for attendees, instructors, users, etc.)
    // Example of a specific field  : Organization Name (only used in organization)

    // Fields
    "form.fields.forms"                         : "Forms",
    "form.fields.firstName"                     : "First name",
    "form.fields.lastName"                      : "Last name",
    "form.fields.name"                          : "Name",
    "form.fields.name.lang"                     : "Name ({lang})",
    "form.fields.title"                         : "Title",
    "form.fields.title.lang"                    : "Title ({lang})",
    "form.fields.short_name"                    : "Short name",
    "form.fields.short_name.lang"               : "Short name ({lang})",
    "form.fields.description"                   : "Description",
    "form.fields.description.lang"              : "Description ({lang})",
    "form.fields.email"                         : "Email",
    "form.fields.fees"                          : "Fees",
    "form.fields.phone"                         : "Phone number",
    "form.fields.extension"                     : "Extension",
    "form.fields.mobilePhone"                   : "Mobile phone",
    "form.fields.phoneType"                     : "Phone type",
    "form.fields.dateOfBirth"                   : "Date of birth",
    "form.fields.gender"                        : "Gender Identity",
    "form.fields.gender.placeholder"            : "Select a gender identity",
    "form.fields.gender.male"                   : "Male",
    "form.fields.gender.female"                 : "Female",
    "form.fields.gender.other"                  : "X",
    "form.fields.gender.prefer_not_to_say"      : "X",
    "form.fields.streetNumber"                  : "Street number",
    "form.fields.address"                       : "Address",
    "form.fields.address2.placeholder"          : "Apartment, suite, unit, floor, building, etc.",
    "form.fields.addressType"                   : "Address Type",
    "form.fields.city"                          : "City",
    "form.fields.country"                       : "Country",
    "form.fields.state"                         : "State",
    "form.fields.province"                      : "Province",
    "form.fields.zip"                           : "Postal code",
    "form.fields.primaryLanguage"               : "Primary Language",
    "form.fields.primaryLanguage.placeholder"   : "Select a language",
    "form.fields.secondaryLanguages"            : "Secondary Language",
    "form.fields.nationality"                   : "Citizenship",
    "form.fields.nationality.placeholder"       : "Select a citizenship",
    "form.fields.nationality.selected"          : "{count} selected citizenships",
    "form.fields.nationality.CA"                : "Canadian",
    "form.fields.nationality.US"                : "American",
    "form.fields.lastConnection"                : "Last connection",
    "form.fields.language"                      : "Language",
    "form.fields.general"                       : "General",
    "form.fields.generalInfo"                   : "General Information",
    "form.fields.status"                        : "Status",
    "form.fields.relation"                      : "Your relationship with the participant",
    "form.fields.relation.FATHER"               : "Father",
    "form.fields.relation.MOTHER"               : "Mother",
    "form.fields.relation.DAUGHTER"             : "Daughter",
    "form.fields.relation.SON"                  : "Son",
    "form.fields.relation.CHILD"                : "Child",
    "form.fields.relation.OTHER"                : "Other",
    "form.fields.birthCountry"                  : "Birth country",
    "form.fields.identifyAsIndigenous"          : "Identify as Indigenous",
    "form.fields.identifyAsIndigenous.required" : "Please specify if you identify as Indigenous.",
    "form.fields.indigenousGroup"               : "Indigenous group",
    "form.fields.indigenousGroup.required"      : "Indigenous group is required.",
    "form.fields.ethnicity"                     : "Ethnicity",
    "form.fields.ethnicity.required"            : "Ethnicity is required.",
    "form.fields.ethnicityNote"                 : "Note",
    "form.fields.ethnicityNote.required"        : "Note is required.",

    "form.fields.waiver.required"      : "Please select an option.",
    "form.fields.waiver.defaultAccept" : "I have read and accept the waiver",
    "form.fields.waiver.doNotSign"     : "I prefer not to sign",
    "form.fields.waiver.notMandatory"  : "This waiver is not mandatory",
    "form.fields.waiver.mandatory"     : "This waiver is mandatory",
    "form.fields.waiver.clear"         : "Clear selected",

    // Canada
    "form.fields.country.ca"  : "Canada",
    "form.fields.state.ca.ab" : "Alberta",
    "form.fields.state.ca.bc" : "British Columbia",
    "form.fields.state.ca.pe" : "Prince Edward Island",
    "form.fields.state.ca.mb" : "Manitoba",
    "form.fields.state.ca.nb" : "New Brunswick",
    "form.fields.state.ca.ns" : "Nova Scotia",
    "form.fields.state.ca.on" : "Ontario",
    "form.fields.state.ca.qc" : "Quebec",
    "form.fields.state.ca.sk" : "Saskatchewan",
    "form.fields.state.ca.nl" : "Newfoundland and Labrador",
    "form.fields.state.ca.nu" : "Nunavut",
    "form.fields.state.ca.nt" : "Northwest Territories",
    "form.fields.state.ca.yt" : "Yukon",

    // Error messages (Yup)
    "form.validation.firstName.required"       : "First name is required",
    "form.validation.lastName.required"        : "Last name is required",
    "form.validation.name.required"            : "Name is required",
    "form.validation.name.lang.required"       : "Name ({lang}) is required",
    "form.validation.title.required"           : "Title is required",
    "form.validation.description.required"     : "Description is required",
    "form.validation.email.required"           : "Email is required",
    "form.validation.email.valid"              : "Email must be valid",
    "form.validation.phone.required"           : "Phone number is required",
    "form.validation.phone.valid"              : "Phone number must be valid",
    "form.validation.phoneType.required"       : "Phone type is required",
    "form.validation.dateOfBirth.required"     : "Date of birth is required",
    "form.validation.dateOfBirth.valid"        : "Date of birth must be of a valid format",
    "form.validation.gender.required"          : "Gender Identity is required",
    "form.validation.address.required"         : "Address is required",
    "form.validation.streetNumber.required"    : "Street number is required",
    "form.validation.addressType.required"     : "Address Type is required",
    "form.validation.city.required"            : "City is required",
    "form.validation.country.required"         : "Country is required",
    "form.validation.state.required"           : "State is required",
    "form.validation.province.required"        : "Province is required",
    "form.validation.zip.required"             : "Postal code is required",
    "form.validation.zip.valid"                : "Postal code must be valid",
    "form.validation.primaryLanguage.required" : "Primary language is required",
    "form.validation.nationality.required"     : "Citizenship is required",
    "form.validation.ethnicity.required"       : "Ethnicity is required",
    "form.validation.language.required"        : "Language is required",
    "form.validation.birthCountry.required"    : "Birth country is required",

    "form.validation.date.format" : "Date must be of a valid format",

    "form.validation.documents.required" : "This document type is required",

}