import React, { createContext } from "react";
import { AuthContext } from './AuthContext';
import { AccountsContext } from './AccountsContext';
import { CartsContext } from './CartsContext';
import { FormsContext } from './FormsContext';
import { OrganizationContext } from './OrganizationContext'
import withContext from '../helpers/withContexts';

import { setContext, setTag, addBreadcrumb, Severity } from "@sentry/react";
import { sendGAEvent } from "@spordle/ga4";
import { displayI18n } from '../helpers/i18nHelper';
import { I18nContext } from "./I18nContext";
import { MembersContext } from "./MembersContext";
import { AxiosIsCancelled } from "../api/CancellableAPI";

/** @type {React.Context<Omit<RegistrationContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const RegistrationContext = createContext()
RegistrationContext.displayName = 'RegistrationContext';

class RegistrationContextProvider extends React.Component{

    constructor(props){
        super(props);

        this._initState = {
            modalIsOpen: false,
            // loading state for the views that do not use Formik and that need a loading (the views with formik should use formik.isSubmitting)
            modalIsLoading: false,
            currentView: 'existingOrNew',
            currentMemberId: '',

            currentCart: {
                // gateway
                invoiceNumber: '',
                sutToken: '',
                QEErrorCode: '',
                isCheckedOut: false,

                // this is used in the hcrSearch view to use it as a create form or a search form
                hcrSearchCreateMode: false,

                preAuthorizedPayment: false,

                // state to show either waivers or terms in the terms view
                termsWaiverMode: false,

                // state to show the skip to payment button in existing or new
                skipToPayment: false,

                cartInfo: false, // cart from API

                // waiting list states
                waitingListMode: false,
                waitingListReturnData: null, // the member information received in the URL from MyAccount
                waitingListNumber: '',
            },
        }

        this.state = this._initState;

        // these variables should be used to skip views (e.g. skip the questionnaire view if forms is false)
        // they can also be used to store the object/array (e.g. the waivers array can be stored inside the state for easier access)
        // each member will have their own object of the different views - using objects for easier access - registrationContext.memberInfo[memberId].forms
        // example:
        // membersInfo: {
        //     memberId: {
        //         forms: false,
        //         waivers: false,
        //         firstName: '',
        //         alreadyLinked: false, // would've been used to link member at the end, but API does that now, might be useful at some point
        //         // ...
        //     }
        // }
        // not using the state because as a context gets bigger and bigger, the setStates take longer to execute, and that could be a problem
        // when updating the state and trying to read the new data right after, as it might not have updated yet
        // and since these variables are not needed for any UI related updates / refreshes, the use of the state is not necessary
        this.membersInfo = false;
        this.terms = false, // terms is the only view that doesn't depend on a member -> every member has to agree to the same terms (if there are any)

        this.currentOnlineStore = false;

        // in the old cart, the hcrSearch would send the search data (first name, last name, birthdate) through the selectedParticipant state
        // we do not have a selectedParticipant logic in this context, so we need some sort of variable to hold the data to send to the creation view
        this.dataForCreation = {};

        // missing member fields received from a failed create cart call
        this.missingFields = [];
        // selected registration id before receiving missing member fields, so the user can come back to registrations and have it pre selected
        this.selectedRegistrationFeeId = '',

        // used in the noAddress view
        // we want different labels, different api calls and a previous button if the user clicked the "change address" in the address review step
        this.changeAddress = false;

        // used in registration go to organization
        // will be set when a postal code is invalid for an organization and when we find the orgs close to that postal code
        this.goToOrgs = [];
        this.goToOrgsPrevView = '';

        // its better to load the document types in the member creation view so they are already loaded when landing on the documents step
        this.documentTypes = [];
        this.needsDocuments = true;

        this.views = {
            existingOrNew: 'existingOrNew',
            hcrSearch: 'hcrSearch',
            newParticipant: 'newParticipant',
            documents: 'documents',
            participantList: 'participantList',
            confirmAddress: 'confirmAddress',
            request: 'request',
            registrations: 'registrations',
            questions: 'questions',
            position: 'position',
            items: 'items',
            waivers: 'waivers',
            moreParticipant: 'moreParticipant',
            summary: 'summary',
            modalities: 'modalities',
            terms: 'terms',
            gateway: 'gateway',
            confirmation: 'confirmation',
            missingRequiredFields: 'missingRequiredFields',

            // addresses
            noAddress: 'noAddress',
            addressChange: 'addressChange',
            addressReview: 'addressReview',
            goToOrganization: 'goToOrganization',

            // waiting list
            waitingListConfirmation: 'waitingListConfirmation',
            waitingListReturn: 'waitingListReturn',
            waitingListCheckoutError: 'waitingListCheckoutError',
        }
    }

    componentDidUpdate(){
        const waivers = Object.keys(this.membersInfo).reduce((newArray, key) => {
            if(this.membersInfo[key].waivers){
                newArray.pushArray(this.membersInfo[key].waivers)
            }
            return newArray
        }, [])

        try{
            setContext('registration', this.state.currentCart.cartInfo?.shopping_cart_id ? {
                cartId: this.state.currentCart.cartInfo?.shopping_cart_id,
                members: {
                    memberNames: Object.keys(this.membersInfo).map((key) => `${this.membersInfo[key]?.first_name} ${this.membersInfo[key]?.last_name}`).join(),
                    memberIds: Object.keys(this.membersInfo).join(),
                },
                registrations: {
                    registrationNames: Object.keys(this.membersInfo).map((key) => displayI18n('name', this.membersInfo[key]?.registration?.fee?.i18n, this.membersInfo[key]?.registration?.fee?.name, this.props.I18nContext.getGenericLocale())).join(),
                    registrationIds: Object.keys(this.membersInfo).map((key) => this.membersInfo[key]?.registration?.registration_fee_id).join(),
                },
                waivers: {
                    waiverNames: waivers.map((waiver) => displayI18n('name', waiver?.i18n, waiver?.name, this.props.I18nContext.getGenericLocale())).join(),
                    waiverIds: waivers.map((waiver) => waiver?.waiver_id).join(),
                },
                forms: {
                    formIds: Object.keys(this.membersInfo).map((key) => this.membersInfo[key]?.forms?.customFormId).join(),
                },
                terms: {
                    termIds: this.terms ? this.terms.map((term) => term?.term_and_condition?.term_and_condition_id).join() : [],
                },
                installments: {
                    installmentIds: Object.keys(this.membersInfo).map((key) => this.membersInfo[key]?.installments?.installment_mode_id).join(),
                },
                total: this.state.currentCart.cartInfo?.cart_summary?.total,
                rebateTotal: this.state.currentCart.cartInfo?.cart_summary?.rebate_total,
                creditTotal: this.state.currentCart.cartInfo?.cart_summary?.credit_total,
                taxTotal: this.state.currentCart.cartInfo?.cart_summary?.tax_total,
                grandTotal: this.state.currentCart.cartInfo?.cart_summary?.grand_total,
                invoiceNumber: this.state.currentCart.invoiceNumber,
                createdBy: this.state.currentCart.cartInfo?.created_by?.identity_id,
                view: this.state.currentView,
            } : null);
            setTag('cartId', this.state.currentCart.cartInfo?.shopping_cart_id);
        }catch(error){
            console.error(error)
        }
    }

    ////////////////////////////////////////////////////////////////////////
    // Modal
    ////////////////////////////////////////////////////////////////////////

    toggleModal = () => {
        this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }))
    }

    setModalLoading = (isLoading) => {
        this.setState(() => ({ modalIsLoading: isLoading }))
    }

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    // Gets - Provide an easier access to certain parts of the state or cart
    ////////////////////////////////////////////////////////////////////////

    getCurrentMember = () => {
        return this.membersInfo[this.state.currentMemberId];
    }

    getGa4CartItems = () => {
        return this.state.currentCart.cartInfo.cart_detail.map((item) => ({
            currency: 'CAD',
            item_id: item.item_id,
            item_name: item.name,
            item_category: item.item_type,
            quantity: parseInt(item.quantity),
            price: (parseInt(item.amount) + parseInt(item.tax_amount) - parseInt(item.rebate_amount) - parseInt(item.credit_amount)) / 100,
        }));
    }

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    // Sets - Functions returning a promise to set different parts of the state
    ////////////////////////////////////////////////////////////////////////

    setDataForCreation = (data) => {
        this.dataForCreation = data;
    }

    setMissingFields = (missingFields) => {
        this.missingFields = missingFields;
    }

    setSelectedRegistrationFeeId = (selectedRegistrationFeeId) => {
        this.selectedRegistrationFeeId = selectedRegistrationFeeId;
    }

    setCurrentOnlineStore = (onlineStore) => {
        this.currentOnlineStore = onlineStore
    }

    setTermsAndConditions = (terms) => {
        this.terms = terms
    }

    setSkipToPayment = (skip) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    skipToPayment: skip,
                },
            }), resolve)
        })
    }

    setHcrSearchCreateMode = (createMode) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    hcrSearchCreateMode: createMode,
                },
            }), resolve)
        })
    }

    setPreAuthorizedPayment = (preAuthorizedPayment) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    preAuthorizedPayment: preAuthorizedPayment,
                },
            }), resolve)
        })
    }

    setWaiverMode = (waiverMode) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    termsWaiverMode: waiverMode,
                },
            }), resolve)
        })
    }

    setCurrentMemberId = (memberId) => {
        return new Promise((resolve) => { this.setState(() => ({ currentMemberId: memberId }), resolve) })
    }

    setInvoiceNumber = (invoiceNumber) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    invoiceNumber: invoiceNumber,
                },
            }), resolve)
        })
    }

    setSutToken = (sutToken) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    sutToken: sutToken,
                },
            }), resolve)
        })
    }

    setQEErrorCode = (errorCode) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    QEErrorCode: errorCode,
                },
            }), resolve)
        })
    }

    setIsCheckedOut = (isCheckedOut) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    isCheckedOut: isCheckedOut,
                },
            }), resolve)
        })
    }

    setHasActivatedCredits = (memberIds, hasActivatedCredits) => {
        for(let index = 0; index < memberIds.length; index++){
            const memberId = memberIds[index];
            this.membersInfo[memberId].hasActivatedCredits = hasActivatedCredits;
        }
    }

    setChangeAddress = (changeAddress) => {
        this.changeAddress = changeAddress
    }

    setGoToOrgs = (orgs) => {
        this.goToOrgs = orgs
    }

    setGoToOrgsPrevView = (view) => {
        this.goToOrgsPrevView = view
    }

    setDocumentTypes = (dTypes) => {
        this.documentTypes = dTypes
    }

    setNeedsDocuments = (needs) => {
        this.needsDocuments = needs
    }

    setWaitingListMode = (waitingListMode) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    waitingListMode: waitingListMode,
                },
            }), resolve)
        })
    }

    setWaitingListReturnData = (memberInfo) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    waitingListReturnData: memberInfo,
                },
            }), resolve)
        })
    }

    setWaitingListNumber = (waitingListNumber) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({
                currentCart: {
                    ...prevState.currentCart,
                    waitingListNumber: waitingListNumber,
                },
            }), resolve)
        })
    }

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    // Cart
    ////////////////////////////////////////////////////////////////////////

    resetRegistration = (keepModalOpen = false) => {
        sendGAEvent('cart_reset', {
            event_category: 'ecommerce',
            event_label: 'Reset cart',
        });

        this.setState(() => ({
            ...this._initState,
            modalIsOpen: keepModalOpen,
        }))
        this.membersInfo = false;
        this.initialMemberData = {};
        this.disableFields = false;
        this.changeAddress = false;

        if(!keepModalOpen){
            this.terms = false;
            this.currentOnlineStore = false;
        }
    }

    refreshCart = (shoppingCartId = this.state.currentCart.cartInfo.shopping_cart_id) => {
        // no need to do anything with error, it is simply a get cart
        return new Promise((resolve) => {
            this.props.CartsContext.getCart(shoppingCartId)
                .then((cart) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        currentCart: {
                            ...prevState.currentCart,
                            cartInfo: cart,
                        },
                    }), () => resolve(cart))
                })
                .catch(console.error)
        })
    }

    checkout = (paymentMethod) => {
        return this.props.CartsContext.checkoutCart(this.state.currentCart.cartInfo.shopping_cart_id, paymentMethod)
            .then(async(invoiceData) => {
                const waitingListNumber = invoiceData.waiting_list_number?.[0] || null;
                if(!this.state.currentCart.waitingListMode){
                    if(waitingListNumber){ // unexpected waiting list cart / checkout
                        this.setWaitingListNumber(waitingListNumber);
                    }else{
                        sendGAEvent('purchase', {
                            currency: 'CAD',
                            transaction_id: invoiceData.invoice_number,
                            affiliation: this.props.OrganizationContext.organisation_name,
                            value: this.state.currentCart.cartInfo.cart_summary.grand_total / 100,
                            tax: this.state.currentCart.cartInfo.cart_summary.tax_total / 100,
                            items: this.getGa4CartItems(),
                        });
                        await this.setInvoiceNumber(invoiceData.invoice_number)
                        await this.setSutToken(invoiceData.init_info?.sut_token)
                    }
                }else{
                    this.setWaitingListNumber(waitingListNumber);
                }
                return invoiceData
            })
    }

    /**
     * Adds an item to the cart for a specific member
     * @param {string} itemId ID of the item to add to the cart
     * @param {'REGISTRATION'|'OTHER'} type Type of the item to add to the cart
     * @param {string} memberId ID of the member to add an item for
     * @param {string} metaMemberId ID of the meta member to link to the member
     * @returns {Promise}
     */
    addMemberItem = (itemId, type, memberId, metaMemberId, quantity = 1, waitingListItemId) => {
        return this.props.CartsContext.addItem({
            online_store_id: this.currentOnlineStore.store.online_store_id,
            item: {
                item_id: itemId,
                type: type,
                member_id: memberId,
                meta_member_id: metaMemberId,
                waiting_list_item_id: waitingListItemId,
            },
        })
            .then((id) => {
                const item = (type === 'REGISTRATION' ?
                    this.currentOnlineStore.items.registration_fees.find((r) => r.registration_fee_id === itemId)
                    :
                    this.currentOnlineStore.items.other_fees.find((o) => o.other_fee_id === itemId)
                )

                sendGAEvent('add_to_cart', {
                    currency: 'CAD',
                    value: item.fee.amount / 100 * parseInt(quantity),
                    items: [ {
                        item_id: itemId,
                        item_name: item.fee.name,
                        price: item.fee.amount / 100,
                        currency: 'CAD',
                        item_category: type,
                        quantity: parseInt(quantity),
                    } ],
                });
                return id
            })
    }

    /**
     * Building memberInfo object with forms, waivers, items, skipRegistrations etc.
     * @param {string} registrationId
     * @returns {Promise}
     */
    setupRegistration = (registrationId, selectedRegistration) => {
        return new Promise(async(resolve) => {
            const cart = this.state.currentCart.cartInfo

            // member credits
            let memberCredits = [];
            await this.props.MembersContext.getMemberCredits(this.props.OrganizationContext.organisation_id, this.getCurrentMember().members[0].member_id)
                .then((_memberCredits) => {
                    memberCredits = _memberCredits;
                })

            // forms
            let formGroups = null;
            const customFormIds = [];
            const rowIds = [];
            const registrationFee = selectedRegistration // we need the custom forms from the item from the call with the member ID
            if(registrationFee?.custom_form?.custom_form_id){
                customFormIds.push(registrationFee.custom_form.custom_form_id)
                rowIds.push(cart.cart_detail.find((row) => row.item_id === registrationId && row.member.member_id === this.getCurrentMember().members[0].member_id)?.row_id)
            }
            if(registrationFee?.affiliation_fees?.length > 0){
                registrationFee.affiliation_fees.map((affiliationFee) => {
                    if(affiliationFee.custom_form?.custom_form_id){
                        customFormIds.push(affiliationFee.custom_form.custom_form_id)
                        rowIds.push(cart.cart_detail.find((row) => row.item_id === affiliationFee.affiliation_fee_id && row.member.member_id === this.getCurrentMember().members[0].member_id)?.row_id)
                    }
                })
            }

            if(customFormIds.length > 0){
                formGroups = await Promise.all(customFormIds.map((formId) => {
                    return this.props.FormsContext.getForm(formId)
                }))
            }

            let forms = [];
            if(formGroups && cart){
                forms = customFormIds.reduce((newArray, formId, index) => {
                    if(formGroups[index].length > 0){
                        const sortedFormGroups = formGroups[index].sort((a, b) => parseInt(a.sort) - parseInt(b.sort)).map((formGroup) => ({
                            ...formGroup,
                            fields: formGroup.fields.sort((a, b) => parseInt(a.sort) - parseInt(b.sort)),
                        }))
                        newArray.push({
                            formGroups: sortedFormGroups,
                            customFormId: formId,
                            rowId: rowIds[index],
                        })
                    }
                    return newArray
                }, [])
            }

            // waivers
            const waivers = [];
            if(cart){
                cart.cart_detail.filter((row) => row.member.member_id === this.getCurrentMember().members[0].member_id).forEach((item) => {
                    // Each waiver to sign for the current item (grouped by organisation)
                    item.waivers_to_sign?.forEach((waiverToSign) => {
                        // Each waiver givin by the current organisation for the current item
                        waiverToSign.waivers?.forEach((waiver) => {
                            waivers.push({
                                ...waiver,
                                organisation: waiverToSign.organisation,
                                item_id: waiverToSign.item_id,
                                row_id: item.row_id,
                            })
                        })
                    })
                })
            }

            // related items
            let relatedItems = [];
            if(cart){
                relatedItems = this.currentOnlineStore.items.registration_fees
                    ?.find((f) => f.registration_fee_id === cart.cart_detail?.find((r) => r.item_type === 'REGISTRATION' && r.member.member_id === this.getCurrentMember().members[0].member_id)?.item_id)
                    ?.related_items.filter((item) => !item.sold_out && item.active == 1 && item.mandatory == 1) || [];
            }

            // other items
            let otherItems = [];
            if(registrationId === 'None'){
                otherItems = this.currentOnlineStore.items.other_fees.filter((item) => !item.sold_out && item.active === '1');
            }else{
                otherItems = this.currentOnlineStore.items.other_fees.filter((item) => !item.sold_out && item.active === '1' && !relatedItems.find((related) => related.other_fee_id === item.other_fee_id));
            }

            // registration
            let registration = false;
            if(registrationId !== 'None'){
                registration = this.currentOnlineStore.items.registration_fees.find((rFee) => rFee.registration_fee_id === registrationId)
            }

            this.editMember(this.state.currentMemberId, {
                forms: forms,
                waivers: waivers.length === 0 ? false : waivers,
                mandatoryItems: relatedItems.length === 0 ? false : relatedItems,
                otherItems: otherItems.length === 0 ? false : otherItems,
                registration: registration,
                credits: memberCredits,
                installments: registration ? registration.installment_mode || false : false,
                skipRegistrations: registrationId === 'None',
            })

            resolve();
        })
    }

    // using this function with a weird structure to have the same code in the .catch of this function
    // executed if the error is different then 3269 or when one of the api calls crashes,
    // not when the process is done and we change views
    handleError = (err, selectedRegistrationId) => {
        return new Promise((resolve, reject) => {
            const isArray = Array.isArray(err);

            // these error codes below should always come from the addItem call, which returns an array in case of failure
            if(isArray && err.find((e) => e.code == 3266)){
                // postal code is not valid for organization
                this.props.OrganizationContext.findOrganization({
                    organisation_id: this.props.OrganizationContext.federationId,
                    member_id: this.getCurrentMember().members[0].member_id,
                })
                    .then((organisations) => {
                        // filter out orgs with null distance (shouldn't happen, but can)
                        // and then sort to have the shortest distance first
                        // null distance means the postal code of the org is not in the database
                        const filteredOrgs = organisations
                            ?.filter((org) => !!org.distance)
                            ?.sort((orgA, orgB) => parseFloat(orgA.distance) - parseFloat(orgB.distance))

                        this.setGoToOrgsPrevView(this.views.registrations)
                        this.setGoToOrgs(filteredOrgs);
                        this.goToView(this.views.goToOrganization);
                        resolve();

                        // getOrganization to get the short_url field from the API
                        // await orgContext.getOrganization(filteredOrgs[0]?.organisation_id)
                        //     .then((org) => {
                        //     })
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            // no need for error message display here since nothing should be done if this feature doesn't work
                            console.error(error.message)
                            reject()
                        }
                    })
            }else if(isArray && err.find((e) => e.code == 3531)){
                // missing required fields
                // eslint-disable-next-line no-useless-escape
                const regex = /[\[\]]/g
                this.setMissingFields(err.reduce((newArray, error) => {
                    if(error.code == 3531){
                        // origin should look like "members[UUID][field]"
                        const splitOrigin = error.origin.split(regex);
                        // split should look like ["members", "", "UUID", "", "", "field"]
                        const filtered = splitOrigin.filter((temp) => !!temp)
                        newArray.push(filtered[filtered.length - 1])
                    }
                    return newArray
                }, []))
                this.setSelectedRegistrationFeeId(selectedRegistrationId)
                this.goToView(this.views.missingRequiredFields)
            }else{
                reject()
            }
        })
    }

    updateCartItemQuantity = (rowId, quantity) => {
        return this.props.CartsContext.updateItemQuantity(this.state.currentCart.cartInfo.shopping_cart_id, rowId, quantity)
    }

    removeCartItem = (rowId) => {
        return this.props.CartsContext.deleteCartItem(this.state.currentCart.cartInfo.shopping_cart_id, rowId)
            .then((data) => {
                const item = this.state.currentCart.cartInfo.cart_detail.find((i) => i.row_id === rowId);
                const price = (parseInt(item.amount) + parseInt(item.tax_amount) - parseInt(item.rebate_amount) - parseInt(item.credit_amount)) / 100;
                sendGAEvent('remove_from_cart', {
                    currency: 'CAD',
                    value: price * item.quantity,
                    items: [ {
                        item_id: item.item_id,
                        item_name: item.name,
                        price: price,
                        currency: 'CAD',
                        item_category: item.item_type,
                        quantity: item.quantity,
                    } ],
                })
                return data
            })
    }

    removeMemberItemsFromCurrentCart = async(memberId, keepMember = false, withRefresh = false) => {
        // delete calls won't work properly in Promise.all, don't know why
        for(let i = 0; i < this.state.currentCart.cartInfo.cart_detail.length; i++){ // good old for loop because for-in goes through prototypes of the array (which is very bad)
            const row = this.state.currentCart.cartInfo.cart_detail[i];
            if(row.member.member_id === memberId && row.item_type !== 'AFFILIATION' && !(row.item_type === 'OTHER' && row.automatically_added == 1)){
                await this.props.CartsContext.deleteCartItem(this.state.currentCart.cartInfo.shopping_cart_id, row.row_id)
            }
        }

        if(!keepMember)
            await this.removeMember(memberId)

        if(withRefresh){
            await this.refreshCart();
            this.setModalLoading(false);
        }

        return true
    }

    updateCartForms = (fields, rowId, customFormId) => {
        return this.props.CartsContext.updateCartItemForm(this.state.currentCart.cartInfo.shopping_cart_id, rowId, {
            custom_form_id: customFormId,
            fields: fields,
        })
    }

    updateCartPosition = (values) => {
        const rowId = this.state.currentCart.cartInfo.cart_detail.find((row) => row.item_id === this.getCurrentMember().registration.registration_fee_id && row.member.member_id === this.getCurrentMember().members[0].member_id)?.row_id
        return this.props.CartsContext.updateCartItemPosition(this.state.currentCart.cartInfo.shopping_cart_id, rowId, values)
            .then(() => {
                return this.refreshCart()
            })
    }

    /**
     * Updates a waiver for the active cart
     * @param {string} waiverId ID of the waiver that the user signed
     * @param {string} lang Language from getGenericLocale that the user answered in
     * @param {'YES'|'NO'} answer The user's answer
     * @returns {Promise}
     */
    updateCartWaivers = (waiverId, lang, answer) => {
        return this.props.CartsContext.updateCartItemWaiver(this.state.currentCart.cartInfo.shopping_cart_id, this.getCurrentMember().waivers.find((w) => w.waiver_id === waiverId)?.row_id, {
            waiver_id: waiverId,
            language_code: lang,
            answer: answer,
        })
            .then(() => {
                return this.refreshCart()
            })
    }

    updateCartItemInstallments = (rowId, installmentId) => {
        return this.props.CartsContext.updateCartItemInstallment(this.state.currentCart.cartInfo.shopping_cart_id, rowId, installmentId)
    }

    deleteCartItemInstallments = (rowId) => {
        return this.props.CartsContext.deleteCartItemInstallment(this.state.currentCart.cartInfo.shopping_cart_id, rowId)
    }

    /**
     * Updates the terms and conditions for the active cart
     * @param {string} termAndConditionId ID of the terms and conditions
     * @param {string} lang Language from getGenericLocale that the user answered in
     * @returns {Promise}
     */
    updateCartTerms = (termAndConditionId, lang) => {
        return this.props.CartsContext.associateTermsToCart(this.state.currentCart.cartInfo.shopping_cart_id, {
            language_code: lang,
            term_and_condition_id: termAndConditionId,
        })
            .then(() => {
                return this.refreshCart()
            })
    }

    activateMemberCredits = (memberId) => {
        return this.props.CartsContext.updateCartCredits(this.state.currentCart.cartInfo.shopping_cart_id, memberId)
    }

    deactivateMemberCredits = (memberId) => {
        return this.props.CartsContext.deleteCartCredits(this.state.currentCart.cartInfo.shopping_cart_id, memberId)
    }

    /**
     * Sets the new view (skips the view if needed)
     * @param {string} newView The new view to go to
     * @param {boolean} goBack  Wether or not the user clicked to go back in the modal - False by default
     */
    goToView = (newView, goBack = false) => {
        addBreadcrumb({
            type: 'info',
            message: `Registration modal view: ${newView}`,
            level: Severity.Log,
            category: 'Registration modal navigation',
            data: {
                view: newView,
                invoiceNumber: this.state.currentCart.invoiceNumber,
                QEErrorCode: this.state.currentCart.QEErrorCode,
                membersInfo: this.membersInfo,
                metaMember: this.getCurrentMember(),
                skipToPayment: this.state.currentCart.skipToPayment,
                terms: this.terms ? this.terms.map((term) => term.term_and_condition.term_and_condition_id).toString() : undefined,
            },
        });

        switch (newView){
            case this.views.waitingListReturn:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.noAddress:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.addressReview:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.existingOrNew:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.hcrSearch:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.newParticipant:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.documents:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.goToOrganization:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.participantList:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.addressChange:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.confirmAddress:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.request:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.registrations:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.missingRequiredFields:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.questions:
                if(this.getCurrentMember().forms?.length > 0){
                    this.setState(() => ({ currentView: newView }))
                }else{
                    this.goToView((goBack ? this.views.registrations : this.views.position), goBack) // recursive function call to go to the next view's verification
                }
                break;
            case this.views.position:
                if(this.getCurrentMember().registration?.with_position == 1){
                    this.setState(() => ({ currentView: newView }))
                }else{
                    this.goToView((goBack ? this.views.questions : this.views.items), goBack)
                }
                break;
            case this.views.items:
                if(this.getCurrentMember().mandatoryItems || this.getCurrentMember().otherItems || this.getCurrentMember().skipRegistrations){
                    this.setState(() => ({ currentView: newView }))
                }else{
                    this.goToView(goBack ? this.views.questions : this.views.waivers, goBack)
                }
                break;
            case this.views.waivers:
                if(this.getCurrentMember().waivers){
                    this.setWaiverMode(true)
                        .then(() => this.setState(() => ({ currentView: newView })))
                }else{
                    this.goToView(goBack ? this.views.items : this.views.moreParticipant, goBack)
                }
                break;
            case this.views.moreParticipant:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.summary:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.modalities:
                if(parseInt(this.state.currentCart.cartInfo.cart_summary.grand_total) > 0 && // grand total > 0
                   this.state.currentCart.cartInfo.cart_summary.members_amount.some((m) => this.membersInfo[m.member_id].installments && m.total_amount >= this.membersInfo[m.member_id].installments.minimum_threshold)){ // at least 1 cart member has installments and has a total amount > minimum threshold
                    this.setState(() => ({ currentView: newView }))
                }else{
                    this.goToView(goBack ? this.views.summary : this.views.terms, goBack)
                }
                break;
            case this.views.terms:
                if(this.terms){
                    this.setWaiverMode(false)
                        .then(() => this.setState(() => ({ currentView: newView })))
                }else{
                    this.goToView(goBack ? this.views.modalities : this.views.gateway, goBack)
                }
                break;
            case this.views.gateway:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.confirmation:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.waitingListCheckoutError:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.waitingListConfirmation:
                this.setState(() => ({ currentView: newView }))
                break;
        }
    }

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    // Members
    ////////////////////////////////////////////////////////////////////////

    /**
     * Creates a meta member
     * @param {string} firstName Member's first name
     * @param {string} lastName Member's last name
     * @param {string} dateOfBirth Member's date of birth
     * @param {string} [relation] Member's relation with the user - OPTIONNAL
     * @param {string} [memberId] Member id - OPTIONNAL
     * @returns {Promise}
     */
    createMetaMember = (firstName, lastName, dateOfBirth, relation = '', memberId = '') => {
        return this.props.AccountsContext.createMetaMember(
            this.props.AuthContext.userData.userName,
            firstName,
            lastName,
            dateOfBirth,
            relation,
            memberId,
        )
    }

    /**
     * Adds a new member to the registration context's state
     * @param {object} metaMember New member object
     * @returns {Promise}
     */
    addMember = (metaMember) => {
        return this.setCurrentMemberId(metaMember.members[0].member_id)
            .then(() => {
                // if membersInfo in state is false, initialize an object instead
                const newMembersInfo = this.membersInfo || {};
                newMembersInfo[metaMember.members[0].member_id] = metaMember;

                this.membersInfo = newMembersInfo;

                sendGAEvent('select_content', {
                    content_type: 'MEMBER',
                    item_id: metaMember.members[0].member_id,
                })
            })
            .catch(console.error)
    }

    editMember = (memberId, data) => {
        const newMembersInfo = this.membersInfo
        if(newMembersInfo[memberId]){
            newMembersInfo[memberId] = { ...newMembersInfo[memberId], ...data }
            this.membersInfo = newMembersInfo
        }
    }

    removeMember = (memberId) => {
        delete this.membersInfo[memberId];
    }

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    // Addresses
    ////////////////////////////////////////////////////////////////////////

    getFullAddress = (data) => {
        const address = [];

        if(data.street){
            if(data.street_number) address.push(data.street_number);
            address.push(data.street);
            if(data.city) address.push(data.city);
            if(data.province_code) address.push(data.province_code);
            if(data.zip_code) address.push(data.zip_code);
            if(data.country_code) address.push(data.country_code);
        }

        return address.length > 0 ? address.join(', ') : '';
    }

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    render(){
        return (
            <RegistrationContext.Provider value={{ ...this }}>
                {this.props.children}
            </RegistrationContext.Provider>
        )
    }
}

export default withContext(AuthContext, AccountsContext, CartsContext, FormsContext, OrganizationContext, I18nContext, MembersContext)(RegistrationContextProvider);