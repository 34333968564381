import { useContext, useEffect } from 'react';
import {
    Card,
    CardBody,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import RegistrationSummaryMember from './RegistrationSummaryMember';
import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import moment from 'moment';

// contexts
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';
import { sendGAEvent } from '@spordle/ga4';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';

const RegistrationSummary = (props) => {
    const registrationContext = useContext(RegistrationContext)

    const membersArray = Object.keys(registrationContext.membersInfo).map((key) => registrationContext.membersInfo[key])
    const membersWithCredits = membersArray.filter((metaMember) => {
        return registrationContext.membersInfo[metaMember.members[0].member_id].credits?.filter((credit) => {
            return credit.active === '1' && (!credit?.expiration_date || moment(credit.expiration_date).isAfter(moment().subtract(1, 'day'))) && parseInt(credit.balance) > 0
        }).length > 0
    }).filter((metaMember) => !metaMember.hasActivatedCredits)

    const grandTotal = registrationContext.state.currentCart.cartInfo.cart_summary.grand_total / 100;

    useEffect(() => {
        sendGAEvent('view_cart', {
            currency: 'CAD',
            value: grandTotal,
            items: registrationContext.state.currentCart.cartInfo.cart_detail.map((item) => ({
                currency: 'CAD',
                item_id: item.item_id,
                item_name: item.name,
                item_category: item.item_type,
                quantity: parseInt(item.quantity),
                price: (parseInt(item.amount) + parseInt(item.tax_amount) - parseInt(item.rebate_amount) - parseInt(item.credit_amount)) / 100,
            })),
        });
    }, [ grandTotal ])

    useEffect(() => {
        if(!registrationContext.state.currentCart.hasActivatedCredits){
            if(membersWithCredits.length > 0){
                registrationContext.setModalLoading(true);
                registrationContext.activateMemberCredits(membersWithCredits.map((metaMember) => metaMember.members[0].member_id).join(','))
                    .then(async() => {
                        await registrationContext.refreshCart();
                        registrationContext.setModalLoading(false)
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            // no need to do anything with the error since the user can always edit the credits after
                            registrationContext.setModalLoading(false)
                            console.error(e.message)
                        }
                    })
            }

            registrationContext.setHasActivatedCredits(membersWithCredits.map((metaMember) => metaMember.members[0].member_id), true)
        }
    }, [])

    return (
        <div className='position-relative'>
            <OverlayLoader isLoading={registrationContext.state.modalIsLoading}>
                <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                    <Translate id='participant.registrationModal.views.registrationSummary.title' />
                </ModalHeader>
                <ModalBody className="py-4 bg-light">
                    <div className="h3 font-weight-bold mb-2"><Translate id='participant.registrationModal.views.registrationSummary.orderSummary' /></div>
                    <Card className="mb-2">
                        <CardBody className="p-3">
                            {membersArray?.map((metaMember) => (
                                <RegistrationSummaryMember
                                    // adding credit_total in the key to rerender the component when the credits are applied
                                    key={metaMember.members[0].member_id + registrationContext.state.currentCart.cartInfo.cart_summary.credit_total}
                                    metaMember={metaMember}
                                    membersArray={membersArray}
                                />
                            ))}

                            <div className="d-flex font-weight-bold">
                                <div className="font-weight-bold"><Translate id='participant.registrationModal.views.registrationSummary.total' /></div>
                                <div className="ml-auto text-right">
                                    <div>
                                        <CurrencyFormat value={grandTotal} />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter tag='footer' className="bg-light">
                    <Button
                        id='registrationSummaryAnotherParticipant'
                        color="default" outline
                        className="mr-auto"
                        onClick={() => registrationContext.goToView(registrationContext.views.existingOrNew)}
                    ><Translate id='participant.registrationModal.views.registrationMoreParticipants.another' />
                    </Button>
                    <Button
                        id='registrationSummaryNextButton'
                        onClick={() => {
                            sendGAEvent('begin_checkout', {
                                currency: 'CAD',
                                value: grandTotal,
                                items: registrationContext.state.currentCart.cartInfo.cart_detail.map((item) => ({
                                    currency: 'CAD',
                                    item_id: item.item_id,
                                    item_name: item.name,
                                    item_category: item.item_type,
                                    quantity: parseInt(item.quantity),
                                    price: (parseInt(item.amount) + parseInt(item.tax_amount) - parseInt(item.rebate_amount) - parseInt(item.credit_amount)) / 100,
                                })),
                            });
                            registrationContext.goToView(registrationContext.views.modalities)
                        }}
                        color="info"
                    >
                        <Translate id='misc.next' />
                    </Button>
                </ModalFooter>
            </OverlayLoader>
        </div>
    );
}

export default RegistrationSummary;