import LogoMobile from '../../../assets/images/spordleSuite/SLogoWhite.svg';
import HockeyCanada from '../../../assets/images/logos/hc.png';
import getReferrer from '../../../helpers/getReferer';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/I18nContext';

const HeaderLogo = () => {
    const { getGenericLocale } = useContext(I18nContext);
    const attributes = (process.env.REACT_APP_VERSION_CLIENT === 'EIHA' && process.env.REACT_APP_ENVIRONMENT === 'prod') ?
        getAttributes("page.eiha")
        :
        (process.env.REACT_APP_ENVIRONMENT === 'int' || process.env.REACT_APP_ENVIRONMENT === 'stage') ?
            getAttributes("page.spordle")
            :
            getAttributes("page.hockeycanada");

    function getAttributes(plateform){
        const prefix = 'page.';
        const sources = {
            [prefix + 'hockeycanada']: {
                link: {
                    className: "flex-shrink-0 pr-0",
                    target: "_blank",
                    href: getReferrer('HCR-SITE', getGenericLocale()),
                    rel: "noopener noreferrer",
                },
                img: {
                    className: "navbar-logo py-2 pl-2",
                    alt: 'Hockey Canada',
                    src: HockeyCanada,
                },
            },
            [prefix + 'eiha']: {
                img: {
                    className: "navbar-logo py-2 pl-2",
                    alt: 'EIHA',
                    src: '/eiha/EIHA.png',
                },
            },
            [prefix + 'spordle']: {
                img: {
                    className: "navbar-logo",
                    alt: 'Spordle',
                    src: LogoMobile,
                },
            },
        }

        return sources[plateform] ?? sources[prefix + 'spordle'];

    }

    return (
        <div className="d-flex align-items-center">
            {
                attributes.link ?
                    <a {...attributes.link}>
                        <img {...attributes.img} />
                    </a>
                    :
                    <img {...attributes.img} />
            }
            {/* <a href="https://www.spordle.com/" target="_blank" rel="noopener noreferer">
                <img
                    className="border-left pl-3 mx-2 pl-md-3 mx-md-2"
                    style={{width: 100, height: "auto", opacity: "0.65"}}
                    src={LogoSpordle}
                    alt="Spordle"
                />
            </a> */}
        </div>
    )

}

export default HeaderLogo;