import { useContext, useState } from 'react';
import {
    ModalHeader,
    ModalBody,
    Row,
    ModalFooter,
    Button,
    Alert
} from "reactstrap";
import Translate from '@spordle/intl-elements';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { MemberBirthCountryField, MemberCitizenshipField, MemberEmailField, MemberEthnicityField, MemberGenderField, MemberIdentifyAsIndigenousField, MemberPrimaryLanguageField, MemberRelationField } from './memberFields';
import { Form, Formik } from 'formik';
import { array, object, string } from 'yup';
import RegistrationParticipantCard from './RegistrationParticipantCard';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

const RegistrationMissingRequiredFields = (props) => {

    const registrationContext = useContext(RegistrationContext);
    const membersContext = useContext(MembersContext);

    const [ selectedEthnicity, setSelectedEthnicity ] = useState(null);

    const fromRegistrations = !registrationContext.getCurrentMember().skipRegistrations;

    const getInitialValues = () => {
        const temp = {};
        registrationContext.missingFields.forEach((field) => {
            switch (field){
                case 'relation_id':
                case 'gender':
                case 'primary_language':
                case 'birth_country':
                case 'email':
                    temp[field] = '';
                    break;
                case 'identify_as_indigenous':
                case 'indigenous_group_id':
                    temp.identify_as_indigenous = '';
                    temp.indigenous_group_id = '';
                    break;
                case 'ethnicity_id':
                case 'ethnicity_note':
                    temp.ethnicity_id = '';
                    temp.ethnicity_note = '';
                    break;
                case 'citizenships':
                    temp[field] = [];
                    break;
            }
        })
        return temp
    }

    const getValidationSchema = () => {
        const temp = {};
        registrationContext.missingFields.forEach((field) => {
            switch (field){
                case 'relation_id':
                case 'gender':
                case 'primary_language':
                case 'birth_country':
                case 'email':
                    temp[field] = string().required(<Translate id='participant.registrationModal.views.missingRequiredFields.required' />);
                    break;
                case 'identify_as_indigenous':
                case 'indigenous_group_id':
                    temp.identify_as_indigenous = string().required(<Translate id='participant.registrationModal.views.missingRequiredFields.required' />);
                    temp.indigenous_group_id = string().when('identify_as_indigenous', {
                        is: 'YES',
                        then: string().required(<Translate id='participant.registrationModal.views.missingRequiredFields.required' />),
                    });
                    break;
                case 'ethnicity_id':
                case 'ethnicity_note':
                    temp.ethnicity_id = string().required(<Translate id='participant.registrationModal.views.missingRequiredFields.required' />);
                    temp.ethnicity_note = string().when('doesNotMatter', {
                        is: () => !!selectedEthnicity?.withNote,
                        then: string().required(<Translate id='participant.registrationModal.views.missingRequiredFields.required' />),
                    });
                    break;
                case 'citizenships':
                    temp[field] = array().min(1, <Translate id='participant.registrationModal.views.missingRequiredFields.required' />);
                    break;
            }
        })
        return object().shape(temp);
    }

    const getField = (fieldId, key, formik) => {
        switch (fieldId){
            case 'relation_id':
                return <MemberRelationField key={key} fieldName='relation_id' />
            case 'gender':
                return <MemberGenderField key={key} fieldName='gender' />
            case 'primary_language':
                return <MemberPrimaryLanguageField key={key} fieldName='primary_language' />
            case 'citizenship':
                return <MemberCitizenshipField key={key} fieldName='citizenship' />
            case 'birth_country':
                return <MemberBirthCountryField key={key} fieldName='birth_country' />
            case 'email':
                return <MemberEmailField key={key} fieldName='email' />
            case 'identify_as_indigenous':
            case 'indigenous_group_id':
                return (
                    <MemberIdentifyAsIndigenousField
                        key={key}
                        fieldName='identify_as_indigenous'
                        secondaryFieldName='indigenous_group_id'
                        identifyAsIndigenousOnSelected={(values) => {
                            if(values[0] === 'YES')
                                // TODO: HARDCODED
                                // set ethnicity as indigenous
                                formik.setFieldValue('ethnicity_id', '07615f60-af8e-11ec-bed9-023f3d3ef136')
                        }}
                    />
                )
            case 'ethnicity_id':
            case 'ethnicity_note':
                return (
                    <MemberEthnicityField
                        key={key}
                        fieldName='ethnicity_id'
                        secondaryFieldName='ethnicity_note'
                        ethnicityOnSelected={(values, spordleSelect) => {
                            setSelectedEthnicity(spordleSelect.getSpordleTable().getData().find((data) => data.value === values[0]))
                        }}
                        showNote={!!selectedEthnicity?.withNote}
                    />
                )
        }
    }

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={getValidationSchema()}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                membersContext.updateMemberMissingFields(registrationContext.getCurrentMember().members?.[0]?.member_id, values)
                    .then(() => {
                        registrationContext.goToView(fromRegistrations ? registrationContext.views.registrations : registrationContext.views.items)
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            setSubmitting(false);
                            console.error(error);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                            <Translate id='participant.registrationModal.views.missingRequiredFields.title' />
                        </ModalHeader>
                        <ModalBody className="bg-light">
                            <p className='text-center mb-0'>
                                <Translate id='participant.registrationModal.views.missingRequiredFields.text' />
                            </p>
                            <p className='text-center font-weight-bold'>
                                <Translate id='participant.registrationModal.views.missingRequiredFields.text2' />
                            </p>
                            <RegistrationParticipantCard participant={registrationContext.getCurrentMember()} className='mb-0' />
                            <Row form>
                                {registrationContext.missingFields.map((field, index) => getField(field, index, formik))}
                            </Row>

                            {formik.status &&
                                <Alert color='danger' toggle={() => formik.setStatus()}>
                                    {formik.status}
                                </Alert>
                            }
                        </ModalBody>
                        <ModalFooter tag='footer' className="bg-light">
                            <Button id='registrationMissingRequiredFieldsPreviousButton' type="button" onClick={() => registrationContext.goToView(fromRegistrations ? registrationContext.views.registrations : registrationContext.views.items, true)} color="default" outline className="mr-auto"><Translate id='misc.previous' /></Button>
                            <Button id='registrationMissingRequiredFieldsSubmitButton' type="submit" color="info"><Translate id='misc.confirm' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationMissingRequiredFields;