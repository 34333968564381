import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button } from "reactstrap";
import Section from "../../../../../components/visual/structure/Section";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";

/**
 * @description View shown when there are no online stores
 * @returns {React.ReactNode}
 */
const EmptyRegistrations = () => {
    const { organisation_category, website } = useContext(OrganizationContext);

    return (
        <Section>
            <header>
                <h1 className="section-main-title">
                    <Translate id='storesList.page.title' />
                </h1>
            </header>
            <p>
                {organisation_category?.name === "MHA" ?
                    <Translate id='participant.noStore.ahm' />
                    :
                    <Translate id='participant.noStore' />
                }
            </p>
            { website &&
                <footer>
                    <Button tag="a" href={website} target="_blank" rel="noopener noreferrer" color="primary">
                        <Translate id='page.header.visitWebsite' /> <i className="mdi mdi-launch" />
                    </Button>
                </footer>
            }
        </Section>
    )
}

export default EmptyRegistrations;