export default{
    // no address
    "participant.registrationModal.views.noAddress.header"     : "Add an address",
    "participant.registrationModal.views.noAddress.text"       : "Our system detected that your account does not have an address attached to it.",
    "participant.registrationModal.views.noAddress.textChange" : "Please enter your new address.",

    // address change
    "participant.registrationModal.views.addressChange.header"               : "Address change",
    "participant.registrationModal.views.addressChange.text"                 : "Our system detected an address change",
    "participant.registrationModal.views.addressChange.text2"                : "The address \"{address}\" is different than what we have for this player. Would you like to notify the registrar of this address change?",
    "participant.registrationModal.views.addressChange.text3"                : "Please enter the date since the participant resided at that address and the address type.",
    "participant.registrationModal.views.addressChange.moveInYear"           : "Move-in year",
    "participant.registrationModal.views.addressChange.moveInYear.required"  : "Move-in year is required",
    "participant.registrationModal.views.addressChange.moveInYear.future"    : "Move-in year cannot be in the future",
    "participant.registrationModal.views.addressChange.moveInYear.format"    : "Year must be in a valid format",
    "participant.registrationModal.views.addressChange.addressType"          : "Address type",
    "participant.registrationModal.views.addressChange.addressType.required" : "Address type is required",

    // address review
    "participant.registrationModal.views.addressReview.header"           : "Address validation",
    "participant.registrationModal.views.addressReview.text"             : "It's been a while since you verified your address.",
    "participant.registrationModal.views.addressReview.text2"            : "Please confirm that the current address is valid :",
    "participant.registrationModal.views.addressReview.isValid"          : "My address is valid",
    "participant.registrationModal.views.addressReview.isValid.subtitle" : "I still live at this address",
    "participant.registrationModal.views.addressReview.change"           : "Change my address",
    "participant.registrationModal.views.addressReview.change.subtitle"  : "This address is no longer valid",
    "participant.registrationModal.views.addressReview.continue"         : "Continue",

    // registration position
    "participant.registrationModal.views.registrationPosition.header"                 : "Position selection",
    "participant.registrationModal.views.registrationPosition.text"                   : "Please select a position group and a position.",
    "participant.registrationModal.views.registrationPosition.text2"                  : "Know that your choice will not guarantee this position for this member, this is simply a preference and it will be taken into consideration when assigning the positions.",
    "participant.registrationModal.views.registrationPosition.positionGroup"          : "Position group",
    "participant.registrationModal.views.registrationPosition.positionGroup.required" : "Position group is required",
    "participant.registrationModal.views.registrationPosition.position"               : "Position",

    // go to organization
    "participant.registrationModal.views.registrationGoToOrganization.header" : "Invalid postal code",
    "participant.registrationModal.views.registrationGoToOrganization.text"   : "The postal code you entered is invalid for the current organization.",
    "participant.registrationModal.views.registrationGoToOrganization.text2"  : "We recommend you register your participant to {amount, plural, one {the following organization} other {one of the following organizations}}.",
}