import { stringBuilder } from "@spordle/helpers";

/**
 * @description Section Component
 * @param {Object} props
 * @param {string|JSX.Element|React.ReactNode} [props.tag="section"]
 * @param {string} [props.className]
 * @returns {React.ReactNode}
 */
const Section = ({ tag: Tag = "section", className, ...props }) => <Tag className={stringBuilder("section-container", className)} {...props} />
export default Section;