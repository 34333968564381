import { FormikGroup, FormikRadioButton } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import RequestCall from '@spordle/request-calls';
import { Form, Formik, useField } from 'formik';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import Skeleton from '../../../../../../../components/loading/Skeleton';
import {
    Alert,
    Button,
    Collapse,
    Fade,
    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import { object, string } from 'yup';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
// contexts
import { OnlineStoreContext } from '../../../../../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import RegistrationAhmChecker from './RegistrationAhmChecker';
import RegistrationParticipantCard from './RegistrationParticipantCard';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import { stringBuilder } from "@spordle/helpers";
import { fail } from '@spordle/toasts';

const RegistrationOptions = (props) => {
    const onlineStoreContext = useContext(OnlineStoreContext);
    const orgContext = useContext(OrganizationContext);
    const registrationContext = useContext(RegistrationContext);

    const [ selectedRegistrationFee, setSelectedRegistrationFee ] = useState(false);

    const mhaIsValid = registrationContext.getCurrentMember().members?.[0].available_organisations ? !!registrationContext.getCurrentMember().members?.[0].available_organisations.find((orgId) => orgId === orgContext.organisation_id) : true;

    const hasOutstandingBalance = registrationContext.getCurrentMember().members?.[0].outstanding_balance && parseInt(registrationContext.getCurrentMember().members?.[0].outstanding_balance) > 0

    function calcPrice(fee, field){
        let total = parseInt(fee?.fee?.[field]) || 0;

        if(fee?.affiliation_fees?.length > 0){ // adds all affiliation fee amounts
            fee.affiliation_fees.forEach((_aFee) => {
                total += parseInt(_aFee.fee[field] || _aFee.fee.amount);
            });
        }

        if(fee?.related_items?.length > 0){ // adds all mandatory related items amounts
            fee.related_items.forEach((_rItem) => {
                if(_rItem.mandatory === "1") total += parseInt(_rItem.fee?.[field] || _rItem.fee?.amount);
            })
        }

        return total;
    }

    // cartItem containing a registration fee for the current member -> will cause a different default selected registration and change the onSubmit a bit
    const cartItem = useRef(registrationContext.state.currentCart.cartInfo && (registrationContext.state.currentCart.cartInfo.cart_detail?.find((row) => row.item_type === 'REGISTRATION' && row.member.member_id === registrationContext.getCurrentMember().members[0].member_id) || null))

    return (
        <>
            {registrationContext.currentOnlineStore.store &&
                <Formik
                    initialValues={{
                        registrationId: registrationContext.selectedRegistrationFeeId || '',
                        registrationFees: [],
                    }}
                    validationSchema={object().shape({
                        registrationId: string().required(<Translate id='participant.registrationModal.views.registrationOptions.required' />),
                    })}
                    onSubmit={async(values, { setSubmitting, setStatus }) => {
                        try{
                            setStatus();

                            // already a registration for this member in the cart AND is different -> delete every member item in the cart and restart
                            if(registrationContext.state.currentCart.cartInfo.shopping_cart_id &&
                              (
                                  (cartItem.current && cartItem.current.item_id !== values.registrationId) || // has registration and new one is different
                                (registrationContext.getCurrentMember().skipRegistrations && values.registrationId !== 'None') // skipped registration and new one is different
                              )
                            ){
                                // send true to keep the member in the state, we just want to delete the items, not the member
                                await registrationContext.removeMemberItemsFromCurrentCart(registrationContext.getCurrentMember().members[0].member_id, true)
                            }

                            let cartId = '';
                            if((!cartItem.current || cartItem.current.item_id !== values.registrationId) && values.registrationId !== 'None'){
                                cartId = await registrationContext.addMemberItem(
                                    values.registrationId,
                                    'REGISTRATION',
                                    registrationContext.getCurrentMember().members[0].member_id,
                                    // meta_member_id: registrationContext.getCurrentMember().metaMemberId || '' // metaMemberId (and not meta_member_id) because we don't want to link an already linked meta member
                                    registrationContext.getCurrentMember().meta_member_id,
                                )
                            }else{
                                cartId = registrationContext.state.currentCart.cartInfo.shopping_cart_id || '';
                            }

                            let cart = false;
                            if(cartId){
                                cart = await registrationContext.refreshCart(cartId)
                            }

                            // checking if the cart is a waiting list cart
                            // if so, checkout and go to waiting list confirmation
                            // if not, keep going with the logic
                            if(cart && cart.cart_detail.find((row) => row.item_type === 'REGISTRATION')?.waiting_list == 1){
                                await registrationContext.setWaitingListMode(true);
                                await registrationContext.checkout()
                                setSubmitting(false)
                                registrationContext.goToView(registrationContext.views.waitingListConfirmation)
                            }else{
                                await registrationContext.setupRegistration(values.registrationId, selectedRegistrationFee)
                                setSubmitting(false)
                                registrationContext.goToView(registrationContext.views.questions)
                            }
                        }catch(errors){
                            // checking if errors is an array because the addItem call will return an array in case of failure, but not the other calls
                            // that specific call returns an array of errors because we need to show all the missing required fields, which are all separated in multiple errors
                            if(!AxiosIsCancelled(Array.isArray(errors) ? '' : errors.message)){
                                await registrationContext.handleError(errors, values.registrationId)
                                    .catch(() => {
                                        setSubmitting(false);
                                        if(Array.isArray(errors)){
                                            console.error(errors[0].message);
                                            setStatus(<DisplayI18n field='message' defaultValue={errors[0].message} i18n={errors[0].i18n} />)
                                        }else{
                                            console.error(errors.message);
                                            setStatus(<DisplayI18n field='message' defaultValue={errors.message} i18n={errors.i18n} />)
                                        }
                                    })
                            }
                        }
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                                    <Translate id='participant.registrationModal.views.registrationOptions.title' />
                                </ModalHeader>
                                <ModalBody className="py-4 bg-light">
                                    <div className="registration-container">
                                        <RegistrationParticipantCard participant={registrationContext.getCurrentMember()} />
                                        {mhaIsValid ?
                                            <RequestCall
                                                call={() => {
                                                    return onlineStoreContext.getOnlineStoreItems(registrationContext.currentOnlineStore.store.online_store_id, true, { member_id: registrationContext.getCurrentMember().members[0].member_id })
                                                        .then(async(items) => {
                                                            let registrationFees = items.registration_fees;

                                                            const allItemsFound = (storedData) => registrationFees.every((rFee) => storedData.items.registration_fees.find((r) => r.registration_fee_id === rFee.registration_fee_id))

                                                            if(registrationFees){
                                                                if(!allItemsFound(registrationContext.currentOnlineStore)){
                                                                    // couldn't find every available fee in online store fees
                                                                    // refreshing online store data and checking again
                                                                    const onlineStoreData = {};
                                                                    onlineStoreData.store = await onlineStoreContext.getOnlineStore(registrationContext.currentOnlineStore.store.online_store_id, true)
                                                                    onlineStoreData.items = await onlineStoreContext.getOnlineStoreItems(registrationContext.currentOnlineStore.store.online_store_id, true)
                                                                    onlineStoreData.rebates = await onlineStoreContext.getOnlineStoreRebates(registrationContext.currentOnlineStore.store.online_store_id, true)
                                                                    registrationContext.setCurrentOnlineStore({ ...registrationContext.currentOnlineStore, ...onlineStoreData });
                                                                    if(!allItemsFound(onlineStoreData)){
                                                                        // could not find every available fee, giving up and just hiding the missing ones
                                                                        registrationFees = registrationFees.filter((rFee) => onlineStoreData.items.registration_fees.find((r) => r.registration_fee_id === rFee.registration_fee_id))
                                                                    }
                                                                }

                                                                const filteredRegistrations = registrationFees?.filter((f) => f.sold_out == 0 || f.manage_waiting_list == 1)
                                                                if(cartItem.current){
                                                                    formik.setFieldValue('registrationId', cartItem.current.item_id)
                                                                    setSelectedRegistrationFee(registrationFees.find((fee) => fee.registration_fee_id === cartItem.current.item_id) || false)
                                                                }else if(registrationContext.getCurrentMember().skipRegistrations){
                                                                    formik.setFieldValue('registrationId', 'None')
                                                                }
                                                                registrationFees = filteredRegistrations
                                                            }

                                                            formik.setFieldValue('registrationFees', registrationFees)
                                                            if(formik.values.registrationId){
                                                                setSelectedRegistrationFee(registrationFees.find((fee) => fee.registration_fee_id === formik.values.registrationId) || false)
                                                            }
                                                            return { ...items, registration_fees: registrationFees }
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                }}
                                            >
                                                {(snapshot) => {
                                                    if(snapshot.isLoading || snapshot.waitingForResponse){
                                                        return (
                                                            <>
                                                                <Skeleton height='25px' className="w-50 mb-3" />
                                                                <Skeleton count={2} height='75px' />
                                                            </>
                                                        )
                                                    }

                                                    if(snapshot.error)return null;

                                                    return (
                                                        <>
                                                            {(snapshot.data.registration_fees.length > 0) ?
                                                                <div className="font-weight-bold mb-3"><Translate id='participant.registrationModal.views.registrationOptions.available' /></div>
                                                                : (snapshot.data.registration_fees.length === 0 && (!Array.isArray(registrationContext.currentOnlineStore.items.other_fees) || registrationContext.currentOnlineStore.items.other_fees?.length === 0)) ?
                                                                    <div className="font-weight-bold mb-3"><Translate id='participant.registrationModal.views.registrationOptions.available.none' /></div>
                                                                    : null}
                                                            <FormikGroup name="registrationId" rowProps={{ className: 'mx-0 d-block shadow-sm' }}>
                                                                {snapshot.data?.registration_fees?.filter((fee) => fee.active == 1)?.map((registrationFee, index) => (
                                                                    <RegistrationCard
                                                                        index={index}
                                                                        key={registrationFee.registration_fee_id}
                                                                        selectRegistrationFee={() => setSelectedRegistrationFee(registrationFee)}
                                                                        value={registrationFee.registration_fee_id}
                                                                        title={
                                                                            <DisplayI18n
                                                                                field='name'
                                                                                i18n={registrationFee.fee.i18n}
                                                                                defaultValue={registrationFee.fee.name}
                                                                            />
                                                                        }
                                                                        price={
                                                                            moment().isBefore(moment(registrationFee.fee.early_amount_until)) ?
                                                                                <><CurrencyFormat value={calcPrice(registrationFee, 'early_amount') / 100} /> <div className="text-muted small"><Translate id='participant.registrationModal.views.registrationOptions.early' /></div></>
                                                                                : moment().isAfter(moment(registrationFee.fee.late_amount_after)) ?
                                                                                    <><CurrencyFormat value={calcPrice(registrationFee, 'late_amount') / 100} /> <div className="text-muted small"><Translate id='participant.registrationModal.views.registrationOptions.late' /></div></>
                                                                                    :
                                                                                    <>{calcPrice(registrationFee, 'amount') > 0 ? <CurrencyFormat value={calcPrice(registrationFee, 'amount') / 100} /> : <Translate id='storeSingle.section.registration.fee.free' />}</>
                                                                        }
                                                                        alreadyRegistered={registrationFee.already_registered}
                                                                        manageWaitingList={registrationFee.manage_waiting_list == 1 && registrationFee.sold_out == 1}
                                                                        placesLeft={registrationFee.show_registration_count == 1 ? parseInt(registrationFee.available_place) - parseInt(registrationFee.registration_count) : null}
                                                                    />
                                                                ))}
                                                                {(Array.isArray(registrationContext.currentOnlineStore.items.other_fees) && registrationContext.currentOnlineStore.items.other_fees.length > 0) &&
                                                                    <RegistrationCard
                                                                        value={'None'}
                                                                        title={<Translate id='participant.registrationModal.views.registrationOptions.none.subtitle' />}
                                                                        selectRegistrationFee={() => setSelectedRegistrationFee(null)}
                                                                    />
                                                                }
                                                            </FormikGroup>
                                                        </>
                                                    )
                                                }}
                                            </RequestCall>
                                            :
                                            <>
                                                <div className="font-weight-bold mb-3"><Translate id='participant.registrationModal.views.registrationOptions.noRegistration' values={{ orgName: <DisplayI18n field='name' defaultValue={orgContext.organisation_name} i18n={orgContext.i18n} /> }} /></div>
                                                <RegistrationAhmChecker participant={registrationContext.getCurrentMember().members[0]} fromRegistrationOptions />
                                            </>
                                        }

                                        {hasOutstandingBalance &&
                                            <div className='mt-3'>
                                                <Alert color='danger'><Translate id='participant.registrationModal.views.registrationOptions.outstandingBalance' /></Alert>
                                            </div>
                                        }

                                        {formik.status &&
                                            <div className='mt-3'>
                                                <Alert color='danger' toggle={() => formik.setStatus(null)}>{formik.status}</Alert>
                                            </div>
                                        }
                                    </div>

                                </ModalBody>
                                <ModalFooter tag={'footer'} className="bg-light">
                                    <Button
                                        id='registrationOptionsPreviousButton'
                                        onClick={() => {
                                            // registrations is the entry point to the cart
                                            // and since we now add the member right before this step
                                            // it only makes sense to remove it on the Previous click
                                            if(!registrationContext.getCurrentMember().isFinal){
                                                // we want to remove member items & member when we have created a cart
                                                // not displaying an error message since its a previous button
                                                if(registrationContext.state.currentCart.cartInfo.shopping_cart_id){
                                                    registrationContext.setModalLoading(true);
                                                    registrationContext.removeMemberItemsFromCurrentCart(registrationContext.state.currentMemberId, false, true)
                                                        .catch(console.error)
                                                }else{
                                                    // when no cart is created, just remove member
                                                    registrationContext.removeMember(registrationContext.state.currentMemberId)
                                                }
                                            }
                                            registrationContext.setCurrentMemberId('');
                                            registrationContext.goToView(registrationContext.views.existingOrNew, true)
                                        }}
                                        color="default"
                                        type='button'
                                        outline
                                        className="mr-auto"
                                    ><Translate id='misc.previous' />
                                    </Button>
                                    <Button id='registrationOptionsSubmitButton' type='submit' color="info" disabled={!mhaIsValid || hasOutstandingBalance}><Translate id='misc.next' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            }
        </>
    );
}

const RegistrationCard = ({ index, value, title, price, alreadyRegistered, manageWaitingList, placesLeft, selectRegistrationFee }) => {
    const [ field, _meta, _helpers ] = useField('registrationId');

    const getSubtitle = () => {
        if(alreadyRegistered){
            return (
                <UserDisplay.Subtitle>
                    <i className="mdi mdi-alert-outline mr-1" />
                    <Translate id='participant.registrationModal.views.registrationOptions.alreadyRegistered' />
                </UserDisplay.Subtitle>
            )
        }else if(manageWaitingList){
            return (
                <UserDisplay.Subtitle>
                    <i className="mdi mdi-clock-alert-outline mr-1" />
                    <Translate id='participant.registrationModal.views.registrationOptions.waitingList' />
                </UserDisplay.Subtitle>
            )
        }else if(placesLeft !== null && placesLeft !== undefined){
            return (
                <UserDisplay.Subtitle>
                    <Translate
                        id='participant.registrationModal.views.registrationOptions.placesLeft'
                        values={{
                            amount: placesLeft,
                        }}
                    />
                </UserDisplay.Subtitle>
            )
        }
    }

    return (
        <>
            <label
                id={'registrationOptionsRegistrationCard-' + value}
                htmlFor={value}
                className={stringBuilder('registration-option', { 'is-disabled text-muted': alreadyRegistered, 'is-selected': field.value === value })}
                onClick={() => {
                    selectRegistrationFee()
                }}
            >
                <UserDisplay.Container>
                    <FormikRadioButton id={value} name='registrationId' value={value} disabled={alreadyRegistered} />
                </UserDisplay.Container>
                <UserDisplay.Container>
                    <UserDisplay.Title>{title}</UserDisplay.Title>
                    {getSubtitle()}
                </UserDisplay.Container>
                {price &&
                    <UserDisplay.Container className="ml-auto">{ price }</UserDisplay.Container>
                }
            </label>
            <Collapse isOpen={field.value === value && manageWaitingList}>
                <Fade in={field.value === value && manageWaitingList}>
                    <div className='w-100 py-3 px-5 text-muted small text-center'>
                        <div className='mb-3'><Translate id='participant.registrationModal.views.registrationOptions.waitingList.hint' /></div>
                        <div className='mb-3'><Translate id='participant.registrationModal.views.registrationOptions.waitingList.hint2' /></div>
                        <div><Translate id='participant.registrationModal.views.registrationOptions.waitingList.hint3' /></div>
                    </div>
                </Fade>
            </Collapse>
        </>
    )
};

export default RegistrationOptions;
