/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import {stringBuilder}from'@spordle/helpers';import {useField}from'formik';import PropTypes from'prop-types';import {IMaskInput}from'react-imask';import {useIntl}from'react-intl';import {Label,FormText}from'reactstrap';import FormikError from'./FormikError.js';/**
 * @member FormikInputMasked
 * @description
 * FormikInputMasked will create an input text that will work automagically with Fromik(yes it's a typo :)) validations
 * @example
 * <FormikInputMasked mask='a0a 0a0' type="text" name="zip" id="zip" label='payment.billing.form.label.zip'/>
 *
 * @prop {string} name Used to make the FormikInputMasked work with validation
 * @prop {string|JSX.Element} [label] The lang id to be translated OR JSX
 * @prop {string|JSX.Element} [helper] The lang id to be translated OR JSX
 * @prop {string} [placeholder] The lang id to be translated
 * @prop {boolean} [translateHelper=true]
 * @prop {boolean} [translatePlaceholder=true]
 * @prop {boolean} [translateLabel=true]
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @returns {JSX.Element} Returns an input text that will work with Formik
 *
 * @see https://imask.js.org/guide.html
 * @see https://github.com/uNmAnNeR/imaskjs/tree/master/packages/react-imask
 * @copyright Spordle
 */
const FormikInputMasked = ({ label, helper, translatePlaceholder, translateHelper, translateLabel, manualError, ...props }) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const [{ onChange, ...field }, meta, helpers] = useField(props);
    const intl = useIntl();
    if (translatePlaceholder && props.placeholder && typeof props.placeholder === 'string')
        props.placeholder = intl.formatMessage({ id: props.placeholder });
    if (translateHelper && helper && typeof helper === 'string')
        helper = intl.formatMessage({ id: helper });
    if (translateLabel && label && typeof label === 'string')
        label = intl.formatMessage({ id: label });
    return (jsxs(Fragment, { children: [label && typeof label === 'string' ? jsx(Label, { for: props.id || props.name, children: label }) : label, jsx(IMaskInput, { ...field, ...props, 
                // @ts-ignore
                onAccept: props.onAccept || function (value) {
                    helpers.setValue(value);
                }, className: stringBuilder('form-control', props.className, { 'is-invalid': meta.touched && meta.error }), unmask: false }), helper && typeof helper === 'string' ? jsx(FormText, { color: "muted", children: helper }) : helper, !manualError && jsx(FormikError, { name: props.name })] }));
};
FormikInputMasked.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateHelper: PropTypes.bool,
    translateLabel: PropTypes.bool,
    translatePlaceholder: PropTypes.bool,
    name: PropTypes.string.isRequired,
    manualError: PropTypes.bool,
};
FormikInputMasked.defaultProps = {
    translateHelper: true,
    translateLabel: true,
    translatePlaceholder: true,
};
FormikInputMasked.displayName = 'FormikInputMasked';
const FormikInputMasked$1 = FormikInputMasked;export{FormikInputMasked$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/