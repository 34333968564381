/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import {useContext,useState,useCallback}from'react';import {SpordleTableContext}from'./SpordleTableProvider.js';import {stringBuilder}from'@spordle/helpers';// TODO
// https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
const SortableColumn = ({ columnData, orderingStatus, preventHeaderHoverEffect, ...props }) => {
    const spordleTable = useContext(SpordleTableContext);
    const [isHovering, setHovering] = useState(false);
    const buildSortIcon = useCallback(() => {
        if (!columnData.sortable && orderingStatus === 'none')
            return null;
        return (jsx("i", { className: stringBuilder('mdi', spordleTable.props.bordered ? 'ml-auto' : 'ml-1', {
                'mdi-sort-ascending': orderingStatus === 'ASC',
                'mdi-sort-descending': orderingStatus === 'DESC',
                'mdi-sort text-black-50': orderingStatus === 'none',
            }) }));
    }, [columnData.sortable, orderingStatus]);
    return (jsx("th", { className: stringBuilder('text-nowrap align-middle', { 'clickable': columnData.sortable, 'bg-light': isHovering }, columnData.className), onClick: columnData.sortable ? () => spordleTable.columnOrdering(columnData.key) : undefined, ...props, onMouseEnter: preventHeaderHoverEffect ? undefined : () => setHovering(true), onMouseLeave: preventHeaderHoverEffect ? undefined : () => setHovering(false), id: spordleTable.generateId('table', 'header', 'headerColumn', columnData.key, props.id || ''), children: jsxs("div", { className: 'd-flex align-items-center', children: [props.draggable && jsx("i", { style: { cursor: 'ew-resize' }, className: `mdi mdi-drag-vertical ${spordleTable.props.smallTable ? 'ml-n1' : 'ml-n3'} ${isHovering ? 'visible' : 'invisible'}` }), typeof columnData.label === 'function' ? jsx(columnData.label, {}) : columnData.label, buildSortIcon()] }) }));
};
SortableColumn.displayName = 'SortableColumn';
const SortableColumn$1 = SortableColumn;export{SortableColumn$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/