import Translate from '@spordle/intl-elements';
import { Link } from 'react-router-dom';
import ShopIcon from '../../../../../assets/scss/icons/custom-icons/RegistrationIcon';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import Section from '../../../../../components/visual/structure/Section';
import { useContext, useState } from 'react';
import { I18nContext } from '../../../../../contexts/I18nContext';
import getReferrer from '../../../../../helpers/getReferer';
import PromptSharedStoreModal from './PromptSharedStoreModal';

const StoresList = ({ location, stores, ...props }) => {
    const { getGenericLocale } = useContext(I18nContext)
    const [ promptInfo, setPromptInfo ] = useState({
        isLeaving: false,
        link: '',
        store: {},
    });

    return (
        <Section>
            <PromptSharedStoreModal isOpen={promptInfo.isLeaving} promptInfo={promptInfo} toggle={() => setPromptInfo((prev) => ({ ...prev, isLeaving: false }))} />

            <header>
                <h1 className="section-main-title"><Translate id='storesList.page.title' /></h1>
            </header>
            <ul className="list-unstyled">
                {stores
                    .filter((store) => !store.isSharedStore)
                    .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                    .map((store) => (
                        <StoreListItem key={store.online_store_id} store={store} location={location} />
                    ))}
                {stores
                    .filter((store) => store.isSharedStore)
                    .sort((a, b) => displayI18n('name', a.organisation.i18n, a.organisation.organisation_name, getGenericLocale()).localeCompare(displayI18n('name', b.organisation.i18n, b.organisation.organisation_name, getGenericLocale())))
                    .map((store) => (
                        <StoreListItem key={store.online_store_id} store={store} location={location} setPromptInfo={setPromptInfo} />
                    ))}
            </ul>
        </Section>
    );
};

const StoreListItem = ({ index, store, location, setPromptInfo }) => {
    return (
        <li className="registration-forms-list-item" style={{ cursor: 'pointer' }}>
            {store.isSharedStore && store.organisation ?
                <div
                    onClick={() => setPromptInfo({
                        isLeaving: true,
                        link: store.organisation.deploy_on == 'PAGE' ?
                            `${getReferrer('PAGE-VERCEL-2')}/page/${store.organisation.short_name}/participant/${store.online_store_id}`
                            : store.organisation.deploy_on == 'VERCEL' ?
                                `${getReferrer('PAGE-VERCEL')}/page/${store.organisation.short_name}/participant/${store.online_store_id}`
                                :
                                `${getReferrer('PAGE')}/page/${store.organisation.short_url}/participant/${store.online_store_id}`,
                        store: store,
                    })}
                    className="stretched-link"
                    id={'store-' + store.online_store_id}
                />
                :
                <Link to={{ pathname: `${location.pathname}/${store.online_store_id}`, state: { store: store } }} className="stretched-link" id={'store-' + store.online_store_id} />
            }
            <div className="d-flex align-items-center">
                <div className="registration-forms-list-item-icon">
                    <ShopIcon />
                </div>
                <div className="p-3 flex-fill">
                    <h2 className="h3 mb-1">
                        <DisplayI18n
                            field='name'
                            defaultValue={store.name}
                            i18n={store.i18n}
                        />
                    </h2>
                    {(store.isSharedStore && !!store.organisation?.organisation_name) &&
                    <div>
                        <small className="text-muted font-bold">
                            <DisplayI18n
                                field='name'
                                defaultValue={store.organisation?.organisation_name}
                                i18n={store.organisation?.i18n}
                            />
                        </small>
                    </div>
                    }
                    <div>
                        <small className="text-muted font-bold">
                            <DisplayI18n
                                field='name'
                                defaultValue={store.period?.name}
                                i18n={store.period?.i18n}
                            />
                        </small>
                    </div>
                </div>
                <i className={`mdi display-4 text-primary ${store.isSharedStore ? 'mdi-launch font-22 mr-4' : 'mdi-chevron-right mr-3'}`} />
            </div>
        </li>
    )
}

export default StoresList;