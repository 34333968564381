/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsxs,jsx,Fragment}from'react/jsx-runtime';import {FormattedMessage}from'react-intl';import {Pagination,PaginationItem,PaginationLink}from'reactstrap';import useSpordleTable from'./useSpordleTable.js';import PropTypes from'prop-types';/**
 * @member Paginator
 * @description Combines {@link PaginationNav} and {@link PaginationMessage}
 * @example
 * <Paginator />
 */
const PaginationHandler = ({ id, className }) => {
    const spordleTable = useSpordleTable();
    return (jsxs("div", { className: 'd-flex align-items-center mt-3', children: [jsx("div", { className: `small ${spordleTable.state.isMobile ? 'd-none' : 'd-block'}`, children: jsx(PaginationMessage, {}) }), jsx(PaginationNav, { id: id, className: className })] }));
};
PaginationHandler.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
};
/**
 * @member PaginationNav
 * @description The pagination handler.
 * @example
 * <PaginationNav />
 */
const PaginationNav = ({ id, className }) => {
    const spordleTable = useSpordleTable();
    const pagination = spordleTable.getPagination();
    const currentPage = spordleTable.getCurrentPage();
    const pageCount = Math.ceil(spordleTable.filteredData.length / (pagination || 1));
    if (pageCount <= 1)
        return null;
    let pageRangeDisplayed = 1;
    if (pageCount <= 5) {
        pageRangeDisplayed = 5;
    }
    else if (currentPage === 0 || currentPage === 1 || (pageCount - currentPage) === 2) {
        pageRangeDisplayed = 2;
    }
    else if (pageCount === currentPage + 1) { // Last page
        pageRangeDisplayed = 3;
    }
    const getPaginationElement = (index, isBreak = false) => (jsx(PaginationItem, { active: currentPage == index, children: jsx(PaginationLink, { tag: 'button', type: 'button', className: 'h-100', onClick: !isBreak && currentPage != index ? () => spordleTable.goToPage(index) : undefined, id: spordleTable.generateId('pagination', 'selector', 'page', `${index}`, id), tabIndex: 0, "aria-label": isBreak ? undefined : `Page ${index + 1}`, children: isBreak ? '...' : index + 1 }) }, index));
    const getPageSelector = () => {
        const items = [];
        const marginPagesDisplayed = 1;
        if (pageCount <= pageRangeDisplayed) {
            for (let index = 0; index < pageCount; index++) {
                items.push(getPaginationElement(index));
            }
        }
        else {
            let leftSide = pageRangeDisplayed / 2;
            let rightSide = pageRangeDisplayed - leftSide;
            // If the selected page index is on the default right side of the pagination,
            // we consider that the new right side is made up of it (= only one break element).
            // If the selected page index is on the default left side of the pagination,
            // we consider that the new left side is made up of it (= only one break element).
            if (currentPage > pageCount - pageRangeDisplayed / 2) {
                rightSide = pageCount - currentPage;
                leftSide = pageRangeDisplayed - rightSide;
            }
            else if (currentPage < pageRangeDisplayed / 2) {
                leftSide = currentPage;
                rightSide = pageRangeDisplayed - leftSide;
            }
            let index;
            /*eslint prefer-const: "off"*/
            let page;
            let breakView;
            for (index = 0; index < pageCount; index++) {
                page = index + 1;
                // If the page index is lower than the margin defined,
                // the page has to be displayed on the left side of
                // the pagination.
                if (page <= marginPagesDisplayed) {
                    items.push(getPaginationElement(index));
                    continue;
                }
                // If the page index is greater than the page count
                // minus the margin defined, the page has to be
                // displayed on the right side of the pagination.
                if (page > pageCount - marginPagesDisplayed) {
                    items.push(getPaginationElement(index));
                    continue;
                }
                // If the page index is near the selected page index
                // and inside the defined range (pageRangeDisplayed)
                // we have to display it (it will create the center
                // part of the pagination).
                if (index >= currentPage - leftSide && index <= currentPage + rightSide) {
                    items.push(getPaginationElement(index));
                    continue;
                }
                // If the page index doesn't meet any of the conditions above,
                // we check if the last item of the current "items" array
                // is a break element. If not, we add a break element, else,
                // we do nothing (because we don't want to display the page).
                if (items[items.length - 1] !== breakView) {
                    breakView = getPaginationElement(index, true);
                    items.push(breakView);
                }
            }
        }
        return items;
    };
    return (jsxs(Pagination, { listClassName: 'mb-0', className: className, id: spordleTable.generateId('pagination', 'selector', id), "data-testid": 'paginator-handler', children: [jsx(PaginationItem, { disabled: currentPage == 0, children: jsx(PaginationLink, { previous: true, tag: 'button', type: 'button', id: spordleTable.generateId('pagination', 'selector', 'previous', id), onClick: () => spordleTable.goToPage(currentPage - 1), children: jsx("i", { className: 'mdi mdi-chevron-left align-middle' }) }) }), getPageSelector(), jsx(PaginationItem, { disabled: currentPage == pageCount - 1, children: jsx(PaginationLink, { next: true, tag: 'button', type: 'button', id: spordleTable.generateId('pagination', 'selector', 'next', id), onClick: () => spordleTable.goToPage(currentPage + 1), children: jsx("i", { className: 'mdi mdi-chevron-right align-middle' }) }) })] }));
};
/**
 * @member PaginationMessage
 * @description A message about the displayed data linked to the data.
 * @example
 * <PaginationMessage />
 */
const PaginationMessage = () => {
    const spordleTable = useSpordleTable();
    const pagination = spordleTable.getPagination();
    const currentPage = spordleTable.getCurrentPage();
    const createPaginationMessageValue = () => {
        const startIndex = pagination * currentPage;
        const displaySize = spordleTable.dataToDisplay.length;
        const dataLenght = spordleTable.filteredData.length;
        const endIndex = startIndex + (pagination || displaySize) >= dataLenght ? dataLenght : startIndex + pagination;
        return {
            totalItems: dataLenght,
            displayedItems: displaySize,
            itemStartPos: startIndex + 1,
            itemEndPos: endIndex,
        };
    };
    return (jsx(Fragment, { children: spordleTable.props.paginationMessage ?
            typeof spordleTable.props.paginationMessage === 'string' ?
                jsx(FormattedMessage, { id: spordleTable.props.paginationMessage, values: createPaginationMessageValue() })
                :
                    spordleTable.props.paginationMessage(createPaginationMessageValue())
            :
                null }));
};
/**
 * @description Use module import instead
 * @deprecated Use PaginationMessage export
 * @example import { PaginationMessage } from '@spordle/datatables';
 */
PaginationHandler.Message = PaginationMessage;
/**
 * @description Use module import instead
 * @deprecated Use PaginationNav export
 * @example import { PaginationNav } from '@spordle/datatables';
 */
PaginationHandler.Pagination = PaginationNav;
const PaginationHandler$1 = PaginationHandler;export{PaginationMessage,PaginationNav,PaginationHandler$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/