import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<PositionsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const PositionsContext = createContext();
PositionsContext.displayName = 'PositionsContext';

class PositionsContextProvider extends React.Component{


    /**
     * GET[ALL] - Get phone types
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Positions/b9691fc1746092e051e92f1a91443f6c|documentation}
     * @returns {Promise.<Array>}
     */
    getPositions = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `positions`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.positions
                }
                throw response.data.errors[0]
            }, serverError)
    }


    /**
     * GET Specific phone type
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Position%20Groups/702e954f2ffb02cc8a0145bdf1268934|documentation}
     * @returns {Promise.<Array>}
     */
    getPositionGroups = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `position-groups`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.position_groups
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <PositionsContext.Provider value={{ ...this }}>
                {this.props.children}
            </PositionsContext.Provider>
        )
    }
}


export default withContexts(OrganizationContext)(PositionsContextProvider);

