/*
@Copyrights Spordle 2022 - All rights reserved
*/
let intl;
/**
 * @function setExternalTranslate
 * @description Call this function when creating the <IntlProvider></IntlProvider>
 * @param {Object} intlRef the object returned by createIntl from react-intl
 * @example
 * const cache = createIntlCache();
 * setExternalTranslate(createIntl({
 *      locale: locale,
 *      messages: messages[locale],
 * }, cache));
 * @copyright Spordle
 * @deprecated
 */
function setExternalTranslate(intlRef) {
    intl = intlRef;
}
/**
 * @function externalTranslate
 * @description
 * Function to translate text outside the context of the app.
 * This fonction takes for granted that {@link setExternalTranslate} was called
 *
 * **NOTE: Try using the `intl.formatMessage`. Only use this as a plan B**
 * @param {string} translateId The index to be translated
 * @param {Object} [options] Object containing indexes and values to be matched with wildcards
 * @param {string} [defaultMessageId] String index to be shown as a default message if the id prop is not valid
 * @example
 * externalTranslate('tournamentSingle.sweetAlert.confirm.title');
 * externalTranslate('misc.priceValue', {price: 999});
 * @returns {string} The string corresponding to the index given in the current language
 * @copyright Spordle
 * @deprecated
 */
function externalTranslate(translateId, options, defaultMessageId) {
    return intl.formatMessage({ id: translateId, defaultMessage: defaultMessageId ? intl.formatMessage({ id: defaultMessageId }) : undefined }, { ...options });
}export{externalTranslate,setExternalTranslate};
/*
@Copyrights Spordle 2022 - All rights reserved
*/