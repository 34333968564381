import Translate from "@spordle/intl-elements";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { FormattedNumber } from "react-intl";
import PropTypes from 'prop-types';
import { Collapse } from "reactstrap";
import { bytesToMb, isNewFile } from "./uploadHelpers";
import { stringBuilder } from "@spordle/helpers";
import SingleFile from "./SingleFile";
import { fire } from '@spordle/toasts';

const defaultDrop = {
    multiple: false,
    maxSize: 10485760, // 10 MB
    accept: "image/jpeg, image/png, image/jpg",
};

/**
 * @callback onFileClick
 * @description Function called when a user clicks on file name
 * @param {File|object} clickedFile
 * @param {function} downloadFile
 */

/**
 * @callback onFileSelect
 * @description Function to call when new file is added
 * @param {File[]} files Files
 */

/**
 * @typedef {Object} DefaultFile
 * @prop {string} id
 * @prop {string} file_name
 * @prop {string} type
 */

/**
 * @typedef {Object} FileUploadProps
 * @prop {import("react-dropzone").DropzoneProps & React.RefAttributes<DropzoneRef>} [dropzoneProps] Refer to {@link https://react-dropzone.js.org/#src|Documentation}
 * @prop {boolean} [disabled=false] Can't upload or edit if disabled
 * @prop {string} [id] Input id
 * @prop {function} [onError] Function called when an error is triggered -> will overwrite default handling of errors
 * @prop {number} [maxShown=3] Max files shown when multi, when more will be in a collapse
 * @prop {number} [maxCountSize=20] Max amount of files
 * @prop {string} [name=file] Input name
 * @prop {onFileClick} onFileClick Function called when a user clicks on file name
 * @prop {onFileSelect} onFileSelect Function called when a file is added
 * @prop {DefaultFile[]} [defaultFiles] Default files to show
 * @prop {boolean} [preventRemoveExistant] Prevents removing existant files
 * @prop {boolean} [showCreatedAt] Wether or not to show the created date and created by next to the file names
 * @prop {string|number} color Color of the card border and the icons
 */

/**
 * @param {FileUploadProps} [props]
 * @returns {React.ReactNode}
 * @example
 * <FileUpload
 *      name='attachment'
 *      onError={handleError}
 *      maxFileCount={10}
 *      onFileSelect={(file) => setFieldValue('field', file)}
 *      disabled={cannotAddFiles}
 * />
 * @example
 * <FileUpload
 *      name="files"
 *      onFileSelect={(file) => setFieldValue('field', file)}
 *      dropzoneProps={{
 *          multiple: true,
 *          accept: "image/jpeg, image/png, image/jpg, .doc, .docx",
 *      }}
 * />
 */
const FileUpload = ({
    dropzoneProps,
    download = false,
    disabled = false,
    onFileSelect,
    onFileClick,
    onError,
    maxShown = 4,
    maxFileCount = 20,
    name = 'file',
    id,
    defaultFiles,
    preventRemoveExistant,
    showCreatedAt,
}) => {
    const [ currentFiles, setCurrentFiles ] = useState(defaultFiles || []);
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(false);
    const { multiple } = dropzoneProps;

    /**
     * @description Sets a new error to showcase
     * @param {object} newErr
     */
    const setNewError = (newErr) => {
        if(onError){
            onError(<Translate id={newErr.msg} values={newErr.values} />);
        }else{
            fire({ msg: 'components.uploader.imgUpload.error.title', skipInfoTranslate: true, info: <Translate id={newErr.msg} values={newErr.values} /> })
        }
    }

    /**
     * @description Functions that handles the errors of the dropzone
     * @param {array} errors
     */
    const handleRejected = (errors) => {
        const newErr = { msg: 'misc.error' };

        switch (errors[0].errors[0].code){
            case "too-many-files":
                newErr.msg = 'components.uploader.imgUpload.error.tooMany';
                break;
            case 'file-too-large':
                newErr.msg = 'components.uploader.imgUpload.error.tooLarge';
                newErr.values = { maxSize: bytesToMb(dropzoneProps.maxSize || defaultDrop.maxSize) };
                break;
            case 'file-invalid-type':
                newErr.msg = 'components.uploader.imgUpload.error.fileType';
                newErr.values = { accepted: `${dropzoneProps.accept || defaultDrop.accept}`?.replace(/image\//gi, ".") };
                break;
            default:
                break;
        }

        setNewError(newErr);
    }

    /**
     * @description Function that handles files accepted in the dropdown
      Will compare with current files to see if duplicated (when is multiple)
     * @param {File[]} files
     */
    const handleAccepted = (files) => {
        let newArr = files;

        if(multiple){
            newArr = [ ...currentFiles ];

            for(let index = 0; index < files.length; index++){
                const file = files[index];
                // Need to do some research to better compare if two files are the same
                // Or another solution for keys
                if(!newArr.some((f) => f.name == file.name && f.lastModified == file.lastModified && f.size == file.size)){
                    if(newArr.length < maxFileCount){
                        newArr.unshift(file);
                    }else{
                        setNewError({ msg: 'components.uploader.imgUpload.error.max', values: { maxSize: maxFileCount } });
                    }
                }else{
                    setNewError({ msg: 'components.uploader.imgUpload.error.exists', values: { name: file.name } });
                }
            }
        }

        setCurrentFiles(newArr);
        onFileSelect?.(newArr);
    }

    /**
     * @description Handles the removing of a file
     * @param {number} i Index to remove
     */
    const handleRemoveFile = (i) => {
        let newArr = [];

        if(multiple){
            newArr = [ ...currentFiles ];
            newArr.splice(i, 1);
        }

        setCurrentFiles(newArr);
        onFileSelect?.(newArr);
    }

    /**
     * @description Handles view more logic
     * @returns {React.ReactNode}
     */
    const renderFilesList = () => {
        const shown = currentFiles.slice(0, maxShown);
        const hidden = currentFiles.slice(maxShown, currentFiles.length);

        const handleMap = (curr, i) => (
            <SingleFile
                key={(curr.lastModified || curr.id) + curr.name + curr.type}
                file={curr}
                showRemove={(curr.canRemove || isNewFile(curr)) && !disabled && (preventRemoveExistant ? !curr.id : true)}
                handleOnRemove={() => handleRemoveFile(i)}
                onFileClick={onFileClick}
                download={download}
                showCreatedAt={showCreatedAt}
            />
        );

        return (
            currentFiles.length > 0 ?
                <div>
                    <div className="text-muted small mt-2 mb-1"><Translate id='components.uploader.fileUpload.uploadedFiles' values={{ count: currentFiles.length }} /></div>
                    { currentFiles.length > maxShown ?
                        <>
                            <div className={stringBuilder('files-list', { 'isExpanded': collapseIsOpen })}>
                                {shown.map(handleMap)}
                                <Collapse isOpen={collapseIsOpen}>
                                    {hidden.map(handleMap)}
                                </Collapse>
                            </div>
                            <button type="button" className="reset-btn text-link" onClick={() => setCollapseIsOpen(!collapseIsOpen)}>
                                {collapseIsOpen ?
                                    <><Translate id="misc.viewLess" /> <i className="mdi mdi-chevron-up" /></>
                                    :
                                    <><Translate id="misc.viewCountMore" values={{ count: hidden.length }} /> <i className="mdi mdi-chevron-down" /></>
                                }
                            </button>
                        </>
                        :
                        currentFiles.map(handleMap)
                    }
                </div>
                :
                disabled && <Translate id='components.uploader.imgUpload.modal.noFile' />
        )
    }

    useEffect(() => {
        setCurrentFiles(defaultFiles?.[0]?.name ? defaultFiles : []);
    }, [ (defaultFiles?.[0]?.id || '') + JSON.stringify(defaultFiles) ]);

    return (
        <Dropzone
            disabled={disabled}
            {...defaultDrop}
            {...dropzoneProps}
            maxSize={dropzoneProps.maxSize ?? defaultDrop.maxSize}
            onDropRejected={handleRejected}
            onDropAccepted={handleAccepted}
        >
            {({ isDragActive, getRootProps, getInputProps }) => (
                <>
                    {(!currentFiles.length > 0 || multiple) && !disabled &&
                        <div className="position-relative" {...getRootProps()}>
                            <div className={stringBuilder({ 'd-none': !isDragActive })} onClick={(e) => e.stopPropagation()}>
                                <div className='files-dropzone-drop font-medium'>
                                    <Translate id='components.uploader.imgUpload.modal.drop' />
                                </div>
                            </div>
                            <div className='files-dropzone-note'>
                                <span className="text-link mr-1">
                                    <i className="mdi mdi-upload" /> <Translate id='misc.fileUpload.text.1' />
                                </span>
                                <Translate id='misc.fileUpload.text.2' />
                                <div className="small">
                                    <Translate id='misc.fileUpload.maxSize' />: <FormattedNumber unitDisplay="narrow" style="unit" maximumFractionDigits={2} unit="megabyte" value={bytesToMb(dropzoneProps?.maxSize || defaultDrop.maxSize)} />
                                </div>
                            </div>
                        </div>
                    }
                    {renderFilesList()}
                    <input id={id || name} name={name} {...getInputProps()} />
                </>
            )}
        </Dropzone>
    )
}

export default FileUpload;

FileUpload.propTypes = {
    onFileSelect: PropTypes.func.isRequired,
    defaultFiles: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
    download: PropTypes.bool,
    dropzoneProps: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    maxShown: PropTypes.number,
    maxFileCount: PropTypes.number,
    onFileClick: PropTypes.func,
    preventRemoveExistant: PropTypes.bool,
    onError: PropTypes.func,
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

FileUpload.defaultProps = {
    disabled: false,
    download: false,
    dropzoneProps: {
        multiple: false,
        maxSize: 10485760,
        accept: "image/jpeg, image/png, image/jpg",
    },
    name: 'file',
    maxShown: 4,
    maxFileCount: 20,
}