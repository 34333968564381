import moment from 'moment';
import Placeholder from '../../../../../assets/images/placeholders/PageHeaderPlaceholder.jpg';
import ImgLoader from '../../../../../components/loading/ImgLoader';
import ImgPill from '../../../../../components/visual/imgPill/ImgPill';

/**
 * @typedef {Object} PostData
 * @prop {JSX.Element|string} title
 * @prop {JSX.Element|string} body
 * @prop {object} img
 * @prop {string} img.src
 * @prop {string} img.alt
 * @prop {string} created_date
 * @prop {object} author
 * @prop {object} author.logo
 * @prop {string} author.logo.full_path
 * @prop {string} author.logo.file_position
 */
/**
 * @description A single post
 * @param {Object} props
 * @param {boolean} [props.isHighlighted]
 * @param {PostData} props.postData
 * @param {string|JSX.Element} props.titleTag="h3"
 * @param {string} props.className
 * @returns {React.ReactNode}
 */
const PostSingle = ({ isHighlighted, isExternal = false, postData, editPost, titleTag: TitleTag = 'h3', className, ...props }) => {
    const classNames = `publication${!isHighlighted ? isExternal ? ' publication-external' : ' publication-internal' : ` is-isHighlighted`}${className ? ` ${className}` : ''}`

    return (
        <article className={classNames} {...props}>
            <header className="publication-header">
                <div className="align-items-center d-flex mb-2">
                    <ImgPill
                        className="mr-2"
                        size={!isHighlighted ? 'sm' : 'sm'}
                        src={postData.author?.logo?.full_path}
                        filePos={postData.author?.logo?.file_position}
                        alt={`Logo ${postData.author?.organisation_name}`}
                        abbr={postData.author?.abbreviation || postData.author?.organisation_name}
                    />
                    <div>
                        <TitleTag className={isHighlighted ? 'h3 mb-0' : 'publication-author'}>{postData?.title}</TitleTag>
                        {postData?.created_date && <small className="text-muted">&bull; <time>{moment(postData.created_date).fromNow()}</time></small>}
                    </div>
                    {/* { isExternal ?
                        <a className="publication-btn publication-external-btn" rel='noopener noreferrer'>
                            <i className="mdi mdi-launch"></i>
                        </a>
                        :
                        <UncontrolledDropdown className="publication-options-container">
                            <DropdownToggle color='circle'>
                                <i className="mdi mdi-dots-horizontal"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem
                                    className="publication-btn"
                                    onClick={() => {
                                        toggleIsOpen('share');
                                        toggleIsOpen('options');
                                    }}
                                >
                                    <i className="mdi mdi-share mr-2"></i> <Translate id='postSingle.btn.share' />
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem
                                    className="publication-btn"
                                    onClick={() => {
                                        editPost(true, postData);
                                        toggleIsOpen('options');
                                    }}
                                >
                                    <i className="fa fa-cog mr-2"></i> <Translate id='postSingle.btn.edit' />
                                </DropdownItem>
                                <DropdownItem
                                    className="publication-btn"
                                    onClick={() => {
                                        deletePost();
                                        toggleIsOpen('options');
                                    }}
                                >
                                    <i className="fas fa-trash mr-2"></i> <Translate id='postSingle.btn.delete' />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    } */}
                </div>
                <figure className="publication-img-container">
                    <ImgLoader height={250} className="publication-img" src={postData.img?.src || Placeholder} alt={postData.img?.alt || "Post image"} />
                </figure>
            </header>
            <div className="publication-body">
                <div className="publication-content">
                    {
                        isExternal ?
                            <>
                                <span className="publication-external-link ">
                                    <i className="mdi-web mdi" /> {postData?.link}
                                </span>
                                <h4 className="publication-title">{postData?.title}</h4>
                            </>
                            :
                            <p className={!isHighlighted ? 'publication-p' : 'p'}>{postData?.body}</p>
                    }
                </div>
                { isExternal && <a href={postData?.link} target="_blank" className="stretched-link" rel='noopener noreferrer' /> }
            </div>
            {/* <PostShareModal id={postId} isOpen={isOpen.share} toggleModal={() => toggleIsOpen('share')} /> */}
        </article>
    );
}

export default PostSingle;