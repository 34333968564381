/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import SpordleTableProvider,{SearchInput,useSpordleTable}from'@spordle/datatables';import {stringBuilder}from'@spordle/helpers';import {useField}from'formik';import PropTypes from'prop-types';import {useRef,useState,useCallback}from'react';import {PhoneNumberUtil,PhoneNumber,PhoneNumberFormat}from'google-libphonenumber';import {useIntl,IntlProvider,FormattedDisplayName,FormattedMessage}from'react-intl';import NumberFormat from'react-number-format';import {DropdownItem,Label,InputGroup,ButtonDropdown,DropdownToggle,DropdownMenu,FormText}from'reactstrap';import {UAParser}from'ua-parser-js';import i18n,{getMessage}from'../src/i18n.js';import {formikElementStitches}from'../styles/stitches.js';import FormikError from'./FormikError.js';import useFormikElementsGlobalConfigsContext from'../src/useFormikElementsContext.js';const phoneUtil = PhoneNumberUtil.getInstance();
const Flag = formikElementStitches.styled('i', {
    // .flag-icon-background
    backgroundSize: 'contain',
    backgroundPositionX: '50%',
    backgroundRepeat: 'no-repeat',
    // .flag-icon
    position: 'relative',
    display: 'inline-block',
    width: '1.3333333333em',
    height: '1em',
    lineHeight: '1em',
    '&:before': {
        content: '\\00a0',
    },
    variants: {
        flagCode: phoneUtil.getSupportedRegions().reduce((flagCodeVariants, countryCode) => {
            flagCodeVariants[countryCode.toLowerCase()] = {
                backgroundImage: `url("https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/formik-elements/flags/${countryCode.toLowerCase()}.svg"), url("https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/formik-elements/flags/xx.svg")`,
            };
            return flagCodeVariants;
        }, {}),
    },
});
const FlagList = formikElementStitches.styled('ul', {
    minWidth: 250,
    maxHeight: 300,
    overflowY: 'auto',
});
const FlagListItem = formikElementStitches.styled(DropdownItem, {
    cursor: 'pointer',
    alignItems: 'center',
});
const parser = typeof window !== 'undefined' ? new UAParser() : null;
const device = parser?.getDevice();
const isMobile = device?.type === 'mobile' || device?.type === 'console' || device?.type === 'smarttv' || device?.type === 'wearable';
/**
 * @member FormikPhoneInput
 * @description
 * FormikPhoneInput will create an input text that will work automagically with Fromik(yes it's a typo :)) validations
 *
 * **When using Content-Security-Policies**, make sure to add `spordle-frontend-assets.s3.ca-central-1.amazonaws.com` to the `img-src` value
 * @example
 * <FormikPhoneInput name='phone' id='phone' />
 * <FormikPhoneInput name='phone' id='phone' suggestedCountries={['ca', 'fr', 'us']} />
 *
 * @prop {string|React.ReactNode} [label] The lang id to be translated OR JSX
 * @prop {string|React.ReactNode} [helper] The lang id to be translated OR JSX
 * @prop {boolean} [translateHelper=true]
 * @prop {boolean} [translateLabel=true]
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @prop {string[]} [suggestedCountries=['ca', 'us']] A list of {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 ISO 3166-1 alpha-2} suggested countries as strings. These countries will be displayed at the top of the list.
 * @prop {string} [defaultSelectedCountry='ca'] A {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 ISO 3166-1 alpha-2}.
 * @prop {string} [dropDownColor=light] The color of the dropdown toggle button
 * @prop {object} [inputProps] Input props. See the {@link https://github.com/s-yadav/react-number-format doc}
 * @returns {React.ReactNode} Returns an input text that will work with Formik
 *
 * @copyright Spordle
 */
const FormikPhoneInput = ({ label, helper, translateHelper, translateLabel, manualError, disabled, // Custom props
dropDownColor = 'light', // DropdownMenu props
inputProps, // NumberFormat props
loader, // eslint-disable-line @typescript-eslint/no-unused-vars
suggestedCountries, defaultSelectedCountry, ...props }) => {
    const formikElementsGlobalConfigsContext = useFormikElementsGlobalConfigsContext();
    const _suggestedCountries = suggestedCountries || formikElementsGlobalConfigsContext?.formikPhoneInput?.suggestedCountries || ['ca', 'us'];
    const _defaultSelectedCountry = defaultSelectedCountry || formikElementsGlobalConfigsContext?.formikPhoneInput?.defaultSelectedCountry || 'ca';
    // @ts-ignore
    // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
    const [{ onChange, ...field }, meta, helpers] = useField(props);
    const lastCountryRegionCode = useRef(_defaultSelectedCountry);
    const intl = useIntl();
    const currentLocale = intl.locale.split(/(-|_)/)[0];
    const collator = new Intl.Collator(currentLocale, { sensitivity: 'base' });
    if (translateHelper && helper && typeof helper === 'string')
        helper = intl.formatMessage({ id: helper });
    if (translateLabel && label && typeof label === 'string')
        label = intl.formatMessage({ id: label });
    const [isOpen, setIsOpen] = useState(false);
    const getParsedNumber = useCallback(() => {
        try {
            return phoneUtil.parse(field.value, lastCountryRegionCode.current);
        }
        catch (error) {
            const phoneNumber = new PhoneNumber();
            phoneNumber.setCountryCode(phoneUtil.getCountryCodeForRegion(lastCountryRegionCode.current));
            return phoneNumber;
        }
    }, [field.value]);
    const onCountrySelected = (countryCode) => () => {
        parsedNumber.setCountryCode(phoneUtil.getCountryCodeForRegion(countryCode));
        lastCountryRegionCode.current = countryCode;
        helpers.setValue(phoneUtil.format(parsedNumber, PhoneNumberFormat.E164));
    };
    const parsedNumber = getParsedNumber();
    const countryISOCode = phoneUtil.getRegionCodeForNumber(parsedNumber)?.toLowerCase() || lastCountryRegionCode.current;
    const countryNumber = parsedNumber.getCountryCode() || phoneUtil.getCountryCodeForRegion(lastCountryRegionCode.current);
    const getSuggestedCountries = (countriesToDisplay) => {
        if (countriesToDisplay.length > 0) {
            const filteredSuggestedCountries = _suggestedCountries.filter((countryCode) => {
                return countriesToDisplay.some((country) => country.code.toLowerCase() === countryCode.toLowerCase());
            });
            if (filteredSuggestedCountries.length > 0) {
                return (jsxs(Fragment, { children: [jsx("li", { className: 'h6 text-muted dropdown-header', tabIndex: -1, children: jsx(FormattedMessage, { id: 'phoneInput.suggested', values: { count: filteredSuggestedCountries.length }, defaultMessage: i18n.en['phoneInput.suggested'], description: 'Suggestion label' }) }), filteredSuggestedCountries.map((countryCode) => (jsx(FlagItem, { countryCode: countryCode, internationalCountryCode: phoneUtil.getCountryCodeForRegion(countryCode.toUpperCase()), countryISOCode: countryISOCode, onCountrySelected: onCountrySelected }, countryCode))), jsx("li", { className: 'dropdown-divider', role: 'separator', tabIndex: -1, "aria-orientation": 'horizontal' })] }));
            }
        }
    };
    const getPhoneExample = useCallback(() => phoneUtil.format(phoneUtil.getExampleNumber(countryISOCode), PhoneNumberFormat.INTERNATIONAL).split(/\+\d+\s+/g)[1] || '', [countryISOCode]);
    return (jsxs(Fragment, { children: [label && typeof label === 'string' ? jsx(Label, { for: props.id || props.name, children: label }) : label, jsxs(InputGroup, { ...props, id: undefined, name: undefined, children: [jsxs(ButtonDropdown, { isOpen: isOpen, toggle: function () {
                            setIsOpen(!isOpen);
                        }, disabled: disabled, children: [jsx(DropdownToggle, { caret: true, size: 'sm', color: dropDownColor, className: 'border-top border-left border-bottom', disabled: disabled, children: jsx(Flag, { className: 'mr-1', flagCode: countryISOCode, title: intl.formatDisplayName(countryISOCode.toUpperCase(), { type: 'region' }) }) }), jsx(DropdownMenu, { className: 'py-0', children: jsx(SpordleTableProvider, { id: props.id || props.name, dataIndex: 'code', searchKeys: [
                                        { name: `names.${currentLocale}`, weight: 3 },
                                        'code',
                                    ], loadingStatus: 'success', defaultData: phoneUtil.getSupportedRegions().map((countryCode) => ({
                                        code: countryCode.toLowerCase(),
                                        numberCountryCode: phoneUtil.getCountryCodeForRegion(countryCode.toLowerCase()),
                                        names: {
                                            // @ts-ignore
                                            en: new Intl.DisplayNames('en', { type: 'region' }).of(countryCode),
                                            // @ts-ignore
                                            fr: new Intl.DisplayNames('fr', { type: 'region' }).of(countryCode), // French
                                        },
                                    })), fuseOptions: {
                                        shouldSort: false,
                                    }, columnSorting: (countryA, countryB) => {
                                        return collator.compare(countryA.names[currentLocale] || countryA.names.en, countryB.names[currentLocale] || countryB.names.en);
                                    }, defaultSorting: '-code', children: (spordleTable) => (jsxs(Fragment, { children: [jsx("div", { className: 'dropdown-item bg-transparent border-bottom', children: jsx(SearchInput, { autoFocus: !isMobile, searchCoolDown: 0, placeholder: getMessage('phoneInput.search.placeholder', currentLocale), translatePlaceholder: false, autoComplete: 'new-password' }) }), jsx(FlagList, { id: spordleTable.generateId('country', 'container'), className: 'list-unstyled mb-0', children: spordleTable.dataToDisplay.length > 0 ?
                                                    jsxs(IntlProvider, { locale: currentLocale, 
                                                        // @ts-ignore
                                                        messages: i18n[currentLocale], children: [getSuggestedCountries(spordleTable.dataToDisplay), spordleTable.dataToDisplay
                                                                .map((country) => !_suggestedCountries.includes(country.code) && (jsx(FlagItem, { countryCode: country.code, internationalCountryCode: country.numberCountryCode, countryISOCode: countryISOCode, onCountrySelected: onCountrySelected }, country.code)))] })
                                                    :
                                                        jsx("div", { className: 'p-3 text-center text-nowrap border-bottom', id: spordleTable.generateId('empty', 'search'), children: getMessage('phoneInput.noCountries', currentLocale) }) })] })) }) })] }), jsx(NumberFormat, { ...field, ...inputProps, id: props.id, disabled: disabled, type: 'tel', inputMode: 'tel', autoComplete: 'tel-national', value: parsedNumber.getNationalNumber(), className: stringBuilder('form-control', inputProps?.className, { 'is-invalid': meta.touched && meta.error }), format: `+${countryNumber} ${getPhoneExample().replace(/\d/g, '#')}`, placeholder: `+${countryNumber} ${getPhoneExample().replace(/\d/g, '_')}`, mask: '_', allowNegative: false, allowLeadingZeros: true, allowEmptyFormatting: true, isNumericString: true, onValueChange: ({ value }) => {
                            try {
                                helpers.setValue(phoneUtil.format(phoneUtil.parse(`+${countryNumber}${value}`, countryISOCode), PhoneNumberFormat.E164));
                            }
                            catch (error) {
                                helpers.setValue(value);
                            }
                        } })] }), helper && typeof helper === 'string' ? jsx(FormText, { color: "muted", children: helper }) : helper, !manualError && jsx(FormikError, { name: props.name })] }));
};
const FlagItem = ({ onCountrySelected, countryCode, countryISOCode, internationalCountryCode }) => {
    const spordleTable = useSpordleTable();
    return (jsxs(FlagListItem, { active: countryCode === countryISOCode, tag: 'li', id: spordleTable.generateId(countryCode), onClick: onCountrySelected(countryCode), children: [jsx(FormattedDisplayName, { type: "region", value: countryCode.toUpperCase(), children: (countryName) => (jsxs(Fragment, { children: [jsx(FormattedMessage, { id: 'phoneInput.flagName', values: { countryName: countryName }, defaultMessage: i18n.en['phoneInput.flagName'], description: 'Country flag name', children: (text) => jsx(Flag, { flagCode: countryCode, className: 'mr-1', "aria-label": text }) }), countryName] })) }), jsxs("span", { className: 'ml-1 text-muted', children: ["+", internationalCountryCode] })] }, countryCode));
};
FormikPhoneInput.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateHelper: PropTypes.bool,
    translateLabel: PropTypes.bool,
    name: PropTypes.string.isRequired,
    manualError: PropTypes.bool,
};
FormikPhoneInput.defaultProps = {
    translateHelper: true,
    translateLabel: true,
};
FormikPhoneInput.displayName = 'FormikPhoneInput';
const FormikPhoneInput$1 = FormikPhoneInput;export{FormikPhoneInput$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/