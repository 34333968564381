export default{
    "storeSingle.accordion.rebates" : "Discounts",
    "storeSingle.accordion.terms"   : "Terms & conditions",

    "storeSingle.section.registration.title"             : "Registration",
    "storeSingle.section.registration.category"          : "Category",
    "storeSingle.section.registration.memberType"        : "Member type",
    "storeSingle.section.registration.registrationFee"   : "Registration fee",
    "storeSingle.section.registration.noFee"             : "No registration fee available.",
    "storeSingle.section.registration.earlyRegistration" : "Early registration fee",
    "storeSingle.section.registration.lateRegistration"  : "Late registration fee",

    "storeSingle.section.registration.required.included" : "Required items included.",
    "storeSingle.section.registration.fee.free"          : "Free",

    "storeSingle.section.items.title"    : "Items",
    "storeSingle.section.items.itemInfo" : "Item info",
    "storeSingle.section.items.noItem"   : "No items available.",

    "storeSingle.btn.back.list" : "Back to all",
    "storeSingle.btn.back.home" : "Back to home page",
    "storeSingle.btn.seeList"   : "See other stores",
    "storeSingle.btn.register"  : "Register now",
    "storeSingle.btn.manage"    : "Manage store in SID",

    "storeSingle.inactive.text" : "Looks like this store is unavailable.",

    "storeSingle.header.startDate" : "Start date",
    "storeSingle.header.endDate"   : "End date",

    "storeSingle.title.details" : "Details",
}