import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormikError } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
    Label,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Col,
    Row,
    Fade,
    Spinner,
    FormText,
    Alert,
    Collapse
} from "reactstrap";
import { CartsContext } from '../../../../../../../contexts/CartsContext';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import Required from '../../../../../../../components/formik/Required';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { UtilsContext } from '../../../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

import MiniGatewayProvider, { AddressStreet, City, Country, Email, FirstName, LastName, MiniGatewayContext, Paysafe, State, ZipCode } from '@spordle/mini-gateway';
import { AuthContext } from '../../../../../../../contexts/AuthContext';
import { stringBuilder } from "@spordle/helpers";

import MasterCard from "../../../../../../../assets/images/payment/MasterCard.png";
import VisaCard from "../../../../../../../assets/images/payment/VISA.png";
import INTERAC from "../../../../../../../assets/images/payment/Interac.svg";
import CHECK from "../../../../../../../assets/images/payment/Check.svg";
import CASH from "../../../../../../../assets/images/payment/Cash.svg";
import CREDIT from "../../../../../../../assets/images/payment/Credit.svg";
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { addBreadcrumb, Severity } from '@sentry/react';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { sendGAEvent } from '@spordle/ga4';
import { formatSelectData } from '@spordle/spordle-select';

const RegistrationGateway = (props) => {
    const i18nContext = useContext(I18nContext);
    const cartsContext = useContext(CartsContext);
    const authContext = useContext(AuthContext);
    const cartContext = useContext(CartsContext);
    const utilsContext = useContext(UtilsContext);
    const registrationContext = useContext(RegistrationContext);

    const [ sutToken, setSutToken ] = useState('');
    const [ rechoutSutToken, setRechoutSutToken ] = useState('');
    const [ recheckout, setRecheckout ] = useState(false);
    const [ isLoading, setLoading ] = useState(false);

    const [ vaultCards, setVaultCards ] = useState(null);

    const stateSelectRef = useRef(null);
    const countrySelectRef = useRef(null);

    const sentryTrace = (paymentMethod, isRecheckout) => {
        if(isRecheckout){
            addBreadcrumb({
                type: 'info',
                message: 'Recheckout',
                level: Severity.Log,
                category: 'RegistrationGateway - submit',
                data: {
                    paymentMethod: paymentMethod,
                    merchantAccount: registrationContext.currentOnlineStore.store.merchant_account?.provider || 'None',
                },
            });
        }else{
            addBreadcrumb({
                type: 'info',
                message: 'Checkout',
                level: Severity.Log,
                category: 'RegistrationGateway - submit',
                data: {
                    paymentMethod: paymentMethod,
                    merchantAccount: registrationContext.currentOnlineStore.store.merchant_account?.provider || 'None',
                },
            });
        }
    }

    const paysafePrevious = () => {
        setRechoutSutToken(sutToken);
        setSutToken("");
        setRecheckout(true);
    }

    const goToConfirmation = (invoiceNumber = '') => {
        if(invoiceNumber)
            registrationContext.setInvoiceNumber(invoiceNumber)

        registrationContext.goToView(registrationContext.views.confirmation)
        setLoading(false);
    }

    /**
     * Sets Sut token and gets the countries for the countries select
     * Setting the Sut token toggles the mini gateway, which needs the countries for the country select
     */
    const _setSutToken = (sutToken) => {
        setSutToken(sutToken)
        registrationContext.setSutToken(sutToken)
    }

    useEffect(() => {
        if(registrationContext.currentOnlineStore.store.merchant_account?.provider === 'PAYSAFE'){ // Get vault cards only for Paysafe
            cartsContext.getVaultCards()
                .then(setVaultCards)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message);
                        setVaultCards([]);
                    }
                })
        }else{
            setVaultCards([]);
        }
    }, []);

    useEffect(() => {
        if(sutToken){
            addBreadcrumb({
                type: 'info',
                message: 'Paysafe payment',
                level: Severity.Log,
                category: 'RegistrationGateway - submit',
            });
        }
    }, [ sutToken ])

    return (
        <>
            {sutToken ?
                <MiniGatewayProvider
                    apiKey={process.env.REACT_APP_GATEWAY_API_KEY}
                    cartId='someUnusedId'// cartId is not used in our scenario -> leave it to this value so the propTypes validation doesn't show an error
                    initialValues={{
                        firstName: authContext.userData.attributes.name, // Prefilled with logged in user info
                        lastName: authContext.userData.attributes.family_name, // Prefilled with logged in user info
                        email: authContext.userData.attributes.email, // Prefilled with logged in user info
                        country: '',
                        province: '',
                        terms: true, // In this case, there is no terms -> leave this to true so the validationShema doesn't give an error
                    }}
                >
                    <MiniGatewayContext.Consumer>
                        {(gateway) => (
                            <OverlayLoader isLoading={gateway.formik.isSubmitting}>
                                <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                                    <Translate id='storeSingle.registrationModal.gateway.title' />
                                </ModalHeader>
                                <ModalBody className="py-4 bg-light">
                                    <div className="font-weight-bold h4 mt-3"><Translate id='storeSingle.registrationModal.gateway.billingInfo.title' /></div>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <FirstName label={<><Translate id='storeSingle.registrationModal.gateway.billingInfo.label.firstName' /> <Required /></>} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <LastName label={<><Translate id='storeSingle.registrationModal.gateway.billingInfo.label.lastName' /> <Required /></>} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <Email label={<><Translate id='storeSingle.registrationModal.gateway.billingInfo.label.email' /> <Required /></>} />
                                    </FormGroup>

                                    <FormGroup>
                                        <AddressStreet label={<><Translate id='storeSingle.registrationModal.gateway.billingInfo.label.street' /> <Required /></>} />
                                    </FormGroup>

                                    <Row>
                                        <Col md='8'>
                                            <FormGroup>
                                                <City label={<><Translate id='storeSingle.registrationModal.gateway.billingInfo.label.city' /> <Required /></>} />
                                            </FormGroup>
                                        </Col>
                                        <Col md='4'>
                                            <FormGroup>
                                                <ZipCode label={<><Translate id='storeSingle.registrationModal.gateway.billingInfo.label.zip' /> <Required /></>} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <Country
                                                    ref={(r) => countrySelectRef.current = r}
                                                    label={<><Translate id='storeSingle.registrationModal.gateway.billingInfo.label.country' /> <Required /></>}
                                                    noOptionLayout={<Translate id='storeSingle.registrationModal.gateway.country.empty' />}
                                                    placeholder='misc.search'
                                                    renderOption={(option) => option.option.isGroup ? <Translate id={option.option.label} /> : <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                    searchKeys={[
                                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    onOptionSelected={(values, select) => {
                                                        gateway.formik.setFieldValue('province', '');
                                                        stateSelectRef.current?.getSpordleTable().filterChange('countryId', select.getSpordleTable().getData().find((option) => option.value === values[0]).countryId)
                                                    }}
                                                    loadData={(from, extra, spordleTable) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return utilsContext.getCountries()
                                                                    .then((countries) => {
                                                                        return formatSelectData(countries.map((country) => {
                                                                            return {
                                                                                value: country.code,
                                                                                label: country.code,
                                                                                countryId: country.country_id,
                                                                                i18n: country.i18n,
                                                                            }
                                                                        }), {
                                                                            newGroupIndexes: {
                                                                                0: {
                                                                                    label: "misc.select.suggested",
                                                                                    groupId: "suggested",
                                                                                },
                                                                            },
                                                                            getGroupId: (option) => (option.value === 'CA' || option.value === 'US') ? 'suggested' : undefined,
                                                                        })
                                                                    })
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup>
                                                <State
                                                    ref={(r) => stateSelectRef.current = r}
                                                    label={<><Translate id='storeSingle.registrationModal.gateway.billingInfo.label.province' /> <Required /></>}
                                                    noOptionLayout={<Translate id='storeSingle.registrationModal.gateway.provinces.empty' />}
                                                    placeholder='misc.search'
                                                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                    disabled={countrySelectRef.current?.state.selectedOptions.length === 0}
                                                    searchKeys={[
                                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    initFilter={{
                                                        countryId: '',
                                                    }}
                                                    loadData={(from, extra, spordleTable) => {
                                                        switch (from){
                                                            case 'FILTER':
                                                                spordleTable.setLoading();
                                                            case 'CDM':
                                                                if(extra.filters.countryId){
                                                                    return utilsContext.getProvinces(extra.filters.countryId)
                                                                        .then((countries) => {
                                                                            return countries[0].sub_divisions?.map((state) => ({
                                                                                value: state.code,
                                                                                label: state.code,
                                                                                i18n: state.i18n,
                                                                            })) || []
                                                                        })
                                                                }
                                                                return Promise.resolve([])

                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Paysafe
                                        sutToken={sutToken} options={{
                                            style: {
                                                input: {
                                                    "font": "400 14px Arial",
                                                    "font-family": "Montserrat, sans-serif",
                                                    "line-height": "1.5",
                                                    "color": "#525f7f",
                                                },
                                            },
                                        }}
                                    >
                                        {({ CardNumber, Cvv, ExpirationDate }, paysafe) => (
                                            <>
                                                <div className="font-weight-bold h4 mt-3"><Translate id='storeSingle.registrationModal.gateway.cardInfo.title' /></div>
                                                <Fade in={paysafe.loadingPaysafe} mountOnEnter unmountOnExit className={paysafe.loadingPaysafe ? 'text-center' : 'd-none'}>
                                                    <Spinner className="my-5" color="primary" type="grow" />
                                                </Fade>

                                                <Fade in={!paysafe.loadingPaysafe} className={paysafe.loadingPaysafe ? 'd-none' : undefined}>
                                                    <FormGroup>
                                                        <Label for="card-number-mini-gateway"><Translate id='storeSingle.registrationModal.gateway.cardInfo.label.cardNumber' /> <Required /></Label>
                                                        <CardNumber className='form-control' />
                                                    </FormGroup>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="expiration-date-mini-gateway"><Translate id='storeSingle.registrationModal.gateway.cardInfo.label.expirationDate' /> <Required /></Label>
                                                                <ExpirationDate className='form-control' />
                                                                <FormText color="muted"><Translate id='storeSingle.registrationModal.gateway.cardInfo.helper.expirationDate' /></FormText>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="cvv-mini-gateway"><Translate id='storeSingle.registrationModal.gateway.cardInfo.label.cvv' /> <Required /></Label>
                                                                <Cvv className='form-control' />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    {/* Tokenize error */}
                                                    <Collapse isOpen={!!paysafe.tokenizeError} mountOnEnter unmountOnExit>
                                                        <Alert color='danger'>
                                                            {paysafe.tokenizeError}
                                                        </Alert>
                                                    </Collapse>
                                                </Fade>
                                            </>
                                        )}
                                    </Paysafe>

                                    {/* Process payment error */}
                                    <Collapse isOpen={!!gateway.formik.status} mountOnEnter unmountOnExit>
                                        <Alert color='danger'>
                                            {gateway.formik.status}
                                        </Alert>
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter tag='footer' className="bg-light">
                                    <Button
                                        id='registrationGatewayPaysafePreviousButton'
                                        onClick={paysafePrevious}
                                        color="default" outline
                                        className="mr-auto" type='button'
                                        disabled={gateway.formik.isSubmitting}
                                    >
                                        <Translate id='misc.previous' />
                                    </Button>
                                    {(process.env.REACT_APP_ENVIRONMENT === 'int' || process.env.REACT_APP_ENVIRONMENT === 'dev') &&
                                        <Button
                                            id='registrationGatewayPaysafeTokenizeButton'
                                            color="danger"
                                            disabled={gateway.formik.isSubmitting}
                                            type='button'
                                            onClick={() => {
                                                addBreadcrumb({
                                                    type: 'info',
                                                    message: 'Submitting Paysafe payment...',
                                                    level: Severity.Log,
                                                    category: 'RegistrationGateway - submit',
                                                });
                                                gateway.formik.setStatus();
                                                gateway.formik.setSubmitting(true);
                                                gateway.resetErrors();
                                                gateway.validateDisplayedFields().then(async(hasError) => {
                                                    if(!hasError){
                                                        gateway.tokenize().then(({ token }) => {
                                                            // Tokenized!
                                                        }).catch((error) => { // .tokenize error handling - NOTE: MiniGateway will handle showing the error message automatically
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(`tokenize: ${error.error?.code} -> ${error.error?.displayMessage}`);
                                                                gateway.formik.setSubmitting(false);
                                                            }
                                                        })
                                                    }else{
                                                        gateway.formik.setSubmitting(false);
                                                    }
                                                })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){ // .validateDisplayedFields error handling
                                                            console.error(`validateDisplayedFields: ${error.message}`);
                                                            gateway.formik.setSubmitting(false);
                                                        }
                                                    })
                                            }}
                                        >
                                            Tokenize <span className="font-10">(dev & int)</span>
                                        </Button>
                                    }
                                    <Button
                                        id='registrationGatewayPaysafeSubmitButton'
                                        color="info"
                                        disabled={gateway.formik.isSubmitting}
                                        type='button'
                                        onClick={() => {
                                            registrationContext.setPreAuthorizedPayment(false);
                                            addBreadcrumb({
                                                type: 'info',
                                                message: 'Submitting Paysafe payment...',
                                                level: Severity.Log,
                                                category: 'RegistrationGateway - submit',
                                            });
                                            gateway.formik.setStatus();
                                            gateway.formik.setSubmitting(true);
                                            gateway.resetErrors();
                                            gateway.validateDisplayedFields().then(async(hasError) => {
                                                if(!hasError){
                                                    gateway.tokenize().then(({ token }) => {
                                                        cartContext.processPayment(registrationContext.state.currentCart.cartInfo.shopping_cart_id, {
                                                            payment_token: token,
                                                            holder_firstname: gateway.formik.values.firstName,
                                                            holder_lastname: gateway.formik.values.lastName,
                                                            holder_city: gateway.formik.values.city,
                                                            holder_zip: gateway.formik.values.zip,
                                                            holder_address: gateway.formik.values.addressStreet,
                                                            holder_province: gateway.formik.values.province,
                                                            holder_country: gateway.formik.values.country,
                                                        })
                                                            .then(async(permanentPaymentToken) => {
                                                                if(permanentPaymentToken){
                                                                    await registrationContext.setPreAuthorizedPayment(true);
                                                                }
                                                                gateway.formik.setSubmitting(false);
                                                                goToConfirmation()
                                                            })
                                                            .catch((error) => { // .processPayment error handling
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(`processPayment: ${error.message}`);
                                                                    if('i18n' in error){ // error has property 'i18n'
                                                                        gateway.formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                                                    }else{
                                                                        gateway.formik.setStatus(
                                                                            <Translate
                                                                                id={`storeSingle.registrationModal.gateway.paysafe.processing.error.${error.message}`} defaultMessageId='storeSingle.registrationModal.gateway.paysafe.processing.error.generic'
                                                                                values={{
                                                                                    link: (chunk) => <button type='button' className='reset-btn text-info' onClick={paysafePrevious}>{chunk}</button>,
                                                                                }}
                                                                            />,
                                                                        );
                                                                    }
                                                                    gateway.formik.setSubmitting(false);
                                                                }
                                                            })
                                                    }).catch((error) => { // .tokenize error handling - NOTE: MiniGateway will handle showing the error message automatically
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(`tokenize: ${error.error?.code} -> ${error.error?.displayMessage}`);
                                                            gateway.formik.setSubmitting(false);
                                                        }
                                                    })
                                                }else{
                                                    gateway.formik.setSubmitting(false);
                                                }
                                            })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){ // .validateDisplayedFields error handling
                                                        console.error(`validateDisplayedFields: ${error.message}`);
                                                        gateway.formik.setSubmitting(false);
                                                    }
                                                })
                                        }}
                                    >
                                        <Translate id='storeSingle.registrationModal.gateway.pay' />
                                    </Button>
                                </ModalFooter>
                            </OverlayLoader>
                        )}
                    </MiniGatewayContext.Consumer>
                </MiniGatewayProvider>
                :
                <Formik
                    initialValues={{
                        paymentMethod: registrationContext.state.currentCart.QEErrorCode ? 'CREDITCARD' : '',
                        vaultCard: '',
                    }}
                    validationSchema={Yup.object().shape({
                        paymentMethod: Yup.string().required(<Translate id='storeSingle.registrationModal.gateway.paymentMethod.required' />),
                    })}
                    onSubmit={async({ paymentMethod, vaultCard }, { setStatus, setSubmitting }) => {
                        registrationContext.setPreAuthorizedPayment(false);
                        setLoading(true)

                        sendGAEvent('add_payment_info', {
                            currency: 'CAD',
                            value: registrationContext.state.currentCart.cartInfo.cart_summary.grand_total / 100,
                            payment_type: paymentMethod,
                            items: registrationContext.getGa4CartItems(),
                        });

                        if(registrationContext.state.currentCart.QEErrorCode){ // Will have a value only if the merchant account is QE
                            if(paymentMethod === 'CREDITCARD'){ // Retry payment
                                sentryTrace(paymentMethod, true);
                                cartsContext.reCheckout(registrationContext.state.currentCart.cartInfo.shopping_cart_id, paymentMethod, true)
                                    .then((data) => {
                                        if(data.init_info?.TEXT === "SUCCESS" && data.init_info?.URL){
                                            window.location.href = data.init_info.URL;
                                        }else{
                                            console.error(data);
                                            setLoading(false);
                                        }
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message);
                                            setLoading(false);
                                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                        }
                                    })
                            }else{ // Re-checkout
                                sentryTrace(paymentMethod, true);
                                cartsContext.reCheckout(registrationContext.state.currentCart.cartInfo.shopping_cart_id, paymentMethod)
                                    .then(() => {
                                        goToConfirmation(registrationContext.state.currentCart.invoiceNumber);
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message);
                                            setLoading(false);
                                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                        }
                                    })
                            }
                        }else if(registrationContext.currentOnlineStore.store.merchant_account?.provider === 'PAYSAFE' && recheckout){
                            try{
                                if(paymentMethod !== 'CREDITCARD'){
                                    sentryTrace(paymentMethod, true);
                                    await cartsContext.reCheckout(registrationContext.state.currentCart.cartInfo.shopping_cart_id, paymentMethod)
                                    goToConfirmation(); // go straight to confirmation. invoice_number already received from first checkout
                                    return;
                                }
                                // From here -> paymentMethod === 'CREDITCARD'
                                if(vaultCard !== 'new-card'){ // Existing vault card
                                    const fullVaultCard = vaultCards.find((card) => card.id === vaultCard);
                                    const permanentPaymentToken = await cartContext.processPayment(registrationContext.state.currentCart.cartInfo.shopping_cart_id, {
                                        payment_token: fullVaultCard.paymentToken,
                                        holder_country: fullVaultCard.address.country,
                                        holder_province: fullVaultCard.address.state,
                                        holder_firstname: authContext.userData.attributes.name,
                                        holder_lastname: authContext.userData.attributes.family_name,
                                        holder_city: fullVaultCard.address.city,
                                        holder_zip: fullVaultCard.address.zip,
                                        holder_address: fullVaultCard.address.street,
                                    });
                                    if(permanentPaymentToken){
                                        await registrationContext.setPreAuthorizedPayment(true);
                                    }
                                    goToConfirmation(); // go straight to confirmation. invoice_number already received from first checkout
                                    return;
                                }
                                _setSutToken(rechoutSutToken)
                                setLoading(false);
                                return

                            }catch(error){
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(`processPayment: ${error.message}`);
                                    setLoading(false);
                                    if(error.i18n){
                                        setStatus(<Translate id={'storeSingle.registrationModal.gateway.paysafe.processing.error.' + error.message} />)
                                    }else{
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                    }
                                }
                            }
                        }else{
                            sentryTrace(paymentMethod, false);
                            registrationContext.checkout(paymentMethod)
                                .then((invoiceData) => {
                                    registrationContext.setIsCheckedOut(true);
                                    if(invoiceData.waiting_list_number?.length > 0){
                                        registrationContext.goToView(registrationContext.views.waitingListCheckoutError);
                                    }else if(paymentMethod === 'CREDITCARD'){
                                        if(registrationContext.currentOnlineStore.store.merchant_account?.provider === 'QUICK_ENROLLMENT'){
                                            // Go to page
                                            if(invoiceData.init_info?.URL){
                                                window.location.href = invoiceData.init_info.URL;
                                            }else{
                                                goToConfirmation(invoiceData.invoice_number);
                                            }
                                        }else if(registrationContext.currentOnlineStore.store.merchant_account?.provider === 'PAYSAFE'){
                                            if(vaultCard !== 'new-card'){
                                                const fullVaultCard = vaultCards.find((card) => card.id === vaultCard);
                                                cartContext.processPayment(registrationContext.state.currentCart.cartInfo.shopping_cart_id, {
                                                    payment_token: fullVaultCard.paymentToken,
                                                    holder_country: fullVaultCard.address.country,
                                                    holder_province: fullVaultCard.address.state,
                                                    holder_firstname: authContext.userData.attributes.name,
                                                    holder_lastname: authContext.userData.attributes.family_name,
                                                    holder_city: fullVaultCard.address.city,
                                                    holder_zip: fullVaultCard.address.zip,
                                                    holder_address: fullVaultCard.address.street,
                                                })
                                                    .then(async(permanentPaymentToken) => {
                                                        if(permanentPaymentToken){
                                                            await registrationContext.setPreAuthorizedPayment(true);
                                                        }
                                                        goToConfirmation(invoiceData.invoice_number);
                                                    }).catch((error) => { // .processPayment error handling
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(`processPayment (vault): ${error.message}`);
                                                            setLoading(false);
                                                            setRecheckout(true);
                                                            if(error.i18n){
                                                                setStatus(<Translate id={'storeSingle.registrationModal.gateway.paysafe.processing.error.' + error.message} />)
                                                            }else{
                                                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                                            }
                                                        }
                                                    });
                                            }else{
                                                registrationContext.setInvoiceNumber(invoiceData.invoice_number)
                                                _setSutToken(invoiceData.init_info?.sut_token);// Will display the MiniGateway
                                                setLoading(false);
                                            }
                                        }else{
                                            goToConfirmation(invoiceData.invoice_number);
                                        }
                                    }else{
                                        goToConfirmation(invoiceData.invoice_number);
                                    }

                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message);
                                        setLoading(false);
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                    }
                                });
                        }
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={isLoading}>
                                <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                                    <Translate id='storeSingle.registrationModal.gateway.title' />
                                </ModalHeader>
                                <ModalBody className="py-4 bg-light">
                                    {/* ONLINE PAYMENTS - PAYSAFE */}
                                    {registrationContext.currentOnlineStore.store.merchant_account?.provider === 'PAYSAFE' && registrationContext.currentOnlineStore.store?.payment_methods.some((pMethod) => pMethod.code === 'CREDITCARD') &&
                                        <div>
                                            {Array.isArray(vaultCards) ?
                                                <>
                                                    <Label><Translate id='storeSingle.registrationModal.gateway.paymentMethod.online' /></Label>
                                                    <Row form className="mb-2">
                                                        {vaultCards.map((card) => (
                                                            <Col key={card.id} md='6' className='mb-2'>
                                                                <CreditCard card={card} />
                                                            </Col>
                                                        ))}
                                                        <Col md='6' className='mb-2'>
                                                            <div
                                                                id='registrationGatewayNewCard'
                                                                className={stringBuilder("registration-card mb-0 h-100", { 'is-selected': formik.values.vaultCard === 'new-card' })}
                                                                onClick={() => { formik.setFieldValue('vaultCard', 'new-card'); formik.setFieldValue('paymentMethod', 'CREDITCARD'); }}
                                                            >
                                                                <div className="mr-3 flex-shrink-0 text-info">
                                                                    <i className='ti-plus' />
                                                                </div>
                                                                <div className="mr-3">
                                                                    <p className="mb-0 font-weight-bold"><Translate id='storeSingle.registrationModal.gateway.paymentMethod.myCard.add' /></p>
                                                                </div>
                                                                <div className="registration-card-icon"><i className="mdi mdi-check" /></div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <div className='text-center'>
                                                    <Spinner color='primary' type='grow' />
                                                </div>
                                            }
                                        </div>
                                    }

                                    {/* ONLINE PAYMENTS - QUICK_ENROLLMENT */}
                                    {registrationContext.currentOnlineStore.store.merchant_account?.provider === 'QUICK_ENROLLMENT' && registrationContext.currentOnlineStore.store?.payment_methods.some((pMethod) => pMethod.code === 'CREDITCARD') &&
                                        <div>
                                            <Label><Translate id='storeSingle.registrationModal.gateway.paymentMethod.online' /></Label>
                                            <Row form className="mb-2">
                                                <Col key="CREDITCARD-QUICK_ENROLLMENT" md='6' className='mb-2'>
                                                    <PaymentMethod method={registrationContext.currentOnlineStore.store.payment_methods.find((_method) => _method.code === "CREDITCARD")} />
                                                </Col>
                                            </Row>
                                        </div>
                                    }

                                    {registrationContext.currentOnlineStore.store.payment_methods ?
                                        Array.isArray(registrationContext.currentOnlineStore.store.payment_methods) && !!registrationContext.currentOnlineStore.store.payment_methods.filter((pM) => pM.code !== 'CREDITCARD').length ?
                                            <>
                                                {/* MANUAL PAYMENTS (Send my payment manually) */}
                                                <Label><Translate id='storeSingle.registrationModal.gateway.paymentMethod.manual' /></Label>
                                                <Row form>
                                                    {registrationContext.currentOnlineStore?.store.payment_methods ?
                                                        registrationContext.currentOnlineStore.store.payment_methods
                                                            .sort((pMa, pMb) => parseInt(pMa.display_order) - parseInt(pMb.display_order))
                                                            .filter((pMethod) => pMethod.category === "MANUAL")
                                                            .map((pMethod) => (
                                                                <Col key={pMethod.code} md='6' className='mb-2'>
                                                                    <PaymentMethod method={pMethod} />
                                                                </Col>
                                                            ))
                                                        :
                                                        <div className='text-center'>
                                                            <Spinner color='primary' type='grow' />
                                                        </div>
                                                    }
                                                </Row>
                                            </>
                                            : null
                                        :
                                        <div className='text-center'>
                                            <Spinner color='primary' type='grow' />
                                        </div>
                                    }

                                    {/* Display error for a payment method selection */}
                                    <FormikError name='paymentMethod'>
                                        {(message) => (
                                            <Collapse isOpen appear>
                                                <Alert color='danger' className='mb-0'>
                                                    {message}
                                                </Alert>
                                            </Collapse>
                                        )}
                                    </FormikError>

                                    {/* Showing redirection msg */}
                                    <Collapse isOpen={registrationContext.currentOnlineStore.store.merchant_account?.provider === 'QUICK_ENROLLMENT' && formik.values.paymentMethod === 'CREDITCARD' && !registrationContext.state.currentCart.QEErrorCode} mountOnEnter unmountOnExit>
                                        <div className="py-2 small">
                                            <i className="mdi mdi-information-outline text-info" /> <Translate id='storeSingle.registrationModal.gateway.paymentMethod.warning.CREDITCARD' />
                                        </div>
                                    </Collapse>

                                    {/* Showing QE error message */}
                                    <Collapse isOpen={!!registrationContext.state.currentCart.QEErrorCode} mountOnEnter unmountOnExit>
                                        <Alert color='danger' className='mt-3 mb-0'>
                                            <Translate id={`storeSingle.registrationModal.gateway.qe.${registrationContext.state.currentCart.QEErrorCode}`} defaultMessageId='storeSingle.registrationModal.gateway.qe.other' />
                                        </Alert>
                                    </Collapse>

                                    {formik.status &&
                                        <Alert color='danger' toggle={() => formik.setStatus()}>
                                            {formik.status}
                                        </Alert>
                                    }
                                </ModalBody>
                                <ModalFooter tag='footer' className="bg-light">
                                    {!registrationContext.state.currentCart.QEErrorCode &&
                                        <Button
                                            id='registrationGatewayPreviousButton'
                                            onClick={() => { registrationContext.goToView(registrationContext.views.modalities, true) }}
                                            color="default" outline
                                            className="mr-auto" type='button'
                                            disabled={formik.isSubmitting}
                                        >
                                            <Translate id='misc.previous' />
                                        </Button>
                                    }
                                    <Button
                                        id='registrationGatewaySubmitButton'
                                        color="info"
                                        disabled={formik.isSubmitting}
                                        type='submit'
                                    >
                                        {registrationContext.state.currentCart.QEErrorCode && formik.values.paymentMethod === 'CREDITCARD' ? <Translate id='misc.retry' /> : <Translate id='misc.next' />}
                                    </Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            }
        </>
    );
}

const CreditCard = ({ card }) => {
    const formik = useFormikContext();

    const getCardLogo = useCallback(() => {
        switch (card.cardType){
            case 'VI':
                return VisaCard;
            case 'MC':
                return MasterCard;
            default:
                break;
        }
    }, [ card.cardType ]);

    return (
        <div
            id={'registrationGatewayVaultCard-' + card.id}
            className={stringBuilder("registration-card mb-0", { 'is-selected': formik.values.vaultCard === card.id })}
            onClick={() => { formik.setFieldValue('vaultCard', card.id); formik.setFieldValue('paymentMethod', 'CREDITCARD'); }}
        >
            <div className="mr-3 flex-shrink-0">
                <img className="p-1" src={getCardLogo()} height='30' alt='Card logo' />
            </div>
            <div className="mr-3">
                <p className="mb-0 font-weight-bold">**** **** **** {card.lastDigits}</p>
                <p className="mb-0 mt-n1 small">{card.holderName}</p>
            </div>
            <div className="registration-card-icon"><i className="mdi mdi-check" /></div>
        </div>
    )
}

const PaymentMethod = ({ method }) => {
    const formik = useFormikContext();

    const getIcon = useCallback(() => {
        switch (method.code){
            case 'CASH':
                return <img className="p-1" src={CASH} height='35' alt='CASH' />;
            case 'CREDITCARD':
                return <img className="p-1" src={CREDIT} height='35' alt='CREDIT CARD' />;
            case 'INTERAC':
                return <img className="p-1" src={INTERAC} height='35' alt='INTERAC' />
            case 'CHEQUE':
                return <img className="p-1" src={CHECK} height='35' alt='CHECK' />
            default:
                break;
        }
    }, [ method ]);

    return (
        <div
            id={'registrationGatewayPaymentMethod-' + method.code}
            className={stringBuilder("registration-card mb-0 h-100", { 'is-selected': formik.values.paymentMethod === method.code })}
            onClick={() => { formik.setFieldValue('vaultCard', ''); formik.setFieldValue('paymentMethod', method.code); }}
        >
            <div className="mr-3 flex-shrink-0">
                {method.icon ? <img className="p1" src={method.icon} height='35' alt={method.name} /> : getIcon()}
            </div>
            <div className="mr-3">
                <p className="mb-0 font-weight-bold">
                    <DisplayI18n
                        field="name"
                        defaultValue={method.name}
                        i18n={method.i18n}
                    />
                </p>
            </div>
            <div className="registration-card-icon"><i className="mdi mdi-check" /></div>
        </div>
    )
}

// const GroupComponent = (props) => {
//     return(
//         <div className='border-bottom mb-2'>
//             <components.Group {...props} />
//         </div>
//     )
// }

export default RegistrationGateway;