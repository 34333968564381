/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import {stringBuilder}from'@spordle/helpers';import {PureComponent}from'react';import {injectIntl}from'react-intl';import {Fade,Button,Table}from'reactstrap';import FooterBuilder from'./FooterBuilder.js';import PaginationHandler from'./PaginationHandler.js';import {SpordleTableContext}from'./SpordleTableProvider.js';import StickyHeader from'./StickyHeader.js';import {getValueFromString,getMessage}from'./utils.js';class TableView extends PureComponent {
    static displayName = 'SpordleTableTableView';
    static contextType = SpordleTableContext;
    _tableMaxHeight;
    _scrollContainer;
    constructor(props) {
        super(props);
        this._scrollContainer = null;
        this._tableMaxHeight = 0;
        this.state = {
            showScrollButtons: false,
        };
    }
    /**
     * @private
     */
    _calculateHersHeight = () => {
        const headers = document.getElementsByTagName('header');
        let headersHeightSum = 0;
        for (let index = 0; index < headers.length; index++) {
            headersHeightSum += headers.item(index)?.clientHeight ?? 0;
        }
        this._tableMaxHeight = window.innerHeight - headersHeightSum;
    };
    componentDidMount() {
        this._calculateHersHeight();
        if (this.props.scrollButtons) {
            this.setState(() => ({
                showScrollButtons: !!(this.props.scrollButtons && this._scrollContainer && this._scrollContainer.scrollWidth > this._scrollContainer.clientWidth),
            }));
        }
    }
    componentDidUpdate() {
        this.setState((prevState) => {
            const isScrollable = !!(this.props.scrollButtons && this._scrollContainer && this._scrollContainer.scrollWidth > this._scrollContainer.clientWidth);
            if (isScrollable == prevState.showScrollButtons)
                return null;
            return {
                showScrollButtons: isScrollable,
            };
        });
    }
    /**
     * Function to get determine how the data show be rendered
     * @param column The column it is rendering
     * @param rowData The rowData to render
     * @private
     */
    _getCustomRendering = (column, rowData) => {
        return this.context.props.renderRow?.(column.key, rowData, this.context)
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            || (getValueFromString(rowData, column.key) ?? getValueFromString(rowData, column.defaultKey || ''));
    };
    _scroll = (dirrection) => () => {
        const scrollAmount = 500;
        if (this._scrollContainer) {
            switch (dirrection) {
                case 'left':
                    this._scrollContainer.scrollLeft -= scrollAmount;
                    break;
                case 'right':
                    this._scrollContainer.scrollLeft += scrollAmount;
                    break;
            }
        }
    };
    render() {
        return (
        /* This div is used to help querying html elements inside */
        this.context.dataToDisplay.length === 0 ?
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            jsx(Fade, { id: this.context.generateId('emptyLayout'), "data-testid": 'emptyLayout', children: typeof this.context.props.emptyLayout === 'function' ? jsx(this.context.props.emptyLayout, { emptySearch: this.context.getData().length > 0 && !!this.context.getInputValue() && this.context.dataToDisplay.length === 0 }) : this.context.props.emptyLayout })
            :
                jsxs(Fade, { children: [this.state.showScrollButtons &&
                            jsxs("div", { className: 'position-fixed p-3 bottom-0', style: { zIndex: 100 }, children: [jsx(Button, { style: { borderRadius: '50%', height: '40px', width: '40px' }, color: 'primary', className: 'mr-1', onClick: this._scroll('left'), id: this.context.generateId('scroll', 'left'), "aria-label": getMessage('scroll.left.ariaLabel', this.props.intl.locale), children: jsx("i", { className: 'mdi mdi-arrow-left' }) }), jsx(Button, { style: { borderRadius: '50%', height: '40px', width: '40px' }, color: 'primary', onClick: this._scroll('right'), id: this.context.generateId('scroll', 'right'), "aria-label": getMessage('scroll.right.ariaLabel', this.props.intl.locale), children: jsx("i", { className: 'mdi mdi-arrow-right' }) })] }), jsx("div", { ref: (r) => this._scrollContainer = r, style: { scrollBehavior: 'smooth', maxHeight: this.context.props.stickyHeader ? `${this._tableMaxHeight - 100}px` : undefined }, className: stringBuilder('table-responsive scroll-wrapper', { 'spordletable-sticky overflow-auto': this.context.props.stickyHeader }), children: jsxs(Table, { responsive: false, hover: !!this.context.props.tableHover, striped: !!this.context.props.striped, bordered: !!this.context.props.bordered, borderless: !!this.context.props.borderless, size: this.context.props.smallTable ? 'sm' : undefined, className: stringBuilder('v-middle mb-0', this.context.props.tableClassName), innerRef: this.context.props.tableRef, id: this.context.generateId('table'), children: [!this.context.state.isMobile && jsx(StickyHeader, { preventHeaderHoverEffect: this.props.preventHeaderHoverEffect }), jsx("tbody", { className: this.context.props.clickable ? 'clickable' : undefined, id: this.context.generateId('table', 'body'), children: this.context.dataToDisplay.map((rowData, index) => {
                                            const dataIndex = this.context.getDataIndexValue(rowData);
                                            return (jsx("tr", { className: stringBuilder({ 'tr-selected': this.context.props.rowIsHighlighted && this.context.props.rowIsHighlighted(rowData, index, this.context) }), id: this.context.generateId('table', 'body', 'row', dataIndex), children: this.context.state.isMobile ?
                                                    jsx("td", { children: this.context.state.orderedColumns.reduce((visibleColumns, column) => {
                                                            if (column.mobile) {
                                                                column.label;
                                                                visibleColumns.push(jsxs("div", { className: stringBuilder('mb-3', column.dataClassName), id: this.context.generateId('table', 'body', 'row', dataIndex, 'column', column.key), onMouseDown: (e) => { if (e.button === 1)
                                                                        e.preventDefault(); }, onContextMenu: (e) => { this.context.clickRow(rowData, e, column.key); }, onClick: (e) => { this.context.clickRow(rowData, e, column.key); }, onAuxClick: (e) => { if (e.button === 1)
                                                                        this.context.clickRow(rowData, e, column.key); }, children: [jsx("div", { className: stringBuilder('mb-2 font-bold', column.className), children: typeof column.label === 'function' ? jsx(column.label, {}) : column.label }), this._getCustomRendering(column, rowData)] }, dataIndex + '-' + column.key));
                                                            }
                                                            return visibleColumns;
                                                        }, []) })
                                                    :
                                                        this.context.state.orderedColumns.map((column) => (jsx("td", { className: column.dataClassName, id: this.context.generateId('table', 'body', 'row', dataIndex, 'column', column.key), onMouseDown: (e) => { if (e.button === 1)
                                                                e.preventDefault(); }, onContextMenu: (e) => { this.context.clickRow(rowData, e, column.key); }, onClick: (e) => { this.context.clickRow(rowData, e, column.key); }, onAuxClick: (e) => { if (e.button === 1)
                                                                this.context.clickRow(rowData, e, column.key); }, children: this._getCustomRendering(column, rowData) }, dataIndex + '-' + column.key))) }, dataIndex));
                                        }) }), this.context.props.hasFooter && !this.context.state.isMobile && jsx(FooterBuilder, {})] }) }), !this.props.hidePagination && (this.context.state.pagination ?? 0) !== 0 &&
                            jsx(PaginationHandler, { className: this.context.state.isMobile ? 'mx-auto' : 'ml-auto' })] }));
    }
}
const TableView$1 = injectIntl(TableView);export{TableView$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/