import { useImgIsLoaded } from "../customHooks/useImgIsLoaded";
import Skeleton from "./Skeleton";

/**
 *
 * @param {Object} props
 * @param {string|number} props.height
 * @param {string|number} props.width
 * @param {string} props.src
 * @param {string} props.className
 * @param {JSX.Element|React.ReactNode} props.loadingLayout
 * @returns
 */
const ImgLoader = ({ height = "auto", width = "auto", src, loadingLayout, className, ...props }) => {
    const [ ref, isLoaded, onLoad ] = useImgIsLoaded();

    return (
        <>
            {!isLoaded && (loadingLayout ?? <Skeleton width={width} height={height} />)}
            <img ref={ref} className={`${isLoaded ? className : 'd-none'}`} src={src} onLoad={onLoad} {...props} />
        </>
    );
}

export default ImgLoader;