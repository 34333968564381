export default{
    "teamProfile.btn.back" : "Retour à la liste des équipes",

    "teamProfile.helmet.title"       : "Profil de {team}",
    "teamProfile.helmet.description" : "Horaire, liste des joueurs, ligues et tournois et notification de l'équipe {team}.",

    "teamProfile.tabs.roster"       : "Joueurs",
    "teamProfile.tabs.league"       : "Ligues & tournois",
    "teamProfile.tabs.schedule"     : "Horaire",
    "teamProfile.tabs.notification" : "Notification",
}