export default{
    "routes.group.overview.name"     : "Sommaire",
    "routes.group.registration.name" : "Inscription",
    "routes.default.name"            : "Accueil",
    "routes.teams.name"              : "Équipes",
    "routes.staff.name"              : "Personnel",
    "routes.contact.name"            : "Contact",
    "routes.venues.name"             : "Emplacements",
    "routes.schedule.name"           : "Calendrier",
    "routes.registration.name"       : "Participant",
    "routes.clinics.name"            : "Stages",
}