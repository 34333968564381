/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import {useSpordleTable}from'@spordle/datatables';import {stringBuilder}from'@spordle/helpers';import {useRef,useEffect,useCallback}from'react';import SpordleSelect from'./SpordleSelect.js';import {getMessage}from'./utils.js';import {useIntl}from'react-intl';import {SpordleSelectLi,optionMulti,styledOptionClass,styledGroupClass,OptionCheckBox,OptionContent}from'../styles/stitchesOptions.js';const Option = ({ data, index, style, optionClick, displayedData, selectOption, unselectOption, reziseObserver, spordleSelect, previousSelectedOptions, isFirst, isLast, isActive, setActiveOption, isMobile, }) => {
    const { getDataIndexValue, generateId } = useSpordleTable();
    const intl = useIntl();
    const htmlElement = useRef(null);
    const isOption = !data.isGroup; // Option is considered a group when it doesn't have a value
    const optionLabel = data.translateLabel ? intl.formatMessage({ id: data.label }) : data.label;
    useEffect(() => {
        if (htmlElement.current)
            reziseObserver?.observe(htmlElement.current);
        return () => {
            if (htmlElement.current)
                reziseObserver?.unobserve(htmlElement.current);
        };
    }, []);
    const isSelectAllOption = data.value === SpordleSelect.SELECT_ALL_VALUE;
    const selectedOptionsSet = useRef(isSelectAllOption ? new Set(spordleSelect.state.selectedOptions) : null);
    useEffect(() => {
        if (isSelectAllOption) {
            selectedOptionsSet.current = new Set(spordleSelect.state.selectedOptions);
        }
    }, [spordleSelect.state.selectedOptions]);
    const getAdditionalValues = useCallback(() => {
        const isSelected = spordleSelect.state.selectedOptions.includes(data.value);
        const wasSelected = previousSelectedOptions.has(data.value);
        return { isSelected, wasSelected };
    }, [data.value, spordleSelect.state.selectedOptions, previousSelectedOptions]);
    const getIndex = useCallback(getDataIndexValue, [data.value]); // Caches function's result
    const dataIndex = isOption ? getIndex(data) : index.toString();
    const isDisabled = useCallback(() => {
        if (!isOption || spordleSelect.props.disabled) { // Disabled all options when the select is disabled
            return true; // true to mark group OPTION as disabled for SR
        }
        if (isSelectAllOption) {
            return spordleSelect.props.isOptionDisabled ? displayedData.every((dData) => {
                if (dData.value === SpordleSelect.SELECT_ALL_VALUE) {
                    return true;
                }
                // @ts-ignore
                return spordleSelect.props.isOptionDisabled(dData);
            }) : false;
        }
        return spordleSelect.props.isOptionDisabled?.(data) ?? data.isDisabled ?? false;
    }, [data, displayedData])();
    const calculatedOptionStates = getAdditionalValues();
    const builtOptionStates = {
        ...calculatedOptionStates,
        isDisabled,
        // @ts-ignore
        option: data, // typescript error can be safely ignored since this data is used only when isOption === true
    };
    const getAllSelectedStatus = useCallback(() => {
        let allChecked = true;
        let hasOneSelected = false;
        // TODO: This is to improve! It causes some lag with large data sets
        for (let index = 0; index < displayedData.length; index++) {
            const element = displayedData[index];
            if (element.isGroup || element.value === SpordleSelect.SELECT_ALL_VALUE || (spordleSelect.props.isOptionDisabled?.(element) ?? false) || element.isDisabled) {
                continue; // Skips the select all option AND disabled options AND groups
            }
            if (selectedOptionsSet.current.has(element.value)) {
                hasOneSelected = true;
            }
            else {
                allChecked = false;
            }
            if (!allChecked && hasOneSelected) {
                return 'PARTIAL';
            }
        }
        return allChecked;
    }, [displayedData]);
    const selectAll = () => {
        if (getAllSelectedStatus() != true) {
            selectOption(displayedData);
        }
        else {
            const selectedOptionsSet = new Set(spordleSelect.state.selectedOptions);
            unselectOption(displayedData.filter((disData) => selectedOptionsSet.has(disData.value)));
        }
    };
    const optionSettings = typeof spordleSelect.props.optionSetting === 'function' ? spordleSelect.props.optionSetting(builtOptionStates) : spordleSelect.props.optionSetting;
    return (jsx(SpordleSelectLi, { "data-testid": `option-li-${dataIndex}`, mode: isMobile ? 'mobile' : 'normal', id: generateId(isOption ? 'option' : 'group', dataIndex), style: style, className: stringBuilder({ 'spordleSelect-li-multi': isSelectAllOption }, isOption && data.groupId && (!spordleSelect.props.multi || !calculatedOptionStates.wasSelected) ? 'spordleSelect-li-group' : isOption ? 'spordleSelect-li-opt' : undefined, 'spordleSelect-li', { 'spordleSelect-li-start-group': !isOption }, isMobile ? 'spordleSelect-li-mobile' : 'spordleSelect-li-normal'), 
        // SR
        role: 'option', "aria-owns": isOption ? undefined : data.options.map((data) => generateId('option', getDataIndexValue(data))).join(' '), "aria-selected": isDisabled ? undefined : calculatedOptionStates.isSelected, "aria-disabled": isDisabled, "aria-setsize": displayedData.length, "aria-posinset": index + 1, children: jsxs("div", { "data-testid": `option-measurer-${dataIndex}`, ...optionSettings, ref: htmlElement, id: generateId(isOption ? 'option' : 'group', dataIndex, 'measurer'), onMouseDown: (e) => {
                e.preventDefault();
            }, onMouseMove: !isMobile && isOption && !isActive ? () => {
                setActiveOption({
                    id: generateId('option', dataIndex, 'measurer'),
                    value: dataIndex,
                    index: index,
                });
            } : undefined, onMouseLeave: !isMobile ? () => {
                setActiveOption({
                    id: '',
                    value: '',
                    index: -1,
                });
            } : undefined, onClick: !isOption || isDisabled ? undefined : isSelectAllOption ? selectAll : optionClick(builtOptionStates), className: stringBuilder(optionSettings?.className, { 'multi-select-all': isSelectAllOption }, { [`multi-option ${optionMulti}`]: spordleSelect.props.multi && isOption }, isOption ? `spordleSelect-option ${styledOptionClass({ mode: isMobile ? "mobile" : "normal", isClickable: true })}` : `spordleSelect-optgroup ${styledGroupClass}`, { 'spordleSelect-group-option': isOption && data.groupId }, { 'spordleSelect-option-first': isFirst, 'spordleSelect-option-last': isLast }, { 'spordleSelect-option-active': isActive }, isMobile ? 'spordleSelect-option-mobile' : 'spordleSelect-option-normal'), "data-disabled": isOption ? isDisabled : undefined, "data-selected": isOption ? calculatedOptionStates.isSelected : undefined, "data-label": isOption ? optionLabel : undefined, "data-name": isOption ? data.value : undefined, "data-index": index, children: [isOption &&
                    spordleSelect.props.multi ?
                    jsxs(OptionCheckBox, { className: stringBuilder('custom-checkbox custom-control spordleSelect-checkbox', isMobile ? 'spordleSelect-checkbox-mobile' : 'spordleSelect-checkbox-normal'), mode: isMobile ? 'mobile' : 'normal', children: [jsx("input", { type: "checkbox", id: generateId('option', isSelectAllOption ? 'selectAll' : dataIndex, 'checkbox'), 
                                // disabled for: some are selected for the select all option
                                disabled: (isSelectAllOption && getAllSelectedStatus() === 'PARTIAL') || isDisabled, className: "custom-control-input", checked: (isSelectAllOption && !!getAllSelectedStatus()) || calculatedOptionStates.isSelected, onChange: function () { }, tabIndex: -1 }), jsx("label", { className: "custom-control-label", htmlFor: generateId('option', isSelectAllOption ? 'selectAll' : dataIndex, 'checkbox') })] })
                    :
                        isMobile && isOption &&
                            jsxs(OptionCheckBox, { className: 'custom-radio custom-control spordleSelect-checkbox spordleSelect-checkbox-mobile', mode: 'mobile', children: [jsx("input", { type: "radio", id: generateId('option', dataIndex, 'checkbox'), disabled: isDisabled, className: "custom-control-input", checked: calculatedOptionStates.isSelected, onChange: function () { }, tabIndex: -1 }), jsx("label", { className: "custom-control-label", htmlFor: generateId('option', dataIndex, 'checkbox') })] }), jsx(OptionContent, { id: generateId(isOption ? 'option' : 'group', dataIndex, 'content'), className: stringBuilder('spordleSelect-option-content', isMobile ? 'spordleSelect-option-content-mobile' : 'spordleSelect-option-content-normal'), mode: isMobile ? 'mobile' : 'normal', children: isSelectAllOption ?
                        getMessage('selectAll.label', intl.locale)
                        :
                            spordleSelect.props.renderOption?.(builtOptionStates, false) || optionLabel })] }) }));
};export{Option as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/