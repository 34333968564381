/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import React from'react';import PropTypes from'prop-types';import {Label}from'reactstrap';import {useField}from'formik';/**
 * @member FormikSwitch
 * @description
 * FormikSwitch will create a checkbox that will work automagically with Fromik(yes it's a typo :))
 * IMPORTANT -> Validation do work but are not displayed here, check FormikGroup to display validation errors
 * @example
 * <FormikSwitch id="exampleCustomCheckbox" name="exampleCustomCheckbox"/>
 * <FormikSwitch id="terms" name="terms"/>
 *
 * @prop {string} name Used to make the FormikSwitch
 * @returns {JSX.Element} Returns a checkbox displayed as a switch that will work with Formik
 *
 * @copyright Spordle
 */
const FormikSwitch = React.forwardRef((props, ref) => {
    const [field] = useField({ ...props, type: 'checkbox' });
    props.id ||= props.name;
    return (jsx("div", { children: jsxs(Label, { for: props.id, className: 'switch', children: [jsx("input", { ...field, ...props, ref: ref, type: 'checkbox' }), jsx("span", { className: "switch-toggler" })] }) }));
});
FormikSwitch.propTypes = {
    name: PropTypes.string.isRequired,
};
FormikSwitch.displayName = 'FormikSwitch';
const FormikSwitch$1 = React.memo(FormikSwitch);export{FormikSwitch$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/