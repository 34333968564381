/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import {createContext,PureComponent}from'react';const FormikElementsGlobalConfigsContext = createContext(null);
FormikElementsGlobalConfigsContext.displayName = 'FormikElementsGlobalConfigsContext';
/**
 * @typedef {object} FormikDateTimeConfig
 * @property {string} [dateFormat] The default date format
 * @description The default config we want for the FormikDateTime instances under this context
 */
/**
 * @typedef {object} FormikPhoneInputConfig
 * @property {string[]} [suggestedCountries] A list of {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 ISO 3166-1 alpha-2} suggested countries as strings. These countries will be displayed at the top of the list.
 * @property {string} [defaultSelectedCountry] A {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 ISO 3166-1 alpha-2}.
 * @description The default config we want for the FormikPhoneInput instances under this context
 */
/**
 * @typedef {object} FormikAddressConfig
 * @property {string} [getProvinces] Function to get the provinces from, used for the manual address
 * @property {string} [getCountries] Function to get the countries from, used for the manual address
 * @property {string} [countryRestrictions] A list of {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 ISO 3166-1 alpha-2} countries we want the field to handle - Will also be the suggested values in the manual country select
 * @property {string} [fields] The fields we want the field to handle - {@link https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult Documentation}
 * @property {string} [types] The GooglePlaces types the field should handle - {@link https://developers.google.com/maps/documentation/javascript/supported_types Documentation}
 * @description The default config we want for the FormikAddress instances under this context
 */
/**
 * @class FormikElementsGlobalConfigs
 * @namespace FormikElementsGlobalConfigs
 * @description
 * The FormikElementsGlobalConfigs provides settings to the formik elements consumming it.<br>
 * It provides a default date format, phone number suggested countries and more.<br>
 * Props in `[]` are optional.
 *
 * @prop {FormikDateTimeConfig} [formikDateTime]
 * @prop {FormikPhoneInputConfig} [formikPhoneInput]
 * @prop {FormikAddressConfig} [formikAddress]
 *
 * @example
 * <FormikElementsGlobalConfigs
 *     formikPhoneInput={{
 *         suggestedCountries: [ "ca", "us" ],
 *         defaultSelectedCountry: "ca",
 *     }}
 *     formikDateTime={{
 *         dateFormat: 'YYYY-MM-DD',
 *     }}
 *     formikAddress={{
 *         getCountries: getCountries,
 *         getProvinces: getProvinces,
 *     }}
 * >
 *     ...
 * <FormikElementsGlobalConfigs />
 * @since 4.3.0
 * @copyright Spordle
*/
class FormikElementsGlobalConfigs extends PureComponent {
    render() {
        return (jsx(FormikElementsGlobalConfigsContext.Provider, { value: {
                ...this.props,
            }, children: this.props.children }));
    }
}
const FormikElementsGlobalConfigs$1 = FormikElementsGlobalConfigs;export{FormikElementsGlobalConfigsContext,FormikElementsGlobalConfigs$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/