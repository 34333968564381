import PropTypes from 'prop-types';

/**
 * @description Simple divider.
 * @param {String} [size] Size of the divider.
 */
const Divider = ({ size, className }) => {
    const mainClass = `divider${size ? ` divider-${size}` : ''}`;

    return <div className={`${mainClass}${className ? ` ${className}` : ''}`} />
}

Divider.propTypes = {
    size: PropTypes.oneOf([ 'sm', 'md', 'lg' ]),
}

export default Divider;