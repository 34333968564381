import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { jsObjectToApi } from '@spordle/helpers';

/** @type {React.Context<Omit<AccountsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const AccountsContext = createContext();
AccountsContext.displayName = 'AccountsContext';

class AccountsContextProvider extends React.Component{

    /**
     * Gets all the Meta Members from a specified Identity
     * @param {string} identityId ID of the Identity to get Meta Members from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetMetaMembers|documentation}
     * @returns {Promise.<Array>}
     */
    getIdentityMetaMembers = (identityId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/meta-members`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_members
                }
                throw response.data.errors[0]
            }, serverError)
    }

    getIdentityMetaMember = (identityId, metaMemberId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_members[0]
                }
                throw response.data.errors[0]
            }, serverError)
    }

    createMetaMember = (identityId, firstName, lastName, birthdate, relation, memberId) => {
        const params = new URLSearchParams();
        params.append('first_name', firstName);
        params.append('last_name', lastName);
        if(relation) params.append('relation', relation);
        if(birthdate) params.append('birthdate', birthdate);
        if(memberId) params.append('member_id', memberId);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_member_id
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Links an Identity to a Meta Member
     * @param {string} identityId ID of the Identity to link to a Meta Member
     * @param {string} metaMemberId ID of the Meta Member to link an Identity to
     * @param {Array} members
     * @returns {Promise}
     */
    linkMemberToMetaMember = (identityId, metaMemberId, members) => {
        const params = new URLSearchParams();
        members.forEach((memberId, index) => {
            params.append(`members[${index}]`, memberId)
        })

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}/members` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            })
    }

    /**
     * Sets an Identity Role as Primary
     * @param {string} identityRoleId ID of the Identity Role to set as Primary
     */
    enablePrimaryRole = (identityRoleId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/accounts/${identityRoleId}/enable-primary-role` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Removes the Primary property of an Identity Role
     * @param {string} identityRoleId ID of the Identity Role to remove Primary property
     */
    disablePrimaryRole = (identityRoleId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/accounts/${identityRoleId}/disable-primary-role` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Creates a new address for an identity
     * @param {string} identityId Identity ID
     * @param {Object} values Object containing a sub-object "address" and "address2"
     * @returns {Promise}
     */
    createIdentityAddress = (identityId, values) => {
        const params = new URLSearchParams();
        params.append('street_number', values.address.streetNumber || '');
        params.append('street', values.address.address || '');
        params.append('city', values.address.city || '');
        params.append('zip_code', values.address.zipCode || '');
        params.append('province_code', values.address.state || '');
        params.append('country_code', values.address.country || 'CA');
        params.append('map_url', values.address.mapsUrl || '');
        params.append('unit_number', values.address2 || '');
        params.append('origin_address', values.address.origin);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/accounts/${identityId}/addresses` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.identity_address_id
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [PATCH] Update an address for an identity
     * @param {string} identityId ID of the identity to change an address for
     * @param {string} identityAddressId ID of the identity address
     * @param {Object} values Values to update the values with - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/f871d0913cfc7812cc1ada61b64389d5|documentation}
     * @returns {Promise}
     */
    updateIdentityAddress = (identityId, identityAddressId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/accounts/${identityId}/addresses/${identityAddressId}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [DELETE] Delete an address for an identity
     * @param {string} identityId ID of the identity to delete an address for
     * @param {string} identityAddressId ID of the identity address
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/5d57fb4e55adc312e0a1f8e52c9e1961|documentation}
     * @returns {Promise}
     */
    deleteIdentityAddress = (identityId, identityAddressId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/accounts/${identityId}/addresses/${identityAddressId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Returns the members with addresses that doesn't match the identity address
     * @param {string} identityId Identity ID
     * @param {string} identityAddressId Identity address ID
     * @param {Array} membersId Member ID
     * @returns {Promise}
     */
    getNonMatchingMembers = (identityId, identityAddressId, membersId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/addresses/${identityAddressId}/members/compare`,
            query: {
                members_id: membersId.join(),
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [POST] Publish the identity address
     * @param {string} identityId Identity ID
     * @param {string} identityAddressId Identity Address ID
     * @param {Object} values Values - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/508434f46eeca8487ec75ccba71e5e3a|documentation}
     * @returns {Promise}
     */
    publishAddress = (identityId, identityAddressId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/accounts/${identityId}/addresses/${identityAddressId}/publish`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Cancel a waiting list item
     * @param {string} identityId Identity ID
     * @param {string} waitingListItemId ID of the waiting list item
     * @param {Object} values Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Waiting%20List%20Items/70c8e7ddd33316035cf6687be9c70ee5|documentation}
     * @returns {Promise}
     */
    cancelWaitingListItem = (identityId, waitingListItemId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.patch(`/accounts/${identityId}/waiting-list-items/${waitingListItemId}/cancel`, params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <AccountsContext.Provider value={{ ...this }}>
                {this.props.children}
            </AccountsContext.Provider>
        )
    }
}

export default AccountsContextProvider;