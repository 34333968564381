/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import {useSpordleTable}from'@spordle/datatables';import {useState}from'react';import {useIntl}from'react-intl';import {usePopper}from'react-popper';import {menuOffset}from'../styles/stitches.js';import {OptionContainer,StyledOption,optionPlaceholder}from'../styles/stitchesOptions.js';import Option from'./Option.js';import {getMessage}from'./utils.js';const MenuList = ({ isLoading, isMobile, loadingLayout, noOptionLayout, optionClick, selectOption, unselectOption, spordleSelect, multi, previousSelectedOptions, activeOptions, setActiveOption, scrollerListRef, selectorRef, }) => {
    const spordleTable = useSpordleTable();
    const intl = useIntl();
    const [listRef, setListRef] = useState(null);
    const { styles, attributes } = usePopper(selectorRef.current, listRef, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, menuOffset],
                },
            },
        ],
    });
    return (jsx(OptionContainer, { id: spordleTable.generateId('options', 'container'), ref: (r) => {
            setListRef(r);
            // @ts-ignore
            scrollerListRef.current = r;
        }, mode: "normal", className: 'spordleSelect-option-container', "data-virtualized": false, onMouseDown: (e) => {
            e.preventDefault();
        }, style: styles.popper, ...attributes.popper, 
        // SR
        role: 'listbox', "aria-label": getMessage('option.menu.label', intl.locale), "aria-multiselectable": multi, children: isLoading ?
            jsx(StyledOption
            // SR
            , { 
                // SR
                role: 'option', "aria-readonly": true, mode: isMobile ? undefined : 'normal', className: `spordleSelect-option ${optionPlaceholder}`, children: loadingLayout || getMessage('loading', intl.locale) })
            : spordleTable.dataToDisplay.length == 0 ?
                jsx(StyledOption
                // SR
                , { 
                    // SR
                    role: 'option', mode: isMobile ? undefined : 'normal', "aria-readonly": true, className: `spordleSelect-option ${optionPlaceholder}`, children: noOptionLayout || getMessage('noOptions', intl.locale) })
                :
                    spordleTable.dataToDisplay.map((data, index) => (jsx(Option, { data: data, optionClick: optionClick, displayedData: spordleTable.dataToDisplay, index: index, selectOption: selectOption, unselectOption: unselectOption, spordleSelect: spordleSelect, previousSelectedOptions: previousSelectedOptions, isFirst: index === 0, isLast: index === spordleTable.dataToDisplay.length - 1, isActive: activeOptions.value === data.value, activeOptions: activeOptions, setActiveOption: setActiveOption }, Array.isArray(data.value) ? index : data.isGroup ? data.groupId : spordleTable.getDataIndexValue(data)))) }));
};
MenuList.displayName = 'MenuList';export{MenuList as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/