/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {useContext,createElement}from'react';import {jsx}from'react/jsx-runtime';import SortableColumn from'./SortableColumn.js';import {SpordleTableContext}from'./SpordleTableProvider.js';import {isNumber}from'./utils.js';import {stringBuilder}from'@spordle/helpers';const StickyHeader = (props) => {
    const spordleTable = useContext(SpordleTableContext);
    let columnBeingDragged;
    return (jsx("thead", { className: stringBuilder(spordleTable.props.headerClassName, { 'd-none': !!spordleTable.props.hideHeader, 'position-sticky bg-white': spordleTable.props.stickyHeader }), style: { top: spordleTable.props.stickyHeader ? '-0.5px' : undefined }, children: jsx("tr", { id: spordleTable.generateId('table', 'header'), children: spordleTable.state.orderedColumns.map((column, rowIndex) => (createElement(SortableColumn, { ...props, key: column.key, "data-column-index": rowIndex, draggable: spordleTable.props.swapColumns && !spordleTable.state.isMobile, onDragStart: (e) => {
                    // @ts-ignore
                    columnBeingDragged = e.target.dataset.columnIndex;
                }, onDragOver: (e) => { e.preventDefault(); }, onDrop: (e) => {
                    e.preventDefault();
                    if (isNumber(columnBeingDragged)) {
                        // @ts-ignore
                        spordleTable.moveColumnTo(columnBeingDragged, e.currentTarget.dataset.columnIndex);
                    }
                }, columnData: {
                    ...column,
                    label: typeof column.label === 'function' ? jsx(column.label, {}) : column.label,
                }, orderingStatus: column.sortable && spordleTable.state.orderingBy.column === column.key ?
                    spordleTable.state.orderingBy.order ?? 'none'
                    :
                        'none' }))) }) }));
};
StickyHeader.displayName = 'StickyHeader';
const StickyHeader$1 = StickyHeader;export{StickyHeader$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/