/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx,jsxs}from'react/jsx-runtime';import {forwardRef}from'react';import FormikSelect from'./FormikSelect.js';import {formatSelectData}from'@spordle/spordle-select';import {useIntl,FormattedMessage}from'react-intl';import {SelectedOptionContainer}from'../styles/stitchesTimezone.js';import i18n from'../src/i18n.js';/**
 * @member FormikTimezone
 * @description
 * FormikTimezone will create SpordleSelect that will work automagically with Fromik(yes it's a typo :)) validations.
 *
 * Will automatically populate the dropdown with all available timezones from `Intl.supportedValuesOf('timeZone')`. Automatically uses a ponyfill for browsers that do not support `Intl.supportedValuesOf('timeZone')`;
 * @example
 * <FormikTimezone name="method_Type" id="method_Type" label="Method type" suggestedTimezones={[ 'America/Montreal' ]} />
 *
 * @prop {string[]} [suggestedTimezones] A list of IANA timezones to be shown as suggested in the dropdown. Note that the user's timezone is always shown as suggested.
 * @prop {Array<"Africa" | "America" | "Antarctica" | "Arctic" | "Asia" | "Atlantic" | "Australia" | "Europe" | "Indian" | "Pacific">} [regions] A list of region to filter. Only timezones within one of te specified regions will be shown. Leave blank to show all timezones.
 *
 * @copyright Spordle
 * @see https://github.com/Spordle/Spordle-Select
 * @extends FormikSelect
 * @since 4.4.0
 */
const FormikTimezone = forwardRef((props, ref) => {
    const { locale } = useIntl();
    const SUGGESTED_GROUP_ID = 'FORMIK_TIMEZONE_SUGGESTED_GROUP_ID';
    return (jsx(FormikSelect, { ...props, ref: ref, defaultData: [], loadingStatus: undefined, loadData: (from) => {
            switch (from) {
                case 'CDM':
                    return new Promise(async (resolve) => {
                        const suggestedTimezones = new Set([Intl.DateTimeFormat().resolvedOptions().timeZone, ...(props.suggestedTimezones || [])]);
                        const tzRegions = new Set(props.regions);
                        // @ts-ignore
                        const supportedValuesOf = Intl.supportedValuesOf || (await import('@formatjs/intl-enumerator')).supportedValuesOf;
                        resolve(formatSelectData(supportedValuesOf('timeZone')
                            .filter((timezone) => tzRegions.size == 0 || tzRegions.has(timezone.split('/')[0]))
                            .map((timezone) => {
                            return {
                                value: timezone,
                                label: timezone,
                            };
                        }), {
                            createGroupWithId(_data, groupId) {
                                return {
                                    label: groupId,
                                };
                            },
                            getGroupId(data) {
                                if (suggestedTimezones.has(data.value))
                                    return SUGGESTED_GROUP_ID;
                                return data.value.split('/')[0];
                            },
                            getLabel(data) {
                                return data.label.substring(data.label.indexOf('/') + 1);
                            },
                            newGroupIndexes: {
                                0: {
                                    groupId: SUGGESTED_GROUP_ID,
                                    label: 'phoneInput.suggested',
                                },
                            },
                        }));
                    });
            }
        }, renderOption: ({ option }, fromSelectedOption) => {
            const timeZoneNames = new Set();
            if (option.isGroup) {
                if (option.groupId === SUGGESTED_GROUP_ID) {
                    return (jsx(FormattedMessage, { id: 'phoneInput.suggested', values: { count: option.options.length }, defaultMessage: i18n.en['phoneInput.suggested'], description: 'Suggestion label' }));
                }
            }
            else {
                if (fromSelectedOption) {
                    try {
                        timeZoneNames.add(new Intl.DateTimeFormat(locale, {
                            timeZone: option.value,
                            timeZoneName: 'long',
                        }).formatToParts().find((part) => part.type === 'timeZoneName')?.value);
                    }
                    catch (error) {
                        if (process.env.NODE_ENV !== 'production')
                            console.error(error);
                    }
                }
                try {
                    const longOffset = new Intl.DateTimeFormat(locale, {
                        timeZone: option.value,
                        timeZoneName: 'longOffset',
                    }).formatToParts().find((part) => part.type === 'timeZoneName')?.value;
                    if (longOffset)
                        timeZoneNames.add(longOffset);
                }
                catch (error) {
                    if (process.env.NODE_ENV !== 'production')
                        console.error(error);
                }
            }
            return (jsxs(SelectedOptionContainer, { children: [fromSelectedOption || option.group?.groupId === SUGGESTED_GROUP_ID ?
                        option.value
                        :
                            option.label, timeZoneNames.size > 0 &&
                        jsxs("small", { children: ["\u00A0(", Array.from(timeZoneNames).join(', '), ")"] })] }));
        } }));
});
const FormikTimezone$1 = FormikTimezone;export{FormikTimezone$1 as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/