import contexts from "./contexts";
import views from "./views";
import global from "./global";
import routes from "./routes";
import components from "./components";

export default{
    ...contexts,
    ...views,
    ...global,
    ...routes,
    ...components,
}