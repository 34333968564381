import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import MainPhoto from "../../../components/visual/imgPill/MainPhoto";
import { AuthContext } from "../../../contexts/AuthContext";
import getAccountUrl from '../../../helpers/getExternalUrl';
import queryString, { stringifyUrl } from 'query-string';
import Skeleton from "../../../components/loading/Skeleton";
import getReferrer, { getMultiSportCode } from "../../../helpers/getReferer";
import { useIntl } from 'react-intl'

const HeaderLogin = () => {
    const auth = useContext(AuthContext);
    const intl = useIntl()

    return (
        auth.accessToken && auth.userData ?
            <div className="navbar-profile">
                <UncontrolledDropdown>
                    <DropdownToggle tag="button" nav className="navbar-profile-btn">
                        <MainPhoto className="d-none d-md-flex" size="sm" />
                        <MainPhoto className="d-flex d-md-none" size="xs" />
                    </DropdownToggle>
                    <DropdownMenu style={{ minWidth: 300 }} right>
                        <div className="d-flex align-items-center border-bottom p-3 mb-2">
                            <MainPhoto size="sm" />
                            <div className="ml-2">
                                <div className="mb-0 h4">{auth.userData?.attributes?.name} {auth.userData?.attributes?.family_name}</div>
                                <p className="mb-0">{auth.userData?.attributes?.email}</p>
                            </div>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item p-0">
                                <a className="navbar-profile-list-item" href={getAccountUrl()} rel='noopener noreferrer'><i className="ti-home" /> <Translate id='header.account' /></a>
                            </li>
                            {auth.identityRoles.length > 0 &&
                                <li className="list-group-item p-0">
                                    <a
                                        className="navbar-profile-list-item"
                                        href={stringifyUrl({
                                            url: getReferrer('HCR', intl.locale, getMultiSportCode()),
                                            query: { accessToken: auth.accessToken },
                                        })}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <i className="mdi mdi-launch mr-1" /> <Translate id='header.spordle.id' />
                                    </a>
                                </li>

                            }
                            <li className="list-group-item p-0">
                                <button type="button" className="navbar-profile-list-item btn btn-link w-100 text-left" onClick={() => auth.signOut().catch(console.error)}><i className="mdi mdi-logout" /> <Translate id='header.signout' /></button>
                            </li>
                        </ul>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>

            :
            <button type="button" className="navbar-login-btn ml-0">
                <span className="font-medium small">
                    {auth.waitingForLogin ?
                        <Skeleton height={35} width={35} circle />
                        :
                        <a
                            href={
                                queryString.stringifyUrl({
                                    url: getAccountUrl(),
                                    query: {
                                        referrer: window.location.protocol + '//' + window.location.host + window.location.pathname,
                                    },
                                })
                            }
                        >
                            <span className="d-md-inline d-none">
                                <Translate id='header.login' />
                            </span>
                            <i className="text-muted pt-1 font-18 fas fa-sign-in-alt d-md-none" />
                        </a>
                    }
                </span>
            </button>

    )
}

export default HeaderLogin;