import { useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * @description Simple accordion using Collapse from reactstrap.
 *
 * @param {Boolean} [isOpenDefault] Specifies if accordion is open by default or not.
 * @param {React.ElementType} [tag] Tag of the accordion's wrapper.
 * @param {String|Node|React.Element} title Title of the accordion. Displayed in a button, therefore you should only use phrasing content.
 *
 * @example
 * <Accordion title={<span><i className='mdi mdi-information-outline'></i> Learn more</span>}>
 *  <p>More lorem ipsum.</p>
 * </Accordion>
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 */
const Accordion = ({ isOpenDefault,
                     tag: Tag,
                     title,
                     className,
                     children,
                     ...props }) => {

    const [ isOpen, setIsOpen ] = useState(isOpenDefault ?? false);
    const classNames = `accordion-container${className ? ` ${className}` : ''}`;

    const toggleCollapse = () => setIsOpen(!isOpen);

    return (
        <Tag className={classNames} {...props}>
            <button onClick={toggleCollapse} type="button" className={`accordion-btn btn${isOpen ? ' is-open' : ''}`}>{title} <i className="mdi mdi-plus" /></button>
            <Collapse isOpen={isOpen}>
                <div className="p-3 border-top bg-light">
                    {children}
                </div>
            </Collapse>
        </Tag>
    )
}

Accordion.defaultProps = {
    tag: 'div',
}

Accordion.propTypes = {
    isOpenDefault: PropTypes.bool,
    tag: PropTypes.elementType,
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.string,
    ]).isRequired,
}

export default Accordion;