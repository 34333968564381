export default{
    "routes.group.overview.name"     : "Overview",
    "routes.group.registration.name" : "Registration",
    "routes.default.name"            : "Home",
    "routes.teams.name"              : "Teams",
    "routes.staff.name"              : "Staff",
    "routes.contact.name"            : "Contact",
    "routes.venues.name"             : "Venues",
    "routes.schedule.name"           : "Schedule",
    "routes.registration.name"       : "Participant",
    "routes.clinics.name"            : "Clinics",
}