/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {groupBy}from'@spordle/helpers';/**
 * @description A function that will help format data to be compatible with the select AND will be able to create groups
 * @param {any[]} data
 * @param {FormatDataConfigs} configs
 * @returns {any[]} A mixin between the original option data and the formatted option needed for the select to work. A mix of {@link GroupType} and {@link OptionType}.
 * @copyright Spordle
 */
function formatSelectData(data, configs) {
    const configObject = {
        isGroup: function () { return configs?.isGroup?.(this) ?? false; },
        getGroupId: function () { return this.groupId || configs?.getGroupId?.(this); },
        getLabel: function () { return configs?.getLabel?.(this) || this.label; },
        getValue: function () { return configs?.getValue?.(this) || this.value; },
        index: -1, // Will be redefined later
    };
    const groups = new Set();
    const newGroups = new Map(Object.keys(configs?.newGroupIndexes || {})
        .map((groupIndex) => {
        const numberKey = parseInt(groupIndex);
        const newGroup = createNewOption({
            ...configs.newGroupIndexes[numberKey],
            ...configObject,
            isGroup: () => true,
        }, configs.newGroupIndexes[numberKey]);
        groups.add(newGroup.groupId);
        return [numberKey, newGroup];
    }));
    const mappedOptions = data.reduce((finalData, option, index) => {
        const newOption = createNewOption({
            ...option,
            ...configObject,
            index: index,
        }, option);
        if (!newOption.isGroup && newOption.groupId && !groups.has(newOption.groupId)) { // When has groupId and the groupId hasn't been created yet
            const newGroupData = configs?.createGroupWithId?.(newOption, newOption.groupId);
            if (newGroupData) {
                groups.add(newOption.groupId);
                // @ts-ignore
                finalData.push({
                    ...newGroupData,
                    groupId: newOption.groupId,
                    isGroup: true,
                });
            }
        }
        finalData.push(newOption);
        return finalData;
    }, []);
    newGroups.forEach((newGroup, newGroupIndex) => {
        mappedOptions.insertAt(newGroupIndex, newGroup);
    });
    return mapGroupOptions(mappedOptions);
}
function createNewOption(data, option) {
    // @ts-ignore
    const finalOption = {
        label: data.getLabel(),
        isGroup: data.isGroup(),
    };
    const groupId = data.getGroupId();
    if (finalOption.isGroup) {
        finalOption.groupId = groupId;
    }
    else {
        finalOption.value = data.getValue();
        if (groupId)
            finalOption.groupId = groupId;
    }
    return { ...option, ...finalOption };
}
function mapGroupOptions(data) {
    const groupedByOptions = groupBy(data, 'groupId');
    const groupedByOptionsArray = Object.keys(groupedByOptions);
    if (groupedByOptionsArray.length == 0) { // If there are NO groupIds -> skip the extra processing below
        return data;
    }
    const sortedGroupedOptions = groupedByOptionsArray.reduce((groups, groupKey) => {
        // @ts-ignore
        groups[groupKey] = groupedByOptions[groupKey].sort((optionA, optionB) => (optionB.isGroup & 1) - (optionA.isGroup & 1));
        return groups;
    }, {});
    return data.reduce((mappedOptions, option) => {
        if (option.groupId) {
            const isValidGroup = verifyGroup(sortedGroupedOptions, option.groupId);
            if (option.isGroup) { // Add all options when isGroup
                if (sortedGroupedOptions[option.groupId].length > 1) { // Prevent adding a group without options
                    const formattedGroupOptions = [
                        {
                            ...sortedGroupedOptions[option.groupId][0],
                            options: sortedGroupedOptions[option.groupId].slice(1),
                        },
                        ...sortedGroupedOptions[option.groupId].slice(1).map((o) => ({ ...o, group: sortedGroupedOptions[option.groupId][0] })),
                    ];
                    mappedOptions.pushArray(formattedGroupOptions);
                }
            }
            else if (!isValidGroup) { // When the group is invalid, simply add it to the list
                delete option.groupId;
                mappedOptions.push(option);
            }
            // All options with a valid group won't be added a second time. They will be added with the option.isGroup
        }
        else {
            mappedOptions.push(option);
        }
        return mappedOptions;
    }, []);
}
function verifyGroup(sortedGroups, groupId) {
    return sortedGroups[groupId].some((o) => o.isGroup);
}
/**
 * @typedef BuildConfigs
 * @property {function} isGroup Determines if it is a new group
 * @property {function} getGroupId Get the option's groupId when the `groupId` property doesn't exist.
 * @property {function} getValue Get the option's value.
 * @property {function} getLabel Get the option's label.
 * @property {number} index The index of the given option within the list of data
 */
/**
 * @typedef DataWithBuildConfig
 * @extends {BuildConfigs}
 * @description This is a mixin between the option's properties and the {@link BuildConfigs}
 */
/**
 * @typedef FormatDataConfigs
 * @property {isGroup} [isGroup] Determines if it is a new group
 * @property {getGroupId} [getGroupId] Get the option's groupId when the `groupId` property doesn't exist.
 * @property {getValue} [getValue] Get the option's value.
 * @property {getLabel} [getLabel] Get the option's label.
 * @property {Object.<number, GroupType>} [newGroupIndexes] The {@link GroupType|groups} itself to insert at the given index
 * @property {createGroupWithId} [createGroupWithId] Function to be called when the groupId doesn't already exists. Allows to create a new group dynamically
 */
/**
 * @callback isGroup
 * @description Determines if it is a new group
 * @param {DataWithBuildConfig} data
 * @returns {boolean}
 */
/**
 * @callback getGroupId
 * @description
 * Get the option's groupId when the `groupId` property doesn't exist.<br>
 * This `groupId` must match a `isGroup = true` with the same groupId to be displayed as a group's option.
 * @param {DataWithBuildConfig} data
 * @returns {string | void}
 */
/**
 * @callback getValue
 * @description Get the option's value.
 * @param {DataWithBuildConfig} data
 * @returns {string | void}
 */
/**
 * @callback getLabel
 * @description Get the option's label.
 * @param {DataWithBuildConfig} data
 * @returns {string | void}
 */
/**
 * @callback createGroupWithId
 * @description
 * Function to be called when the groupId doesn't already exists.<br>
 * Allows to create a new group dynamically.<br>
 * NOTE: The `groupId` will be automagically added to the {@link GroupType}.
 * @param {OptionType} data
 * @param {string} groupId
 * @returns {GroupType|void} When returned a falsy value, the group won't be created. Return a {@link GroupType} to create the group.
 * @example
 * createGroupWithId: (qual, groupId) => ({
 *     i18n: qual.organisation.i18n,
 * })
 */export{formatSelectData as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/