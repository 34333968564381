export default{
    "storeSingle.accordion.rebates" : "Rabais",
    "storeSingle.accordion.terms"   : "Termes & conditions",

    "storeSingle.section.registration.title"             : "Inscription",
    "storeSingle.section.registration.category"          : "Catégorie",
    "storeSingle.section.registration.memberType"        : "Type de membre",
    "storeSingle.section.registration.registrationFee"   : "Frais d'inscription",
    "storeSingle.section.registration.noFee"             : "Aucun frais d'inscription disponible.",
    "storeSingle.section.registration.earlyRegistration" : "Frais d'inscription hâtifs",
    "storeSingle.section.registration.lateRegistration"  : "Frais d'inscription tardifs",

    "storeSingle.section.registration.required.included" : "Articles obligatoires inclus.",
    "storeSingle.section.registration.fee.free"          : "Gratuit",

    "storeSingle.section.items.title"    : "Articles",
    "storeSingle.section.items.itemInfo" : "Info article",
    "storeSingle.section.items.noItem"   : "Aucun article disponible.",

    "storeSingle.btn.back.list" : "Retour à la liste",
    "storeSingle.btn.back.home" : "Retour à l'accueil",
    "storeSingle.btn.seeList"   : "Voir les autres boutiques",
    "storeSingle.btn.register"  : "S'inscrire maintenant",
    "storeSingle.btn.manage"    : "Gérer l'inscription en ligne sur SID",

    "storeSingle.inactive.text" : "Il semble que cette boutique ne soit pas disponible.",

    "storeSingle.header.startDate" : "Date de début",
    "storeSingle.header.endDate"   : "Date de fin",

    "storeSingle.title.details" : "Détails",
}