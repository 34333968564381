export default{
    "postModal.btn.post" : "Post",
    "postModal.btn.edit" : "Edit",
    "postModal.btn.img"  : "Add image",

    "postModal.input.title"    : "Post Title",
    "postModal.input.textarea" : "What's happening ? ...",

    "postModal.title.new"  : "New post",
    "postModal.title.edit" : "Edit post",

    "postModal.words.remaining" : "words remaining of",
}