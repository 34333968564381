export default{
    "schedule.btn.today" : "Today",

    "schedule.filter.title" : "Filter events",

    "schedule.helmet.title"       : "{org} Schedule",
    "schedule.helmet.description" : "Full schedule of all the teams of {org}. Showed with a calendar.",

    "schedule.page.name" : "Schedule",

    "schedule.tabs.week"  : "Week",
    "schedule.tabs.day"   : "Day",
    "schedule.tabs.month" : "Month",
    "schedule.tabs.full"  : "Full",

    "schedule.daily.empty.title" : "No events",
    "schedule.daily.empty.text"  : "We did not find any events to display. Enjoy your day!",
}