export default{
    "misc.en"      : "English",
    "misc.fr"      : "French",
    "misc.english" : "English",
    "misc.french"  : "French",

    "misc.select"                               : "Select",
    "misc.select.placeholder"                   : "Select...",
    "misc.select.suggested"                     : "Suggested",
    "misc.SimpleSearchTable.search.placeholder" : "Search",
    "misc.price"                                : "Price",
    "misc.more"                                 : "More",
    "misc.before"                               : "Before",
    "misc.after"                                : "After",
    "misc.or"                                   : "Or",
    "misc.priceValue"                           : "$ {price}",
    "misc.loading"                              : "Loading...",
    "misc.error"                                : "An error occurred",
    "misc.success"                              : "Success",
    "misc.warning"                              : "Warning",
    "misc.danger"                               : "Error",
    "misc.info"                                 : "Info",
    "misc.yes"                                  : "Yes",
    "misc.no"                                   : "No",
    "misc.ok"                                   : "Ok",
    "misc.noSelection"                          : "No selection possible",
    "misc.noResults"                            : "No result",
    "misc.search"                               : "Search",
    "misc.searchResults"                        : "Search Result(s)",
    "misc.moreFilter"                           : "Show filters",
    "misc.lessFilter"                           : "Hide filters",
    "misc.noTranslation"                        : "No translation available",
    "misc.optional"                             : "Optional",
    "misc.hcr#"                                 : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "Member ID" : "HCR#",
    "misc.retry"                                : "Try again",
    "misc.fatalError.goback"                    : "Return to the platform",

    "misc.googlePlaces.open"                    : "Open in Google Maps",
    "misc.googlePlaces.placeholder"             : "Indicate a location",
    "misc.googlePlaces.manualAddress.toggleOn"  : "Enter address manually",
    "misc.googlePlaces.manualAddress.toggleOff" : "Indicate address with Google helper",

    "misc.previous" : "Previous",
    "misc.next"     : "Next",
    "misc.add"      : "Add",
    "misc.create"   : "Create",
    "misc.remove"   : "Remove",
    "misc.edit"     : "Edit",
    "misc.confirm"  : "Confirm",
    "misc.cancel"   : "Cancel",

    "misc.yo"               : "y/o",
    "misc.yrs"              : "yrs.",
    "misc.gender"           : "Gender Identity",
    "misc.female"           : "Female",
    "misc.male"             : "Male",
    "misc.nonBinary"        : "Non Binary",
    "misc.nonSpecified"     : "Prefer not to say",
    "misc.preferToDescribe" : "Prefer to self-describe",

    "misc.notSupported.title"  : "Browser not supported",
    "misc.notSupported.text.1" : "The version of this browser is not supported by our platform.",
    "misc.notSupported.text.2" : "Please check for updates or try another browser",

    // file upload
    "misc.fileUpload.text.1"  : "Add document",
    "misc.fileUpload.text.2"  : "or drop it here",
    "misc.fileUpload.maxSize" : "Maximum upload file size",

    // Clipboard helper
    "misc.clipboard.success"         : "Copied to clipboard!",
    "misc.clipboard.address.success" : "Address copied!",

    "misc.clipboard.error" : "Could not copy to clipboard",
}