import { FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useState } from 'react';
import {
    Alert,
    Button, Card,
    CardBody, Col,
    Collapse, ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
import { string, object } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
// contexts
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

const RegistrationModalities = (props) => {
    const registrationContext = useContext(RegistrationContext);

    const [ members, setMembers ] = useState(Object.keys(registrationContext.membersInfo).map((memberId) => ({
        member: registrationContext.membersInfo[memberId],
        cartItem: registrationContext.state.currentCart.cartInfo.cart_detail.find((r) => r.item_type === 'REGISTRATION' && r.member.member_id === memberId),
        onlineFee: registrationContext.membersInfo[memberId].registration,
        isOpen: false,
        memberSummary: registrationContext.state.currentCart.cartInfo.cart_summary?.members_amount?.find((memb) => memb.member_id === memberId),
        // we set the initial value to this to avoid making API calls for nothing
        installmentMode: 'FULL_PAY',
    })));

    const [ isLoading, setIsLoading ] = useState(false);

    const getInitialValues = () => {
        const temp = {};
        members.forEach((data) => {
            temp[`installmentSelect_${data.member.members[0].member_id}`] = '';

        })
        return temp;
    }

    const getValidationSchema = () => {
        const temp = {};
        members.forEach((data) => {
            temp[`installmentSelect_${data.member.members[0].member_id}`] = string().when('does_not_matter', {
                is: () => {
                    // not required if total amount is smaller than minimum threshold or if total amount is 0
                    return !(registrationContext.state.currentCart.cartInfo.cart_summary.members_amount.find((m) => m.member_id === data.member.members[0].member_id)?.total_amount < data.onlineFee?.installment_mode?.minimum_threshold ||
                             registrationContext.state.currentCart.cartInfo.cart_summary.members_amount.find((m) => m.member_id === data.member.members[0].member_id)?.total_amount == 0)
                },
                then: string().required(<Translate id='participant.registrationModal.views.registrationModalities.required' />),
            });
        });
        return object().shape(temp);
    }

    const getInstallment = (memberId, installmentId, cartItem, setStatus) => {
        setIsLoading(true);
        const index = members.map((m) => m.member.members[0].member_id).indexOf(memberId);
        new Promise((resolve, reject) => {
            if(installmentId !== 'FULL_PAY'){
                registrationContext.updateCartItemInstallments(cartItem.row_id, installmentId)
                    .then(resolve).catch(reject)
            }else if(installmentId !== members[index].installmentMode){
                registrationContext.deleteCartItemInstallments(cartItem.row_id)
                    .then(resolve).catch(reject)
            }else{
                resolve()
            }
        })
            .then(async() => {
                const cart = await registrationContext.refreshCart();
                members[index].isOpen = installmentId !== 'FULL_PAY';
                members[index].cartItem = cart.cart_detail.find((row) => row.item_type === 'REGISTRATION' && row.member.member_id === memberId);
                members[index].installmentMode = installmentId;
                members[index].memberSummary = cart.cart_summary?.members_amount?.find((memb) => memb.member_id === memberId);
                setMembers(members);
                setIsLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    setIsLoading(false);
                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                }
            })
    }

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={getValidationSchema()}
            onSubmit={() => {
                registrationContext.goToView(registrationContext.views.terms)
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting || isLoading}>
                        <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                            <Translate id='participant.registrationModal.views.registrationModalities.title' />
                        </ModalHeader>
                        <ModalBody className="py-4 bg-light">
                            {members.map(({ member, memberSummary, cartItem, onlineFee, isOpen }) => (
                                <Card className="mb-2" key={member.members[0].member_id}>
                                    <CardBody className="p-3">
                                        <Row>
                                            <Col md='8'>
                                                <div className="h4 font-weight-bold mb-0">{member.members[0].first_name + ' ' + member.members[0].last_name}</div>
                                                <div className=""><Translate id='participant.registrationModal.views.registrationModalities.title' /></div>
                                            </Col>
                                            <Col md='4'>
                                                <FormikSelect
                                                    id={`installmentSelect_${member.members[0].member_id}`} name={`installmentSelect_${member.members[0].member_id}`}
                                                    search={false}
                                                    loadData={(from) => {
                                                        if(from === 'CDM'){
                                                            const modalities = [ { label: 'participant.registrationModal.views.registrationModalities.payInFull', value: 'FULL_PAY' } ]
                                                            if(onlineFee && onlineFee.installment_mode)
                                                                modalities.push({
                                                                    label: "participant.registrationModal.views.registrationModalities.installments",
                                                                    value: onlineFee.installment_mode.installment_mode_id,
                                                                })
                                                            return Promise.resolve(modalities);
                                                        }
                                                    }}
                                                    renderOption={(option) => option.option.value === 'FULL_PAY' ? <Translate id={option.option.label} /> : <Translate id={option.option.label} values={{ number: parseInt(onlineFee.installment_mode.number_of_payment) + 1 }} />}
                                                    loadingStatus='success'
                                                    onOptionSelected={(values) => getInstallment(member.members[0].member_id, values[0], cartItem, formik.setStatus)}
                                                />
                                            </Col>
                                        </Row>
                                        <Collapse isOpen={isOpen}>
                                            <div className="modalities-container border-top border-bottom mt-3">
                                                <div className="w-sm-50">
                                                    {memberSummary?.payments?.map((payment, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <div className={`d-flex${index === 0 ? ' font-weight-bold' : ''}`} key={index}>
                                                            <div>{index !== 0 ? moment(payment.payment_date).format('YYYY-MM-DD') : <Translate id='participant.registrationModal.views.registrationModalities.payNow' />}</div>
                                                            <div className="ml-auto"><CurrencyFormat value={payment.amount / 100} /></div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <hr />
                                                <div className="font-weight-bold d-flex w-sm-50">
                                                    <div><Translate id='participant.registrationModal.views.registrationModalities.total' />: </div>
                                                    <div className="ml-auto">
                                                        {memberSummary?.payments && <CurrencyFormat value={memberSummary.total_amount / 100} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </CardBody>
                                </Card>
                            ))}

                            {formik.status &&
                                <Alert color='danger' toggle={() => formik.setStatus()}>
                                    {formik.status}
                                </Alert>
                            }
                        </ModalBody>
                        <ModalFooter tag='footer' className="bg-light">
                            <Button
                                id='registrationModalitiesPreviousButton'
                                type='button'
                                onClick={() => registrationContext.goToView(registrationContext.views.summary, true)}
                                color="default"
                                outline
                                className="mr-auto"
                            >
                                <Translate id='misc.previous' />
                            </Button>
                            <Button
                                id='registrationModalitiesSubmitButton'
                                type='submit'
                                color="info"
                            >
                                <Translate id='misc.next' />
                            </Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationModalities;