import Translate from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import { useContext, useEffect, useState } from "react";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import CustomModal from "../../../components/modal/CustomModal";
import { I18nContext } from "../../../contexts/I18nContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import { fail } from "@spordle/toasts";

const PromptModal = ({ isOpen, toggle, link }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const [ orgBranchId, setOrgBranchId ] = useState();
    const { organisation_id, organisation_category, getOrganizationBranch } = useContext(OrganizationContext);

    useEffect(() => {
        if(organisation_id){
            if(organisation_category.name === "NSO"){
                setOrgBranchId(null);
            }else if(organisation_category.name === "Member branches"){
                setOrgBranchId(organisation_id);
            }else{
                getOrganizationBranch(organisation_id)
                    .then(({ organisation_id: orgId }) => {
                        setOrgBranchId(orgId);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setOrgBranchId(null);
                        }
                    });
            }
        }
    }, [ organisation_id ]);

    return (
        <CustomModal
            className="pointer-events-auto"
            size="sm"
            isOpen={isOpen}
            onClick={(e) => e.currentTarget == e.target && toggle()}
        >
            <div className="text-right">
                <button type="button" onClick={toggle} className="btn-circle"><i className="mdi mdi-close" /></button>
            </div>
            <div className="text-center">
                {/* <i className="mdi mdi-arrow-right display-1 text-primary"/> */}
                <div className="h1"><Translate id='sidenav.modal.title' /></div>
                <p className="mt-3 mb-4">
                    <Translate id='sidenav.modal.p' /> <br />
                    <strong>{typeof link === "function" ? link(getGenericLocale()) : link}</strong>
                </p>
                <div className="d-flex">
                    <button
                        onClick={toggle}
                        type="button"
                        className="btn btn-default mr-2 w-50"
                    >
                        <Translate id='sidenav.btn.no' />
                    </button>
                    <a
                        target="_blank"
                        className={stringBuilder(`btn btn-primary w-50`, { "disabled": orgBranchId === undefined })}
                        href={typeof link === "function" ? link(getGenericLocale(), orgBranchId) : link}
                        rel='noopener noreferrer'
                    >
                        <Translate id='sidenav.btn.yes' /> <i className="mdi mdi-arrow-right" />
                    </a>
                </div>
            </div>
        </CustomModal>
    )
}

export default PromptModal;