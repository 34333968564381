import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import CustomModal from '../../../../../../components/modal/CustomModal';
import ImgPill from '../../../../../../components/visual/imgPill/ImgPill';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const PostModal = ({ isEdit, isOpen, postData, toggleModal }) => {
    const orgContext = useContext(OrganizationContext);
    const MAX_CHAR = 200;
    const intl = useIntl();
    const [ image, setImage ] = useState(null);
    const [ textInput, setTextInput ] = useState({
        isFocused: false,
        title: '',
        content: '',
        wordsLeft: MAX_CHAR,
    });

    useEffect(() => {
        setTextInput(() => {
            const newContent = isEdit && isOpen && postData.body ? postData.body : '';
            const newTitle = isEdit && isOpen && postData.title ? postData.title : '';

            return {
                title: newTitle,
                content: newContent,
                wordsLeft: MAX_CHAR - newContent.length,
            }
        });
    }, [ isEdit ]);

    const resetImg = () => setImage(null);

    const onContentChange = (e) => {
        setTextInput((prev) => {
            return { ...prev, content: e.target.value, wordsLeft: MAX_CHAR - e.target.value.length }
        });
    };

    const onTitleChange = (e) => {
        setTextInput((prev) => {
            return { ...prev, title: e.target.value }
        })
    }

    const onImageChange = (e) => {
        if(e.target.files && e.target.files[0]){
            const img = e.target.files[0];
            setImage(URL.createObjectURL(img));
        }
    }

    const renderContent = () => {
        const content = textInput.content <= MAX_CHAR ?
            textInput.content
            : (
                <>
                    {textInput.content.slice(0, MAX_CHAR)}<span className="text-danger bg-light">{textInput.content.slice(MAX_CHAR, textInput.content.length)}</span>
                </>
            );

        return content;
    }

    return (
        <CustomModal tag="section" isOpen={isOpen} onClick={(e) => e.target == e.currentTarget && toggleModal()} className="post-publication-modal">
            <header className="post-publication-modal-header">
                <button onClick={toggleModal} type="button" className="btn-circle"><i className="mdi mdi-arrow-left" /></button>
                <h3 className="post-publication-modal-title"><Translate id={isEdit ? 'postModal.title.edit' : 'postModal.title.new'} /></h3>
                <button type="button" className="post-publication-modal-btn btn btn-primary"><Translate id={isEdit ? 'postModal.btn.edit' : 'postModal.btn.post'} /></button>
            </header>
            <div>
                <div className="d-flex align-items-center mb-2">
                    <ImgPill
                        size='sm'
                        className="mr-2"
                        src={orgContext.logo?.full_path}
                        alt={`Logo ${orgContext.organisation_name}`}
                        abbr={orgContext.abbreviation}
                    />
                    <div className="h4 mb-0">
                        <DisplayI18n
                            field='name'
                            defaultValue={orgContext.organisation_name}
                            i18n={orgContext.i18n}
                        />
                    </div>
                </div>
                <div className="mb-2">
                    <input
                        value={textInput.title}
                        onChange={(e) => onTitleChange(e)}
                        className="form-control bg-light"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'postModal.input.title' })}
                    />
                </div>
                <div className={`post-publication-textarea-container${textInput.isFocused ? ` is-focused` : ''}`}>
                    <textarea
                        value={textInput.content}
                        className="post-publication-textarea"
                        onChange={(e) => onContentChange(e)}
                        placeholder={intl.formatMessage({ id: 'postModal.input.textarea' })}
                    />
                    <div className="post-publication-textarea-overlay">
                        {renderContent()}
                    </div>
                    { image &&
                        <div className="post-publication-img-container">
                            <button className="post-publication-img-delete badge btn-dark" type="button" onClick={resetImg}><i className="mdi mdi-close" /></button>
                            <img className="post-publication-img" src={image} alt="" />
                        </div>
                    }
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="position-relative">
                        <button type="button" className="btn btn-default">
                            <i className="mdi-image mdi" /> <Translate id={'postModal.btn.img'} />
                        </button>
                        <input className="post-publication-img-input" onChange={(e) => onImageChange(e)} type="file" />
                    </div>
                    <small>
                        <span className={textInput.wordsLeft <= 50 ? (textInput.wordsLeft <= 10 ? 'text-danger' : 'text-warning') : (textInput.wordsLeft < 150 ? 'text-muted' : '')}>
                            {textInput.wordsLeft}
                        </span> <Translate id='postModal.words.remaining' /> <b>{MAX_CHAR}</b>
                    </small>
                </div>
            </div>
        </CustomModal>
    );
}

export default PostModal;