import { useContext, useRef } from 'react';

import {
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
    Row,
    Col,
    ModalFooter,
    Button,
    Collapse,
    Alert
} from "reactstrap";

import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import Translate from '@spordle/intl-elements';

import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import RegistrationParticipantCard from './RegistrationParticipantCard';
import { RegistrationContext } from '../../../../../../../contexts/RegistrationContext';
import { FormikSelect } from '@spordle/formik-elements';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import Required from '../../../../../../../components/formik/Required';
import { PositionsContext } from '../../../../../../../contexts/PositionsContext';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';

const RegistrationPosition = (props) => {
    const registrationContext = useContext(RegistrationContext);
    const positionsContext = useContext(PositionsContext);

    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/components/SpordleSelect').default>} */
    const positionSelectRef = useRef();

    return (
        <Formik
            initialValues={{
                positionGroupId: '',
                positionId: '',
            }}
            validationSchema={object().shape({
                positionGroupId: string().required(<Translate id='participant.registrationModal.views.registrationPosition.positionGroup.required' />),
                positionId: string(),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                setStatus();
                registrationContext.updateCartPosition(values)
                    .then(() => {
                        setSubmitting(false);
                        registrationContext.goToView(registrationContext.views.items)
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="bg-dark text-light">
                            <Translate id='participant.registrationModal.views.registrationPosition.header' />
                        </ModalHeader>
                        <ModalBody className="py-4 bg-light">
                            <div className="registration-container">
                                <RegistrationParticipantCard participant={registrationContext.getCurrentMember()} />

                                <p className='font-weight-bold mb-3'>
                                    <Translate id='participant.registrationModal.views.registrationPosition.text' />
                                </p>
                                <p>
                                    <Translate id='participant.registrationModal.views.registrationPosition.text2' />
                                </p>

                                <Row form>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='positionGroupId'><Translate id='participant.registrationModal.views.registrationPosition.positionGroup' /> <Required /></Label>
                                            <FormikSelect
                                                id='positionGroupId' name='positionGroupId'
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return positionsContext.getPositionGroups()
                                                                .then((positionGroups) => {
                                                                    return positionGroups.reduce((newArray, positionGroup) => {
                                                                        if(positionGroup.active == 1){
                                                                            newArray.push({
                                                                                value: positionGroup.position_group_id,
                                                                                label: positionGroup.name,
                                                                                i18n: positionGroup.i18n,
                                                                            })
                                                                        }
                                                                        return newArray
                                                                    }, [])
                                                                })
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                onOptionSelected={(values) => {
                                                    positionSelectRef.current?.getSpordleTable().filterChange('positionGroupId', values[0])
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='positionId'><Translate id='participant.registrationModal.views.registrationPosition.position' /></Label>
                                            <FormikSelect
                                                id='positionId' name='positionId'
                                                ref={positionSelectRef}
                                                disabled={!formik.values.positionGroupId}
                                                initFilter={{
                                                    positionGroupId: '',
                                                }}
                                                loadData={(from, extra, spordleTable) => {
                                                    switch (from){
                                                        case 'FILTER':
                                                            spordleTable.setLoading();
                                                        case 'CDM':
                                                            return positionsContext.getPositions()
                                                                .then((positions) => {
                                                                    return positions.reduce((newArray, position) => {
                                                                        if(position.active == 1 && (!extra.filters.positionGroupId || extra.filters.positionGroupId === position.position_group.position_group_id)){
                                                                            newArray.push({
                                                                                value: position.position_id,
                                                                                label: position.name,
                                                                                i18n: position.i18n,
                                                                            })
                                                                        }
                                                                        return newArray
                                                                    }, [])
                                                                })
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Collapse isOpen={!!formik.status}>
                                    <Alert color='danger' className='mt-3 mb-3'>{formik.status}</Alert>
                                </Collapse>
                            </div>
                        </ModalBody>
                        <ModalFooter tag={'footer'} className="bg-light">
                            <Button id='registrationPositionPreviousButton' onClick={() => registrationContext.goToView(registrationContext.views.questions, true)} color="default" outline className="mr-auto"><Translate id='misc.previous' /></Button>
                            <Button id='registrationPositionSubmitButton' type='submit' color="info"><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationPosition;