import { addMethod, string, mixed } from 'yup';
import API_SPORDLE from '../api/API-Spordle';
import queryString from 'query-string';
import { debounce } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { isMoment } from 'moment';

const cachedZipCodes = {};

const debounceZipCodeValidation = debounce((zipCode, resolve) => {
    API_SPORDLE.get(queryString.stringifyUrl({
        url: '/utils/postal-codes',
        query: {
            postal_code: zipCode,
        },
    }))
        .then((response) => {
            if(response.data.status){
                cachedZipCodes[zipCode] = !!response.data.postal_code;
                resolve(!!response.data.postal_code)
            }
            resolve(false);
        }, () => {
            resolve(false);
        })
}, 200)

addMethod(string, 'zipCode', function(message = <Translate id='form.validation.zip.valid' />){
    return this.transform((zip) => zip.toUpperCase().replace(/\s/, ''))
        .test({
            name: 'zip-validation',
            message: message,
            test: (zipCode) => {
                if(!zipCode || typeof zipCode !== 'string'){
                    return false;
                }

                if(zipCode.length < 3){
                    return false;
                }

                if(zipCode in cachedZipCodes){
                    return cachedZipCodes[zipCode];// Returning the cached value when it's set
                }

                return new Promise((resolve) => {
                    debounceZipCodeValidation(zipCode, resolve)
                });
            },
        })
})

addMethod(mixed, 'isDate', function(message = <Translate id='form.validation.date.format' />){
    return this.test({
        name: 'date-validation-format',
        message: message,
        test: function(date){
            if(date){
                return isMoment(date);
            }
            return true;
        },
    })
});