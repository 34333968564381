export default{
    "default.page.name" : "Home",

    "default.helmet.title"       : "{org} Registration Page",
    "default.helmet.description" : "{org} Page's landing page with all recent news.",

    "default.page.welcome.title" : "Welcome",
    "default.page.welcome.text"  : "to {org}'s official Spordle Page.",

    "default.page.register.btn"         : "Register Now",
    "default.page.register.text.branch" : "Register on your local MHA",
    "default.page.register.text.mha"    : "Get started and register now!",

    "default.page.post.ourGame.title" : "Our Game Is Back",
}