import Translate from '@spordle/intl-elements';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import GameEn from '../../../assets/images/placeholders/game_is_back_en.jpg';
import GameFr from '../../../assets/images/placeholders/game_is_back_fr.jpg';
import { I18nContext } from '../../../contexts/I18nContext';
import { OnlineStoreContext } from '../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { isSidMultiSport } from '../../../helpers/getReferer';
import { DisplayI18n, displayI18n } from '../../../helpers/i18nHelper';
import SearchOrg from '../../../layouts/layout-components/header/SearchOrg';
import PostSingle from './Posts/components/PostSingle';

const Default = () => {
    const orgContext = useContext(OrganizationContext);
    const { getGenericLocale, locale } = useContext(I18nContext);
    const { state } = useContext(OnlineStoreContext);
    const intl = useIntl();

    useEffect(() => {
        document.title = intl.formatMessage({ id: 'default.helmet.title' }, { org: displayI18n('name', orgContext?.i18n, orgContext?.organisation_name, getGenericLocale()) })
    }, [ intl.locale ])

    return (
        <>
            <section style={{ overflow: "visible" }} className="section-container">
                <header>
                    <h1 className="display-4 mb-0 font-weight-bold">
                        <Translate id='default.page.welcome.title' />
                    </h1>
                    <p className="font-weight-normal">
                        <Translate id='default.page.welcome.text' values={{ org: <DisplayI18n field="name" defaultValue={orgContext?.organisation_name} i18n={orgContext?.i18n} /> }} />
                    </p>
                </header>
                {orgContext?.organisation_category?.name === "MHA" ?
                    state.cachedStore.stores?.length > 0 ?
                        <Link className="mt-3 btn btn-primary d-block" to={(location) => `${location.pathname}/participant`} replace>
                            <Translate id='default.page.register.btn' />
                        </Link>
                        :
                        orgContext.website &&
                            <a className="mt-3 btn btn-primary d-block" rel="noopener noreferrer" target="_blank" href={orgContext.website}>
                                <Translate id='default.page.register.btn' /> <i className="mdi mdi-launch" />
                            </a>
                    :
                    <div className="mt-3">
                        <SearchOrg organisationId={orgContext?.organisation_id} placeholder='default.page.register.text.branch' id="welcomeSearch" pagination={5} className="px-0" />
                    </div>
                }
            </section>
            {!isSidMultiSport() &&
                <section className="section-container">
                    <header>
                        <h2 className="section-main-title">Publications</h2>
                    </header>
                    <PostSingle
                        isHighlighted
                        isExternal
                        postData={{
                            author: orgContext?.federation,
                            created_date: '2021-08-20',
                            link: "https://hockeycanada.ca/" + locale + "/exclusive/return-to-hockey",
                            title: <Translate id='default.page.post.ourGame.title' />,
                            img: {
                                src: locale === "fr-ca" ? GameFr : GameEn,
                                alt: intl.formatMessage({ id: 'default.page.post.ourGame.title' }),
                            },
                        }}
                    />
                </section>
            }
            {/* <section className="section-container">
                <header className="d-flex align-items-center mb-3">
                    { orgContext.logo &&
                        <div className="max-w-md-25 mr-3"><img src={orgContext.logo?.full_path} alt={`Logo ${orgContext.organisation_name}`}/></div>
                    }
                    <div className="flex-fill">
                        <h2 className="register-block-title">
                            <Translate id='default.page.register.text' />
                        </h2>
                    </div>
                </header>
                <footer className="mt-2 w-100 text-center">
                    <Link className="btn btn-primary d-block" to={location => `${location.pathname}/participant`} replace>
                        <Translate id='default.page.register.btn' />
                    </Link>
                </footer>
            </section> */}
            {/* <Posts /> */}
        </>
    )
};

export default Default;