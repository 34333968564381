import Translate, { DateFormat } from "@spordle/intl-elements";
import { FormatBytes, formatFileName, getIcon, triggerDownload } from "./uploadHelpers";

/**
 * @description Renders a stylized file's info
 * @param {object} props
 * @param {File|object} props.file File to show
 * @param {function} [props.onFileClick] Function called when clicking on file name
 * @param {function} props.handleOnRemove Function called when clicking on remove
 * @param {bool} [props.showRemove=true] Shows or hide remove btn
 * @param {bool} [props.download] If clicking on file triggers download
 * @param {bool} [props.showCreatedAt] Wether or not to show the created date and created by next to the file names
 * @returns {React.ReactNode}
 */
const SingleFile = ({ file, onFileClick, handleOnRemove, showRemove, download, showCreatedAt }) => {

    /**
     * @description Renders the name truncated with a label that says new if it's a newly uploaded file
     * @returns {React.ReactNode}
     */
    const renderName = () => {
        return (
            <>
                {formatFileName(file.name)}
                {file instanceof File ?
                    <small className="ml-1 text-success">
                        <div className="small">
                            <Translate id='components.uploader.fileUpload.newFile' />
                        </div>
                    </small>
                    : showCreatedAt && file.created_by && file.created_at ?
                        <div className='ml-auto pl-2 align-self-center small text-muted text-nowrap'>
                            {file.created_by.name} {file.created_by.family_name}
                            <span className='ml-1'>(<DateFormat value={file.created_at} />)</span>
                        </div>
                        :
                        null}
            </>
        )
    }

    return (
        <div className="d-flex align-items-center">
            <span style={{ minWidth: 0 }} className='files-list-item'>
                <i className={getIcon(file.type) + ' mr-1'} />
                { onFileClick ? // If on file click exists
                    <div style={{ minWidth: 0 }} className="d-flex btn-link clickable w-100" onClick={() => onFileClick(file)}>
                        {renderName()}
                    </div>
                    :
                    download ? // If download is set to true
                        <a
                            style={{ minWidth: 0 }}
                            className="d-flex btn-link w-100"
                            download={file.name}
                            target="_blank"
                            {...file.url_path ? { href: file.url_path, onClick: (e) => triggerDownload(file) } : { href: '!#' }}
                        >
                            {renderName()}
                        </a>
                        : // If there's no onFileClick and download is not set to true
                        <div style={{ minWidth: 0 }} className="d-flex text-dark">{renderName()}</div>
                }
            </span>
            {file.size && // Shows the file size when there's one
                <span className="small flex-shrink-0 mr-3 text-nowrap">
                    <FormatBytes bytes={file.size} />
                </span>
            }
            {showRemove && // If show remove, will show the btn
                <button
                    type="button"
                    onClick={handleOnRemove}
                    className="reset-btn ml-auto mr-1 text-danger mdi mdi-close flex-shrink-0"
                />
            }
        </div>
    )
}

export default SingleFile;