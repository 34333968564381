import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Alert,
    Label,
    FormGroup
} from "reactstrap";
import { object, string } from 'yup';
import { RegistrationContext } from '../../../../../../../../contexts/RegistrationContext';
import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import { AccountsContext } from '../../../../../../../../contexts/AccountsContext';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import { FormikAddress, FormikInputText, getFormikAddressInitialValues } from '@spordle/formik-elements';

const RegistrationNoAddress = (props) => {
    const registrationContext = useContext(RegistrationContext);
    const accountsContext = useContext(AccountsContext);
    const auth = useContext(AuthContext);

    return (
        <Formik
            initialValues={{
                address: getFormikAddressInitialValues(),
                address2: '',
            }}
            validationSchema={object().shape({
                address: object().address(true, {
                    streetNumber: <Translate id='form.validation.streetNumber.required' />,
                    address: <Translate id='form.validation.address.required' />,
                    city: <Translate id='form.validation.city.required' />,
                    zipCode: <Translate id='form.validation.zip.required' />,
                    state: <Translate id='form.validation.province.required' />,
                    country: <Translate id='form.validation.country.required' />,
                }).required(<Translate id='form.validation.address.required' />),
                address2: string(),
            })}
            onSubmit={async(values, { setStatus, setSubmitting }) => {
                if(registrationContext.changeAddress){
                    await accountsContext.deleteIdentityAddress(auth.userData.userName, auth.userData.addresses?.filter((address) => address.active == 1)[0]?.identity_address_id)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false);
                                console.error(error.message);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        })
                }

                await accountsContext.createIdentityAddress(auth.userData.userName, values)
                    .then(async() => {
                        await auth.getAccountAddresses(auth.userData.userName)
                        setSubmitting(false);
                        registrationContext.goToView(registrationContext.views.existingOrNew);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            setSubmitting(false);
                            console.error(error.message);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader wrapTag='header' tag="div" toggle={registrationContext.toggleModal} className="text-white bg-dark">
                            <Translate id='participant.registrationModal.views.noAddress.header' />
                        </ModalHeader>
                        <ModalBody className="py-4 bg-light">
                            <div className='registration-container'>
                                <div className='font-weight-bold mb-2'>
                                    {registrationContext.changeAddress ?
                                        <Translate id='participant.registrationModal.views.noAddress.textChange' />
                                        :
                                        <Translate id='participant.registrationModal.views.noAddress.text' />
                                    }
                                </div>

                                <FormikAddress
                                    id='address' name='address'
                                    required
                                    label='form.fields.address'
                                    allowManualPlace
                                />

                                <FormGroup>
                                    <Label for="address2"><Translate id='form.fields.address' /> 2</Label>
                                    <FormikInputText id='address2' name='address2' helper={<span className="small text-muted"><Translate id='form.fields.address2.placeholder' /></span>} translateHelper={false} autoComplete='new-address2' trim />
                                </FormGroup>

                                {formik.status &&
                                    <div className='mt-3'>
                                        <Alert color='danger' toggle={() => formik.setStatus(null)}>{formik.status}</Alert>
                                    </div>
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter tag='footer' className="bg-light">
                            {registrationContext.changeAddress &&
                                <Button
                                    id='noAddressPreviousButton'
                                    type="button" color="info" outline
                                    className='mr-auto'
                                    onClick={() => {
                                        registrationContext.setChangeAddress(false);
                                        registrationContext.goToView(registrationContext.views.addressReview, true)
                                    }}
                                >
                                    <Translate id='misc.previous' />
                                </Button>
                            }
                            <Button id='noAddressNextButton' type="submit" color="info"><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationNoAddress;