import React, { useEffect, useState, useContext } from 'react';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { Link } from 'react-router-dom';
import RegistrationFeesTable from './RegistrationFeesTable';
import Placeholder from '../../../../../assets/images/placeholders/NoImage_Item.png';
import Table from '../../../../../components/table/Table';
import Accordion from '../../../../../components/accordion/Accordion';
import NewRegistrationModal from './registration_refactor/RegistrationModal';
import { OnlineStoreContext } from '../../../../../contexts/OnlineStoreContext';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import { I18nContext } from '../../../../../contexts/I18nContext';
import Skeleton from '../../../../../components/loading/Skeleton';
import { AuthContext } from '../../../../../contexts/AuthContext';
import queryString from 'query-string';
import getAccountUrl from '../../../../../helpers/getExternalUrl';
import { Button } from 'reactstrap';
import moment from 'moment';
import ImgPill from '../../../../../components/visual/imgPill/ImgPill';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { RegistrationContext } from '../../../../../contexts/RegistrationContext';
import Section from '../../../../../components/visual/structure/Section';
import { fail } from '@spordle/toasts';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import getReferrer from '../../../../../helpers/getReferer';

const StoreSingle = ({ match, location, data, ...props }) => {
    const auth = useContext(AuthContext);
    const orgContext = useContext(OrganizationContext)

    const [ store, setStore ] = useState(location.state?.store || null);
    const [ storeIsAvailable, setStoreAvailability ] = useState(true);
    const [ items, setItems ] = useState(null);
    const [ rebates, setRebates ] = useState(null);
    const storeContext = useContext(OnlineStoreContext);
    const registrationContext = useContext(RegistrationContext);

    useEffect(() => {
        // if(auth.accessToken && location.hash === '#register-confirmation'){
        // }
        // setModalIsOpen(true)
    }, [ auth.accessToken ])

    // Not promise all because we don't want the whole process to cancel
    useEffect(() => {
        if(location.state?.store)
            storeContext.presetCachedStore(location.state?.store);

        // In case it's a shared & direct link
        if(!storeContext.state.cachedStore?.stores){
            storeContext.getOnlineStores({ active: "1", status: "ACTIVE", 'start_date.lte': new Date().toISOString(), 'end_date.gte': new Date().toISOString() })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }

        storeContext.getOnlineStore(match.params.store)
            .then((_store) => {
                setStore(_store);
                return _store;
            })
            .then((_store) => {
                if(_store.status == "ACTIVE"){
                    storeContext.getOnlineStoreItems(match.params.store)
                        .then((items) => {
                            const otherItemsToDisplay = [];

                            if(items?.other_fees)
                                otherItemsToDisplay.push(...items.other_fees)

                            if(items?.registration_fees){
                                items.registration_fees.forEach((rFee) => {
                                    if(rFee.related_items){
                                        rFee.related_items.forEach((relatedItem) => {
                                            if(!otherItemsToDisplay.find((toDisplay) => toDisplay.other_fee_id === relatedItem.other_fee_id)){
                                                // unshift simply because in the Select Items view the related items are displayed at the top, so we do the same here
                                                otherItemsToDisplay.unshift(relatedItem)
                                            }
                                        })
                                    }
                                })
                            }
                            setItems({ ...items, otherItemsToDisplay: otherItemsToDisplay })
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })

                    storeContext.getOnlineStoreRebates(match.params.store)
                        .then(setRebates)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                }
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setStoreAvailability(false);
                }
            });
    }, []);

    const toggleNewModal = () => {
        // registrationContext.currentOnlineStore = storeContext.state.cachedStore;
        registrationContext.setCurrentOnlineStore(storeContext.state.cachedStore);
        registrationContext.setTermsAndConditions(storeContext.state.cachedStore.store.terms_and_conditions)

        const addresses = auth.userData.addresses?.filter((address) => address.active == 1)
        const waitingListNotifications = auth.userData.waitingListNotifications?.filter((item) => item.status === 'WAITING_FOR_CLIENT' && item.organisation.organisation_id === orgContext.organisation_id && item.type === 'REGISTRATION') || []
        // test github
        if(waitingListNotifications && waitingListNotifications.length > 0 && waitingListNotifications[0]){
            const item = waitingListNotifications[0];
            registrationContext.setWaitingListReturnData({
                firstName: item.member.first_name,
                lastName: item.member.last_name,
                memberId: item.member.member_id,
                waitingListItemId: item.waiting_list_item_sequential_number,
                registrationFeeId: item.registration_fee.registration_fee_id,
                registrationName: item.registration_fee.name,
                registrationI18n: JSON.stringify(item.registration_fee.i18n),
            })
            registrationContext.goToView(registrationContext.views.waitingListReturn);
        }else if(addresses && addresses.length > 0 && addresses[0]){
            const address = addresses[0]
            const yearOld = (date) => moment(new Date()).diff(date, 'years') >= 1

            if((address.updated_at && yearOld(address.updated_at)) || yearOld(address.created_at)){
                // address has been created or updated more than a year ago
                registrationContext.goToView(registrationContext.views.addressReview)
            }
        }else{
            // no address
            registrationContext.goToView(registrationContext.views.noAddress)
        }

        registrationContext.toggleModal();
    };

    useEffect(() => {
        if(storeContext.state.cachedStore.rebates &&
           storeContext.state.cachedStore.items &&
           auth.isLoggedIn() &&
           (location.hash === '#register' || location.hash === '#register-confirmation' || location.hash === '#waiting-list-return') &&
           !registrationContext.state.modalIsOpen){
            toggleNewModal();
        }
    }, [ storeContext.state.cachedStore.rebates, storeContext.state.cachedStore.items ])

    const getStoreStatus = () => {
        if(!storeIsAvailable){
            return 'ERROR';
        }
        if(!store || (store?.status == "ACTIVE" && moment().isBetween(store.start_date, store.end_date))){ // Active if store hasnt loaded yet to show skeleton loading. Once store is "GET", we check availability
            return "ACTIVE"
        }
        return "CLOSED"

    }

    const isActive = getStoreStatus() == "ACTIVE";

    return (
        <>
            <Section>
                <header className={isActive ? 'mb-4' : undefined}>
                    { storeContext.state.cachedStore?.stores?.length > 1 && isActive &&
                        <Link className="mb-3 d-block" to={location.pathname.replace(/\/[^/]*$/, '')} id='back-to-stores-list'>
                            <i className="mdi mdi-chevron-left" /> <Translate id='storeSingle.btn.back.list' />
                        </Link>
                    }
                    <div className="section-main-title d-flex">
                        <h1 className='mb-0'>
                            <DisplayI18n
                                field="name"
                                defaultValue={store?.name}
                                i18n={store?.i18n}
                            />
                        </h1>
                        {(auth.identityRoles && auth.identityRoles !== []) &&
                            <div className='ml-auto'>
                                {(auth.identityRoles
                                    ?.some((identityRole) => (identityRole.organisation.organisation_id === store?.organisation?.[0]?.organisation_id && identityRole.active == '1'))
                                    && auth.canDoAction(
                                        [ 'READ', 'EDIT' ],
                                        'page',
                                        'online_store_manage',
                                        false,
                                        auth.identityRoles?.find((identityRole) => (identityRole.organisation.organisation_id === store?.organisation?.[0]?.organisation_id))?.components,
                                    )) &&
                                        <a
                                            id='store-got-to-hcr'
                                            href={`${getReferrer('HCR')}/onlineregistration/profile/${store.online_store_id}/overview`}
                                            target='_blank'
                                            className='w-100 text-dark'
                                            rel="noreferrer"
                                        >
                                            <Translate id='storeSingle.btn.manage' /><i className='mdi mdi-launch ml-1' />
                                        </a>
                                }
                            </div>
                        }
                    </div>
                    {isActive ?
                        <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-end">
                            {store &&
                                <NewRegistrationModal />
                            }
                            <Button
                                id='openRegistrationModalButton' className="mb-md-0 mb-2 mr-2" color='primary'
                                disabled={auth.waitingForLogin || !store || !storeContext.state.cachedStore.rebates || !storeContext.state.cachedStore.items}
                                onClick={auth.isLoggedIn() ? toggleNewModal : () => {
                                    window.location.href = queryString.stringifyUrl({
                                        url: getAccountUrl(),
                                        query: {
                                            referrer: `${window.location.origin}${window.location.pathname}#register`,
                                        },
                                    });
                                }}
                            >
                                <Translate id='storeSingle.btn.register' />
                            </Button>

                            <div className="ml-md-auto mb-md-0 mb-2 text-md-right">
                                { store?.start_date &&
                                    <small className="d-block">
                                        <span className="font-weight-bold"><Translate id='storeSingle.header.startDate' /></span> : <time dateTime={store?.start_date}><DateFormat value={store.start_date} /></time>
                                    </small>
                                }
                                { store?.end_date &&
                                    <small className="d-block">
                                        <span className="font-weight-bold"><Translate id='storeSingle.header.endDate' /></span> : <time dateTime={store?.end_date}><DateFormat value={store.end_date} /></time>
                                    </small>
                                }
                            </div>
                        </div>
                        :
                        <h2 className="display-3 font-weight-bold">Oops !</h2>
                    }
                </header>
                {isActive ?
                    <>
                        <h2><Translate id='storeSingle.section.registration.title' /></h2>
                        { items?.registration_fees ?
                            items?.registration_fees.length > 0 ?
                                <Table className="mb-4" tableClassName="table-expandable">
                                    <RegistrationFeesTable fees={items?.registration_fees} />
                                </Table>
                                :
                                <p className="mb-4"><Translate id='storeSingle.section.registration.noFee' /></p>
                            :
                            <div className="mb-4">
                                <Skeleton height={45} />
                                <Skeleton height={60} />
                            </div>
                        }

                        <h2><Translate id='storeSingle.section.items.title' /></h2>
                        { items?.otherItemsToDisplay ?
                            items?.otherItemsToDisplay.length > 0 ?
                                <Table tableClassName="table-fixed table-striped">
                                    <thead>
                                        <tr>
                                            <th><Translate id='storeSingle.section.items.title' /></th>
                                            <th className="store-item-info-cell"><Translate id='storeSingle.section.items.itemInfo' /></th>
                                            <th className="text-right">
                                                <Translate id='misc.price' />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.otherItemsToDisplay
                                            .filter((item) => item.active == 1)
                                            .sort((item1, item2) => parseInt(item1.display_order || '0') - parseInt(item2.display_order || '0'))
                                            .map((item, index) => (
                                                <tr key={item.other_fee_id}>
                                                    <td className="p-2 align-top">
                                                        <ImgPill
                                                            className="rounded-lg"
                                                            filePos={item.image?.file_position}
                                                            src={item.image?.full_path}
                                                            placeholder={Placeholder}
                                                            alt={item.name}
                                                        />
                                                    </td>
                                                    <td className="align-top">
                                                        <div className="h4 font-weight-bold">
                                                            <DisplayI18n
                                                                field="name"
                                                                i18n={item.fee.i18n}
                                                                defaultValue={item.fee.name}
                                                            />
                                                        </div>
                                                        <span className="text-muted small">
                                                            <DisplayI18n
                                                                field="description"
                                                                i18n={item.fee.i18n}
                                                                defaultValue={item.fee.description}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td className="font-weight-bold align-top text-right">
                                                        {item.fee.amount / 100 > 0 ? <CurrencyFormat value={item.fee.amount / 100} /> : <Translate id='storeSingle.section.registration.fee.free' />}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                                :
                                <p><Translate id='storeSingle.section.items.noItem' /></p>
                            :
                            <>
                                <Skeleton height={45} />
                                <Skeleton height={100} />
                            </>
                        }
                    </>
                    :
                    <>
                        <p><Translate id='storeSingle.inactive.text' /></p>
                        { storeContext.state.cachedStore?.stores?.length > 1 ?
                            <Link className="btn btn-primary btn-sm" to={location.pathname.replace(/\/[^/]*$/, '')}>
                                <Translate id='storeSingle.btn.seeList' />
                            </Link>
                            :
                            <Link className="btn btn-primary btn-sm" to={match.url.replace(/\/participant.*$/, '')}>
                                <Translate id='storeSingle.btn.back.home' />
                            </Link>
                        }
                    </>
                }
            </Section>
            {isActive &&
                <Section>
                    <header>
                        <h2 className="section-main-title"><Translate id='storeSingle.title.details' /></h2>
                    </header>
                    { rebates && rebates.length > 0 &&
                        <Accordion title={<span><i className='mdi mdi-information-outline' /> <Translate id='storeSingle.accordion.rebates' /></span>}>
                            {rebates.map((rebate) => (
                                <h3 className="font-weight-bold" key={rebate.rebate_id}>
                                    <DisplayI18n field='title' i18n={rebate.i18n} defaultValue={rebate.title} />
                                </h3>
                            ))}
                        </Accordion>
                    }
                    { store?.terms_and_conditions?.length > 0 &&
                        <Accordion title={<span><i className='mdi mdi-information-outline' /> <Translate id='storeSingle.accordion.terms' /></span>}>
                            <I18nContext.Consumer>
                                { (i18n) => (
                                    store.terms_and_conditions.map((term, index) => (
                                        <React.Fragment key={term.term_and_condition.term_and_condition_id}>
                                            <h3 className="font-weight-bold"><DisplayI18n field="title" i18n={term.term_and_condition?.i18n} defaultValue={term.term_and_condition?.title} /></h3>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: displayI18n('description', term.term_and_condition?.i18n, term.term_and_condition?.description, i18n.getGenericLocale())?.replace(/href/g, "rel='noopener noreferrer' target='_blank' href"),
                                                }}
                                            />
                                        </React.Fragment>
                                    ))
                                )}
                            </I18nContext.Consumer>
                        </Accordion>
                    }
                </Section>
            }
        </>
    );
}

export default StoreSingle;