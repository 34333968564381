/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {useContext}from'react';import {useIntl}from'react-intl';import {IntlElementsContext}from'./IntlElementsContext.js';/**
 * The hook equivalent to the `Translate` component.
 * @param {TranslateProps} translateProps
 * @example
 * useTranslate({ id: 'settings.label.edit' });
 * useTranslate({ id: `settings.error.${formik.status}` });
 * useTranslate({ id: 'misc.priceValue', values: { price: tournament.price } });
 * useTranslate({ id: 'contexts.identityRolesContext.errors.3090', defaultMessageId: 'misc.error' });
 * useTranslate({ id: 'contexts.identityRolesContext.errors.3090', defaultMessage: 'Yo big' });
 * @see {Translate}
 * @copyright Spordle
 * @since 4.2.0
 */
const useTranslate = (translateProps) => {
    const globalConfigs = useContext(IntlElementsContext);
    const { formatMessage } = useIntl();
    const finalDefaultMessage = translateProps.defaultMessage ??
        (translateProps.defaultMessageId ? formatMessage({ id: translateProps.defaultMessageId }, translateProps.values) : undefined) ??
        globalConfigs?.translate?.defaultMessage ??
        (globalConfigs?.translate?.defaultMessageId ? formatMessage({ id: globalConfigs.translate.defaultMessageId }, translateProps.values) : undefined);
    return formatMessage({
        id: translateProps.id,
        defaultMessage: finalDefaultMessage,
    }, { ...globalConfigs?.translate?.values, ...translateProps.values });
};export{useTranslate as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/