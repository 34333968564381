/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {shouldPolyfill}from'@formatjs/intl-getcanonicallocales/should-polyfill';import {shouldPolyfill as shouldPolyfill$1}from'@formatjs/intl-locale/should-polyfill';import {shouldPolyfill as shouldPolyfill$2}from'@formatjs/intl-listformat/should-polyfill';import {shouldPolyfill as shouldPolyfill$3}from'@formatjs/intl-displaynames/should-polyfill';import {shouldPolyfill as shouldPolyfill$4}from'@formatjs/intl-pluralrules/should-polyfill';import {shouldPolyfill as shouldPolyfill$5}from'@formatjs/intl-numberformat/should-polyfill';import {shouldPolyfill as shouldPolyfill$6}from'@formatjs/intl-datetimeformat/should-polyfill';import {shouldPolyfill as shouldPolyfill$7}from'@formatjs/intl-relativetimeformat/should-polyfill';/**
 * Function that will load Intl polyfills asynchronously with `en` and `fr` locales
 * @return {Promise}
 * @see https://formatjs.io/docs/polyfills
 */
async function getI18nPolyfills() {
    const injectedPolyfills = [];
    if (shouldPolyfill()) { // https://formatjs.io/docs/polyfills/intl-getcanonicallocales
        injectedPolyfills.push('canonicallocales');
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }
    if (shouldPolyfill$1()) { // https://formatjs.io/docs/polyfills/intl-locale
        injectedPolyfills.push('intl-locale');
        await import('@formatjs/intl-locale/polyfill');
    }
    if (shouldPolyfill$2()) { // https://formatjs.io/docs/polyfills/intl-listformat
        injectedPolyfills.push('intl-listformat');
        await import('@formatjs/intl-listformat/polyfill');
        await Promise.all([
            import('@formatjs/intl-listformat/locale-data/en'),
            import('@formatjs/intl-listformat/locale-data/fr'),
        ]);
    }
    if (shouldPolyfill$3()) { // https://formatjs.io/docs/polyfills/intl-displaynames
        injectedPolyfills.push('intl-displaynames');
        await import('@formatjs/intl-displaynames/polyfill');
        await Promise.all([
            import('@formatjs/intl-displaynames/locale-data/en'),
            import('@formatjs/intl-displaynames/locale-data/fr'),
        ]);
    }
    if (shouldPolyfill$4()) { // https://formatjs.io/docs/polyfills/intl-pluralrules
        injectedPolyfills.push('intl-pluralrules');
        await import('@formatjs/intl-pluralrules/polyfill');
        await Promise.all([
            import('@formatjs/intl-pluralrules/locale-data/en'),
            import('@formatjs/intl-pluralrules/locale-data/fr'),
        ]);
    }
    if (shouldPolyfill$5()) { // https://formatjs.io/docs/polyfills/intl-numberformat
        injectedPolyfills.push('intl-numberformat');
        await import('@formatjs/intl-numberformat/polyfill');
        await Promise.all([
            import('@formatjs/intl-numberformat/locale-data/en'),
            import('@formatjs/intl-numberformat/locale-data/fr'),
        ]);
    }
    if (shouldPolyfill$6()) { // https://formatjs.io/docs/polyfills/intl-datetimeformat
        injectedPolyfills.push('intl-datetimeformat');
        await import('@formatjs/intl-datetimeformat/polyfill');
        await Promise.all([
            import('@formatjs/intl-datetimeformat/locale-data/en'),
            import('@formatjs/intl-datetimeformat/locale-data/fr'),
        ]);
    }
    if (shouldPolyfill$7()) { // https://formatjs.io/docs/polyfills/intl-relativetimeformat
        injectedPolyfills.push('intl-relativetimeformat');
        await import('@formatjs/intl-relativetimeformat/polyfill');
        await Promise.all([
            import('@formatjs/intl-relativetimeformat/locale-data/en'),
            import('@formatjs/intl-relativetimeformat/locale-data/fr'),
        ]);
    }
    if (injectedPolyfills.length) { // For logging purposes
        await import('@sentry/react')
            .then((sentry) => {
            sentry.addBreadcrumb({
                type: 'info',
                message: 'Injected i18n polyfills',
                level: sentry.Severity.Log,
                category: 'polyfills',
                data: {
                    polyfills: injectedPolyfills.toString(),
                },
            });
        })
            .catch(() => {
            // @sentry/react is not a package
            // Do nothing -> optional dependency
        });
    }
}export{getI18nPolyfills as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/