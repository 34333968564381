/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import {useSpordleTable}from'@spordle/datatables';import {stringBuilder}from'@spordle/helpers';import {forwardRef,memo,useState,useRef,useEffect,useMemo}from'react';import {useIntl}from'react-intl';import {usePopper}from'react-popper';import {areEqual,VariableSizeList}from'react-window';import ResizeObserver from'resize-observer-polyfill';import {menuOffset,height}from'../styles/stitches.js';import {shownScrollbar}from'../styles/stitchesMobileViewList.js';import {OptionContainer}from'../styles/stitchesOptions.js';import Option from'./Option.js';import {getMessage}from'./utils.js';const DEFAULT_OPTION_SIZE = 30;
const VirtualizedMenu = ({ optionClick, isOpen, selectOption, unselectOption, isMulti, spordleSelect, previousSelectedOptions, activeOptions, setActiveOption, scrollerListRef, virtualizedListRef, selectorRef, isMobile, }) => {
    const intl = useIntl();
    const spordleTable = useSpordleTable();
    const [listRef, setListRef] = useState(null);
    const reziseObserver = useRef(new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            const { height, y, top } = entry.contentRect;
            const { marginTop, marginBottom } = window.getComputedStyle(entry.target);
            const index = entry.target.getAttribute('data-index');
            const calculatedMarginTop = isNaN(parseFloat(marginTop)) ? 0 : parseFloat(marginTop);
            const calculatedMarginBottom = isNaN(parseFloat(marginBottom)) ? 0 : parseFloat(marginBottom);
            const size = height + y + top + calculatedMarginTop + calculatedMarginBottom; // y padding + height + border height + y margins
            if (sizeMap.current[index] !== size && size != 0) {
                // @ts-ignore
                sizeMap.current[index] = size;
            }
        });
        list.current?.resetAfterIndex(0); // Resets react-window items -> Forces to recalculate height of rendered items
    }));
    const { styles } = usePopper(selectorRef.current, listRef, {
        placement: 'bottom-start',
        modifiers: [{
                name: 'offset',
                options: {
                    offset: [0, menuOffset],
                },
            }],
    });
    const list = useRef(null);
    const sizeMap = useRef({});
    const getSize = (index) => sizeMap.current[index] || DEFAULT_OPTION_SIZE;
    useEffect(() => {
        if (!isOpen) { // Reset scroll when closing
            list.current?.scrollTo(0);
        }
    }, [isOpen]);
    useEffect(() => () => reziseObserver.current.disconnect(), []);
    const outerElement = useMemo(() => {
        // eslint-disable-next-line react/display-name
        return forwardRef((props, ref) => jsx(OuterElement, { ...props, ref: ref, isMobile: isMobile }));
    }, []);
    return (jsx(VariableSizeList, { ref: (r) => {
            // @ts-ignore
            list.current = r;
            // @ts-ignore
            virtualizedListRef.current = r;
        }, itemCount: spordleTable.dataToDisplay.length, height: isMobile ? 300 : height, width: '100%', itemSize: getSize, className: isMobile ? stringBuilder('spordleSelect-option-container-mobile', shownScrollbar().toString()) : 'spordleSelect-option-container', outerElementType: outerElement, outerRef: (r) => {
            setListRef(r);
            // @ts-ignore
            scrollerListRef.current = r;
        }, innerElementType: (props) => (jsx("ul", { ...props, "data-virtualized": true, 
            // SR
            role: 'listbox', "aria-label": getMessage('option.menu.label', intl.locale), "aria-multiselectable": isMulti, children: props.children })), initialScrollOffset: 0, style: isMobile ? { position: undefined } : { position: undefined, ...styles.popper }, layout: 'vertical', estimatedItemSize: DEFAULT_OPTION_SIZE, itemData: {
            spordleTable,
            optionClick,
            selectOption,
            unselectOption,
            reziseObserver: reziseObserver.current,
            previousSelectedOptions,
            activeOptions,
            setActiveOption,
            isMobile,
            spordleSelect,
        }, children: Row }));
};
// eslint-disable-next-line react/prop-types
const OuterElement = forwardRef(({ isMobile, ...props }, ref) => (jsx(OptionContainer, { ...props, ref: ref, as: 'div', onMouseDown: (e) => {
        e.preventDefault();
    }, mode: isMobile ? 'mobile' : 'normal', children: props.children })));
/* eslint-disable react/prop-types */
const Row = memo(({ index, style, data }) => {
    delete style.width;
    return (jsx(Option, { data: data.spordleTable.dataToDisplay[index], optionClick: data.optionClick, index: index, style: style, displayedData: data.spordleTable.dataToDisplay, selectOption: data.selectOption, unselectOption: data.unselectOption, reziseObserver: data.reziseObserver, spordleSelect: data.spordleSelect, previousSelectedOptions: data.previousSelectedOptions, isFirst: index === 0, isLast: index === data.spordleTable.dataToDisplay.length - 1, isActive: data.activeOptions.value === data.spordleTable.dataToDisplay[index].value, activeOptions: data.activeOptions, setActiveOption: data.setActiveOption, isMobile: data.isMobile }));
}, areEqual);
/* eslint-enable react/prop-types */
Row.displayName = 'VirtualizedRow';
OuterElement.displayName = 'VirtualizedMenuOuterElement';
VirtualizedMenu.displayName = 'VirtualizedMenu';export{VirtualizedMenu as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/