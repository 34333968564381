export default{
    "schedule.btn.today" : "Aujourd'hui",

    "schedule.filter.title" : "Filtrer les événements",

    "schedule.helmet.title"       : "Horaire de {org}",
    "schedule.helmet.description" : "Horaire complet de toutes les équipes de {org}, présenté sous forme de calendrier.",

    "schedule.page.name" : "Calendrier",

    "schedule.tabs.week"  : "Semaine",
    "schedule.tabs.day"   : "Jour",
    "schedule.tabs.month" : "Mois",
    "schedule.tabs.full"  : "Complet",

    "schedule.daily.empty.title" : "Aucun événement.",
    "schedule.daily.empty.text"  : "Nous n'avons pas trouvé d'événement à afficher. Passez une excellente journée !",
}