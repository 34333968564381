import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { useState } from 'react';
import TableExpandableRow from '../../../../../components/table/TableExpandableRow';
import Divider from '../../../../../components/visual/Divider';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';

const RegistrationFeesRow = ({ fee, ...props }) => {
    const [ expandDetails, setExpandDetails ] = useState(false);
    const toggleExpandDetails = (bool) => setExpandDetails(bool ?? !expandDetails);

    function calcPrice(field){
        let total = parseInt(fee.fee?.[field]) || 0;

        if(fee.affiliation_fees?.length > 0){ // adds all affiliation fee amounts
            fee.affiliation_fees.forEach((_aFee) => {
                total += parseInt(_aFee.fee[field] || _aFee.fee.amount);
            });
        }

        if(fee.related_items?.length > 0){ // adds all mandatory related items amounts
            fee.related_items.forEach((_rItem) => {
                if(_rItem.mandatory === "1") total += parseInt(_rItem.fee?.[field] || _rItem.fee?.amount);
            })
        }

        return total;
    }

    const price = calcPrice('amount');
    const mandatoryItems = fee.related_items?.length > 0 ? fee.related_items.filter((_rI) => _rI.mandatory === "1") : null;

    return (
        <>
            <tr>
                <td><button type='button' className={`btn-circle ${expandDetails ? 'rotated' : ''}`} onClick={() => toggleExpandDetails()}><i className="mdi mdi-chevron-down" /></button></td>
                <td className="text-nowrap">
                    <DisplayI18n
                        field='name'
                        defaultValue={fee.fee.name}
                        i18n={fee.fee.i18n}
                    />
                </td>
                <td>
                    <DisplayI18n
                        field='name'
                        defaultValue={fee.member_type?.name}
                        i18n={fee.member_type?.i18n}
                    />
                </td>
                <td className="font-weight-bold text-right">
                    {price / 100 > 0 ? <CurrencyFormat value={price / 100} /> : <Translate id='storeSingle.section.registration.fee.free' />}
                    {mandatoryItems && <>* <small className="text-nowrap d-block text-muted">*<Translate id='storeSingle.section.registration.required.included' /></small></>}
                </td>
            </tr>
            <TableExpandableRow colSpan="4" isExtended={expandDetails}>
                <div className="h3"><Translate id='storeSingle.section.registration.registrationFee' />: {price / 100 > 0 ? <CurrencyFormat value={price / 100} /> : <Translate id='storeSingle.section.registration.fee.free' />}</div>
                <Divider size="md" className="mb-3" />
                { fee.fee?.early_amount &&
                    <div className="mb-2 w-md-50">
                        <small className="text-muted"><Translate id='misc.before' /> <DateFormat value={fee.fee?.early_amount_until} /></small>
                        <div className="d-flex justify-content-md-between">
                            <div className="h4 mb-0 mr-2"><Translate id='storeSingle.section.registration.earlyRegistration' /></div>
                            <div><CurrencyFormat value={calcPrice('early_amount') / 100} /></div>
                        </div>
                    </div>
                }
                { fee.fee?.late_amount &&
                    <div className="mb-2 w-md-50">
                        <small className="text-muted"><Translate id='misc.after' /> <DateFormat value={fee.fee?.late_amount_after} /></small>
                        <div className="d-flex justify-content-md-between">
                            <div className="h4 mb-0 mr-2"><Translate id='storeSingle.section.registration.lateRegistration' /></div>
                            <div><CurrencyFormat value={calcPrice('late_amount') / 100} /></div>
                        </div>
                    </div>
                }
                { ((fee.fee?.late_amount || fee.fee?.earlyAmount) && mandatoryItems) &&
                    <Divider size="sm" className="my-3" />
                }
                { mandatoryItems &&
                    <div className="mb-2 w-md-50">
                        <small className="text-muted"><Translate id='participant.registrationModal.views.registrationSummary.requiredItems' /></small>
                        <ul className="list-unstyled mb-0">
                            {mandatoryItems.map((_item) => (
                                <li className="d-flex justify-content-md-between" key={'mandatory-' + _item.other_fee_id}>
                                    {/* <img src={} /> */}
                                    <DisplayI18n
                                        field="name"
                                        i18n={_item.fee.i18n}
                                        defaultValue={_item.fee.name}
                                    />
                                    <div className="ml-2"><CurrencyFormat value={parseInt(_item.fee?.amount) / 100} /></div>
                                </li>
                            ))}
                        </ul>
                    </div>
                }
            </TableExpandableRow>
        </>
    );
}

export default RegistrationFeesRow;